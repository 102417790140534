var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview"},[_c('div',{staticClass:"preview__title"},[_vm._v("Reality vs Inspiration")]),_c('div',{staticClass:"preview__list"},[(_vm.clientItems.length)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"item__top"},[_c('div',{staticClass:"item__user"},[_c('div',{staticClass:"image"},[(_vm.getCurrentCustomer.picUrl)?_c('img',{attrs:{"src":_vm.getCurrentCustomer.picUrl,"alt":_vm.getCurrentCustomer.name}}):_c('img',{attrs:{"src":require("@/assets/default-profile-picture.png"),"alt":_vm.getCurrentCustomer.name}})]),_c('a',{staticClass:"name",attrs:{"href":_vm.getCurrentCustomer.ig_page
                ? _vm.getCurrentCustomer.ig_page
                : _vm.getCurrentCustomer.fb_page,"target":"_blank"}},[_vm._v(_vm._s(_vm.getCurrentCustomer.name))])])]),_c('div',{staticClass:"item__list"},_vm._l((_vm.clientItems),function(item,index){return _c('div',{key:index,staticClass:"cell"},[(
              _vm.isImage(
                item.mediaUrlReduced ? item.mediaUrlReduced : item.mediaUrl
              )
            )?_c('img',{attrs:{"src":item.mediaUrlReduced ? item.mediaUrlReduced : item.mediaUrl}}):_c('video',{attrs:{"src":item.mediaUrlReduced ? item.mediaUrlReduced : item.mediaUrl}})])}),0)]):[_c('div',{staticClass:"item"},[_c('div',{staticClass:"placeholder"},[_vm._m(0),_c('div',{staticClass:"text"},[_vm._v(" No "+_vm._s(_vm.socialPlatformType)+" account connected ")])])])],(_vm.inspirationItems.length)?_vm._l((_vm.inspirationItems),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item__top"},[_c('div',{staticClass:"item__user"},[_c('div',{staticClass:"image"},[(index === 0)?_c('img',{attrs:{"src":require("@/assets/img/icons/One.png")}}):_vm._e(),(index === 1)?_c('img',{attrs:{"src":require("@/assets/img/icons/Two.png")}}):_vm._e(),(index === 2)?_c('img',{attrs:{"src":require("@/assets/img/icons/Three.png")}}):_vm._e()]),(item.link)?_c('a',{staticClass:"name",attrs:{"target":"_blank","href":item.link}},[_vm._v(_vm._s(item.link.replace(/\/$/, "").split("/").pop()))]):_vm._e()])]),_c('div',{staticClass:"item__list"},_vm._l((item.items),function(item,index){return _c('div',{key:index,staticClass:"cell"},[(item.items[0] && item.items[0].mediaUrl)?_c('img',{attrs:{"src":item.items[0].externalMediaType === 'VIDEO'
                  ? item.items[0].thumbnailUrl
                  : item.items[0].mediaUrl}}):_vm._e()])}),0)])}):[_vm._m(1),_vm._m(2)]],2),(_vm.isLoading)?_c('Loader'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"icon-instagram-logo-fill-svgrepo-com"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"placeholder"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"icon-instagram-logo-fill-svgrepo-com"})]),_c('div',{staticClass:"text"},[_vm._v("No inspiration IG profile")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"placeholder"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"icon-instagram-logo-fill-svgrepo-com"})]),_c('div',{staticClass:"text"},[_vm._v("No inspiration IG profile")])])])
}]

export { render, staticRenderFns }