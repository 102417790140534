export const SUPPORTED_SOCIAL_PLATFORMS = [
  { id: 1, name: "facebook" },
  { id: 2, name: "instagram" },
  { id: 3, name: "linkedin" },
];
export const SUPPORTED_BRAND_STYLES = [
  { id: 1, name: "Clear" },
  { id: 2, name: "Exciting" },
  { id: 3, name: "Professional" },
  { id: 4, name: "Luxury" },
  { id: 5, name: "Sophisticated" },
  { id: 6, name: "Modest" },
];

export const SUPPORTED_TIME_ZONES = [
  { id: "EST", name: "Eastern (GMT-4)" },
  { id: "CST", name: "Central  (GMT-5)" },
  { id: "MST", name: "Mountain (GMT-6)" },
  { id: "PST", name: "Pacific (GMT-7)" },
  { id: "ALT", name: "Alaska (GMT-8)" },
  { id: "HST", name: "Hawaii (GMT-10)" },
];

export const SUPPORTED_FILTERS = [
  { id: "normal", name: "normal" },
  { id: "bright", name: "bright" },
  { id: "cromatic", name: "cromatic" },
  { id: "black_and_white", name: "black_and_white" },
  { id: "sunny", name: "sunny" },
  { id: "warm", name: "warm" },
  { id: "hightlight", name: "hightlight" },
  { id: "vintage", name: "vintage" },
  { id: "dark", name: "dark" },
  { id: "venice", name: "venice" },
];

export const SEND_BY_ITEMS = [
  { id: 1, name: "sms" },
  { id: 2, name: "whatsapp" },
];

export const PLANS = [
  { id: 1, name: "Basic" },
  { id: 2, name: "Plus" },
  { id: 3, name: "Premium" },
];

export const ACTIVITY_STATE = [
  { id: 1, name: "Trial" },
  { id: 2, name: "Active" },
  { id: 3, name: "Canceled" },
  { id: 4, name: "Testing" },
  { id: 5, name: "Inactive" },
];

export const SUPPORTED_TEMPLATE_TYPES = [
  { id: 1, name: "Other" },
  { id: 2, name: "This or That", imagesCount: 2 },
  { id: 3, name: "New Listing", imagesCount: 1, domains: ["real_estate"] },
  {
    id: 4,
    name: "Price Reduced Listing",
    imagesCount: 1,
    domains: ["real_estate"],
  },
];

export const TASK_STATUS = [
  { name: "Todo" },
  { name: "Done" },
  // { name: "Todo", icon: "mdi-new-box", color: "grey" },
  // { name: "In Progress", icon: "mdi-run", color: "blue" },
  // { name: "Done", icon: "mdi-check", color: "green" },
  // { name: "Canceled", icon: "mdi-cancel", color: "red" },
];

export const POST_AWAY = [
  { id: null, name: "Unknown" },
  { id: 1, name: "Yes" },
  { id: 2, name: "No" },
];

export const EXCLUDED_OWNERS = [
  "wonder_one",
  "ashton",
  "sofia",
  "bohdan",
  "kayley",
  "amanda",
  "alex",
  "oleg",
  "dar",
  "tester",
  "nir",
  "ira",
];

export const MEDIA_MODE_LIST = [
  { value: "", name: "None" },
  { value: "light", name: "Light" },
  { value: "dark", name: "Dark" },
  { value: "color", name: "Color" },
];

export const RESIDENCE = [
  { value: "", name: "None" },
  { value: "city", name: "City" },
  {
    value: "suburb",
    name: "Suburb",
  },
];
