import axios from "axios";
import CalendarPost from "@/models/Post/CalendarPost";
import Post from "@/models/Post";
import qs from "qs";
import Caption from "@/models/Caption";

export default {
  namespaced: true,
  state: {
    editedItemIdBeforeDelete: null,
  },
  mutations: {
    setEditedItemIdBeforeDelete(state, newId) {
      state.editedItemIdBeforeDelete = newId;
    },
  },
  actions: {
    async fetchCalendarData({ commit }, params) {
      try {
        const { data } = await axios.get(`calendar/${params.customer}`, {
          params: {
            customerId: params.customer,
            since: params.since,
            until: params.until,
            updatedSince: params.lastUpdate || undefined,
          },
        });
        return data && data.items
          ? data.items.map((el) => new CalendarPost(el))
          : [];
      } catch (e) {
        throw e;
      }
    },
    async savePost({ commit }, params = {}) {
      try {
        const { data } = await axios.post(`calendar`, params);
        return data.map((el) => new CalendarPost(el));
      } catch (e) {
        throw e;
      }
    },
    async updatePost({ commit }, params = {}) {
      try {
        const { data } = await axios.put(`/calendar/${params.id}`, {
          publishAtStr: params.publishAtStr,
          customerId: params.customerId,
          topicSubId: params.topicSubId,
          mls_link: params.mls_link,
        });
        return data.map((el) => new CalendarPost(el));
      } catch (e) {
        throw e;
      }
    },
    async fetchContentCaption({ commit }, id) {
      try {
        const { data } = await axios.get(`caption?id=${id}`);
        return new Caption(data);
      } catch (e) {
        throw e;
      }
    },
    async getTopicList({ commit }, domain) {
      try {
        const { data } = await axios.get(`topic?domain=${domain}`);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async updateDate({ commit }, params = {}) {
      try {
        const { data } = await axios.put(`/calendar/${params.id}`, {
          publishAtStr: params.publishAtStr,
        });
        return data;
      } catch (e) {
        throw e;
      }
    },
    async updatePostByAnyField({ commit }, params = {}) {
      try {
        const { data } = await axios.put(`/calendar/${params.id}`, {
          ...params,
        });
        return data;
      } catch (e) {
        throw e;
      }
    },
    async updatePostsDate({ commit }, params = {}) {
      try {
        await axios.put(`/smpost`, params);
      } catch (e) {
        throw e;
      }
    },
    async deleteCalendarPost({ commit }, id) {
      try {
        await axios.delete(`calendar/${id}`);
      } catch (e) {
        throw e;
      }
    },
    async getPost({ commit }, id) {
      try {
        const { data } = await axios.get(`smpost/${id}`);
        data.sort((a, b) => a.createAt - b.createAt);
        return data.map((o) => new Post(o));
      } catch (e) {
        throw e;
      }
    },
    async getPostByCalendarID({ commit }, id) {
      try {
        const { data } = await axios.get(`calendar/${id}`);
        return new Post({ ...data, ...data.smpost });
      } catch (e) {
        throw e;
      }
    },
    async getStoryByCalendarID({ commit }, id) {
      try {
        const { data } = await axios.get(`calendar/${id}`);
        return new Post({ ...data, ...data.smpost });
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getEditedItemIdBeforeDelete(state) {
      return state.editedItemIdBeforeDelete;
    },
  },
};
