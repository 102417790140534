<template>
  <div>
    <v-app-bar color="#ffff" dense dark>
      <v-img
        class="mx-2"
        src="@/assets/img/logo_full.svg"
        max-height="120"
        max-width="120"
        contain
      ></v-img>
      <v-toolbar-title></v-toolbar-title>

      <v-spacer></v-spacer>
      <v-row
        class="pointer"
        justify="center"
        @click="logOutFromCustomerPage"
        v-if="getCurrentCustomer"
      >
        <v-col cols="1">
          <v-avatar>
            <img src="@/assets/default-profile-picture.png" />
          </v-avatar>
        </v-col>
        <v-col>
          <h1>{{ getCurrentCustomer.name }}</h1>
        </v-col>
      </v-row>

      <v-avatar>
        <v-container fluid style="height: 300px">
          <v-row justify="center">
            <v-menu bottom min-width="200px" rounded offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-avatar>
                    <img
                      src="@/assets/default-profile-picture.png"
                      alt="John"
                    />
                  </v-avatar>
                </v-btn>
              </template>
              <v-card>
                <v-list-item-content class="justify-center">
                  <div class="mx-auto text-center">
                    <v-avatar color="brown">
                      <span class="white--text text-h5">User name</span>
                    </v-avatar>
                    <h3>User name</h3>
                    <p class="text-caption mt-1">user.email</p>
                    <v-divider class="my-3"></v-divider>
                    <v-btn depressed rounded text> Edit Account </v-btn>
                    <v-divider class="my-3"></v-divider>
                    <v-btn depressed rounded text> Disconnect </v-btn>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </v-row>
        </v-container>
      </v-avatar>
      <v-menu v-model="showMenu" absolute offset-y style="max-width: 600px">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar>
            <img
              :src="getCurrentUser.picture_url"
              v-bind="attrs"
              v-on="on"
              :alt="getCurrentUser.name"
            />
          </v-avatar>
        </template>

        <v-list class="user-menu-actions">
          <v-list-item @click="logOutHandler"
            >Logout {{ username }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import H from "@/utils/helper";
export default {
  name: "Header",
  data: () => ({
    showMenu: false,
  }),
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    username() {
      return H.capitalizeFirstLetter(this.getCurrentUser.name);
    },
  },
  methods: {
    ...mapMutations("clientSelection", ["refreshCurrentCustomer"]),
    ...mapActions("auth", ["logOutUser"]),
    logOutFromCustomerPage() {
      this.$router.push({ name: "client-selection-page" });
    },
    logOutHandler() {
      this.logOutUser();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.user-menu-actions {
  .v-list-item {
    cursor: pointer;
  }
  .v-list-item:hover {
    cursor: pointer;
    background-color: #e3e3e3;
  }
}
</style>
