<template>
  <div class="list" :class="className">
    <Loader v-if="isLoading" />
    <div
      class="list__wrapper"
      id="wrapper__list"
      v-infinite-scroll="loadMoreImages"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
    >
      <template v-for="(item, index) in mediaFiles">
        <div class="item" :key="index">
          <h4 class="item__date">
            {{ postDate(item) }}
          </h4>
          <div
            class="item__content"
            :class="{ active: getMediaLibrarySelectionsId.includes(item.id) }"
          >
            <div
              class="item-info"
              v-if="
                getMediaLibrarySelectionsId.indexOf(item.id) + 1 || item.used
              "
            >
              <span v-if="item.used" class="item__used">Used</span>
              <p
                class="item__amount"
                v-if="getMediaLibrarySelectionsId.indexOf(item.id) + 1"
              >
                {{ getMediaLibrarySelectionsId.indexOf(item.id) + 1 }}
              </p>
            </div>

            <v-menu
              open-on-hover
              absolute
              :position-x="350"
              :position-y="200"
              close-delay="350"
              open-delay="150"
              max-width="700px"
              origin="center center"
              transition="scale-transition"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <img
                    :class="imagesFilter"
                    @click="selectFileItem(item)"
                    :src="getMediaUrl(item)"
                    :id="item.id"
                    @dragend="selectFileItem(item)"
                    v-if="isImage(item.mediaUrl)"
                  />
                  <draggable v-else tag="div" class="item__media">
                    <video
                      class="message-img"
                      :ref="'mediaLibraryLibrary' + index"
                      :poster="
                        getMediaUrl(item) && isImage(getMediaUrl(item))
                          ? getMediaUrl(item)
                          : ''
                      "
                      controls
                      @play="
                        setToActiveVideo({
                          element: $refs['mediaLibraryLibrary' + index][0],
                          refName: 'mediaLibraryLibrary' + index,
                        })
                      "
                      @dragend="selectFileItem(item)"
                      @click="selectFileItem(item)"
                    >
                      <source :src="item.mediaUrl" />
                    </video>
                  </draggable>
                  <span
                    class="tag-btn-wrapper"
                    :class="{
                      active:
                        item.tagDescription ||
                        item.tagClient ||
                        item.tagProduct ||
                        item.externalCaption,
                    }"
                  >
                    <button
                      class="item__tag"
                      :class="{
                        tag:
                          item.tagDescription ||
                          item.tagClient ||
                          item.tagProduct ||
                          item.externalCaption,
                      }"
                      @click="addTag(item)"
                    >
                      <i class="icon-tag_icon"></i>
                    </button>
                    <button
                      class="remove-tag"
                      v-if="
                        item.tagDescription || item.tagClient || item.tagProduct
                      "
                      @click="deleteTag(item)"
                    >
                      <i class="icon-close"></i>
                    </button>
                  </span>
                  <button
                    v-if="item.canGetMoreInformation"
                    class="item__user"
                    @click="selectMediaForMoreMediaHandler(item)"
                  >
                    <i class="icon-user-avatar"></i>
                  </button>
                  <button
                    v-if="isCanHidden"
                    :class="{ hidden: item.hidden }"
                    class="item__hidden"
                    @click="addToHiddenHandler(item)"
                  >
                    <i class="icon-eye"></i>
                  </button>
                  <button
                    class="item__favorite"
                    :class="{ favorite: item.favorite }"
                    @click="addToFavoriteHandler(item)"
                  >
                    <i class="icon-star"></i>
                  </button>
                </span>
              </template>
              <span>
                <v-card class="elevation-4 pa-4" color="#EBDEF0" rounded>
                  <div v-if="isShowMediaInfo">
                    <div v-if="item.mediaUrl">
                      <a :href="item.mediaUrl" target="_blank"
                        ><strong>Media link</strong></a
                      >
                      <CopyButton :data="item.mediaUrl" />
                    </div>
                    <div v-if="item.id">
                      <strong>Media ID: </strong>
                      {{
                        item.id.slice(0, 3).concat(`...${item.id.slice(-4)}`)
                      }}
                      <CopyButton :data="item.id" />
                    </div>
                    <v-divider style="border-width: thin" class="ma-2" />
                  </div>
                  <div v-if="item.externalSocialPlatform">
                    <h3>
                      {{ capitalizeLetter(item.externalSocialPlatform) }}
                    </h3>
                  </div>
                  <div v-if="item.tagDescription">
                    <strong>Description: </strong>
                    <span>
                      {{ item.tagDescription }}
                    </span>
                    <CopyButton :data="item.tagDescription" />
                  </div>
                  <div v-if="item.tagClient">
                    <strong>Client Information: </strong>
                    <span>
                      {{ item.tagClient }}
                    </span>
                    <CopyButton :data="item.tagClient" />
                  </div>
                  <div v-if="item.tagProduct">
                    <strong>Product Information: </strong>
                    <span>
                      {{ item.tagProduct }}
                    </span>
                    <CopyButton :data="item.tagProduct" />
                  </div>
                  <div v-if="item.externalTimestamp">
                    <strong>Date: </strong
                    >{{ dateFormatter(item.externalTimestamp) }}
                  </div>
                  <div v-if="item.externalCaption">
                    <strong>Message: </strong>
                    <span v-html="highlightText(item.externalCaption)">{{
                      item.externalCaption
                    }}</span>
                    <!-- <span><span v-for="(s, i) in parsedMsg" v-bind:class="getClass(i%2)">{{s}}</span></span> -->
                    <CopyButton :data="item.externalCaption" />
                  </div>
                  <div
                    v-if="
                      item.externalLikeCount &
                      (item.externalLikeCount != 'None')
                    "
                  >
                    <strong>Likes: </strong>{{ item.externalLikeCount }}
                  </div>
                  <div
                    v-if="
                      item.externalCommentsCount &
                      (item.externalCommentsCount != 'None')
                    "
                  >
                    <strong>Comments: </strong>{{ item.externalCommentsCount }}
                  </div>
                  <div v-if="item.externalPermalink">
                    <a :href="item.externalPermalink" target="_blank"
                      ><strong>Link to post</strong>(in new tab)</a
                    >
                    <CopyButton :data="item.externalPermalink" />
                  </div>
                  <div v-if="item.externalVideoTitle">
                    <strong>Video Title: </strong>{{ item.externalVideoTitle }}
                  </div>
                  <!-- <div v-if="item.externalId"><strong>Id: </strong>{{ item.externalId }}</div>
                  <div v-if="item.externalIgId"><strong>IgId: </strong>{{ item.externalIgId }}</div> -->
                </v-card>
              </span>
            </v-menu>
          </div>
          <p class="item__sendBy" v-if="item.sendBy">by {{ item.sendBy }}</p>
        </div>
      </template>
    </div>
    <button
      @click="downloadMedia()"
      class="download-media"
      v-if="getMediaLibrarySelectionsId.length"
    >
      <i class="icon-download-media"></i>
    </button>
    <button
      v-if="getMediaLibrarySelectionsId.length"
      class="generate-template"
      :disabled="getMediaLibrarySelectionsId.length > 2"
      @click="showTemplatePopUp(getMediaLibrarySelectionsId.length)"
      :style="{
        backgroundColor:
          getMediaLibrarySelectionsId.length > 2 ? '#ccc !important' : 'orange',
      }"
    >
      <v-icon>mdi-file-image-plus-outline</v-icon>
    </button>
    <GetMoreMediaInformation
      v-if="isShowMoreInformationPopup"
      :currentMediaFile="currentMediaForMoreInformation"
      @close="isShowMoreInformationPopup = false"
      @updateStatus="$emit('updateMediaForMoreInformationStatus', $event)"
    />
    <TagInfo
      v-if="isShowTags"
      :isDeletePopup="isDelete"
      :editTagType="deleteTagType"
      :mediaFile="mediaUrlForTag"
      @deleteTag="deleteCurrentTag"
      @close="isShowTags = false"
      :modalTitle="modalTitle"
      @updateMedia="$emit('tagStatus', $event)"
    />
    <ModalDialog
      :isShow="warningSizeVideo.isShow"
      :title="warningSizeVideo.title"
      :footerButtons="warningSizeVideo.footerButtons"
      @ok="approveVideo()"
      :isCanClose="false"
      :isClickOutside="false"
    >
      <template v-slot:description>
        The aspect ratio of the selected video doesn’t comply with Instagram’s
        requirement
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="templateDialog.isShow"
      :size="900"
      customClass="template-modal"
      @close="
        templateDialog.isShow = false;
        editedTemplate = null;
        isEditTemplate = false;
      "
    >
      <template v-slot:description>
        <Templates
          @closeTemplateModal="
            templateDialog.isShow = false;
            editedTemplate = null;
            isEditTemplate = false;
          "
          v-if="templateDialog.isShow"
          :post="currentPost"
          :isMediaTemplate="isMediaTemplate"
          :editedTemplate="editedTemplate"
        />
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="mediaTemplateDialog.isShow"
      :size="500"
      customClass="template-list"
      @close="mediaTemplateDialog.isShow = false"
    >
      <template v-slot:description>
        <MediaTemplates
          @close="mediaTemplateDialog.isShow = false"
          @mediaTemplate="setTemplateOfMedia($event)"
          :mediaAmount="mediaAmount"
          :getMediaLibrarySelections="getMediaLibrarySelections"
          v-if="mediaTemplateDialog.isShow"
        />
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { WIDGETS_ID } from "@/constants/dasboard";
import Loader from "@/components/global/Loader";
import GetMoreMediaInformation from "@/components/global/GetMoreMediaInformation";
import MyTemplate from "@/components/global/MyTemplate";
import TagInfo from "@/components/global/TagInfo";
import CopyButton from "@/components/global/CopyButton";
import ModalDialog from "@/components/global/ModalDialog";
import H from "@/utils/helper";
import helper from "@/mixins/helper";
import Templates from "@/components/templatesModal/Templates";
import MediaTemplates from "@/components/templatesModal/MediaTemplates";

export default {
  name: "ByClient",
  components: {
    Loader,
    GetMoreMediaInformation,
    TagInfo,
    CopyButton,
    ModalDialog,
    Templates,
    MediaTemplates,
  },
  props: {
    mediaFiles: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
    isCanHidden: {
      type: Boolean,
      default: false,
    },
    isFilterEnabled: {
      type: Boolean,
      default: false,
    },
    isCanChangePostOrStoriesMedia: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    currentPage: 1,
    perPage: 5,
    selectedFilesID: [],
    selectedMediaFiles: [],
    isShowMoreInformationPopup: false,
    currentMediaForMoreInformation: null,
    mediaUrlForTag: null,
    isShowTags: false,
    busy: false,
    isDelete: false,
    deleteTagType: "all",
    warningSizeVideo: {
      isShow: false,
      title: "Please note!",
      footerButtons: [{ name: "OK", emitName: "ok", btnClass: "violet" }],
    },
    isGrayColor: false,
    templateDialog: {
      isShow: false,
    },
    mediaTemplateDialog: {
      isShow: false,
    },
    editedTemplate: null,
    isEditTemplate: false,
    isMediaTemplate: false,
    mediaAmount: null,
  }),
  computed: {
    ...mapGetters("instagramPost", ["getIsStoriesTabActive"]),
    ...mapGetters("dragAndDrop", ["getDropOnTheInstagramWidgetFlag"]),
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("mediaLibraryWidget", [
      "getMediaLibrarySelections",
      "getMediaLibrarySelectionsId",
    ]),
    ...mapGetters("post", ["getCurrentPost"]),
    ...mapGetters("stories", ["getCurrentStories"]),
    currentPost() {
      return this.getIsStoriesTabActive
        ? this.getCurrentStories
        : this.getCurrentPost;
    },
    imagesFilter() {
      return this.isFilterEnabled ? this.getCurrentCustomer.filter : "normal";
    },
    isShowMediaInfo() {
      return process.env.VUE_APP_ENVIRONMENT != "production";
    },
    modalTitle() {
      return this.isDelete && this.deleteTagType === "all"
        ? "Are you sure you want to delete all information about this media file?"
        : this.deleteTagType === "client" || this.deleteTagType === "product"
        ? `Are you sure you want to delete ${
            this.mediaUrlForTag.tagClient && this.deleteTagType === "client"
              ? `'${this.mediaUrlForTag.tagClient}'`
              : ""
          } ${
            this.mediaUrlForTag.tagProduct && this.deleteTagType === "product"
              ? `'${this.mediaUrlForTag.tagProduct}'`
              : ""
          } tag(s)?`
        : this.isDelete && this.deleteTagType === "description"
        ? `Are you sure you want to delete description?`
        : "Add more information";
    },
  },
  mixins: [helper],
  watch: {
    filter: {
      handler() {
        this.getMedia();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("mediaLibraryWidget", ["updateMediaFile", "downloads"]),
    ...mapMutations("mediaLibraryWidget", [
      "addMediaLibrarySelection",
      "removeMediaLibrarySelection",
      "clearMediaLibrarySelections",
    ]),
    ...mapMutations("dragAndDrop", ["setFile", "setDropOnTheInstagramWidget"]),
    ...mapMutations("post", ["updatePostMediaFilesFromMediaLibrary"]),
    ...mapMutations("dashboard", ["setActiveFlagById"]),
    ...mapMutations("stories", ["updateStoriesMediaFilesFromMediaLibrary"]),
    ...mapMutations("post", ["refreshCurrentPost"]),
    ...mapMutations("stories", ["refreshCurrentStories"]),
    ...mapMutations("videoPlayer", ["setToActiveVideo"]),
    loadMoreImages() {
      this.busy = true;
      this.infinityScroll();
    },
    infinityScroll() {
      this.$emit("loadMoreItems");
      this.busy = false;
    },
    selectFileItem(file) {
      if (!this.getMediaLibrarySelectionsId.includes(file.id)) {
        this.addMediaLibrarySelection(file);
        if (!this.isImage(file.mediaUrl)) {
          this.getVideoData(file);
        } else {
          this.updateMedia(file);
        }
      } else {
        this.removeMediaLibrarySelection(file);
        this.updateMedia(file);
      }
    },
    highlightText(inputText) {
      return this.highlightHashtags(this.highlighttags(inputText));
    },
    getVideoData(file) {
      let video = document.createElement("video");
      video.src = file.mediaUrl;
      let self = this;

      video.addEventListener(
        "loadedmetadata",
        function () {
          self.isVideoSizeSuccessForAspectRatio(
            this.videoWidth / this.videoHeight,
            file
          );
        },
        false
      );
      video.remove();
    },
    isVideoSizeSuccessForAspectRatio(aspectRatio, file) {
      let min = this.getIsStoriesTabActive ? 9 / 16 : 4 / 5;
      let max = this.getIsStoriesTabActive ? 1 : 1.91;

      if (aspectRatio >= min && aspectRatio <= max) {
        this.updateMedia(file);
      } else {
        this.warningSizeVideo.isShow = true;
        this.currentVideo = file;
      }
    },
    approveVideo() {
      this.updateMedia(this.currentVideo);
      this.warningSizeVideo.isShow = false;
      this.currentVideo = null;
    },
    updateMedia(file) {
      if (this.isCanChangePostOrStoriesMedia) {
        this.updatePostMediaFilesFromMediaLibrary(file);
        this.updateStoriesMediaFilesFromMediaLibrary(file);
        this.setActiveFlagById(WIDGETS_ID.TEXT_CREATION);
        this.setActiveFlagById(WIDGETS_ID.INSTAGRAM_POST);
        this.setActiveFlagById(WIDGETS_ID.MEDIA_LIBRARY);
      }
    },
    test(value) {
      return value >= 0.8 && value <= 1.91;
    },
    highlightHashtags(inputText) {
      var check = new RegExp("(#+[a-zA-Z0-9(_)]{1,})", "ig");
      return inputText.toString().replace(check, function (matchedText, a, b) {
        return '<span style="color:blue">' + matchedText + "</span>";
      });
    },
    highlighttags(inputText) {
      var check = new RegExp("(@+[a-zA-Z0-9\\.(_)]{1,})", "ig");
      return inputText.toString().replace(check, function (matchedText, a, b) {
        return '<span style="color:purple">' + matchedText + "</span>";
      });
    },
    dateFormatter(date = new Date(), format = "MM/D/YYYY") {
      return this.$dayjs.unix(date).format(format);
    },
    async addToFavoriteHandler(mediaFile) {
      try {
        await this.updateMediaFile({
          id: mediaFile.id,
          favorite: String(!mediaFile.favorite),
          customerId: this.getCurrentCustomer.id,
        });
        this.$emit("updateMediaList", mediaFile);
      } catch (e) {
        throw e;
      }
    },
    async addToHiddenHandler(mediaFile) {
      try {
        await this.updateMediaFile({
          id: mediaFile.id,
          hidden: String(!mediaFile.hidden),
          customerId: this.getCurrentCustomer.id,
        });
        mediaFile.hidden = !mediaFile.hidden;
        this.$emit("changedHiddenFlag", mediaFile);
      } catch (e) {
        throw e;
      }
    },
    getFileName(link) {
      const resultArray = link.split("/");
      if (resultArray.length > 1) {
        return resultArray[resultArray.length - 1];
      } else {
        return "";
      }
    },
    async downloadMedia() {
      try {
        const data = await this.downloads({
          customerId: this.getCurrentCustomer.id,
          imageUrlList: this.getMediaLibrarySelections.map((i) => i.mediaUrl),
        });
        if (data) {
          this.download(data);
          this.clearMediaLibrarySelections();
          this.refreshCurrentStories();
          this.refreshCurrentPost();
        }
      } catch (e) {
        throw e;
      }
    },
    selectMediaForMoreMediaHandler(mediaFile) {
      this.currentMediaForMoreInformation = mediaFile;
      this.isShowMoreInformationPopup = true;
    },
    addTag(mediaFile) {
      this.isShowTags = true;
      this.mediaUrlForTag = mediaFile;
      this.isDelete = false;
    },
    deleteTag(mediaFile) {
      this.deleteTagType = "all";
      this.mediaUrlForTag = mediaFile;
      this.isShowTags = true;
      this.isDelete = true;
    },
    deleteCurrentTag(data) {
      this.deleteTagType = data.flag;
      this.mediaUrlForTag = data.mediaFile;
      this.isShowTags = true;
      this.isDelete = true;
    },
    capitalizeLetter(string) {
      return H.capitalizeFirstLetter(string);
    },
    postDate(postData) {
      let format = "";

      if (
        new Date().getFullYear() ===
        +this.dateFormatter(postData.createdAt, "YYYY")
      ) {
        format = postData.isShowFullDate ? "MM/DD H:MM" : "H:MM";
      } else {
        format = postData.isShowFullDate ? "MM/DD/YY H:MM" : "H:MM";
      }

      return this.dateFormatter(postData.createdAt, format);
    },
    showTemplatePopUp(media) {
      this.mediaTemplateDialog.isShow = true;
      this.mediaAmount = media;
    },
    setTemplateOfMedia(template) {
      this.mediaTemplateDialog.isShow = false;
      this.templateDialog.isShow = true;
      this.isMediaTemplate = true;
      this.editedTemplate = template.template;
      this.getMediaLibrarySelections.forEach((item) => {
        this.clearMedia(item.id);
      });
      this.clearMediaLibrarySelections();
    },
    clearMedia(mediaId) {
      this.getCurrentPost.media = this.getCurrentPost.media.filter(
        (i) => i.id !== mediaId
      );
      this.getCurrentStories.media = this.getCurrentPost.media.filter(
        (i) => i.id !== mediaId
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.list {
  &__wrapper {
    overflow-y: auto;
    height: calc(100vh - 180px);
    grid-template-columns: repeat(auto-fill, minmax(calc(33% - 24px), 1fr));
    overflow-x: hidden;
    display: grid;
    padding: 0 24px;
    grid-gap: 12px;
    align-content: start;
    position: relative;
    @media screen and (max-width: 1440px) {
      grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    }
  }
  .download-media {
    position: absolute;
    bottom: 50px;
    left: 30%;
    transform: translateX(-50%);
    width: 60px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    color: $color-white;
    justify-content: center;
    background: $color-violet;
    border-radius: 16px;
    font-size: 16px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background: darken($color-violet, 10);
    }
  }
  .gray-btn {
    background: gray !important;
  }
  .generate-template {
    position: absolute;
    bottom: 50px;
    left: 70%;
    transform: translateX(-50%);
    width: 60px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    color: $color-white;
    justify-content: center;
    border-radius: 16px;
    font-size: 16px;
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 100;
    min-width: 60px !important;
    i {
      color: #fff;
    }
    &:hover {
      background: darken(orange, 10);
    }
  }
  &.uploads {
    .list {
      &__wrapper {
        height: calc(100vh - 262px);
      }
    }
  }
  .item {
    width: 100%;
    &__date {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: #2f4858;
      margin-bottom: 4px;
    }
    &__content {
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      border: 2px solid transparent;
      box-sizing: border-box;
      height: 150px;
      @media screen and (max-width: 1500px) {
        height: 120px;
      }
      &.active {
        border: 2px solid $color-violet;
      }
      video,
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item__media {
      height: 100%;
    }
    &__favorite {
      position: absolute;
      right: 8px;
      bottom: 8px;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 11px;
      color: $color-grey2;
      background: $color-white;
      transition: all 0.3s ease;
      &.favorite {
        color: $color-violet;
      }
    }
    &__hidden {
      position: absolute;
      right: 8px;
      bottom: 30px;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 16px;
      color: $color-grey2;
      background: $color-white;
      transition: all 0.3s ease;
      &.hidden {
        color: $color-violet;
      }
    }
    .tag-btn-wrapper {
      position: absolute;
      left: 8px;
      bottom: 8px;
      height: 20px;
      .remove-tag {
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-size: 9px;
        color: $color-grey2;
        background: $color-white;
        transition: all 0.3s ease;
      }
      &.active {
        width: 50px;
        &:hover {
          .remove-tag {
            left: 22px;
          }
        }
      }
    }
    &__tag {
      position: absolute;
      z-index: 3;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 11px;
      color: $color-grey2;
      background: $color-white;
      transition: all 0.3s ease;
      &.tag {
        color: $color-red;
      }
    }
    .tag-info {
      position: absolute;
      left: 8px;
      bottom: 8px;
    }
    &__user {
      position: absolute;
      left: 8px;
      bottom: 30px;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 11px;
      color: $color-grey2;
      background: $color-white;
      transition: all 0.3s ease;
      &.tag {
        color: $color-violet;
      }
    }
    &__sendBy {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
      color: $color-black;
      opacity: 0.4;
      margin-bottom: 0;
    }
    .item-info {
      position: absolute;
      right: 8px;
      top: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 10;
    }
    &__amount {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: $color-white;
      background: $color-violet;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
    }
    &__used {
      height: 18px;
      font-weight: 600;
      font-size: 11px;
      line-height: 130%;
      color: #ffffff;
      background: #e69d5b;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      margin-bottom: 10px;
    }
  }
}
</style>
