import BaseClass from "@/models/BaseClass";

const fields = [
  "createdAt",
  "client_tag",
  "id",
  "mediaId",
  "sourceLink",
  "metaCaptionHTML",
  "style",
  "text",
  "usedPostId",
  { field: "captionHTML", default: null },
  { field: "dislike", default: false },
  { field: "like", default: false },
  { field: "used", default: false },
  { field: "isClickedToEditIcon", default: false },
  { field: "seedContent", default: null },
  { field: "seed_ids", default: null },
  { field: "generateCount", default: null },
  { field: "template", default: null },
  { field: "score", default: null },
  { field: "viewedAt", default: 0 },
  { field: "labels", default: [] },
  { field: "tags", default: [] },
  { field: "text_areas", default: {} },
];

export default class Caption extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);

    this.fakeText = obj.text || null;
    this.fakeCaptionHtml = !obj.editedByCustomer ? obj.captionHTML : null;

    this.textBeforeEdit = obj.text || null;
    this.captionHtmlBeforeEdit = !obj.editedByCustomer ? obj.captionHTML : null;
  }
}
