<template>
  <div data-app class="wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "ClientLayout",
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.wrapper {
  width: 100%;
  height: auto;
}
</style>
