<template>
  <div class="modify-template">
    <div class="modify-template__header" :class="{ blur: isLoading }">
      <button class="btn violet" @click="changeTemplate()">
        Change template
      </button>
      <span
        v-if="currentTemplate && currentTemplate.id"
        class="modify-template__template-info"
      >
        {{ currentTemplate.id }} -
        <v-icon @click="openInBannerbear(currentTemplate.id)"
          >mdi-link-variant</v-icon
        >
      </span>
    </div>
    <div class="modify-template__body" :class="{ blur: isLoading }">
      <div class="edit-area">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="edit-area__controls-wrapper">
            <template v-for="(item, index) in inputFields">
              <div
                class="edit-area__control"
                :key="index"
                v-if="item.hidden !== true"
              >
                <div
                  class="edit-area__control-color"
                  v-bind:style="{ backgroundColor: item.textColor }"
                >
                  <div
                    class="colors"
                    v-if="getCurrentCustomer.brand_color_palettes"
                  >
                    <div
                      class="colors__pallet"
                      v-for="(
                        pallet, index
                      ) in getCurrentCustomer.brand_color_palettes"
                      :key="index"
                    >
                      <div v-if="pallet.length" class="colors__pallet-items">
                        <div
                          class="item"
                          v-for="(color, index) in pallet"
                          :key="index"
                          v-bind:style="{ backgroundColor: color }"
                          @click="changeTextColor(color, item)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="edit-area__input-wrapper">
                  <div class="edit-area__input-val">
                    <v-textarea
                      outlined
                      :value="item.hint ? '' : item.value"
                      :label="item.layer"
                      :placeholder="item.hint ? item.value : ''"
                      hide-details="auto"
                      :rules="item.hint ? [rules.required] : []"
                      @input="updateTextValue($event, item)"
                    ></v-textarea>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </v-form>
        <div class="edit-area__controls-wrapper" v-if="backgroundField.length">
          <template v-for="(item, index) in backgroundField">
            <div
              class="edit-area__control"
              v-if="item.hidden !== true"
              :key="index + 'a'"
            >
              <div
                class="edit-area__control-color"
                v-bind:style="{ backgroundColor: item.value }"
              >
                <div
                  class="colors"
                  v-if="getCurrentCustomer.brand_color_palettes"
                >
                  <div
                    class="colors__pallet"
                    v-for="(
                      pallet, index
                    ) in getCurrentCustomer.brand_color_palettes"
                    :key="index"
                  >
                    <div v-if="pallet.length" class="colors__pallet-items">
                      <div
                        class="item"
                        v-for="(color, index) in pallet"
                        :key="index"
                        v-bind:style="{ backgroundColor: color }"
                        @click="changeBgColor(color, item)"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="edit-area__input-wrapper">
                <div class="edit-area__input-val">
                  <span class="name">{{ item.layer }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="edit-area__b-row">
          <div class="edit-area__images">
            <template v-for="(item, index) in editImages">
              <div class="item" :key="index" v-if="item.hidden !== true">
                <div class="item__name">{{ item.layer }}</div>
                <div
                  class="item__img"
                  v-if="!isDropActive && !item.isLoadingImage"
                  @dragover="dragHandler(true)"
                  :id="item.name"
                >
                  <button class="edit file-uploader">
                    <Uploader
                      :componentRef="'uploader_icon_' + index"
                      isUseCustomElementInSlot
                      :isDrop="false"
                      :isMultiple="false"
                      fileFormat="png|jpeg|gif|svg|jpg"
                      className="uploader-custom-icon"
                      @uploadFile="startUploadFileHandler($event, item)"
                      @notValidFormat="notValidFormatHandler"
                    >
                      <template v-slot:uploader-block>
                        <v-icon> mdi-image </v-icon>
                      </template>
                    </Uploader>
                  </button>
                  <button class="edit" @click="changeImage(item.name, item)">
                    <i class="icon-edit"></i>
                  </button>
                  <img v-if="item.value" :src="item.value" alt="" />
                </div>
                <div
                  v-else-if="isDropActive && !item.isLoadingImage"
                  class="item__img"
                  @dragleave="dragHandler(false)"
                  :id="item.name"
                >
                  <Uploader
                    :componentRef="'template_uploader_' + index"
                    className="uploader-container"
                    fileFormat="png|jpeg|gif|svg|jpg"
                    :isShowDragAndDropText="false"
                    :isMultiple="false"
                    @uploadFile="startUploadFileHandler($event, item)"
                    @notValidFormat="notValidFormatHandler"
                  />
                </div>
                <div
                  v-else
                  class="item__img loader-template-img"
                  :id="item.name"
                >
                  <v-progress-circular
                    indeterminate
                    color="#7E5689"
                  ></v-progress-circular>
                </div>
              </div>
            </template>
          </div>
          <button
            class="btn violet"
            :class="{ disabled: !valid || !mediaIsValid }"
            :disabled="!valid || !mediaIsValid"
            @click="generateTemplate()"
          >
            Preview <i class="icon-back"></i>
          </button>
        </div>
      </div>
      <div class="result-area">
        <div class="result-area__image">
          <img
            v-if="currentTemplate.preview_url"
            :src="currentTemplate.preview_url"
          />
        </div>
        <div class="result-area__btn">
          <button
            class="btn violet"
            :class="{ disabled: !isApprove }"
            :disabled="!isApprove"
            @click="approveTemplate()"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
    <ModalDialog
      :isShow="templateListDialog.isShow"
      :size="500"
      customClass="template-list"
      @close="templateListDialog.isShow = false"
    >
      <template v-slot:description>
        <TemplatesList
          v-if="templateListDialog.isShow"
          @close="templateListDialog.isShow = false"
          @newTemplate="setNewTemplate($event)"
          :post="post"
          :currentTemplate="currentTemplate"
          :isMediaTemplate="isMediaTemplate"
        />
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="templateMediaDialog.isShow"
      :size="500"
      customClass="template-list"
      @close="templateMediaDialog.isShow = false"
    >
      <template v-slot:description>
        <TemplatesMediaWidget
          :calendarMediaItems="mediaItemsList"
          :templateId="currentTemplate.id"
          :mediaRatio="editImageRatio"
          @changeImage="setNewImage($event)"
        />
      </template>
    </ModalDialog>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import ModalDialog from "@/components/global/ModalDialog";
import TemplatesList from "@/components/templatesModal/TemplatesList";
import TemplatesMediaWidget from "@/components/templatesModal/TemplatesMediaWidget";
import validationRules from "@/shared/validationRules";
import Loader from "@/components/global/Loader";
import Uploader from "@/components/global/Uploader";
import uploader from "@/mixins/uploader";
import Post from "@/models/Post";
import H from "@/utils/helper";
import Template from "@/models/Template";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Templates",
  components: {
    ModalDialog,
    TemplatesList,
    TemplatesMediaWidget,
    Loader,
    Uploader,
  },
  props: {
    post: {
      type: Object,
      default: new Post(),
    },
    editedTemplate: {
      type: Object,
      default: new Template(),
    },
    template: {
      type: Object,
      default: () => new Template(),
    },
    isEditTemplate: {
      type: Boolean,
      default: false,
    },
    similarTemplates: {
      type: Array,
      default: () => [],
    },
    isMediaTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    templateListDialog: {
      isShow: false,
    },
    templateMediaDialog: {
      isShow: false,
    },
    currentTemplate: new Template(),
    defaultImagesList: [],
    editTemplateIndex: null,
    isLoading: false,
    isApprove: false,
    editImageName: "",
    editImageRatio: "",
    valid: true,
    mediaIsValid: true,
    isDropActive: false,
  }),
  mixins: [validationRules, uploader],
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("post", ["getCurrentPost", "getMediaIsLoading"]),
    ...mapGetters("stories", ["getCurrentStories", "getMediaIsLoading"]),
    ...mapGetters("instagramPost", ["getIsStoriesTabActive"]),

    mediaItemsList() {
      return this.post.media.filter((item) => !item.template);
    },

    editImages() {
      this.currentTemplate.parameters.forEach((parameter, index) => {
        parameter.indexInList = index;
      });

      return this.currentTemplate.parameters.length
        ? this.currentTemplate.parameters.filter((i) => i.type === "image_url")
        : [];
    },

    backgroundField() {
      const uniqueList = [];
      this.currentTemplate.parameters.forEach((obj) => {
        if (
          this.currentTemplate.parameters.filter((o) => o.layer === obj.layer)
            .length === 1 &&
          obj.type === "color"
        ) {
          uniqueList.push(obj);
        }
      });
      return uniqueList;
    },

    inputFields() {
      let textFields;
      if (this.currentTemplate.parameters.length) {
        textFields = this.currentTemplate.parameters.filter(
          (i) => i.type === "text"
        );
        let colors = this.currentTemplate.parameters.filter(
          (i) => i.type === "color"
        );
        colors.forEach((i) => {
          let indexElement = textFields.findIndex(
            (item) => item.layer === i.layer
          );
          if (indexElement !== -1) {
            textFields[indexElement].textColor = i.value;
            textFields[indexElement].nameColor = i.name;
          }
        });
      } else {
        textFields = [];
      }
      return textFields;
    },
  },
  async created() {
    if (this.isEditTemplate || this.isMediaTemplate) {
      this.currentTemplate = this.editedTemplate;
    } else if (this.template) {
      this.currentTemplate = H.deepCopy(this.template);
      this.setDefaultImages(this.template.parameters);
    } else {
      await this.getTemplatesData();
    }
    if (this.isEditTemplate) {
      this.getEditTemplateIndex();
    }
  },
  methods: {
    ...mapActions("templates", ["getTemplatesInfo", "createImageFromTemplate"]),

    getEditTemplateIndex() {
      if (this.post.type === "post") {
        this.editTemplateIndex = this.getCurrentPost.media.findIndex((item) => {
          if (item.template) {
            return item.template.id === this.currentTemplate.id;
          }
        });
      } else {
        this.editTemplateIndex = this.getCurrentStories.media.findIndex(
          (item) => {
            if (item.template) {
              return item.template.id === this.currentTemplate.id;
            }
          }
        );
      }
    },

    changeTemplate() {
      this.templateListDialog.isShow = true;
    },

    changeImage(name, item) {
      this.templateMediaDialog.isShow = true;
      this.editImageName = name;
      this.editImageRatio = item.image_layer_type;
    },

    setDefaultImages(parameters) {
      if (parameters.length) {
        const images = parameters.filter((i) => i.type === "image_url");
        this.defaultImagesList = images.map((i) => {
          return {
            name: i.name,
            value: i.value,
          };
        });
      }
    },

    async getTemplatesData() {
      try {
        this.isLoading = true;
        let params = {
          calendarItemId: this.post.calendarItemId,
          customerId: this.getCurrentCustomer.id,
        };
        let { similarTemplates, template } = await this.getTemplatesInfo(
          params
        );
        this.currentTemplate = template;

        this.setDefaultImages(template.parameters);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },

    async setNewTemplate(template) {
      this.templateListDialog.isShow = false;
      await this.generateTemplate(template.id);
    },

    prepareParams() {
      this.currentTemplate.parameters = this.currentTemplate.parameters.map(
        (item, index) => {
          if (item.textColor) {
            return {
              hint: this.currentTemplate.parameters[index].hint,
              layer: this.currentTemplate.parameters[index].layer,
              name: this.currentTemplate.parameters[index].name,
              type: this.currentTemplate.parameters[index].type,
              value: this.currentTemplate.parameters[index].value,
            };
          } else {
            return item;
          }
        }
      );
    },

    mediaValidation() {
      this.editImages.forEach((item) => {
        if (item.hint) {
          let mathEl = this.defaultImagesList.findIndex((i) => {
            return i.name === item.name && i.value === item.value;
          });
          if (mathEl !== -1) {
            document
              .getElementById(this.defaultImagesList[mathEl].name)
              .classList.add("invalid");
            this.mediaIsValid = false;
          }
        }
      });
    },

    async generateTemplate(newTemplateId) {
      if (!newTemplateId) {
        this.$refs.form.validate();
        this.mediaValidation();
        if (!this.$refs.form.validate() || !this.mediaIsValid) return;
      }
      try {
        this.prepareParams();
        this.isLoading = true;
        const params = {
          task: newTemplateId ? "change_template" : "create_image",
          inputParameters: this.currentTemplate.parameters,
          templateId: newTemplateId || this.currentTemplate.id,
          customerId: this.getCurrentCustomer.id,
          contentTemplateId: this.getIsStoriesTabActive
            ? this.getCurrentStories.contentTemplateId
            : this.getCurrentPost.contentTemplateId,
          calendarItemId: this.getIsStoriesTabActive
            ? this.getCurrentStories.calendarItemId
            : this.getCurrentPost.calendarItemId,
        };
        const data = await this.createImageFromTemplate(params);
        this.currentTemplate = data.template;
        this.currentTemplate.isAutoGenerated =
          this.editedTemplate && this.editedTemplate.isAutoGenerated
            ? this.editedTemplate.isAutoGenerated
            : false;
        this.isApprove = true;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },

    setNewImage(image) {
      let editedItem = this.currentTemplate.parameters.findIndex(
        (item) => item.name.trim() === this.editImageName.trim()
      );
      this.currentTemplate.parameters[editedItem].value = image.mediaUrl;
      this.currentTemplate.parameters[editedItem].externalId = image.externalId;
      this.currentTemplate.parameters[editedItem].externalService =
        image.externalService;

      if (!this.isEditTemplate && this.defaultImagesList.length) {
        document
          .getElementById(this.currentTemplate.parameters[editedItem].name)
          .classList.remove("invalid");
      }
      this.mediaIsValid = true;
      this.templateMediaDialog.isShow = false;
      this.editImageName = "";
      this.editImageRatio = "";
    },

    changeTextColor(color, item) {
      const index = this.currentTemplate.parameters.findIndex(
        (i) => i.layer === item.layer && i.type === "color"
      );
      this.currentTemplate.parameters[index].value = color;
    },

    changeBgColor(color, item) {
      const index = this.currentTemplate.parameters.findIndex(
        (i) => i.layer === item.layer
      );
      this.currentTemplate.parameters[index].value = color;
    },

    approveTemplate() {
      const isShutterstock = this.editImages.some(
        (item) =>
          item.externalService && item.externalService === "shutterstock"
      );
      const isLicensed = isShutterstock ? false : null;
      if (isShutterstock) {
        this.currentTemplate.isLicensed = false;
      }

      if (this.post.type === "post" && !this.isEditTemplate) {
        let newMedia = {
          isTemplate: true,
          mediaUrl: this.currentTemplate.preview_url,
          template: this.currentTemplate,
          type: "image",
          isLicensed: isLicensed,
        };
        this.getCurrentPost.media = [...this.getCurrentPost.media, newMedia];
      } else if (this.post.type === "post" && this.isEditTemplate) {
        let newMedia = {
          isTemplate: true,
          mediaUrl: this.currentTemplate.preview_url,
          template: this.currentTemplate,
          type: "image",
          isLicensed: isLicensed,
        };
        this.getCurrentPost.media.splice(this.editTemplateIndex, 1, newMedia);
      } else if (this.post.type === "story" && !this.isEditTemplate) {
        let newMedia = {
          isTemplate: true,
          mediaUrl: this.currentTemplate.preview_url,
          template: this.currentTemplate,
          type: "image",
          isLicensed: isLicensed,
        };
        this.getCurrentStories.media = [
          ...this.getCurrentStories.media,
          newMedia,
        ];
      } else if (this.post.type === "story" && this.isEditTemplate) {
        let newMedia = {
          isTemplate: true,
          mediaUrl: this.currentTemplate.preview_url,
          template: this.currentTemplate,
          type: "image",
          isLicensed: isLicensed,
        };
        this.getCurrentStories.media.splice(
          this.editTemplateIndex,
          1,
          newMedia
        );
      }
      this.$emit("closeTemplateModal");
    },

    updateTextValue(newValue, elem) {
      let editedItem = this.currentTemplate.parameters.findIndex(
        (item) => item.type === "text" && elem.name === item.name
      );
      this.currentTemplate.parameters[editedItem].value = newValue;
    },
    dragHandler(flag = false) {
      this.isDropActive = flag;
    },
    async startUploadFileHandler(file, item) {
      try {
        this.isDropActive = false;

        this.currentTemplate.parameters.splice(item.indexInList, 1, {
          ...item,
          ...{ isLoadingImage: true },
        });

        const {
          url,
          mediaId,
          fields: { key },
        } = await this.uploadFileHandler(file);

        this.currentTemplate.parameters.splice(item.indexInList, 1, {
          ...item,
          ...{ isLoadingImage: false, value: url + key },
        });
      } catch (e) {
        console.error(e);
      }
    },
    notValidFormatHandler() {
      this.isDropActive = false;
    },
    openInBannerbear(id) {
      const url = `https://app.bannerbear.com/projects/2by4GqMJgG61ERad9x/templates/${id}/edit`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.modify-template {
  &__header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
    justify-content: space-between;
    &.blur {
      filter: blur(4px);
    }
  }
  &__template-info {
    padding-right: 20px;
  }
  &__title {
    margin-right: 5px;
    font-size: 16px;
    font-weight: 700;
  }
  &__name {
    font-size: 16px;
    font-weight: 500;
  }
  &__edit {
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $color-violet;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-gap: 40px;
    &.blur {
      filter: blur(4px);
    }
  }
  .result-area {
    position: relative;
    padding: 20px 0;
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      left: -20px;
      top: 0;
      bottom: 0;
      background: #ccc;
    }
    &__image {
      width: 100%;
      height: 100%;
      max-height: 400px;
      margin-bottom: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .edit-area {
    &__controls-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
    &__control {
      display: flex;
      align-items: flex-end;
      min-height: 48px;
      &-color {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        .colors {
          position: absolute;
          background: #fff;
          left: 20px;
          top: 0;
          z-index: 10;
          transform: scale(0);
          transition: all 0.3s ease;
          box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
          &:before {
            content: "";
            width: 20px;
            height: 100%;
            position: absolute;
            left: -20px;
            top: 0;
            bottom: 0;
          }
          &__pallet {
            display: flex;
            flex-direction: row;
            &-items {
              display: flex;
              align-items: center;
              padding: 10px;
              .item {
                width: 50px;
                height: 50px;
                border: 1px solid #ccc;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
        &:hover {
          .colors {
            transform: scale(1);
          }
        }
      }
    }
    &__input {
      &-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }
      &-val {
        width: 100%;
        display: inline-flex;
        .name {
          display: block;
          font-weight: 600;
          position: relative;
          top: 3px;
        }
      }
    }
    &__images {
      display: flex;
      flex-wrap: wrap;
      .item {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 15px;
        margin-bottom: 5px;
        &:last-child {
          margin-right: 0;
        }
        &__name {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        &__img {
          position: relative;
          display: inline-flex;
          height: 150px;
          width: 150px;
          background: #ccc;
          border: 2px solid transparent;
          &.invalid {
            border: 2px solid red;
          }
          .file-uploader {
            right: 34px !important;
            ::v-deep {
              button {
                bottom: 8px;
                right: 2px;
              }
              .mdi-image::before {
                font-size: 17px !important;
                color: #7e5689;
              }
            }
            .uploader-custom-icon {
              padding-top: 7px !important;
            }
          }
          .edit {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            font-size: 11px;
            color: #7e5689;
            background: #ffffff;
            transition: all 0.3s ease;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    &__b-row {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.uploader-container {
  height: 100%;
}

.loader-template-img {
  align-items: center;
  justify-content: center;
}

.btn {
  &.disabled {
    background: #ccc;
  }
  i {
    margin-left: 10px;
    display: inline-flex;
  }
}
</style>
