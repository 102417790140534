import axios from "axios";
const axiosFunctions = axios.create({
  baseURL: process.env.VUE_APP_FUNCTIONS_URL,
});

export default {
  namespaced: true,
  actions: {
    async fetchLead({ commit }, { name, params }) {
      try {
        const { data } = await axiosFunctions.get(`/facebook/${name}`, {
          params,
        });

        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
