<template>
  <v-navigation-drawer
    :class="{ mini: getSidebarIsMin, big: !getSidebarIsMin }"
    permanent
    app
    :mini-variant="getSidebarIsMin"
  >
    <div>
      <img
        class="logo mini-logo"
        v-if="getSidebarIsMin"
        src="@/assets/img/logo_short.svg"
      />
      <img class="logo" v-else src="@/assets/img/logo_full.svg" width="200" />
    </div>
    <v-row class="menu-btn">
      <v-col cols="6">
        <v-btn @click.stop="toggleSidebar" elevation="2" x-small>
          <i v-if="getSidebarIsMin" class="icon-open-menu"></i>
          <i v-else class="icon-close-menu"></i>
        </v-btn>
      </v-col>
    </v-row>

    <v-list class="menu" dense>
      <v-list-item
        href
        to="/client-selection-page"
        class="client-selection-container"
        @click="logOutFromCustomerPage"
      >
        <v-list-item-icon>
          <v-badge bordered top color="#E56969" dot>
            <i class="icon-client-selection"></i>
          </v-badge>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Client Selection</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--      <v-list-item-->
      <!--        v-if="getCurrentCustomer"-->
      <!--        class="menu-items-container current-customer-container"-->
      <!--      >-->
      <!--        <v-list-item-icon>-->
      <!--          <v-avatar color="#C4C4C4">-->
      <!--            <img-->
      <!--              v-if="getCurrentCustomer.picUrl"-->
      <!--              :src="getCurrentCustomer.picUrl"-->
      <!--              class="img-user-avatar"-->
      <!--            />-->
      <!--            <i v-else class="icon-user-avatar"></i>-->
      <!--          </v-avatar>-->
      <!--        </v-list-item-icon>-->

      <!--        <v-list-item-content-->
      <!--          v-if="getCurrentCustomer && getCurrentCustomer.name"-->
      <!--        >-->
      <!--          <v-list-item-title>{{ getCurrentCustomer.name }}</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->
      <v-list-item
        v-for="item in menuList"
        :key="item.title"
        class="menu-items-container"
        link
        @click="routerController(item)"
        :class="{
          active: fetchIsActiveRoute(item),
          hidden:
            item.title === 'Dashboard' &&
            getCurrentCustomer.domain === 'beauty',
          bottom: item.isBottom,
        }"
      >
        <v-list-item-icon>
          <i :class="item.icon"></i>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="bottom-icons-container">
        <!--        <v-list-item class="menu-items-container">
          <v-list-item-icon>
            <v-badge bordered top color="#E56969" dot>
              <i class="icon-notification"></i>
            </v-badge>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Notification</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
        <v-list-item class="menu-user-container">
          <v-list-item-icon>
            <v-avatar color="#C4C4C4">
              <img
                v-if="getCurrentUser && getCurrentUser.picture_url"
                :src="getCurrentUser.picture_url"
                class="img-user-avatar"
              />
              <i v-else class="icon-user-avatar"></i>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content v-if="getCurrentUser && getCurrentUser.name">
            {{ getCurrentUser.name }}
          </v-list-item-content>
          <v-list-item-action v-if="!getSidebarIsMin">
            <v-btn @click="logOutHandler" icon>
              <i class="icon-logout"></i>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-if="getSidebarIsMin"
          @click="logOutHandler"
          class="menu-logout-container"
        >
          <v-list-item-icon>
            <i class="icon-logout"></i>
          </v-list-item-icon>
        </v-list-item>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      menu: [
        {
          title: "Calendar",
          icon: "icon-calendar",
          color: "black",
          routeName: "calendar",
        },
        {
          title: "Dashboard",
          icon: "icon-dashboard",
          color: "black",
          routeName: "dashboard",
        },
        {
          title: "Content Page",
          icon: "icon-created_posts",
          color: "black",
          routeName: "create-post",
          childRoutesNames: [
            "create-post-pre-feedback",
            "create-post-approved",
            "create-post-rejected",
            "create-post-archive",
          ],
        },
        {
          title: "SMM Client Page",
          icon: "icon-media-library",
          color: "black",
          routeName: "smm-client-page",
        },
        {
          title: "Tasks",
          icon: "icon-tasks-svgrepo-com",
          color: "black",
          routeName: "tasks",
        },
        {
          title: "Content Per Month",
          icon: "icon-ftkpercentage",
          color: "black",
          routeName: "contentMixture",
          isBottom: true,
        },
      ],
      menuForReadOnly: [
        {
          title: "Dashboard",
          icon: "icon-dashboard",
          color: "black",
          routeName: "dashboard",
        },
      ],
      menuForMetaTester: [
        {
          title: "Content Page",
          icon: "icon-created_posts",
          color: "black",
          routeName: "create-post",
          childRoutesNames: [
            "create-post-pre-feedback",
            "create-post-approved",
            "create-post-rejected",
            "create-post-archive",
          ],
        },
      ],
      isMin: true,
      drawer: true,
    };
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("commonState", ["getSidebarIsMin"]),
    menuList() {
      if (!this.getCurrentCustomer) return [];
      if (this.getCurrentCustomer.meta_tester) return this.menuForMetaTester;
      if (!this.getCurrentUser) return [];
      if (this.getCurrentUser.read_only) return this.menuForReadOnly;
      return this.menu;
    },
  },
  methods: {
    ...mapMutations("clientSelection", ["refreshCurrentCustomer"]),
    ...mapActions("auth", ["logOutUser"]),
    ...mapActions("commonState", ["sidebarSize"]),
    logOutFromCustomerPage() {
      this.$router.push({ name: "client-selection-page" });
    },
    logOutHandler() {
      this.logOutUser();
      this.$router.push({ name: "login" });
    },
    routerController(route) {
      if (this.$route.name !== route.routeName) {
        if (route.childRoutesNames) {
          route.childRoutesNames[0] !== this.$route.name
            ? this.$router.push({ name: route.childRoutesNames[0] })
            : null;
        } else {
          this.$router.push({ name: route.routeName });
        }
      }
    },
    fetchIsActiveRoute(route) {
      if (route.childRoutesNames) {
        let flag = false;
        route.childRoutesNames.forEach((routeName) => {
          routeName === this.$route.name ? (flag = true) : null;
        });
        return flag;
      } else {
        return route.routeName === this.$route.name;
      }
    },
    toggleSidebar() {
      this.sidebarSize();
    },
  },
};
</script>
<style lang="scss">
.v-navigation-drawer__border {
  display: none;
}
.v-divider {
  width: calc(100% - 20px);
  margin: auto;
}
</style>

<style scoped lang="scss">
@import "@/assets/styles/vars";
.mini {
  width: 100%;
  min-width: 60px;
  .logo {
    padding: 16px 13px 0 12px;
    width: 60px;
  }
  .menu-btn {
    padding: 17px 20px 0 20px;
    margin-top: unset;
    .v-btn {
      padding: unset;
      min-width: 20px;
      background-color: #f4eff5;
      i {
        color: #77597e !important;
      }
    }
  }
  .client-selection-container {
    margin-left: 5px;
    position: relative;
    .v-list-item__icon {
      display: inline-flex;
      align-items: center;
    }
    &:after {
      content: "";
      width: 100%;
      left: -3px;
      bottom: -3px;
      background: rgba(0, 0, 0, 0.12);
      height: 1px;
      position: absolute;
      min-height: auto;
    }
  }
  .menu {
    padding-top: 96px !important;
    height: calc(100% - 112px);
    display: flex;
    flex-direction: column;
  }
  .menu-items-container {
    display: flex;
    min-width: 36px;
    height: 36px;
    justify-content: center;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    &.hidden {
      display: none;
    }
    .v-list-item__icon {
      padding-top: 3px;
      margin-left: 16px;
    }
  }
  .menu-user-container {
    .v-avatar {
      min-width: 33px !important;
      height: 33px !important;
      background-color: #e5dde8 !important;
      border-radius: 50%;
      overflow: hidden;
      i {
        color: #7e5789;
        font-size: 12px;
      }
      .img-user-avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid $color-violet-opacity;
      }
    }
  }
  .menu-logout-container {
    padding-left: 20px;
  }
  .icon-user-avatar {
    .v-avatar {
      min-width: 33px !important;
      width: 33px !important;
      height: 33px !important;
      background-color: #e5dde8 !important;
      border-radius: 0%;
      overflow: hidden;
      i {
        color: #7e5789;
        font-size: 12px;
      }
      .img-user-avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid $color-violet-opacity;
      }
    }
  }
}
.big {
  width: 100%;
  min-width: 200px;
  .logo {
    padding: 16px 0 0 16px;
  }
  .menu-btn {
    padding: 17px 0 0 220px;
    margin-top: unset;
    .v-btn {
      padding: unset;
      min-width: 20px;
      background-color: #f4eff5;
      i {
        color: #77597e !important;
      }
    }
  }
  .menu {
    padding-top: 96px !important;
    height: calc(100% - 112px);
    display: flex;
    flex-direction: column;
  }
  .menu-items-container {
    display: flex;
    min-width: 36px;
    justify-content: center;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    .v-list-item__icon {
      padding-top: 3px;
      margin-left: 6px;
    }
    &.hidden {
      display: none;
    }
  }
  .menu-user-container {
    cursor: pointer;
    .v-avatar {
      min-width: 33px !important;
      width: 33px !important;
      height: 33px !important;
      background-color: #e5dde8 !important;
      i {
        color: #7e5789;
        font-size: 12px;
      }
      .img-user-avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid $color-violet-opacity;
      }
    }
    .v-list-item__content {
      text-transform: capitalize;
    }
  }
  .menu-logout-container {
    padding-left: 20px;
  }
  .client-selection-container {
    margin-left: 16px;
    position: relative;
    .v-list-item__icon {
      display: inline-flex;
      align-items: center;
    }
    &:after {
      content: "";
      width: 100%;
      left: -10px;
      bottom: -3px;
      background: rgba(0, 0, 0, 0.12);
      height: 1px;
      position: absolute;
      min-height: auto;
    }
  }
}

.menu-btn-item {
  cursor: pointer;
}

.menu-items-container {
  &.active {
    &:after {
      content: "";
      background: #7e5689;
      border-radius: 3px 0px 0px 3px;
      width: 2px;
      height: 34px;
      display: block;
      position: absolute;
      right: -10px;
    }
  }
}
.active {
  background-color: #7e5689;
  .v-list-item__title {
    color: #ffffff;
  }
  i {
    color: #ffffff;
  }
  .active-line {
    width: 5px;
    background: #7e5689;
  }
}
.current-customer-container {
  background-color: #f3eef4;
  .v-list-item__title {
    color: #7e5789;
  }
  i {
    color: #ffffff;
  }
  .v-avatar {
    min-width: 20px !important;
    width: 20px !important;
    height: 20px !important;
    background-color: #cdc1d2 !important;
    i {
      color: #7e5789;
      font-size: 12px;
    }
  }
}
.v-list {
  cursor: pointer;
  padding: unset !important;
}

.v-list-item {
  flex: 0;
  &.bottom {
    margin-top: auto;
  }
  &__title {
    font-weight: 500 !important;
    font-size: 14px !important;
    color: $color-blueberry;
  }
  &__icon {
    margin-right: 10px !important;
    color: $color-blueberry;
  }
}
</style>
