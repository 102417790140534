import axios from "axios";

const axiosFunctions = axios.create({
  baseURL: process.env.VUE_APP_FUNCTIONS_URL,
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},

  actions: {
    async fetchCustomersPreboarding({ commit }) {
      try {
        const { data } = await axiosFunctions.get(
          `stripe/customer/preboarding`
        );

        return data;
      } catch (e) {
        console.log("Error during fetchCustomersPreboarding:", e);
        throw e;
      }
    },
    async fetchCustomersWithoutStripe({ commit }) {
      try {
        const { data } = await axiosFunctions.get(
          `stripe/customer/unallocated`
        );

        return data;
      } catch (e) {
        console.log("Error during fetchCustomersWithoutStripe:", e);
        throw e;
      }
    },
    async allocateExistingCustomer({ commit }, params = {}) {
      try {
        const { data } = await axiosFunctions.post(
          `stripe/customer/allocate`,
          params
        );
        return data;
      } catch (e) {
        console.log("Error during allocateExistingCustomer:", e);
        throw e;
      }
    },
  },
};
