import axios from "axios";
import Post from "@/models/Post";
import PostCount from "@/models/Post/PostCount";

export default {
  namespaced: true,
  state: {
    preFeedbackApprovedList: [],
    approvedList: [],
    approvedListRush: [],
  },
  mutations: {
    setToFeedbackApprovedList(state, id) {
      state.preFeedbackApprovedList.push(id);
    },
    setToApprovedList(state, id) {
      state.approvedList.push(id);
    },
    setToApprovedListRush(state, item) {
      state.approvedListRush.push(item);
    },
    deleteFromFeedbackApprovedList(state, id) {
      state.preFeedbackApprovedList = state.preFeedbackApprovedList.filter(
        (val) => val !== id
      );
    },
    deleteFromApprovedList(state, id) {
      state.approvedList = state.approvedList.filter((val) => val !== id);
      state.approvedListRush = state.approvedListRush.filter(
        (val) => val.id !== id
      );
    },
    refreshStateByName(state, { stateName, value }) {
      state[stateName] = value;
    },
  },
  actions: {
    async fetchPosts(
      { commit },
      { routeName = "", customerId = null, limit = 100 }
    ) {
      try {
        const { data } = await axios.get(
          `${customerId}/smpost?customer_approve=${routeName}&limit=${limit}`
        );
        return data.map((o) => new Post(o));
      } catch (e) {
        throw e;
      }
    },
    async fetchPostsLength({ commit }, { customerId = null }) {
      try {
        const { data } = await axios.get(`${customerId}/smpost?count=true`);
        return new PostCount(data);
      } catch (e) {
        throw e;
      }
    },
    async exportPosts({ commit }, params = {}) {
      try {
        return await axios.post(`smpost/export`, params);
      } catch (e) {
        throw e;
      }
    },
    async updatePublishDate({ commit }, params = {}) {
      try {
        return await axios.put(`smpost`, params);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async getSuggestedPublishDate({ commit }, postId) {
      try {
        const { data } = await axios.get(`calendar/${postId}`);
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async deletePost({ commit }, id) {
      try {
        await axios.delete(`smpost/${id}`);
      } catch (e) {
        throw e;
      }
    },
    async updatePosts({ commit }, params = {}) {
      try {
        await axios.put(`/smpost`, params);
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getFeedbackApprovedList(state) {
      return state.preFeedbackApprovedList;
    },
    getApprovedList(state) {
      return state.approvedList;
    },
    getApprovedRushList(state) {
      return state.approvedListRush;
    },
  },
};
