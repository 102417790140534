<template>
  <div class="content-creation">
    <v-tabs class="tabs-controls" v-model="tab" centered align-with-title>
      <v-tabs-slider color="yellow"></v-tabs-slider>

      <v-tab
        v-for="item in tabs"
        :key="item.id"
        @click="setIsStoriesTabActive(item.id)"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabs" :key="item.id">
        <v-card flat>
          <component :is="item.component"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Post from "@/components/dashboard/widgets/creationWidget/Post";
import Stories from "@/components/dashboard/widgets/creationWidget/Stories";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "TextCreation",
  data() {
    return {
      tab: 0,
      tabs: [
        {
          id: 0,
          title: "Post",
          component: Post,
        },
        {
          id: 1,
          title: "Story",
          component: Stories,
        },
      ],
    };
  },
  watch: {
    getIsStoriesTabActive: function (flag) {
      this.tab = flag ? 1 : 0;
    },
  },
  computed: {
    ...mapGetters("instagramPost", ["getIsStoriesTabActive"]),
  },
  created() {
    this.tab = this.getIsStoriesTabActive ? 1 : 0;
    this.isEditPost();
  },
  methods: {
    ...mapMutations("instagramPost", ["setIsStoriesTabActive"]),
    isEditPost() {
      if (this.$route.params.isEdit && this.tab === 1) {
        this.tabs = this.tabs.filter((i) => i.id == this.getIsStoriesTabActive);
      } else if (this.$route.params.isEdit && this.tab === 0) {
        this.tabs = this.tabs.filter((i) => {
          return i.id == this.getIsStoriesTabActive;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.content-creation {
  min-height: calc(100vh - 95px);
  .tabs-controls {
    .v-tabs-bar {
      height: 35px;
    }
    .v-slide-group {
      .v-slide-group__content {
        justify-content: space-between;
        padding: 0 24px;
        margin-bottom: 16px;
        .v-tabs-slider-wrapper {
          display: none;
        }
        & > *:last-child {
          margin-right: inherit !important;
        }
        .v-tab {
          margin-left: 0 !important;
          display: inline-flex;
          width: calc(50% - 4px);
          border-radius: 4px 4px 0px 0px;
          height: 28px;
          align-items: center;
          font-family: $base-font;
          letter-spacing: inherit !important;
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 140% !important;
          text-transform: none !important;
          color: rgba(31, 35, 37, 0.2) !important;
          border-bottom: 1px solid rgba(81, 84, 86, 0.4);
          background: transparent;
          transition: all 0.3s ease;
          &--active {
            color: $color-violet !important;
            background: rgba(126, 86, 137, 0.1);
            border-bottom: 1px solid $color-violet;
          }
        }
      }
    }
  }
  .v-tabs-items {
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
}
</style>
