import Vue from "vue";
import Vuex from "vuex";

import dashboard from "@/store/modules/dashboard";
import communication from "@/store/modules/communication";
import filesController from "@/store/modules/filesController";
import instagramPost from "@/store/modules/instagramPost";
import createPost from "@/store/modules/createPost";
import clientDashboard from "@/store/modules/clientDashboard";
import clientSelection from "@/store/modules/clientSelection";
import auth from "@/store/modules/auth";
import dragAndDrop from "@/store/modules/dragAndDrop";
import mediaLibraryWidget from "@/store/modules/mediaLibraryWidget";
import post from "@/store/modules/creationPostStories/post";
import stories from "@/store/modules/creationPostStories/stories";
import clientForm from "@/store/modules/clientForm";
import videoPlayer from "@/store/modules/videoPlayer";
import referrals from "@/store/modules/referrals";
import commonState from "@/store/modules/commonState";
import insights from "@/store/modules/insights";
import lead from "@/store/modules/lead";
import seed from "@/store/modules/seed";
import contentGeneration from "@/store/modules/contentGeneration";
import globals from "@/store/modules/globals";
import calendar from "@/store/modules/calendar";
import templates from "@/store/modules/templates";
import contentMixture from "@/store/modules/contentMixture";
import customerPreboarding from "@/store/modules/customerPreboarding";
import customerSubscription from "@/store/modules/customerSubscription";
import task from "@/store/modules/task";
import preMeeting from "@/store/modules/preMeeting";
import smbClientSelection from "@/store/modules/smb/smbClientSelection";
import smbPost from "@/store/modules/smb/smbPost";
import recommendations from "@/store/modules/smb/recommendations";
import creationContentType from "@/store/modules/smb/creationContentType";
import removeBg from "@/store/modules/smb/removeBg";

import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";
const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);
export const store = new Vuex.Store({
  modules: {
    globals,
    dashboard,
    communication,
    filesController,
    instagramPost,
    createPost,
    clientDashboard,
    clientSelection,
    auth,
    dragAndDrop,
    mediaLibraryWidget,
    post,
    stories,
    clientForm,
    videoPlayer,
    referrals,
    commonState,
    insights,
    contentGeneration,
    lead,
    seed,
    calendar,
    templates,
    contentMixture,
    customerPreboarding,
    customerSubscription,
    task,
    preMeeting,
    smbClientSelection,
    smbPost,
    recommendations,
    creationContentType,
    removeBg,
  },
  plugins: [logrocketPlugin],
});
