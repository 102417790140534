import Cookies from "js-cookie";

export default function auth({ next, nextMiddleware }) {
  if (!Cookies.get("isUserLoggedIn")) {
    return next({
      name: "login",
    });
  }
  return nextMiddleware();
}
