<template>
  <v-container fluid>
    GDrive/iCloud/Dropbox
    <br />

    <v-chip small v-for="(fileSync, index) in filesSyncList" :key="index">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="openExternally(fileSync)">
            <v-icon
              small
              class="pa-1"
              v-on="on"
              v-if="fileSync.type === 'iCloudAlbum'"
              >mdi-apple
            </v-icon>
            <v-icon
              small
              class="pa-1"
              v-on="on"
              v-if="fileSync.type === 'googleDrive'"
            >
              mdi-google-drive</v-icon
            >
            <v-icon
              small
              class="pa-1"
              v-on="on"
              v-if="fileSync.type === 'dropbox'"
            >
              mdi-dropbox</v-icon
            >
          </v-btn>
          <span v-on="on">
            ({{ getHoursAgoString(fileSync) }}) {{ fileSync.name }}
          </span>
        </template>
        <span> {{ fileSync.id }} </span>
      </v-tooltip>
    </v-chip>
    <v-btn small @click="addFileSyncDialog = true">
      <v-icon small>mdi-file-sync-outline</v-icon>
      Add
    </v-btn>

    <v-dialog v-model="addFileSyncDialog" persistent max-width="800px">
      <v-form>
        <v-card>
          <v-card-title class="text-h5">Add File Sync</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-select
                  :items="['googleDrive', 'iCloudAlbum', 'dropbox']"
                  v-model="fileSyncType"
                  label="Type"
                  :rules="[rules.required]"
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-if="fileSyncType !== 'dropbox'"
                  v-model="filesSyncId"
                  placeholder="ID"
                  label="ID*"
                  required
                  :rules="[rules.required, rules.digitLettersSigns]"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-if="fileSyncType === 'dropbox'"
                  v-model="fileSyncSharedLink"
                  placeholder="https://www.dropbox.com/sh/..."
                  label="Shared Link"
                  :rules="[rules.url, rules.dropboxUrl]"
                  @change="setDropboxId"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="fileSyncName"
                  placeholder="Name"
                  label="Name"
                ></v-text-field>
              </v-row>
            </v-container>
            {{ errorString }}
          </v-card-text>

          <v-card-actions>
            <v-btn class="cancel" text @click="cleanAndClose">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="submit"
              text
              @click.prevent="save()"
              :loading="loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import validationRules from "@/shared/validationRules";
import H from "@/utils/helper";
import { mapActions } from "vuex";

export default {
  name: "AddFileSync",
  mixins: [validationRules],
  props: {
    initialFilesSync: {
      type: Object,
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.filesSync = H.deepCopy(this.initialFilesSync);
  },
  data: () => ({
    fileSyncType: "googleDrive",
    filesSyncId: "",
    fileSyncName: "",
    fileSyncSharedLink: "",
    addFileSyncDialog: false,
    filesSync: {},
    loading: false,
    errorString: "",
  }),
  methods: {
    ...mapActions("clientSelection", ["updateCustomer", "fetchCustomerInfo"]),

    setDropboxId() {
      this.filesSyncId = this.fileSyncSharedLink
        .replace("/h?dl=", "?dl=")
        .split("/")
        .pop()
        .split("?")[0];
    },

    async save() {
      try {
        this.loading = true;

        this.fileSyncType === "dropbox";

        const params = {
          files_sync: {
            files_sync_type: this.fileSyncType,
            files_sync_id: this.filesSyncId,
            files_sync_name: this.fileSyncName,
            files_sync_shared_link: this.fileSyncSharedLink,
          },
        };

        const data = await this.updateCustomer({
          customerId: this.customerId,
          params: params,
        });

        let myFilesSync = H.deepCopy(this.filesSync);

        if (this.fileSyncType === "iCloudAlbum") {
          if (!myFilesSync.icloud_album) myFilesSync["icloud_album"] = {};

          myFilesSync.icloud_album[this.filesSyncId] = {
            type: this.fileSyncType,
            name: this.fileSyncName,
          };
        }
        if (this.fileSyncType === "googleDrive") {
          if (!myFilesSync.google_drive) myFilesSync["google_drive"] = {};

          myFilesSync.google_drive[this.filesSyncId] = {
            type: this.fileSyncType,
            name: this.fileSyncName,
          };
        }
        if (this.fileSyncType === "dropbox") {
          if (!myFilesSync.dropbox) myFilesSync["dropbox"] = {};

          myFilesSync.dropbox[this.filesSyncId] = {
            type: this.fileSyncType,
            name: this.fileSyncName,
            shared_link: this.fileSyncSharedLink,
          };
        }

        this.filesSync = myFilesSync;
        await this.fetchCustomerInfo(this.customerId);

        this.$emit("modifiedFilesSync", myFilesSync);
        this.cleanAndClose();
      } catch (error) {
        console.error(error);
        this.errorString = error;
      } finally {
        this.loading = false;
      }
    },

    cleanAndClose() {
      this.addFileSyncDialog = false;
      this.fileSyncType = "googleDrive";
      this.filesSyncId = "";
      this.fileSyncName = "";
      this.fileSyncSharedLink = "";
    },
    getHoursAgoString(fileSync) {
      if (!fileSync.hoursAgo) return "Never synced";
      return `${fileSync.hoursAgo}H Ago`;
    },

    openExternally(fileSync) {
      let url = null;
      if (fileSync.type === "googleDrive") {
        url = `https://drive.google.com/drive/folders/${fileSync.id}`;
      }
      if (fileSync.type === "iCloudAlbum") {
        url = `https://www.icloud.com/sharedalbum/#${fileSync.id}`;
      }
      if (fileSync.type === "dropbox") {
        url = fileSync.shared_link;
      }
      window.open(url, "_blank");
    },
  },
  computed: {
    filesSyncList() {
      if (!this.filesSync) return [];
      let result = [];
      if (this.filesSync.icloud_album) {
        const icloudAlbumIds = Object.keys(this.filesSync.icloud_album);
        let icloudAlbums = icloudAlbumIds.map((k) => {
          return {
            type: "iCloudAlbum",
            id: k,
            ...this.filesSync.icloud_album[k],
          };
        });
        result = [...result, ...icloudAlbums];
      }
      if (this.filesSync.google_drive) {
        const googleDriveIds = Object.keys(this.filesSync.google_drive);
        let googleDrives = googleDriveIds.map((k) => {
          return {
            type: "googleDrive",
            id: k,
            ...this.filesSync.google_drive[k],
          };
        });
        result = [...result, ...googleDrives];
      }
      if (this.filesSync.dropbox) {
        const dropboxIds = Object.keys(this.filesSync.dropbox);
        let dropboxes = dropboxIds.map((k) => {
          return {
            type: "dropbox",
            id: k,
            ...this.filesSync.dropbox[k],
          };
        });
        result = [...result, ...dropboxes];
      }
      let extendedResult = [];
      result.map((r) => {
        if (r.folder_path && r.folder_path.includes("/")) return;
        const hoursAgo = r.last_created_checkpoint
          ? this.$dayjs().diff(this.$dayjs(r.last_created_checkpoint), "hours")
          : null;

        extendedResult.push({
          hoursAgo,
          ...r,
        });
      });
      return extendedResult;
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel {
  margin: 0 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  height: 37px;
  min-width: 120px;
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  border: 1px solid #7e5689;
  color: #1f2325;
  &:hover {
    background: rgba(126, 86, 137, 0.1);
  }
}
.submit {
  margin: 0 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  height: 37px;
  min-width: 120px;
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  background: #7e5689;
  color: #fff;
  &:hover {
    background: #61346e;
  }
}
</style>
