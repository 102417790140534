import axios from "axios";
import NewImage from "@/models/NewImage";

export default {
  namespaced: true,
  actions: {
    async getUrlForSendFile({ commit }, payload) {
      const fileName = payload.file.name.replace(/\s/g, "");
      const params = {
        file_name: fileName,
        customerId: payload.customerId,
        upload_type: payload.type,
        isTag: payload.isTag ? payload.isTag : false,
        imageId: payload.imageId ? payload.imageId : null,
      };
      try {
        const { data } = await axios.post(`media`, params);
        return new NewImage(data);
      } catch (e) {
        throw e;
      }
    },

    async uploadFile({ commit }, { url, file, fields }) {
      try {
        let formData = new FormData();
        for (const key in fields) {
          formData.append(key, fields.key);
        }
        formData.append("file", file);
        const { data } = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/formData",
          },
        });
        return data;
      } catch (e) {
        throw e;
      }
    },

    //TODO: ANNA
    async fetchMoreInformationAboutMedia({ commit }, params = {}) {
      try {
        return await axios.post(`/message/send`, params);
      } catch (e) {
        throw e;
      }
    },

    async setTagForFile({ commit }, params = {}) {
      try {
        return await axios.put("media", params);
      } catch (e) {
        throw e;
      }
    },
  },
};
