import Cookies from "js-cookie";

export default function guest({ next, nextMiddleware }) {
  if (Cookies.get("isUserLoggedIn")) {
    return next({
      name: "client-selection-page",
    });
  }
  return nextMiddleware();
}
