<template>
  <div class="brief" :class="{ 'menu-open': !getSidebarIsMin }">
    <BriefHeader />
    <div class="brief__content">
      <div class="brief__top-row">
        <div class="brief__top-row-item">
          <ToDo />
        </div>
        <div class="brief__top-row-item">
          <Messages />
        </div>
      </div>
      <div class="brief__b-row">
        <Preview />
      </div>
      <div class="brief__footer">
        <button class="btn violet" @click="closeBrief()">Work Wonders</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BriefHeader from "@/components/brief/BriefHeader";
import ToDo from "@/components/brief/ToDo";
import Messages from "@/components/brief/Messages";
import Preview from "@/components/brief/Preview";

export default {
  name: "Index",
  components: { BriefHeader, ToDo, Messages, Preview },
  props: {
    sidebarIsOpen: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("commonState", ["getSidebarIsMin"]),
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  methods: {
    closeBrief() {
      this.$emit("hideBrief");
    },
  },
};
</script>

<style scoped lang="scss">
.brief {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 60px;
  z-index: 100;
  transition: all 0.3s ease;
  background: #f3f2f3;
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  &.menu-open {
    left: 256px;
    transition: all 0.3s ease;
  }
  &__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    @media screen and (max-height: 950px) {
      padding: 10px 20px;
    }
  }
  &__top-row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 60px;
    @media screen and (max-height: 950px) {
      margin-bottom: 20px;
    }
    &-item {
      width: 45%;
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    padding-bottom: 20px;
    @media screen and (max-height: 950px) {
      padding-bottom: 10px;
      margin-top: 20px;
    }
    .btn {
      height: 50px;
      min-width: 300px;
      font-size: 16px;
      @media screen and (max-height: 950px) {
        height: 45px;
      }
    }
  }
}
</style>
