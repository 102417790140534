import BaseClass from "@/models/BaseClass";

const fields = [
  { field: "name", default: "" },
  { field: "type", default: "" },
  { field: "url", default: "" },
  { field: "jsonData", default: null },
];

export default class Chart extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
  }
}
