import BaseClass from "@/models/BaseClass";
import H from "@/utils/helper";
import { log } from "logrocket";

const fields = [
  "address_city",
  "address_country",
  "address_state",
  "address_street",
  "createdAt",
  "last_visited",
  "last_name",
  "name",
  "fb_page",
  "mail",
  "business_email",
  "slack_email",
  "website",
  "primary_phone",
  "smm_id",
  "ig_page",
  "updatedAt",
  "personal_name",
  "business_signature",
  "SendReminderAt",
  "customerId",
  "customerName",
  "expiresAt",
  "exportedAt",
  "story_per_week",
  "post_per_week",
  "id",
  "status",
  "send_by",
  "time_zone",
  { field: "filter", default: "normal" },
  "about_client",
  "owner_id",
  "about_business",
  "permanent_export_note",
  "zillow_page",
  "ig_page_link",
  "fb_page_link",
  "saas_redirect_link",
  "area",
  "profession",
  "template_group",
  { field: "unread_messages", default: 0 },
  { field: "activity_state", default: null },
  { field: "postsIdList", default: [] },
  { field: "saas_redirect_link_list", default: [] },
  { field: "picUrl", default: null },
  { field: "plan", default: null },
  { field: "note", default: [] },
  { field: "Product", default: [], alias: "products" },
  { field: "domain", default: null },
  { field: "subdomains", default: [] },
  { field: "post_recipe", default: [] },
  { field: "hashtags", default: [] },
  { field: "hashtags_constant", default: [] },
  { field: "stock_media_search_terms", default: [] },
  { field: "hashtags_number", default: 0 },
  { field: "social_platforms", default: [] },
  { field: "additional_phones", default: [] },
  { field: "preferred_sites", default: [] },
  { field: "inspiration_links", default: [] },
  { field: "files_sync", default: {} },
  { field: "categories", default: [] },
  { field: "referenceTimeZone", default: null },
  { field: "brand_font_families", default: [] },
  { field: "brand_color_palettes", default: [] },
  { field: "brand_logo", default: null },
  { field: "square_logo", default: null },
  { field: "brokerage_logo", default: null },
  { field: "brand_headshots", default: [] },
  { field: "brand_styles", default: [] },
  { field: "showPopup", default: false },
  { field: "showPopupTasks", default: 0 },
  { field: "churn_type", default: null },
  { field: "cancellation_reason", default: null },
  { field: "stripe_state", default: null },
  { field: "stripe_customer_id", default: null },
  { field: "last_sent_message", default: null },
  { field: "show_brief", default: false },
  { field: "rush_post", default: false },
  { field: "is_automated", default: false },
  { field: "first_content_generated", default: false },
  { field: "additional_emails", default: [] },
  { field: "brand_values", default: [] },
  { field: "target_customers", default: [] },
  { field: "postAnyway", default: null },
  { field: "plan_name", default: "", alias: "planName" },
  { field: "media_mode", default: "" },
  { field: "residence", default: "" },
  { field: "website", default: "" },
  { field: "onboarding_required", default: false },
  { field: "manual_posting", default: false },
  { field: "allow_publish", default: true },
  { field: "is_atproperties", default: false },
  { field: "beta_features", default: [] },
  { field: "stripe_coupon", default: "" },
  { field: "saas_redirect_link_linkedin", default: "" },
  { field: "li_page_link", default: "" },
  {
    field: "business_assets",
    default: [],
  },
];

export default class Customer extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
    this.meta_tester = !H.isEmpty(obj, "meta_tester")
      ? obj.meta_tester === "true"
      : false;
  }
}
