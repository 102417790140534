<template>
  <div class="scheduling d-flex">
    <date-picker
      :lang="lang"
      class="date-picker"
      :value="dateStr"
      value-type="format"
      :format="dateFormat"
      :clearable="isClearable"
      placeholder="Select a date"
      :disabled-date="disabledBeforeDays"
      @input="changeDate"
    ></date-picker>
    <date-picker
      class="date-picker"
      :value="timeStr"
      type="time"
      :minute-step="15"
      :disabled="dateStr.trim() === ''"
      value-type="format"
      :format="timeFormat"
      @input="changeTime"
      :clearable="false"
      placeholder="Select a time"
    ></date-picker>
  </div>
</template>

<script>
//import LogRocket, { log } from "logrocket";
import helper from "@/mixins/helper";

export default {
  name: "Scheduling",
  data: () => ({
    testUnix: null,
    dateStr: "",
    timeStr: "",
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
    },
  }),
  mixins: [helper],
  props: {
    date: {
      type: String,
      default: "",
    },
    unix: {
      type: Number,
      default: null,
    },
    dateFormat: {
      type: String,
      default: "MM/DD/YYYY",
    },
    timeFormat: {
      type: String,
      default: "hh:mm A",
    },
    disabledTime: {
      type: Number,
      default: -24,
    },
    isClearable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    date: function () {
      let { dateStr, timeStr } = this.parseStrDate(
        this.date,
        this.timeFormat,
        this.dateFormat
      );
      this.dateStr = dateStr;
      this.timeStr = timeStr;
    },
  },
  created() {
    if (this.date) {
      let { dateStr, timeStr } = this.parseStrDate(
        this.date,
        this.timeFormat,
        this.dateFormat
      );
      this.dateStr = dateStr;
      this.timeStr = timeStr;
    }
  },
  methods: {
    disabledBeforeDays(date) {
      return (
        this.$dayjs(date).startOf("day").unix() <
        this.$dayjs().add(this.disabledTime, "h").startOf("day").unix()
      );
    },
    changeDate(newDate) {
      if (newDate) {
        let time = this.timeStr.trim() === "" ? "12:00" : this.timeStr;
        this.$emit(
          "changeDate",
          this.parseToStartVariant(
            newDate,
            this.$dayjs(newDate + " " + time).format("HH:mm")
          )
        );
      } else {
        this.$emit("clearDate");
      }
    },
    changeTime(newTime) {
      if (newTime) {
        this.$emit(
          "changeDate",
          this.parseToStartVariant(
            this.dateStr,
            this.$dayjs(this.dateStr + " " + newTime).format("HH:mm")
          )
        );
      }
    },
    parseToStartVariant(date, time) {
      return (
        this.$dayjs(date).format("YYYY-MM-DD") +
        "_" +
        this.$dayjs(date + " " + time).format("HH-mm-ss")
      );
    },
  },
};
</script>

<style lang="scss">
.mx-datepicker-popup {
  .mx-btn-icon-double-left,
  .mx-btn-icon-double-right {
    display: none !important;
  }
}
.d-flex {
  display: flex;
}
.scheduling {
  .date-picker {
    margin: 0 5px;
  }
}
</style>
