import axios from "axios";
import Customer from "@/models/Customer";
import CustomerAuth from "@/models/Customer/customerAuth";
import qs from "qs";
import Cookies from "js-cookie";

export default {
  namespaced: true,
  state: {
    currentCustomer: new Customer({}),
    customerExportStatus: {},
    customerChatstate: {},
    customerShowPopupTasks: 0,
    customersArray: [],
  },
  mutations: {
    setCookiesCustomerInformation(state, customer) {
      sessionStorage.currentCustomer = JSON.stringify(customer);
    },
    setCurrentCustomer(state, customer) {
      state.currentCustomer = customer;
    },
    refreshCurrentCustomer(state) {
      sessionStorage.currentCustomer = null;
      state.currentCustomer = null;
    },
    setCustomerExportStatus(state, { customerId, exportStatus }) {
      const currentCustomerExportStatus = { ...state.customerExportStatus };
      currentCustomerExportStatus[customerId] = exportStatus;
      state.customerExportStatus = currentCustomerExportStatus;
    },
    setCustomerChatstate(state, { customerId, chatstate }) {
      const currentChatstate = { ...state.customerChatstate };
      currentChatstate[customerId] = chatstate;
      state.customerChatstate = currentChatstate;
    },
    setCustomerShowPopupTasks(state, value) {
      state.customerShowPopupTasks = value;
    },
    setCustomersArray(state, customersArray) {
      state.customersArray = customersArray;
    },
  },
  actions: {
    async fetchCustomers(
      { commit },
      {
        id,
        paginationOptions = {
          page: 1,
          itemsPerPage: 1000,
        },
      }
    ) {
      try {
        const { data } = await axios.get(`${id}/customer`, {
          params: {
            page: paginationOptions.page,
            per_page: paginationOptions.itemsPerPage,
          },
        });
        const customers = data.data.map((o) => new CustomerAuth(o));
        commit("setCustomersArray", customers);
        return {
          data: customers,
          meta: data.metadata,
          currentTime: data.current_time,
        };
      } catch (e) {
        throw e;
      }
    },
    async fetchCustomerInfo({ commit }, id) {
      try {
        const { data } = await axios.get(`customer/${id}`);
        commit("setCurrentCustomer", new Customer(data));
        return new Customer(data);
      } catch (e) {
        console.error(e);
        return new Customer({});
      }
    },

    async updateCustomerChatstate({ commit }, customerId) {
      try {
        const { data } = await axios.get(`customer/${customerId}/chatstate`);
        if (!data) return;
        commit("setCustomerChatstate", { customerId, chatstate: data });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async sendNote({ commit }, { customerId, params = {} }) {
      try {
        await axios.put(`customer/${customerId}`, params);
      } catch (e) {
        throw e;
      }
    },
    async updateCustomer({ commit }, { customerId, params = {} }) {
      try {
        await axios.put(`customer/${customerId}`, params);
      } catch (e) {
        console.log("Error during updateCustomer:", e);
        throw e;
      }
    },
    async updateCustomerExportStatus({ commit }, id) {
      try {
        let obj = { updating: true };
        const { data } = await axios.get(`customer/${id}/export_status`);
        obj = { updating: false, exportStatus: JSON.parse(data.body) };
        commit("setCustomerExportStatus", {
          customerId: id,
          exportStatus: obj,
        });
      } catch (e) {
        console.log("Error during updateCustomerExportStatus:", e);
        throw e;
      }
    },
  },
  getters: {
    getCustomerExpostStatus(state) {
      return state.customerExportStatus;
    },
    getCustomersArray(state) {
      return state.customersArray;
    },
    getCustomerChatstate(state) {
      return state.customerChatstate;
    },
    getCurrentCustomer(state) {
      return state.currentCustomer;
    },
    getCustomerShowPopupTasks(state) {
      return state.customerShowPopupTasks;
    },
    getCustomerAddress(state) {
      if (state.currentCustomer == null) {
        return null;
      }
      return state.currentCustomer.address_state &&
        state.currentCustomer.address_city
        ? state.currentCustomer.address_city +
            ", " +
            state.currentCustomer.address_state
        : state.currentCustomer.address_state &&
          !state.currentCustomer.address_city
        ? state.currentCustomer.address_state
        : state.currentCustomer.address_city &&
          !state.currentCustomer.address_state
        ? state.currentCustomer.address_city
        : "";
    },
  },
};
