<template>
  <div
    :class="className"
    @dragover="$emit('dragoverEmit')"
    @dragleave="$emit('dragleaveEmit')"
  >
    <file-upload
      :input-id="componentRef"
      :ref="componentRef"
      :multiple="isMultiple"
      v-model="filesArray"
      :maximum="10"
      :accept="acceptFiles"
      :drop="isDrop"
      :drop-directory="true"
      @input="inputFilter"
      class="file-upload"
    >
      <template v-if="isUseCustomElementInSlot">
        <slot name="uploader-block"></slot>
      </template>
      <template v-else>
        <div class="file-upload__content">
          <span class="file-upload__icon">
            <i class="icon-drag_and_drop"></i>
          </span>
          <p class="file-upload__text">
            Drag & Drop files here or <span>select files to upload</span>
          </p>
        </div>
        <div class="file-upload__messages">
          <i class="icon-attach"></i>
        </div>
      </template>
    </file-upload>
    <span
      v-show="
        isShowDragAndDropText &&
        $refs[componentRef] &&
        $refs[componentRef].dropActive
      "
      >Drag and drop here for upload</span
    >
  </div>
</template>

<script>
import { log } from "logrocket";

export default {
  name: "Uploader",
  data: () => ({
    filesArray: [],
    defaultFormats: "",
  }),
  props: {
    className: {
      type: String,
      default: "",
    },
    isDrop: {
      type: Boolean,
      default: true,
    },
    componentRef: {
      type: String,
      default: "upload",
    },
    emitNameForSuccessUploadMedia: {
      type: String,
      default: "uploadFile",
    },
    acceptFiles: {
      type: String,
      default: "",
    },
    fileFormat: {
      type: String,
      default: "",
      //for add new format example: 'pdf|xls|avi'
    },
    isUseCustomElementInSlot: {
      type: Boolean,
      default: false,
    },
    isShowDragAndDropText: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    inputFilter: function (newFile) {
      this.filesArray.map((file, index) => {
        file.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          file.blob = URL.createObjectURL(file.file);
        }
        if (!this.validationFile(file)) {
          this.filesArray = this.filesArray.filter(
            (itemInList, itemIndex) => itemIndex !== index
          );
          this.$emit("notValidFormat");
        }
      });
      if (this.filesArray.length) {
        this.$emit(this.emitNameForSuccessUploadMedia, this.filesArray);
      }
      this.filesArray = [];
    },
    validationFile(file) {
      const reg = new RegExp(this.fileFormat, "i");
      return reg.test(file.name);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
.uploader-container {
  background: #fafafa;
  border: 1px solid #eeeff0;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: flex;
  padding: 2px;
  margin-bottom: 16px;
  cursor: pointer;
  ::v-deep {
    .file-upload {
      width: 100%;
      background: #fafafa;
      border: 1.5px dashed #4e5778;
      box-sizing: border-box;
      border-radius: 4px;
      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: 0 auto;
      }
      &__icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background: rgba(78, 87, 120, 0.2);
        margin-right: 12px;
        i {
          font-size: 14px;
          color: $color-blueberry;
        }
      }
      &__text {
        margin-bottom: 0;
        max-width: 134px;
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        color: $color-grey2;
        text-align: start;
        span {
          color: $color-violet;
          text-decoration: underline;
        }
      }
      &__messages {
        display: none;
      }
      label {
        cursor: pointer !important;
      }
    }
  }
  &.messages {
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    padding: 0;
    ::v-deep {
      .file-upload {
        background: transparent;
        width: 20px;
        height: 36px;
        border: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &__content {
          display: none;
        }
        &__messages {
          display: flex;
          cursor: pointer;
          i {
            font-size: 14px;
            color: $color-blueberry;
          }
        }
      }
    }
  }
}
</style>
