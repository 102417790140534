<template>
  <div class="modify-template">
    <button class="close" @click="cancel()">
      <i class="icon-close"></i>
    </button>
    <div class="modify-template__wrapper">
      <v-form ref="form" lazy-validation>
        <v-card>
          <div class="modify-template__header">
            <div class="title">Template:</div>
            <!-- <div class="btn-wrap"> -->
            <!-- <v-btn
                rounded
                :loading="isLoadingGetNextTemplate"
                elevation="2"
                raised
                text
                color="primary"
                dark
                @click="getAndSwitchToNextTemplate"
              >
                Find another template
              </v-btn> -->
            <!-- </div> -->
            <v-autocomplete
              :loading="isLoadingGetNextTemplate"
              :items="getFilteredTemplates"
              v-model="template"
              class="template-autocomplete"
              return-object
              item-text="name"
              :menu-props="{ maxHeight: 500 }"
              @change="switchTemplate"
              label="Select Template"
            >
              <template slot="item" slot-scope="data">
                <img :src="data.item.preview_url" width="80px" />
              </template>
            </v-autocomplete>
          </div>
          <v-container :fluid="true">
            <v-row>
              <v-col md="7" sm="6">
                <v-card>
                  <v-card-text>
                    <v-list dense>
                      <v-list-item
                        dense
                        v-for="(parameter, index) in listItems"
                        :key="`listItems-${index}`"
                      >
                        <v-list-item-content>
                          <div class="form-row">
                            <v-menu
                              offset-x
                              open-on-hover
                              close-delay="150"
                              open-delay="100"
                              origin="center center"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-on="on"
                                  v-bind="attrs"
                                  class="color"
                                  :style="`${getStyle(
                                    parameter['layer_name']
                                  )}`"
                                  >{{ parameter["group"] }}</span
                                >
                              </template>

                              <ColorSelect
                                @setColor="setParameterColor(parameter, $event)"
                              />
                            </v-menu>
                            <v-text-field
                              @change="modifyTemplate($event, parameter)"
                              :label="`${parameter['layer_name']} ${
                                parameter['description']
                                  ? '  (' + parameter['description'] + ')'
                                  : ''
                              }`"
                              v-model="parameter['value']"
                              required
                              :rules="[rules.required]"
                            ></v-text-field>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        dense
                        v-for="(parameter, index) in listExtraItems"
                        :key="`listExtraItems-${index}`"
                      >
                        <v-list-item-content>
                          <div class="form-row">
                            <v-menu
                              offset-x
                              open-on-hover
                              close-delay="150"
                              open-delay="100"
                              origin="center center"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-on="on"
                                  v-bind="attrs"
                                  class="color"
                                  :style="`${getStyle(
                                    parameter['layer_name']
                                  )}`"
                                  >{{ parameter["group"] }}</span
                                >
                              </template>

                              <ColorSelect
                                @setColor="setParameterColor(parameter, $event)"
                              />
                            </v-menu>
                            {{ parameter["layer_name"] }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                      <div class="select-image">
                        <div
                          class="select-image__item"
                          v-for="(parameter, index) in listImagesItems"
                          :key="`listImagesItems-${index}-${imagesAlternativesCount}-${imageLayerValues}`"
                        >
                          <div class="main-img__name">
                            {{ parameter["layer_name"] }}
                          </div>
                          <div class="main-img__img">
                            <img
                              :src="
                                imageLayerValues[getImageLayerIndex(parameter)]
                                  .thumbnailUrl
                              "
                            />
                            <!-- @click="openImageNewTab(parameter)" -->
                            <div class="edit-image">
                              <v-dialog
                                max-width="500"
                                v-model="
                                  isDialogMediaGrid[
                                    getImageLayerIndex(parameter)
                                  ]
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="main-img__btn">
                                    <v-btn
                                      :loading="isLoadingImage(parameter)"
                                      v-on="on"
                                      @click="clickSelectImage(parameter)"
                                      v-bind="attrs"
                                    >
                                    </v-btn>
                                  </div>
                                </template>
                                <MediaGrid
                                  :mediaIndex="getImageLayerIndex(parameter)"
                                  :parameter="parameter"
                                  :noMoreImages="noMoreImages"
                                  :gridImagesAlternatives="
                                    imagesAlternatives[
                                      getImageLayerIndex(parameter)
                                    ]
                                  "
                                  @getMoreImages="
                                    getMediaImages(parameter, true)
                                  "
                                  @select="setImageFromGrid($event)"
                                  @closeModal="isDialogMediaGrid = []"
                                  :isDialogMediaGrid="
                                    isDialogMediaGrid[
                                      getImageLayerIndex(parameter)
                                    ]
                                  "
                                />
                              </v-dialog>
                            </div>
                          </div>
                          <div
                            class="photo-info"
                            v-if="
                              service == 'unsplash' &&
                              getStockImageUser(parameter) &&
                              getStockImageUser(parameter).userName
                            "
                          >
                            Photo by
                            <a
                              :href="`https://unsplash.com/@${
                                getStockImageUser(parameter).userUsername
                              }?utm_source=Social-Wonder&utm_medium=referral`"
                              >{{ getStockImageUser(parameter).userName }}</a
                            >
                            on
                            <a
                              href="https://unsplash.com/?utm_source=Social-Wonder&utm_medium=referral"
                              >Unsplash</a
                            >
                          </div>
                          <v-col>
                            <v-row>
                              <v-text-field
                                append-outer-icon="mdi-image-search-outline"
                                @click:append-outer="
                                  getAndSetRandomImage(parameter)
                                "
                                @change="searchTermModified(parameter)"
                                label="Stock-Media Search term"
                                v-model="
                                  stockMediaSearchTerm[
                                    getImageLayerIndex(parameter)
                                  ]
                                "
                              ></v-text-field>
                            </v-row>
                          </v-col>
                        </div>
                      </div>
                    </v-list>
                  </v-card-text>
                  <div class="footer-actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      rounded
                      :loading="isLoadingGetImageFromTemplate"
                      :disabled="isLoadingImageArray.some((v) => v)"
                      elevation="2"
                      raised
                      text
                      color="primary"
                      dark
                      @click="createNewImage"
                    >
                      Generate Image
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
              <v-col md="5" sm="6">
                <v-card class="pa-2">
                  <v-card-text>
                    <img :src="image || preview_url" width="100%" />
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      rounded
                      :loading="isLoadingGetImageFromTemplate"
                      :disabled="!image || !isTemplateFull || !generatePressed"
                      elevation="2"
                      raised
                      text
                      color="primary"
                      dark
                      @click="saveAndClose"
                    >
                      Approve
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import validationRules from "@/shared/validationRules";
import H from "@/utils/helper";
import helper from "@/mixins/helper";
import ColorSelect from "@/components/templates/ColorSelect";
import MediaGrid from "@/components/templates/MediaGrid";

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "ModifyImageFromTemplateForm",
  mixins: [validationRules, helper],
  components: { ColorSelect, MediaGrid },
  props: {
    isDialogModifyImageTemplate: {
      type: Boolean,
      default: false,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    mediaObjectIndex: {
      type: Number,
      default: -1,
    },
  },
  data: () => ({
    isLoadingGetImageFromTemplate: false,
    isLoadingGetNextTemplate: false,
    isLoadingImageArray: [],
    isLoadingDiagestTemplate: false,
    isDialogMediaGrid: [],
    skippedTemplatesIds: [],
    stockMediaSearchTerm: [],
    imagesAlternativesCount: 0,
    image: null,
    imagesAlternatives: [],
    imageLayerValues: [],
    stockImageUser: [],
    stockMediaNextPage: [],
    stockMediaNextPageNumber: [],
    template: null,
    generatePressed: false,
    stock_media_search_terms: [],
    lastKey: [],
    currentContentReference: null,
    ratio: null,
    service: "pexels",
    noMoreImages: false,
  }),
  async created() {
    // console.log(
    //   "ImageFromTemplate created.  mediaObjectIndex:",
    //   this.mediaObjectIndex,
    //   "isPost",
    //   this.isPost,
    //   "isStory",
    //   this.isStory,
    //   "isDialogModifyImageTemplate",
    //   this.isDialogModifyImageTemplate,
    //   "getFilteredTemplates",
    //   this.getFilteredTemplates
    // );
    this.setContentReference();
    await this.init();
  },
  watch: {
    isDialogModifyImageTemplate(visible) {
      // console.log("isDialogModifyImageTemplate changed...", visible)
      if (visible) {
        this.init();
      }
    },
  },
  computed: {
    ...mapGetters("post", ["getCurrentPost"]),
    ...mapGetters("stories", ["getCurrentStories"]),
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("templates", ["getFilteredTemplates", "getInputParameters"]),
    isTemplateFull() {
      let errors = this.mandatoryParameters.filter((p) => p["value"] == null);
      return Boolean(errors.length == 0);
    },

    // getimagesAlternatives(imageIndex) {
    //   // console.log(
    //   //   "this.imagesAlternatives[imageIndex]",
    //   //   this.imagesAlternatives[imageIndex]
    //   // );
    //   return this.imagesAlternatives[imageIndex];
    // },

    parameters() {
      if (!this.template) {
        return [];
      }
      return this.template["parameters"];
    },
    templateEditLink() {
      return `https://app.bannerbear.com/projects/2by4GqMJgG61ERad9x/templates/${this.external_id}/edit`;
    },
    mandatoryParameters() {
      if (!this.parameters) {
        return [];
      }
      return this.parameters.filter((p) => p["isMandatory"]);
    },

    listImagesItems() {
      return this.mandatoryUnlockedParameters.filter((p) =>
        ["image_url"].includes(p["name"])
      );
    },
    listItems() {
      return this.mandatoryUnlockedParameters.filter((p) =>
        ["text"].includes(p["name"])
      );
    },
    listExtraItems() {
      return this.mandatoryUnlockedParameters.filter(
        (p) =>
          p["name"] == "color" &&
          ["BACKGROUND COLOR", "BACKGROUND", "USERNAME"].includes(
            p["layer_name"]
          )
      );
    },

    mandatoryUnlockedParameters() {
      if (!this.parameters) {
        return [];
      }
      return this.parameters.filter((p) => p["isMandatory"] && !p["isLocked"]);
    },
    external_id() {
      if (!this.template) {
        return [];
      }
      return this.template["external_id"];
    },
    preview_url() {
      if (!this.template) {
        return [];
      }
      return this.template["preview_url"];
    },
    permalink() {
      if (!this.template) {
        return [];
      }
      return this.template["permalink"];
    },
  },
  methods: {
    ...mapActions("templates", [
      "createImageFromTemplate",
      "getTemplate",
      "diagestTemplate",
      "getStockMedia",
    ]),
    ...mapActions("mediaLibraryWidget", ["uploadMediaFromUrl", "getMediaInfo"]),
    ...mapActions("mediaLibraryWidget", ["fetchMediaData"]),
    ...mapMutations("templates", ["setInputParameters"]),
    setContentReference() {
      if (this.isPost) {
        this.currentContentReference = this.getCurrentPost;
        this.ratio = "square";
      } else if (this.isStory) {
        this.currentContentReference = this.getCurrentStories;
        this.ratio = "vertical";
      }
    },
    async init() {
      this.template = H.deepCopy(
        this.currentContentReference.media[this.mediaObjectIndex].template
      );
      if (this.template.template_type) {
        console.log(
          "template_type",
          this.template.template_type,
          "this.template",
          this.template
        );
        this.setInputParametersFromTemplate();
      }
      this.image =
        this.currentContentReference.media[this.mediaObjectIndex].mediaUrl;

      const imageValues = {
        mediaUrl: "loading.gif",
        thumbnailUrl: "loading.gif",
      };
      this.setImageLayerValues(0, imageValues);
      this.setImageLayerValues(1, imageValues);
      this.setParametersGroups();
      await this.setImages();
    },

    setInputParametersFromTemplate() {
      this.template.parameters.map((p) => this.modifyTemplate(p.value, p));
    },

    setDefaultSearchTerms(index) {
      if (this.getCurrentCustomer.stock_media_search_terms.length) {
        const randomSearchTerm = H.getRandomElement(
          this.getCurrentCustomer.stock_media_search_terms
        );
        this.stockMediaSearchTerm[index] = randomSearchTerm;
        // this.stockMediaNextPageNumber = Array(3).fill(H.getRandomInt(10));
      }
    },
    getTemplateName() {
      if (!this.template) return "?";
      return this.template["name"];
    },

    openImageNewTab(parameter) {
      const mediaIndex = this.getImageLayerIndex(parameter);
      window.open(this.imageLayerValues[mediaIndex].mediaUrl, "_blank");
    },

    searchTermModified(parameter) {
      const mediaIndex = this.getImageLayerIndex(parameter);
      this.imagesAlternatives[mediaIndex] = [];
      this.stockMediaNextPage[mediaIndex] = null;
      this.stockMediaNextPageNumber[mediaIndex] = null;
    },

    async clickSelectImage(parameter) {
      if (
        !this.imagesAlternatives[this.getImageLayerIndex(parameter)] ||
        this.imagesAlternatives[this.getImageLayerIndex(parameter)].length == 0
      ) {
        await this.getMediaImages(parameter, false);
      }
    },

    getImageFromParameters(layer_name) {
      let foundIndex = this.parameters.findIndex(
        (p) =>
          p["layer_name"] == layer_name &&
          p["name"] == "image_url" &&
          p["value"]
      );
      if (foundIndex >= 0) {
        return this.parameters[foundIndex].value;
      }
      return "";
    },

    getGroupString(inputString) {
      const regex = /^.* (GROUP \d*)\s*.*$/;
      const match = inputString.match(regex);
      if (match && match.length > 0) return match[1];
    },

    setParameterValue(index, field, value) {
      this.template.parameters[index][field] = value;
    },

    setParameterColor(parameter, color) {
      this.template.parameters.map((param, index) => {
        if (param["name"] != "color") return;
        if (parameter["group"] && param["group"] == parameter["group"]) {
          this.setParameterValue(index, "value", color);
          return;
        }
        if (param["layer_name"] == parameter["layer_name"]) {
          this.setParameterValue(index, "value", color);
        }
      });
    },

    setParametersGroups() {
      this.template.parameters.map((p, index) => {
        const layerNameAndDescription = `${p["layer_name"]} ${p["description"]}`;
        const groupString = this.getGroupString(layerNameAndDescription);
        if (groupString && layerNameAndDescription.includes(groupString)) {
          this.setParameterValue(index, "group", groupString.split(" ")[1]);
        }
      });
    },

    getStyle(layer_name) {
      let relevant_param = this.template.parameters.filter(
        (param) => param["layer_name"] == layer_name && param["name"] == "color"
      );
      const param_value = relevant_param.length && relevant_param[0]["value"];
      const param_style = `;background-color:${param_value}`;
      return param_style;
    },
    async runDiagestTemplate(inputTemplate = null) {
      try {
        this.isLoadingDiagestTemplate = true;
        let params = {
          calendarItemId: this.currentContentReference.calendarItemId,
          customerId: this.getCurrentCustomer.id,
          template: inputTemplate || this.template,
          inputParameters: this.getInputParameters,
        };
        this.template = await this.diagestTemplate(params);
        this.image = this.template.preview_url;
      } finally {
        this.isLoadingDiagestTemplate = false;
      }
    },

    setImageLayerValues(index, values) {
      const tmpImageLayerValues = [...this.imageLayerValues];
      tmpImageLayerValues[index] = values;
      this.imageLayerValues = tmpImageLayerValues;
    },

    setStockMediaSearchTerm(index, value) {
      const tmpStockMediaSearchTerm = [...this.stockMediaSearchTerm];
      tmpStockMediaSearchTerm[index] = value;
      this.stockMediaSearchTerm = tmpStockMediaSearchTerm;
    },

    async setImages() {
      this.listImagesItems.map(async (parameter) => {
        //try get image from inputParameters...
        const index = this.getImageLayerIndex(parameter);
        if (this.getInputParameters) {
          const imageValues = this.getImageValuesFromInputParameters(parameter);
          if (imageValues) {
            this.setImageLayerValues(index, imageValues);
            this.setStockMediaSearchTerm(index, imageValues.searchTerm);
            return;
          }
        }

        //try get image from parameters...
        if (parameter.value) {
          const imageValues = {
            mediaUrl: parameter.value,
            thumbnailUrl: parameter.thumbnailUrl || parameter.value,
            searchTerm: parameter.searchTerm || "",
          };
          this.setImageLayerValues(index, imageValues);
          this.setStockMediaSearchTerm(index, imageValues.searchTerm);
          return;
        }

        // setting random image
        this.setDefaultSearchTerms(index);
        await this.getMediaImages(parameter, false);
        await this.setRandomImage(parameter);
        const imageValues = this.getImageValuesFromInputParameters(parameter);
        this.setImageLayerValues(index, imageValues);
      });
    },

    getImageValuesFromInputParameters(parameter) {
      let foundIndex = this.getInputParameters.findIndex(
        (p) =>
          p["layer_name"] == parameter["layer_name"] &&
          p["name"] == "image_url" &&
          p["value"]
      );
      // console.log("foundIndex", foundIndex);
      if (foundIndex >= 0) {
        return {
          mediaUrl: this.getInputParameters[foundIndex].value,
          thumbnailUrl:
            this.getInputParameters[foundIndex].thumbnailUrl ||
            this.getInputParameters[foundIndex].value,
          searchTerm: this.getInputParameters[foundIndex].searchTerm,
        };
      }
      return null;
    },

    async getAndSetRandomImage(parameter) {
      await this.getMediaImages(parameter, false);
      await this.setRandomImage(parameter);
      await this.setImages();

      // this.isDialogMediaGrid[this.getImageLayerIndex(parameter)] = true
      await this.clickSelectImage(parameter);
    },
    // async getAndSwitchToNextTemplate() {
    //   await this.getNextTemplate();
    //   await this.setImages();
    // },

    async switchTemplate(template) {
      try {
        this.isLoadingGetNextTemplate = true;
        await this.runDiagestTemplate(template);
        this.setParametersGroups();
        await this.setImages();
        await this.createNewImage();
      } finally {
        this.isLoadingGetNextTemplate = false;
      }
    },

    // async getNextTemplate() {
    //   try {
    //     this.isLoadingGetNextTemplate = true;
    //     if (this.template)
    //       this.skippedTemplatesIds.push(this.template.external_id);
    //     this.template = await this.callGetTemplate();

    //     if (!this.template && this.skippedTemplatesIds.length) {
    //       this.skippedTemplatesIds = [];
    //       this.template = await this.callGetTemplate();
    //     }

    //     this.image = this.template && this.template.preview_url;
    //   } finally {
    //     this.isLoadingGetNextTemplate = false;
    //   }
    // },

    // async callGetTemplate() {
    //   let params = {
    //     skipTemplatesIds: this.skippedTemplatesIds,
    //     calendarItemId: this.currentContentReference.calendarItemId,
    //     customerId: this.getCurrentCustomer.id,
    //     ratio: this.ratio,
    //     tags: this.tags,
    //   };
    //   return await this.getTemplate(params);
    // },

    getImageLayerIndex(parameter) {
      if (!parameter || !parameter["layer_name"]) return;
      if (parameter["layer_name"].includes("MAIN IMAGE")) return 0;
      if (parameter["layer_name"].includes("SUB IMAGE")) return 1;
    },

    isLoadingImage(parameter) {
      return (
        this.isLoadingImageArray &&
        this.isLoadingImageArray[this.getImageLayerIndex(parameter)]
      );
    },
    getStockImageUser(parameter) {
      return (
        this.stockImageUser &&
        this.stockImageUser[this.getImageLayerIndex(parameter)]
      );
    },

    // getImageUrlToShow(parameter, toReturnFullImage = false) {

    //   if (this.getInputParameters) {
    //     let foundIndex = this.getInputParameters.findIndex(
    //       (p) =>
    //         p["layer_name"] == parameter["layer_name"] &&
    //         p["name"] == "image_url" &&
    //         p["value"]
    //     );
    //     if (foundIndex >= 0) {
    //       return toReturnFullImage
    //         ? this.getInputParameters[foundIndex].value
    //         : this.getInputParameters[foundIndex].thumbnailUrl ||
    //         this.getInputParameters[foundIndex].value;
    //     }
    //     return "loading.gif";
    //   }
    //   return this.getImageFromParameters(parameter["layer_name"]);
    // },

    async getMediaImages(parameter, forceMore = false) {
      if (!parameter) return;
      const imageIndex = this.getImageLayerIndex(parameter);
      try {
        this.isLoadingImageArray[imageIndex] = true;

        if (!this.imagesAlternatives[imageIndex])
          this.imagesAlternatives[imageIndex] = [];

        if (!forceMore && this.imagesAlternatives[imageIndex].length > 0)
          return;

        let ratio = null;
        if (parameter["image_layer_type"]) {
          ratio = parameter["image_layer_type"];
        } else {
          if (parameter["layer_name"].includes("SQUARE")) ratio = "square";
          if (parameter["layer_name"].includes("PORTRAIT")) ratio = "vertical";
          if (parameter["layer_name"].includes("LANDSCAPE"))
            ratio = "horizontal";
        }
        if (this.stockMediaSearchTerm[imageIndex]) {
          if (this.service == "unsplash") {
            this.noMoreImages = false;
            const params = {
              service: this.service,
              searchTerms: [this.stockMediaSearchTerm[imageIndex]],
              countPerSearchTerm: 9,
              ratio,
            };
            const { items } = await this.getStockMedia(params);
            if (items && items.length) {
              const images = items.filter((image) =>
                this.isImage(image.mediaUrl)
              );
              this.imagesAlternatives[imageIndex] = [
                ...this.imagesAlternatives[imageIndex],
                ...images,
              ];
            }
          }
          if (this.service == "pexels") {
            this.noMoreImages = false;
            const params = {
              service: this.service,
              searchTerm: this.stockMediaSearchTerm[imageIndex],
              countPerSearchTerm: 9,
              ratio,
              nextPage: this.stockMediaNextPage[imageIndex],
              page: this.stockMediaNextPageNumber[imageIndex],
            };
            const { items, next_page } = await this.getStockMedia(params);
            if (items && items.length) {
              const images = items.filter((image) =>
                this.isImage(image.mediaUrl)
              );
              this.imagesAlternatives[imageIndex] = [
                ...this.imagesAlternatives[imageIndex],
                ...images,
              ];
            }
            this.stockMediaNextPage[imageIndex] = next_page;
          }
        } else {
          for (const type_value of [1, 2]) {
            const params = {
              customerId: this.getCurrentCustomer.id,
              type: type_value,
              notUsed: true,
              showHidden: false,
              startKey: this.lastKey[type_value],
              limit: "9",
            };
            const { items, last_key } = await this.fetchMediaData(params);
            this.lastKey[type_value] = last_key;
            if (items && items.length) {
              const images = items.filter((image) =>
                this.isImage(image.mediaUrl)
              );
              this.imagesAlternatives[imageIndex] = [
                ...this.imagesAlternatives[imageIndex],
                ...images,
              ];
            }
          }
          this.noMoreImages = !this.lastKey.some((v) => v);
        }

        this.imagesAlternativesCount =
          this.imagesAlternatives[imageIndex].length;
      } finally {
        this.isLoadingImageArray[imageIndex] = false;
      }
    },

    async setRandomImage(parameter) {
      // console.log("setRandomImage,.....");
      const imageIndex = this.getImageLayerIndex(parameter);
      let mediaItem;
      do {
        if (!this.imagesAlternatives[imageIndex].length) {
          this.modifyTemplate("/img/default-inst-img.42d27dab.svg", {
            layer_name: parameter["layer_name"],
            name: "image_url",
          });
          return;
        }

        // mediaItem = this.imagesAlternatives[imageIndex].splice(
        //   Math.floor(
        //     Math.random() * this.imagesAlternatives[imageIndex].length
        //   ),
        //   1
        // );
        const randomIndex = Math.floor(
          Math.random() * this.imagesAlternatives[imageIndex].length
        );
        mediaItem = this.imagesAlternatives[imageIndex][randomIndex];

        if (!mediaItem) {
          this.modifyTemplate("/img/default-inst-img.42d27dab.svg", {
            layer_name: parameter["layer_name"],
            name: "image_url",
          });
          return;
        }
      } while (!this.isImage(mediaItem.mediaUrl));
      this.modifyTemplate(
        mediaItem.mediaUrl,
        {
          layer_name: parameter["layer_name"],
          name: "image_url",
        },
        mediaItem.thumbnailUrl,
        this.stockMediaSearchTerm[imageIndex]
      );
      this.stockImageUser[imageIndex] = {
        userName: mediaItem.userName,
        userUsername: mediaItem.userUsername,
      };
    },

    modifyTemplate(
      newValue,
      parameter,
      thumbnailUrl = null,
      searchTerm = null
    ) {
      var foundIndex =
        this.getInputParameters &&
        this.getInputParameters.findIndex(
          (p) =>
            p["layer_name"] == parameter["layer_name"] &&
            p["name"] == parameter["name"]
        );
      const tmpInputParameters = [...this.getInputParameters];
      if (foundIndex == null || foundIndex < 0) {
        tmpInputParameters.push({
          layer_name: parameter["layer_name"],
          name: parameter["name"],
          value: newValue,
          searchTerm: searchTerm,
        });
      } else {
        tmpInputParameters[foundIndex]["value"] = newValue;
        if (thumbnailUrl)
          tmpInputParameters[foundIndex]["thumbnailUrl"] = thumbnailUrl;
        if (searchTerm)
          tmpInputParameters[foundIndex]["searchTerm"] = searchTerm;
      }
      this.setInputParameters(tmpInputParameters);
    },

    async setImageFromGrid(event) {
      // console.log("setImageFromGrid", event);
      const { image, parameter } = event;
      this.modifyTemplate(
        image["mediaUrl"],
        { ...parameter },
        image["thumbnailUrl"]
      );
      await this.setImages();
    },

    async createNewImage() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoadingGetImageFromTemplate = true;
      try {
        let params = {
          calendarItemId: this.currentContentReference.calendarItemId,
          customerId: this.getCurrentCustomer.id,
          template: this.template,
          inputParameters: this.getInputParameters,
        };
        const data = await this.createImageFromTemplate(params);
        if (!data) return;
        this.template = data.template;
        this.image = data.image;
        this.generatePressed = true;
      } finally {
        this.isLoadingGetImageFromTemplate = false;
      }
    },
    isMediaInfoReady(mediaInfo) {
      let mediaState = mediaInfo["mediaState"];
      if (!mediaState) return false;
      let jpg = mediaState["jpg"];
      let mp4 = mediaState["mp4"];
      let thumbnail = mediaState["thumbnail"];

      if (!(thumbnail || jpg || mp4)) return false;
      if (thumbnail == "error" || jpg == "error" || mp4 == "error") return true;
      if (thumbnail == "wip" || jpg == "wip" || mp4 == "wip") return false;

      return true;
    },

    async saveAndClose() {
      this.isLoadingGetImageFromTemplate = true;

      try {
        const data = await this.uploadMediaFromUrl({
          customerId: this.getCurrentCustomer.id,
          inputMediaUrl: this.image,
          createdBy: "6",
          header: {
            "User-Agent":
              "CoolBot/0.0 (https://example.org/coolbot/; coolbot@example.org)",
          },
        });
        if (data) {
          const media_id = data.data["media_id"];
          var mediaInfo = await this.getMediaInfo(media_id);
          while (!this.isMediaInfoReady(mediaInfo)) {
            await H.sleep(200);
            mediaInfo = await this.getMediaInfo(media_id);
          }
          mediaInfo["template"] = { ...this.template };
          this.currentContentReference.media[this.mediaObjectIndex] = mediaInfo;
        }

        this.setInputParameters([]);
        this.$emit("close");
      } finally {
        this.isLoadingGetImageFromTemplate = false;
      }
    },
    async cancel() {
      this.skippedTemplatesIds = [];
      this.setInputParameters([]);
      this.template = null;
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  display: flex;
}
.preview {
  display: inline-flex;
}
.modify-template {
  position: relative;
  &__wrapper {
    max-height: 90vh;
    overflow-y: auto;
  }
  &__header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 20px;
    border-bottom: 1px solid #ccc;
    .title {
      font-size: 16px !important;
    }
    .btn-wrap {
      button {
        box-shadow: none !important ;
        height: 36px;
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        color: #ffffff;
        justify-content: center;
        background: #7e5689;
        border-radius: 16px;
        font-size: 16px;
        transition: all 0.3s ease;
        cursor: pointer;
        ::v-deep {
          .v-btn__content {
            color: #ffffff;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .form-row {
    display: inline-flex;
    align-items: center;
    .color {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      text-align: center;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
      font-size: 12px;
      color: white;
      vertical-align: middle;
      border: 1px solid #ccc;
      margin-right: 10px;
    }
    .v-text-field {
      padding-top: 3px;
      margin-top: 0;
    }
  }
  .footer-actions {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 20px;
  }
  .main-img {
    &__wrapper {
      display: flex;
    }
    &__name {
      padding-right: 10px;
    }
    &__img {
      width: 100%;
      max-width: 200px;
      height: 190px;
      margin-right: 10px;
      border: 1px solid #ccc;
      margin-bottom: 5px;
      position: relative;
      .edit-image {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        cursor: pointer;
        bottom: 0;
        .v-btn {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .v-list {
    padding-top: 0;
    padding-bottom: 0;
    &-item {
      padding-left: 0;
    }
  }
}
.close {
  width: 25px;
  height: 25px;
  border: 1px solid #000;
  background: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -30px;
  border-radius: 50%;
  i {
    font-size: 10px;
  }
}

.select-image {
  display: flex;
  justify-content: space-between;
  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.v-menu__content {
  .v-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    img {
      width: 100%;
      max-width: 125px;
    }
  }
}
</style>
