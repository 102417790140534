<template>
  <div class="template-media-library">
    <div class="template-media-library__wrapper">
      <div
        class="item"
        @click="$emit('changeImage', { mediaUrl: item.mediaUrl })"
        v-for="item in calendarMediaItems"
        :key="item.mediaId"
      >
        <img
          :src="item.mediaUrlReduced ? item.mediaUrlReduced : item.mediaUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateRelatedMedia",
  props: {
    calendarMediaItems: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.template-media-library {
  width: 100%;
  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    max-height: 500px;
    min-height: 300px;
    overflow-y: auto;
    padding-right: 2px;
    .item {
      width: 100%;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
