import axios from "axios";

const axiosFunctions = axios.create({
  baseURL: process.env.VUE_APP_FUNCTIONS_URL,
});

export default {
  namespaced: true,
  state: {
    tasksList: [],
  },
  mutations: {
    setToTasksList(state, newValue) {
      state.tasksList = newValue;
    },
  },
  actions: {
    async getTask({ commit }, taskId = null) {
      try {
        if (!taskId) throw new Error("missing task id");
        const { data } = await axiosFunctions.get(`task/${taskId}`);
        data.sort((a, b) => a.createAt - b.createAt);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async listTasks({ commit }, params = {}) {
      try {
        const { data } = await axiosFunctions.get("task", { params });
        data.sort((a, b) => a.createAt - b.createAt);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async fetchTasks({ commit }, params) {
      try {
        const { data } = await axiosFunctions.get("task", { params });
        commit("setToTasksList", data);
      } catch (e) {
        throw e;
      }
    },
    async updateTask({ commit }, { taskId, params = {} }) {
      try {
        await axiosFunctions.put(`task/${taskId}`, params);
      } catch (e) {
        throw e;
      }
    },
    async createTask({ commit }, params = {}) {
      try {
        const { data } = await axiosFunctions.post("task", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getTasksList(state) {
      let result = [];

      const noClosedTasks = state.tasksList
        .filter((task) => !task.isClosed)
        .map((task) => task.customerId);

      noClosedTasks.forEach((task) => {
        const findInClosedTasksByCustomerId = result.find(
          (i) => i.customerId === task
        );

        if (findInClosedTasksByCustomerId) {
          findInClosedTasksByCustomerId.count += 1;
        } else {
          result = [
            ...result,
            ...[
              {
                customerId: task,
                count: 1,
              },
            ],
          ];
        }
      });

      return result;
    },
  },
};
