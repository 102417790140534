<template>
  <v-app>
    <div
      class="wrapper"
      :class="{
        'open-nav': isOpenNav,
        'main-smb': $route.name === 'smb-client',
      }"
      data-app
    >
      <div class="wrapper__sidebar" v-if="!isLoading">
        <SmbSidebar @toggleSidebar="isOpenNav = !isOpenNav" />
      </div>
      <div class="wrapper__content">
        <SmbHeader />
        <Loader v-if="isLoading" />
        <router-view v-else />
        <SmbPopup
          :isShow="isShowSmbPopup && isCanShowPopup"
          :title="getPopupText"
          @close="closePopupHandler"
        />
      </div>
    </div>
  </v-app>
</template>

<script>
import LogRocket from "logrocket";

import { mapActions, mapGetters, mapMutations } from "vuex";

import Loader from "@/components/global/Loader";
import SmbHeader from "@/components/smb-client/SmbHeader";
import SmbPopup from "@/components/smb-client/SmbPopup.vue";
import SmbSidebar from "@/components/smb-client/SmbSidebar";
import { ACTIVITY_STATE } from "@/constants/globals";
import helper from "@/mixins/helper";
import initHelpHero from "helphero";
var HelpHero = initHelpHero("Ccs0ZVgDss");
export default {
  name: "SmbClient",
  data: () => ({
    isLoading: false,
    isCanShowPopup: true,
    isOpenNav: true,
    scheduledContent: [],
    blockedCustomersIdsForLogrocket: [
      "1f13170e-be5a-11ed-8e4f-9a2ec078a965",
      "dan18982-9e4a-11ec-a0ac-1d80a5b0104f",
      "d20fc034-0f48-11ee-ac60-f29a369f6d3b",
      "xHCz",
    ],
    activityStateList: ACTIVITY_STATE,
  }),
  mixins: [helper],
  components: {
    SmbHeader,
    Loader,
    SmbPopup,
    SmbSidebar,
  },
  async created() {
    this.isLoading = true;
    if (this.$route.params.id && !this.getCurrentSmbCustomer.id) {
      try {
        await this.getSmbCustomerData(this.$route.params.id);
      } catch (e) {
        await this.$router.push({ name: "saas-page-not-found" });
      }
    }

    if (
      this.getCurrentSmbCustomer.activity_state ===
        this.activityStateList.find((activity) => activity.id === 3).name ||
      this.getCurrentSmbCustomer.activity_state ===
        this.activityStateList.find((activity) => activity.id === 5).name
    ) {
      await this.$router.push({ name: "saas-no-access" });
    }

    if (this.getCurrentSmbCustomer.onboarding_required) {
      await this.$router.push({ name: "saas-onboarding" });
    }

    if (
      !this.blockedCustomersIdsForLogrocket.includes(
        this.getCurrentSmbCustomer.id
      )
    ) {
      LogRocket.init("m2bv2o/smm-dashboard");
      LogRocket.startNewSession();
      LogRocket.identify(this.getCurrentSmbCustomer.id, {
        name: this.getCurrentSmbCustomer.name,
      });
    }
    await this.amountPosts();
    this.calculateVh();
    window.addEventListener("resize", this.calculateVh);
    window.addEventListener("orientationchange", this.calculateVh);
    this.setValueForShowPopupAbility();
    this.isLoading = false;
    this.delighted();

    HelpHero.identify(this.getCurrentSmbCustomer.id, {
      name: this.getCurrentSmbCustomer.name,
    });
  },
  computed: {
    ...mapGetters("smbClientSelection", [
      "getCurrentSmbCustomer",
      "isShowSmbPopup",
      "getPopupText",
      "getIsNeedToRedirectAfterOkAction",
    ]),
  },
  methods: {
    ...mapActions("smbPost", ["fetchAmountsPostsToReview"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    ...mapMutations("smbClientSelection", ["setPopupSettings"]),
    async getSmbCustomerData(customerId) {
      await this.fetchSmbClientInfo(customerId);
    },
    calculateVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");
    },
    closePopupHandler(isChecked) {
      this.setPopupSettings({
        isShowPopup: false,
        isNeedToRedirect: this.getIsNeedToRedirectAfterOkAction,
      });

      if (isChecked) {
        localStorage.setItem(
          `isShowThisMessage-${this.getCurrentSmbCustomer.id}`,
          false
        );
      }

      this.setValueForShowPopupAbility();
      if (this.$isMobile()) {
        if (this.getIsNeedToRedirectAfterOkAction) {
          this.$router.push({ name: "generate-content" });
        }
      } else {
        if (this.$route.name !== "generate-content") {
          this.amountPosts();
          if (this.getIsNeedToRedirectAfterOkAction) {
            this.$router.push({ name: "generate-content" }).catch(() => {});
          }
        }
      }
    },
    getValueFromLocalStorage(key = "") {
      return localStorage.getItem(key);
    },
    setValueForShowPopupAbility() {
      this.isCanShowPopup = !this.getValueFromLocalStorage(
        `isShowThisMessage-${this.getCurrentSmbCustomer.id}`
      );
    },
    async amountPosts() {
      try {
        await this.fetchAmountsPostsToReview({
          customerId: this.getCurrentSmbCustomer.id,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  min-height: calc(var(--vh) * 100);
  display: flex;
  flex-direction: row;
  padding-left: 60px;
  transition: all 0.3s ease;
  &.open-nav {
    padding-left: 245px;
    transition: all 0.3s ease;
    @media screen and (max-width: 1500px) {
      padding-left: 235px;
    }
  }
  @media screen and (max-width: 760px) {
    padding-left: 0 !important;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    flex-shrink: 0;
    z-index: 11;
    min-height: calc(var(--vh) * 100);
    @media screen and (max-width: 760px) {
      display: none;
    }
  }
  &.main-smb {
    padding-left: 0 !important;
    .wrapper__sidebar {
      display: none !important;
    }
    ::v-deep {
      .header {
        display: flex !important;
      }
    }
  }
}
</style>
