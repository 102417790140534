<template>
  <div class="media">
    <span class="close" @click="closeModal()">
      <i class="icon-close"></i>
    </span>
    <div class="media__list">
      <template v-for="(image, index) in mediaList">
        <div class="media__list-item" :key="`${mediaIndex}-${index}`">
          <img
            :src="image.thumbnailUrl || image.mediaUrl"
            @click="selectImage(image)"
          />
        </div>
      </template>
    </div>
    <div class="media__more">
      <v-btn :disabled="noMoreImages" @click="getMoreImages" elevation="2"
        >Get More Media</v-btn
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "mediaGrid",
  props: {
    gridImagesAlternatives: {
      type: Array,
      default: () => [],
    },
    mediaIndex: {
      type: Number,
      default: 0,
    },
    isDialogMediaGrid: {
      type: Boolean,
    },
    noMoreImages: {
      type: Boolean,
      default: false,
    },
    parameter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mediaList: [],
    };
  },
  created() {
    this.mediaList = this.gridImagesAlternatives;
  },
  watch: {
    isDialogMediaGrid() {
      this.mediaList = this.gridImagesAlternatives;
    },
  },

  methods: {
    selectImage(image) {
      this.$emit("select", { image, parameter: { ...this.parameter } });
      this.closeModal();
    },
    async getMoreImages() {
      this.$emit("getMoreImages");
      this.mediaList = this.gridImagesAlternatives;
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss">
.media {
  width: 100%;
  background: #fff;
  padding: 40px 20px;
  position: relative;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    &-item {
      width: 100%;
      height: 150px;
      border: 1px solid #ccc;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
}
</style>
