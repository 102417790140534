import BaseClass from "@/models/BaseClass";
import MediaFile from "@/models/MediaFile";
import H from "@/utils/helper";

const fields = [
  "body",
  "createdAt",
  "from",
  "id",
  "source",
  "to",
  "updatedAt",
  "mediaUrl",
  "isBodyLink",
  { field: "outgoing", default: false },
  { field: "canGetMoreInformation", default: true },
  { field: "smmId", default: null },
  { field: "isEmail", default: false },
];

export default class Message extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
    this.mediaInfo = !H.isEmpty(obj, "mediaInfo")
      ? new MediaFile(obj.mediaInfo)
      : new MediaFile();
  }
}
