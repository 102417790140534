export default {
  namespaced: true,
  state: {
    isDropOnTheInstagramWidget: false,
    currentDroppedFile: null,
  },
  mutations: {
    setDropOnTheInstagramWidget(state, flag = false) {
      state.isDropOnTheInstagramWidget = flag;
    },
    setFile(state, file = null) {
      state.currentDroppedFile = file;
      state.isDropOnTheInstagramWidget = false;
    },
  },
  actions: {
    generateText({ commit }, params) {},
  },
  getters: {
    getDropOnTheInstagramWidgetFlag(state) {
      return state.isDropOnTheInstagramWidget;
    },
    getCurrentDroppedFile(state) {
      return state.currentDroppedFile;
    },
  },
};
