import axios from "axios";

export default {
  namespaced: true,
  actions: {
    async fetchSmmList({ commit }) {
      try {
        const { data } = await axios.get("/smm");
        return data;
      } catch (e) {
        throw e;
      }
    },
    async sendClientForm({ commit }, params = {}) {
      try {
        const { data } = await axios.post("/customer", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
