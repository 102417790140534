<template>
  <v-card elevation="2" class="color-picker">
    <v-container>
      <v-row>
        <v-col>
          <v-color-picker
            v-model="color"
            :swatches="getCurrentCustomer.brand_color_palettes"
            show-swatches
            dot-size="25"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
          ></v-color-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-space-between">
          <v-btn class="ml-1" rounded min-width="120" @click="$emit('close')">
            Cancel
          </v-btn>
          <v-btn
            class="mr-1 save-btn"
            rounded
            color="#7E5689"
            @click="$emit('saveColor', color)"
            min-width="120"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectColor",
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  data: () => ({
    color: null,
  }),
};
</script>

<style scoped lang="scss">
.save-btn {
  color: #fff !important;
}
.color-picker {
  ::v-deep {
    .v-color-picker {
      &__swatches {
      }
      &__color {
        > div {
          border: 1px solid #ccc;
        }
      }
    }
  }
}
</style>
