import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  methods: {
    ...mapActions("filesController", ["getUrlForSendFile", "uploadFile"]),

    async uploadFileHandler(
      filesList,
      customerId = this.getCurrentCustomer.id
    ) {
      for (const file of filesList) {
        const { url, mediaId, fields } = await this.createUrlForFile(
          file,
          customerId,
          "media"
        );

        await this.uploadFile({
          url,
          file: file && file.file ? file.file : file,
          fields,
        });
        await this.createUrlForFile(file, customerId, "media", true, mediaId);

        return { url, mediaId, fields };
      }
    },

    async createUrlForFile(
      file,
      customerId,
      type,
      isTag = false,
      imageId = null
    ) {
      try {
        const { url, mediaId, fields } = await this.getUrlForSendFile({
          file,
          customerId,
          type,
          isTag,
          imageId,
        });
        return { url, mediaId, fields };
      } catch (e) {
        throw e;
      }
    },
  },
};
