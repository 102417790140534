export default function isCustomerSelected({ next, nextMiddleware }) {
  if (
    sessionStorage.currentCustomer == "null" ||
    !sessionStorage.currentCustomer
  ) {
    return next({
      name: "client-selection-page",
    });
  }
  return nextMiddleware();
}
