<template>
  <div class="messages">
    <div class="messages__title">Messages</div>
    <div class="messages__wrapper">
      <div v-if="messages.length" class="messages__list">
        <div
          class="messages__list-item"
          v-for="item in messages"
          :key="item.id"
        >
          <div class="l-cell">
            <div class="icon">
              <i class="icon-envelope" v-if="item.isEmail"></i>
              <i class="icon-sms" v-else></i>
            </div>
            <div class="text">
              {{
                item.body
                  ? item.body
                  : item.mediaUrl && !item.body
                  ? "[SYSTEM_MESSAGE] MEDIA FROM CUSTOMER"
                  : "[SYSTEM_MESSAGE]"
              }}
            </div>
          </div>
          <div class="r-cell">
            <span class="date">{{
              $dayjs.unix(item.createdAt).format("DD/MM/YYYY")
            }}</span>
          </div>
        </div>
      </div>
      <div v-else class="messages__blank">
        <div class="icon">
          <i class="icon-communication"></i>
        </div>
        <div class="text">No messages sent by client</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Messages",
  data: () => ({
    messages: [],
    loading: true,
  }),
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  async mounted() {
    await this.fetchMessages();
    this.loading = false;
  },
  methods: {
    ...mapActions("communication", ["getChatMessages"]),
    async fetchMessages() {
      const data = await this.getChatMessages(this.getCurrentCustomer.id);
      this.messages = data
        .filter((i) => i.outgoing === false)
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    },
  },
};
</script>

<style scoped lang="scss">
.messages {
  width: 100%;
  &__title {
    width: 100%;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
    @media screen and (max-height: 950px) {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  &__wrapper {
    width: 100%;
    background: #fff;
  }
  &__list {
    overflow-y: auto;
    max-height: 200px;
    &-item {
      border-bottom: 1px solid #ccc;
      padding: 5px 20px;
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      min-height: 65px;
      .l-cell {
        display: inline-flex;
        align-items: center;
        .icon {
          margin-right: 5px;
          display: inline-flex;
          i {
            font-size: 24px;
          }
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }

  &__blank {
    height: 200px;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(126, 89, 137, 1);
    background: rgba(126, 89, 137, 0.2);
    border-radius: 5px;
    border: 1px solid rgba(126, 89, 137, 0.4);
    .text {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
    }
    .icon {
      width: 42px;
      height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: #ffffff;
      font-size: 22px;
      margin-bottom: 20px;
      color: rgba(126, 89, 137, 1);
    }
  }
}
</style>
