<template>
  <div>
    <div class="rich-text">
      <VueEditor
        :editor-toolbar="toolbar"
        :placeholder="placeholder"
        v-model="note"
      />
      <div class="controls">
        <div class="controls__item">
          <Uploader
            :ref="fileUploaderRef"
            class="uploader"
            className="messages"
            fileFormat="pdf"
            acceptFiles="image/*, video/*, application/pdf"
            @uploadFile="$emit('uploadFile', $event)"
            componentRef="client-note"
            isUseCustomElementInSlot
          >
            <template v-slot:uploader-block>
              <v-icon> mdi-paperclip </v-icon>
            </template>
          </Uploader>
        </div>
        <div class="controls__item" v-if="isShowControlsBtn">
          <button
            v-if="isEditFile"
            @click="$emit('cancelEdit')"
            class="save cancel"
          >
            Cancel
          </button>
          <button @click="$emit('save')" class="save">Save</button>
        </div>
      </div>
    </div>

    <div class="attach-list" v-if="attachFiles.length">
      <div class="attach-list__item" v-for="file in attachFiles" :key="file.id">
        <div class="file-name">{{ file.name }}</div>
        <div class="file-remove" @click="$emit('removeFile', file)">
          <i class="icon-close"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Uploader from "@/components/global/Uploader";

export default {
  name: "NoteRichTextEditor",
  components: { Uploader },
  props: {
    toolbar: {
      type: Array,
      default: () => [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        /* [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent*/
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
        ["clean"], // remove formatting button
      ],
    },
    noteMessage: {
      type: String,
      default: "",
    },
    attachFiles: {
      type: Array,
      default: () => [],
    },
    fileUploaderRef: {
      type: String,
      default: "fileUploader" + new Date(),
    },
    isEditFile: {
      type: Boolean,
      default: false,
    },
    isShowControlsBtn: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  computed: {
    note: {
      get() {
        return this.noteMessage;
      },
      set(value) {
        this.$emit("updateNote", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.rich-text {
  position: relative;
  .quillWrapper {
    ::v-deep {
      .ql-snow .ql-tooltip,
      .ql-tooltip.ql-editing {
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }
  }
  .controls {
    position: absolute;
    bottom: 1px;
    right: 1px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    &__item {
      margin-left: 15px;
    }
    .save {
      background: #7e5689;
      height: 36px;
      padding: 0 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      border-radius: 3px 0 3px 0;
      color: #fff;
    }
    .cancel {
      background: $color-grey2 !important;
      margin-right: 5px;
    }
  }
}
.attach-list {
  display: inline-flex;
  flex-wrap: wrap;
  &__item {
    margin-top: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $color-violet;
    padding: 0 7px;
    border-radius: 4px;
    height: 20px;
    margin-right: 4px;
    .file-name {
      font-size: 12px;
      color: $color-white;
      font-weight: 500;
      width: 100%;
      max-width: 210px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .file-remove {
      margin-left: 8px;
      font-size: 9px;
      color: $color-white;
      cursor: pointer;
    }
  }
}
</style>
