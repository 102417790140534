import axios from "axios";
import RecommendationResponse from "@/models/Recommendations/RecommendationResponse";
import Customer from "@/models/Customer";

const axiosFunctions = axios.create({
  baseURL: process.env.VUE_APP_FUNCTIONS_URL,
});
export default {
  namespaced: true,
  state: {
    recommendations: [],
    done: false,
  },
  mutations: {
    setRecommendationList(state, newRecommendation) {
      state.recommendations = newRecommendation;
    },
    setToRecommendationList(state, newRecommendation) {
      state.recommendations = [...state.recommendations, ...newRecommendation];
    },
    setIsDoneFlag(state, newDoneFlag) {
      state.done = newDoneFlag;
    },
    removeRecommendationById(state, recommendationId) {
      state.recommendations = state.recommendations.filter(
        (recommendation) => recommendation.id !== recommendationId
      );
    },
    setLoadingFlagForTheRecommendation(state, { recommendationButton, flag }) {
      state.recommendations.forEach((recommendation) => {
        if (recommendation.id === recommendationButton.id) {
          recommendation.isLoading = flag;
        }
      });
    },
    removeRecommendationThatHaveIndexMoreThan(state, index) {
      state.recommendations = state.recommendations.filter(
        (recommendation, recommendationIndex) => recommendationIndex < index
      );
    },
  },
  actions: {
    async fetchRecommendationsList({ commit }, params = {}) {
      try {
        const { data } = await axiosFunctions.get(
          `recommendation?customerId=${params.customerId}&limit=${params.limit}`
        );
        const { list, done } = new RecommendationResponse(data);

        commit(
          params.isLoadMoreClicked
            ? "setToRecommendationList"
            : "setRecommendationList",
          list
        );
        commit("setIsDoneFlag", done);

        return new RecommendationResponse(data);
      } catch (e) {
        throw e;
      }
    },
    async createPostFromRecommendation({ commit }, params = {}) {
      try {
        const { data } = await axiosFunctions.post(`recommendation`, params);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async createSaasSmbPosts({ commit }, params = {}) {
      try {
        await axiosFunctions.post(`saas`, params);
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getRecommendationList(state) {
      return state.recommendations;
    },
    getIsDoneFlagForRecommendationList(state) {
      return state.done;
    },
  },
};
