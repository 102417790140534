<template>
  <div>
    <v-tooltip v-if="isShowBtn" bottom>
      <template v-slot:activator="{ on }"
        ><v-btn
          class="mt-2"
          :loading="isLoadingGetTemplate"
          @click="createTemplateMediaFromCreationWidget()"
          v-on="on"
          color="orange lighten-2"
          dark
          block
        >
          <v-icon v-if="filteredTemplates && filteredTemplates.length > 0"
            >mdi-file-image-plus-outline</v-icon
          >
          <v-icon v-else>mdi-file-image-remove-outline</v-icon>
        </v-btn>
      </template>
      <span style="font-size: 12px">Create image from template</span>
    </v-tooltip>

    <v-icon
      v-if="
        isShowMediaWidgetBtn && (!templateTypes || templateTypes.length === 0)
      "
      red
      >mdi-file-image-remove-outline</v-icon
    >
    <v-menu
      v-if="isShowMediaWidgetBtn && templateTypes && templateTypes.length > 0"
      :loading="isLoadingGetTemplate"
      offset-y
      top
      open-on-hover
      close-delay="150"
      open-delay="5"
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-if="!isLoadingGetTemplate" dark v-on="on" v-bind="attrs"
          >mdi-file-image-plus-outline</v-icon
        >
        <v-icon v-else dark v-on="on" v-bind="attrs"
          >mdi-refresh mdi-spin</v-icon
        >
      </template>

      <v-list>
        <v-list-item
          v-for="(templateType, index) in templateTypes"
          :key="index"
          link
        >
          <v-list-item-title
            @click="createTemplateMediaFromSelections(templateType)"
            v-text="templateType"
          ></v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- <v-btn
        v-for="templateType in templateTypes"
        :key="templateType"
        @click="createTemplateMediaFromSelections(templateType)"
      >
        {{ templateType }}
      </v-btn> -->
    </v-menu>

    <ImageFromTemplate
      :key="mediaObjectIndex"
      v-if="isShowTemplate && file.template"
      @close="closeImageFromTemplate(mediaObjectIndex)"
      :mediaObjectIndex="mediaObjectIndex"
      :isDialogModifyImageTemplate="
        isDialogModifyImageTemplate[mediaObjectIndex]
      "
      :isPost="isPost"
      :isStory="!isPost"
    />
    <ModalDialog
      :isShow="isNoMatchingTemplates"
      title="Templates"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="[{ name: 'Ok', emitName: 'close', btnClass: 'violet' }]"
      @close="isNoMatchingTemplates = false"
    >
      <template v-slot:description> No matching templates found </template>
    </ModalDialog>
  </div>
</template>

<script>
import ImageFromTemplate from "@/components/templates/ImageFromTemplate";
import ModalDialog from "@/components/global/ModalDialog";
import MediaField from "@/models/MediaFile/MediaField";
import { mapActions, mapGetters, mapMutations } from "vuex";
import H from "@/utils/helper";
export default {
  name: "MyTemplate",
  data: () => ({
    isLoadingGetTemplate: false,
    isTemplateVisible: false,
    currentContentReference: null,
    myType: null,
    fetchedTemplates: [],
    isNoMatchingTemplates: false,
  }),
  props: {
    file: {
      type: Object,
      default: () => {},
    },
    isShowBtn: {
      type: Boolean,
      default: false,
    },
    isShowMediaWidgetBtn: {
      type: Boolean,
      default: false,
    },
    isShowTemplate: {
      type: Boolean,
      default: false,
    },
    mediaObjectIndex: {
      type: Number,
      default: 1,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    isDialogModifyImageTemplate: {
      type: Boolean,
      default: true,
    },
    isDialogModifyImageTemplateArray: {
      type: Array,
      default: () => [],
    },
    mediaLibrarySelection: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    filteredTemplates(newValue) {
      let isBtnWithGrayColor = newValue.length < 1;
      this.$emit("isAddOrRemoveGrayColorToBtn", isBtnWithGrayColor);
    },
  },
  components: {
    ImageFromTemplate,
    ModalDialog,
  },

  async created() {
    setTimeout(async () => {
      //without this delay the fetchTemplates can happen before the calendarItem is properly loaded...
      await this.init();
      await this.fetchTemplates();
      this.setFilteredTemplates(this.filteredTemplates);
    }, 1000);
  },

  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("post", ["getCurrentPost"]),
    ...mapGetters("stories", ["getCurrentStories"]),
    ...mapGetters("mediaLibraryWidget", ["getMediaLibrarySelections"]),
    ...mapGetters("instagramPost", ["getIsStoriesTabActive"]),

    filteredTemplates() {
      if (!(this.fetchedTemplates && this.fetchedTemplates.length > 0))
        return [];
      const storyOrStoryStr = this.isStory ? "story" : "post";
      if (this.file && this.file.template && this.file.template.template_type) {
        return this.fetchedTemplates.filter(
          (t) =>
            t.template_type === this.file.template.template_type &&
            t.images_count === this.file.template.images_count &&
            t.name.toLowerCase().includes(storyOrStoryStr)
        );
      }

      if (this.isShowMediaWidgetBtn) {
        const len =
          (this.mediaLibrarySelection && this.mediaLibrarySelection.length) ||
          -1;
        return this.fetchedTemplates.filter(
          (t) =>
            t.template_type &&
            t.images_count === len &&
            t.name.toLowerCase().includes(storyOrStoryStr)
        );
      }
      const results = this.fetchedTemplates.filter((t) =>
        t.name.toLowerCase().includes(storyOrStoryStr)
      );
      // console.log("filteredTemplate results:", results);
      return results;
    },

    templateTypes() {
      // console.log("filteredTemplates", filteredTemplates);
      const foundTemplateTypes = [
        ...new Set(this.filteredTemplates.map((t) => t.template_type)),
      ];
      // console.log("foundTemplateTypes", foundTemplateTypes);
      return foundTemplateTypes.filter((t) => t != "Other");
    },
  },
  methods: {
    ...mapMutations("post", [
      "pushToPostMediaFiles",
      "clearPostSelectionsMediaFiles",
    ]),
    ...mapMutations("stories", [
      "pushToStoriesMediaFiles",
      "clearStoriesSelectionsMediaFiles",
    ]),
    ...mapActions("templates", [
      "listTemplates",
      "getTemplate",
      "diagestTemplate",
      "createImageFromTemplate",
    ]),
    ...mapMutations("templates", [
      "setFilteredTemplates",
      "setInputParameters",
    ]),
    ...mapMutations("mediaLibraryWidget", ["clearMediaLibrarySelections"]),
    async init() {
      if (this.isStory) this.currentContentReference = this.getCurrentStories;
      else if (this.isPost) this.currentContentReference = this.getCurrentPost;
    },

    async runDiagestTemplate(initialTemplate, inputParameters) {
      try {
        let params = {
          calendarItemId: this.currentContentReference.calendarItemId,
          customerId: this.getCurrentCustomer.id,
          template: initialTemplate,
          inputParameters: inputParameters,
        };
        return await this.diagestTemplate(params);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async createTemplateMediaFromCreationWidget() {
      try {
        const initialTemplate = H.getRandomElement(this.filteredTemplates);
        if (!initialTemplate) {
          console.error("no template found...");
          this.isNoMatchingTemplates = true;
          return;
        }
        const inputParameters = [];

        const template = await this.runDiagestTemplate(
          initialTemplate,
          inputParameters
        );
        let mediaObject = {
          template: template,
          mediaUrl: template["preview_url"],
        };

        if (this.isTemplateFull(template)) {
          const mediaObjectFull = await this.createMediaObjectFromUrl(
            template,
            inputParameters
          );
          mediaObject = mediaObjectFull;
        }

        const newMediaObj = new MediaField(mediaObject);
        if (this.isPost) {
          this.pushToPostMediaFiles(newMediaObj);
        } else if (this.isStory) {
          this.pushToStoriesMediaFiles(newMediaObj);
        }
        this.setInputParameters(inputParameters);
        this.setFilteredTemplates(this.filteredTemplates);

        setTimeout(() => {
          this.setIsDialogModifyImageTemplate(
            this.currentContentReference.media.length - 1,
            true
          );
        }, 1000);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.isLoadingGetTemplate = false;
      }
    },

    async createTemplateMediaFromSelections(templateType = null) {
      // console.log("createTemplateMediaFromSelections", templateType);

      this.isLoadingGetTemplate = true;
      try {
        const imagesCount = this.getMediaLibrarySelections.length;
        // const template = await this.getInitialTemplate(
        //   templateType,
        //   imagesCount
        // );
        const initialTemplate = H.getRandomElement(this.filteredTemplates);
        if (!initialTemplate) {
          console.error("no template found...");
          return;
        }

        const inputParameters = await this.constructInputParameters(
          initialTemplate["parameters"],
          imagesCount
        );

        const template = await this.runDiagestTemplate(
          initialTemplate,
          inputParameters
        );

        const mediaObject = await this.createMediaObjectFromUrl(
          template,
          inputParameters
        );

        const newMediaObj = new MediaField(mediaObject);
        if (this.isPost) {
          this.pushToPostMediaFiles(newMediaObj);
          this.clearPostSelectionsMediaFiles();
        } else if (this.isStory) {
          this.pushToStoriesMediaFiles(newMediaObj);
          this.clearStoriesSelectionsMediaFiles();
        }
        this.clearMediaLibrarySelections();

        this.setFilteredTemplates(this.filteredTemplates);
        this.setInputParameters(inputParameters);
        if (this.isTemplateFull(template)) return;

        setTimeout(() => {
          this.setIsDialogModifyImageTemplate(
            this.currentContentReference.media.length - 1,
            true
          );
        }, 1000);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.isLoadingGetTemplate = false;
      }
    },

    async createMediaObjectFromUrl(template, inputParameters) {
      let paramsCreateImage = {
        calendarItemId: this.currentContentReference.calendarItemId,
        customerId: this.getCurrentCustomer.id,
        template: template,
        inputParameters: inputParameters,
      };
      const data = await this.createImageFromTemplate(paramsCreateImage);
      if (data) {
        return {
          mediaUrl: data.image,
          template: data.template,
        };
      }
      console.error("didn't create image...");
      return {
        mediaUrl: template["preview_url"],
        template: template,
      };
    },

    async fetchTemplates() {
      try {
        this.isLoadingGetTemplate = true;
        let params = {
          calendarItemId: this.currentContentReference.calendarItemId || "1234",
          customerId: this.getCurrentCustomer.id,
        };
        this.fetchedTemplates = await this.listTemplates(params);
      } catch (e) {
        this.isError = true;
      } finally {
        this.isLoadingGetTemplate = false;
      }
    },

    async constructInputParameters(templateParameters, imagesCount = 0) {
      const mainImage = templateParameters.find((p) =>
        p["layer_name"].includes("MAIN IMAGE")
      );
      const subImage = templateParameters.find((p) =>
        p["layer_name"].includes("SUB IMAGE")
      );
      let inputParameters = [];
      if (imagesCount > 0) {
        inputParameters.push({
          layer_name: mainImage["layer_name"],
          name: "image_url",
          value: this.getMediaLibrarySelections[0].mediaUrl,
        });
      }
      if (imagesCount > 1) {
        inputParameters.push({
          layer_name: subImage["layer_name"],
          name: "image_url",
          value: this.getMediaLibrarySelections[1].mediaUrl,
        });
      }

      return inputParameters;
    },

    async getInitialTemplate(templateType = null, imagesCount = null) {
      let paramsGetTemplate = {
        calendarItemId: this.currentContentReference.calendarItemId || "1234",
        customerId: this.getCurrentCustomer.id,
        template_type: templateType,
        // images_count: imagesCount,
        // ratio: ratio,
        // ratio: (this.isPost) ? "square" : "vertical",
      };
      let template = await this.getTemplate(paramsGetTemplate);
      // console.log("template", template);
      return template;
    },

    isTemplateFull(template) {
      if (!template || !template["parameters"]) {
        return false;
      }
      const parameters = template["parameters"];
      const mandatoryParameters = parameters.filter((p) => p["isMandatory"]);

      let errors = mandatoryParameters.filter((p) => p["value"] == null);
      return Boolean(errors.length == 0);
    },

    setIsDialogModifyImageTemplate(index, value) {
      this.$emit("setIsDialogModifyImageTemplate", { index, value });
    },
    closeImageFromTemplate(index) {
      this.$root.$emit("mediaObjectModified", {
        index,
        reason: "templateModified",
      });
      this.setIsDialogModifyImageTemplate(index, false);
    },
  },
};
</script>

<style scoped></style>
