import axios from "axios";

export default {
  namespaced: true,
  actions: {
    async fetchCustomerInsights({ commit }, { id, params }) {
      try {
        const { data } = await axios.get(`/customer/${id}/social_insights`, {
          params,
        });

        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
