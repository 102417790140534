import Cookies from "js-cookie";

import axios from "axios";

import User from "@/models/User";

export default {
  namespaced: true,
  state: {
    currentUser: null,
    user: {
      loggedIn: false,
      isCustomerSelected: false,
    },
  },
  mutations: {
    setUser(state, user) {
      state.currentUser = user;
      Cookies.set("currentUser", user);
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setAuthFlag(state, id) {
      state.user.loggedIn = true;
      Cookies.set("isUserLoggedIn", true);
    },
    removeAuthFlag(state) {
      state.user.loggedIn = false;
      Cookies.remove("isUserLoggedIn");
    },
  },
  actions: {
    async loginUser({ commit }, params = {}) {
      try {
        const { data } = await axios.get(
          `smm/${params.name}_${params.password}`
        );
        commit("setUser", new User(data));
        commit("setAuthFlag");
      } catch (e) {
        throw e;
      }
    },
    logOutUser({ commit }, params = {}) {
      commit("removeAuthFlag");
    },
  },
  getters: {
    auth(state) {
      return state.user;
    },
    getCurrentUser(state) {
      return state.currentUser;
    },
  },
};
