export default {
  data: () => ({
    rules: {
      beforePercent: (v) => /^[0-9][0-9]?%|^100%$/.test(v) || "Not valid value",
      afterPercent: (v) => v.split("%")[1] === "" || "Not valid value",
      beforeCommission: (v) => v.split("$")[0] === "" || "Not valid value",
      commission: (v) => isFinite(v.split("$")[1]) || "Not valid value",
      text: (v) => /^[a-zA-Z]+ [a-zA-Z]+$/.test(v) || "Not valid value",
      email: (v) => {
        if (v && v.length > 0) {
          return (
            /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(
              v
            ) || "Must be valid email. For example: example@example.com"
          );
        } else {
          return true;
        }
      },
      url: (v) =>
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(
          v
        ) ||
        v === "" ||
        v === null ||
        v === undefined ||
        "Not valid url",
      dropboxUrl: (v) =>
        /^https:\/\/www.dropbox.com\/sh\/[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(
          v
        ) ||
        v === "" ||
        v === null ||
        v === undefined ||
        "Not valid dropbox shared link. only 'can view' shares are supported",
      phone: (v) => {
        if (v && v.length > 0) {
          return /^(\d){1,12}$/g.test(v) || "Not valid phone number.";
        } else {
          return true;
        }
      },
      phoneLength: (v) =>
        v && v.length === 10 ? true : "Wrong phone number entered.",
      numbers: (v) =>
        /^[-+]?[0-9]*[.,]?[0-9]+(?:[eE][-+]?[0-9]+)?$|^$|^\s$/.test(v) ||
        v === null ||
        v === undefined ||
        "Not valid numeric",
      integer: (v, msg = "Not valid integer") =>
        /^\d+$/.test(v) || v === null || v === undefined || msg,
      max100: (v) =>
        (v >= 0 && v <= 100) ||
        "The value must be greater than 0 and less than 100",
      max60: (v) => (v >= 1 && v <= 60) || "Max value is 60 ",
      requiredAndNumber: (v) =>
        (v !== null &&
          v !== undefined &&
          /^[-+]?[0-9]*[.,]?[0-9]+(?:[eE][-+]?[0-9]+)?$/.test(v)) ||
        "Field is required and must be valid numeric",
      required: (v) => !!v > 0 || "Field is required",
      requiredValue: (v) =>
        (v !== undefined && v !== null) || "Field is required",
      requiredSelect: (v) => {
        return v.name !== undefined || "Field select is required";
      },
      requiredMultiselect: (v) => v.length > 0 || "Field select is required",
      minLength: (v) =>
        (v && v.length > 2) || "Field length should be more than 3",
      loginEmailRules: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      passwordMustBeWithoutSpaces: (v) =>
        v.trim().length > 0 || "Password must not contain spaces",
      fieldMustBeWithoutSpaces: (v) =>
        v === "" ||
        v === null ||
        v === undefined ||
        v.length === 0 ||
        /^\S+$/.test(v) ||
        "Field must not contain spaces",
      digitLettersSigns: (v) =>
        /^[A-Za-z0-9-_+=]+$/.test(v) ||
        "Fiels can contain on digits or letters",
      existingEmail: (v, arr) => {
        if (v.length === 0) return true;
        let existingEmails = arr.filter((i) => i === v);
        if (existingEmails.length > 1) {
          return "This email already exist";
        } else {
          return true;
        }
      },
      emailList: (v) => {
        if (v.length) {
          return (
            v.every((i) =>
              /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(
                i
              )
            ) || "Must be valid email. For example: example@example.com"
          );
        } else {
          return true;
        }
      },
      invalidChipEmail: (email) => {
        if (email.length) {
          return !/^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(
            email
          );
        } else {
          return true;
        }
      },
      emailExists(arr) {
        if (arr.length && new Set(arr).size !== arr.length) {
          return "This email already exist";
        } else {
          return true;
        }
      },
      isEmailHasSpecificDomainName: (v) => {
        const domain = v.split("@")[1];
        if (!domain) return "Not a valid email";

        const isValidDomain = allowedDomains.some((allowedDomain) =>
          domain.endsWith(allowedDomain)
        );

        return isValidDomain || "Email must have a valid domain";
      },
    },
  }),
};

const allowedDomains = [
  "com",
  "net",
  "realtor",
  "realty",
  "realestate",
  "biz",
  "org",
  "info",
  "mobi",
  "asia",
  "bike",
  "clothing",
  "guru",
  "singles",
  "holdings",
  "ventures",
  "equipment",
  "plumbing",
  "camera",
  "gallery",
  "estate",
  "graphics",
  "lighting",
  "photography",
  "construction",
  "contractors",
  "directory",
  "kitchen",
  "land",
  "technology",
  "today",
  "sexy",
  "diamonds",
  "tips",
  "voyage",
  "careers",
  "photos",
  "recipes",
  "shoes",
  "enterprises",
  "uno",
  "cab",
  "domains",
  "limo",
  "menu",
  "management",
  "center",
  "academy",
  "company",
  "computer",
  "ceo",
  "training",
  "support",
  "solutions",
  "email",
  "systems",
  "builders",
  "camp",
  "education",
  "glass",
  "institute",
  "codes",
  "cheap",
  "club",
  "repair",
  "coffee",
  "florist",
  "house",
  "international",
  "solar",
  "store",
  "global",
  "design",
  "vegas",
  "tech",
  "online",
  "xyz",
  "kiwi",
  "eco",
  "london",
  "shop",
  "host",
  "rent",
  "college",
];
