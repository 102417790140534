<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import ClientLayout from "@/layouts/ClientLayout";
import LoginLayout from "@/layouts/LoginLayout";
import SelectionLayout from "@/layouts/SelectionLayout";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import SmbClientLayout from "@/layouts/SmbClientLayout";
import SmbBoardingLayout from "@/layouts/SmbBoardingLayout.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters("videoPlayer", ["getActiveVideo"]),
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
  watch: {
    getActiveVideo(newValue, oldValue) {
      if (oldValue && newValue) {
        oldValue.pause();
      }
    },
  },
  components: {
    MainLayout,
    ClientLayout,
    LoginLayout,
    SelectionLayout,
    EmptyLayout,
    SmbClientLayout,
    SmbBoardingLayout,
  },
};
</script>

<style lang="scss">
@import "./assets/styles/main.scss";
</style>
<style lang="css">
@import "./assets/styles/filters.css";
</style>
