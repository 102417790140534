import axios from "axios";

export default {
  namespaced: true,
  actions: {
    async sendReferralForm({ commit }, params = {}) {
      try {
        const { data } = await axios.post(
          process.env.VUE_APP_BACKEND_URL_REFERRAL,
          params
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
