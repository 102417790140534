import axios from "axios";
import Customer from "@/models/Customer";

export default {
  namespaced: true,
  state: {
    currentSmbCustomer: new Customer(),
    isShowPopup: false,
    popUpText: "",
    isNeedToRedirect: true,
  },
  mutations: {
    setCurrentSmbClient(state, customer) {
      state.currentSmbCustomer = customer;
    },
    setPopupSettings(
      state,
      { isShowPopup = false, popUpText = "", isNeedToRedirect = true }
    ) {
      state.isShowPopup = isShowPopup;
      state.popUpText = isShowPopup ? popUpText : "";
      state.isNeedToRedirect = isNeedToRedirect;
    },
  },
  actions: {
    async fetchSmbClientInfo({ commit }, id) {
      try {
        const { data } = await axios.get(`customer/${id}`);
        commit("setCurrentSmbClient", new Customer(data));
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async createPosts({ commit }, params = {}) {
      try {
        const { data } = await axios.post(`calendar`, params);
        if (data !== "error") {
          return data;
        }
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getCurrentSmbCustomer(state) {
      return state.currentSmbCustomer;
    },
    isCanSeeDemoFeatures(state) {
      const idsAllowList = ["dan18982-9e4a-11ec-a0ac-1d80a5b0104f"];
      return idsAllowList.includes(state.currentSmbCustomer.id);
    },
    isShowSmbPopup(state) {
      return state.isShowPopup;
    },
    getPopupText(state) {
      return state.popUpText;
    },
    getIsNeedToRedirectAfterOkAction(state) {
      return state.isNeedToRedirect;
    },
  },
};
