import Post from "@/models/Post";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    currentPost: new Post(),
    prevHistoryArray: [],
    nextHistoryArray: [],
    isUpdatePostData: false,
    cropFormat: "square",
    selectedPostList: [],
    mediaIsLoading: [],
  },
  mutations: {
    wipePostsHistory(state) {
      state.prevHistoryArray = [];
      state.nextHistoryArray = [];
    },
    setCurrentPost(state, newPost) {
      state.currentPost = newPost;
    },
    refreshCurrentPost(state) {
      state.currentPost = new Post();
    },
    setCropFormat(state, cropFormat) {
      state.cropFormat = cropFormat;
    },
    pushToPostMediaFiles(state, newFile) {
      state.currentPost.media = [...state.currentPost.media, ...[newFile]];
    },
    pushToStartMediaPosition(state, newFile) {
      state.currentPost.media = [...[newFile], ...state.currentPost.media];
    },
    clearPostSelectionsMediaFiles(state) {
      const mediaObjects = state.currentPost.media.filter(
        (i) => !i.isFromMedia
      );
      state.currentPost.media = [...mediaObjects];
    },
    updatePostMediaFilesFromMediaLibrary(state, fileFromMediaLibrary) {
      fileFromMediaLibrary["preview"] = {};
      let mediaUrls = state.currentPost.media.map((i) => {
        if (i.id && i.isFromMedia) {
          return i.id;
        }
      });
      if (mediaUrls.includes(fileFromMediaLibrary.id)) {
        state.currentPost.media = state.currentPost.media.filter((file) => {
          if (file.isFromMedia) {
            return file.id !== fileFromMediaLibrary.id;
          } else {
            return file;
          }
        });
      } else {
        state.currentPost.media = [
          ...state.currentPost.media,
          ...[fileFromMediaLibrary],
        ];
      }
    },
    setMediaIsLoading(state, { index, value }) {
      let arr = [...state.mediaIsLoading];
      arr[index] = value;
      state.mediaIsLoading = arr;
    },
    setToPreview(state, post) {
      state.prevHistoryArray.unshift(Object.assign({}, post));
    },
    setToNext(state, post) {
      state.nextHistoryArray.push(Object.assign({}, post));
    },
    updatePreview(state, arrayOfPost = []) {
      state.prevHistoryArray = [...arrayOfPost];
    },
    updateNext(state, arrayOfPost = []) {
      state.nextHistoryArray = [...arrayOfPost];
    },
    deleteFromPreview(state) {
      state.prevHistoryArray = state.prevHistoryArray.filter((val, index) => {
        return index !== 0;
      });
    },
    deleteFromNext(state) {
      state.nextHistoryArray = state.nextHistoryArray.filter((val, index) => {
        return index !== state.nextHistoryArray.length - 1;
      });
    },
    updatePostData(state, data) {
      state.isUpdatePostData = data;
    },
    setSelectedPostList(state, selectedPost) {
      state.selectedPostList.unshift(selectedPost);
    },
    refreshSelectedPostList(state, selectedPost) {
      state.selectedPostList = [];
    },
    deleteSelectedPostListById(state, postId) {
      state.selectedPostList = state.selectedPostList.filter((postInList) => {
        return postInList.id !== postId;
      });
    },
  },
  actions: {
    showPreview({ commit }) {},
    async runCopywriter({ commit }, params = {}) {
      try {
        const { data } = await axios.post(`copywriter`, params);
        if (params.action === "getLabels") {
          return data;
        }
        return data ? new Post(data) : new Post();
      } catch (e) {
        throw e;
      }
    },
    async updateCoupon({ commit }, params = {}) {
      try {
        const { data } = await axios.put(`smpost`, params);
        return data ? new Post(data) : null;
      } catch (e) {
        throw e;
      }
    },
    async deletePostOrStoryById(_, id) {
      try {
        await axios.delete(`smpost/${id}`);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },

  getters: {
    getCurrentPost(state) {
      return state.currentPost;
    },
    getPrevHistoryArray(state) {
      return state.prevHistoryArray;
    },
    getNextHistoryArray(state) {
      return state.nextHistoryArray;
    },
    getUpdatePostData(state) {
      return state.isUpdatePostData;
    },
    getSelectedPostsList(state) {
      return state.selectedPostList;
    },
    getCropFormat(state) {
      return state.cropFormat;
    },
    getMediaIsLoading(state) {
      return state.mediaIsLoading;
    },
    getCropDataNumber(state) {
      return state.cropFormat === "square"
        ? 1
        : state.cropFormat === "horizontal"
        ? 1.899
        : 4 / 4.99;
    },
  },
};
