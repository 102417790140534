import axios from "axios";
import Customer from "@/models/Customer";
import ContentMixture from "@/models/ContentMixture";

export default {
  namespaced: true,
  state: {
    content: null,
  },
  actions: {
    async fetchContentPerMonth({ commit }, params) {
      try {
        const { data } = await axios.get(`planning`, {
          params,
        });

        return data.map((o) => new ContentMixture(o));
      } catch (e) {
        throw e;
      }
    },
    async updateMixture({ commit }, params) {
      try {
        const { data } = await axios.put(`planning`, params);

        return data.map((o) => new ContentMixture(o));
      } catch (e) {
        throw e;
      }
    },
  },
};
