import H from "@/utils/helper";

export default class BaseClass {
  setFieldsParams(obj, fields, isUpdateClass) {
    if (obj instanceof BaseClass && !isUpdateClass) {
      Object.keys(obj).forEach((element) => {
        this[element] = obj[element];
      });
    } else {
      fields.forEach((e) => {
        const field = e instanceof Object ? e.field : e;
        const defaultValue =
          e instanceof Object
            ? e.default instanceof Object
              ? getPrototypeType(e.default) === "Array"
                ? [...e.default]
                : getPrototypeType(e.default) === "Object"
                ? Object.assign({}, e.default)
                : e.default
              : e.default
            : "";
        const alias = e instanceof Object ? e.alias : null;
        if (field === "id") {
          this[field] =
            !H.isEmpty(obj) && !H.isEmpty(obj, field)
              ? obj[field]
              : defaultValue;
        } else if (!H.isEmpty(obj) && !H.isEmpty(obj, field)) {
          this[alias || field] =
            !H.isEmpty(obj) && !H.isEmpty(obj, field)
              ? obj[field]
              : defaultValue;
        } else {
          this[alias || field] =
            !H.isEmpty(obj) && !H.isEmpty(obj, field)
              ? obj[field]
              : defaultValue;
        }
      });
    }
  }

  constructor(obj = {}, fields = [], isUpdateClass = false) {
    this.setFieldsParams(obj, fields, isUpdateClass);
  }
}

function getPrototypeType(data) {
  return Object.prototype.toString.call(data).slice(8, -1);
}
