<template>
  <div class="form-wrapper">
    <v-form
      class="mt-0 justify-center"
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submitHandler"
    >
      <div class="theme">
        <Loader
          v-if="isLoadingGenerateTextBtn || isLoadingRephraseTextBtn"
          class="loader"
        />
        <div class="theme__info">
          <div class="theme__info-row">
            <span class="name">Theme:</span>
            <span class="val">{{ getCurrentPost.contentTheme }}</span>
          </div>
          <div class="theme__info-row">
            <span class="name">Sub-theme:</span>
            <span class="val">{{ getCurrentPost.contentSubtheme }}</span>
          </div>
        </div>
        <div class="theme__data">
          <div class="theme__data-field">
            <input
              :class="
                getCurrentPost.customSubject
                  ? 'subject__modified'
                  : 'subject__not_modified'
              "
              v-model="subject"
              placeholder="Subject:"
            />
            <div class="undo-btn">
              <v-btn
                :disabled="!isCalendarCustomSubject"
                x-small
                @click="cleanCustomSubject"
              >
                <i class="icon-undo-arrow-svgrepo-com" />
              </v-btn>
            </div>
          </div>
          <div class="theme__data-buttons">
            <v-btn
              :disabled="isDisabledNextSubject"
              x-small
              @click="nextSubjectHandler"
            >
              <i class="icon-right" />
            </v-btn>
          </div>
        </div>
        <div v-if="getCurrentPost.preferred_site">
          <a :href="getCurrentPost.preferred_site" target="_blank">{{
            getCurrentPost.preferred_site
          }}</a>
        </div>
      </div>
      <div class="form-control ma-0 image-format-container">
        <v-row class="pb-3">
          <v-col cols="9">
            <span class="form-control__name">Media files</span>
            <div class="upload-area">
              <draggable
                :key="`${getMediaIsLoading}`"
                v-if="getCurrentPost.media.length"
                tag="span"
                v-model="getCurrentPost.media"
                @start="
                  setPostBeforeDraggable(
                    JSON.parse(JSON.stringify(getCurrentPost))
                  )
                "
                @change="
                  changeImageHandler(JSON.parse(JSON.stringify(getCurrentPost)))
                "
                @end="setPostBeforeDraggable()"
              >
                <transition-group name="no" class="upload-list ma-1" tag="div">
                  <div
                    class="upload-list__item"
                    v-for="(file, index) in getCurrentPost.media"
                    :key="`post_${index}`"
                  >
                    <i
                      class="icon-edit"
                      v-if="file.isTemplate || file.template"
                      @click="editTemplate(file)"
                    ></i>
                    <i
                      class="icon-remove"
                      @click="deleteContentHandler(file, index)"
                    ></i>
                    <img v-if="getMediaIsLoading[index]" src="loading.gif" />
                    <img
                      v-else-if="file && file.mediaUrl && file.type === 'image'"
                      :class="imagesFilter"
                      class="post-image"
                      :alt="getMediaUrl(file)"
                      :src="getMediaUrl(file)"
                    />
                    <div
                      class="inst-video"
                      v-else-if="file && file.mediaUrl && file.type === 'video'"
                    >
                      <video
                        :ref="'myVideo' + index"
                        @play="setToActiveVideo($refs['myVideo' + index][0])"
                        class="post-image"
                      >
                        <source
                          :src="file.mediaUrl"
                          :type="'video/' + getFileType(file.mediaUrl)"
                          :poster="getMediaUrl(file)"
                        />
                      </video>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :loading="isLoadingExtensionPasteBtn"
                  :disabled="!getCurrentPost.calendarItemId"
                  v-on="on"
                  class="clipboardExtension"
                  block
                  @click="uploadMediaAndSeedsFromExtension"
                >
                  <v-icon>mdi-clipboard-arrow-up-outline</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 12px">Paste from Chrome Extension</span>
            </v-tooltip>
            <v-btn
              class="mt-2 show-template"
              color="orange lighten-2"
              dark
              block
              :loading="isLoadingTemplate"
              :disabled="isLoadingTemplate"
              @click="showTemplatePopUp()"
            >
              <v-icon>mdi-file-image-plus-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="generate">
        <div class="like-wrapper">
          <button
            class="like"
            :class="{ active: getCurrentPost.textLike }"
            @click.prevent="isActive('like')"
          >
            <i class="icon"></i>
          </button>
          <button
            class="dislike"
            :class="{ active: getCurrentPost.textDislike }"
            @click.prevent="isActive('dislike')"
          >
            <i class="icon"></i>
          </button>
        </div>
        <div class="rc">
          <div class="history-controls">
            <v-btn
              x-small
              :disabled="isDisabledHistoryPrev"
              @click="showPrevFormVersion"
            >
              <i class="icon-previous"></i>
            </v-btn>
            <v-btn
              x-small
              :disabled="isDisabledHistoryNext"
              @click="showNextFormVersion"
            >
              <i class="icon-next"></i>
            </v-btn>
          </div>
          <div class="generate__btn-wrapper">
            <v-btn
              color="primary"
              :disabled="!getCurrentPost.isGenerable"
              :loading="isLoadingGenerateTextBtn"
              small
              @click="generateTextHandler(true)"
            >
              Change</v-btn
            >
            <v-btn
              color="primary"
              :disabled="!getCurrentPost.isGenerable"
              :loading="isLoadingRephraseTextBtn"
              small
              @click="rephraseTextHandler()"
              class="rephrase"
            >
              Rephrase</v-btn
            >
          </div>
        </div>
      </div>
      <div class="form-control">
        <span class="form-control__name">Caption</span>
        <MyRichTextEditor
          :value="getCurrentPost.fakeCaptionHtml || getCurrentPost.fakeText"
          :is-used-html="!!getCurrentPost.fakeCaptionHtml"
          @editorChanged="editorChangedHandler"
          @blur="blurTextEditorHandler"
          :placeholder="captionHint"
          is-show-emoji
          is-show-tooltip
          :link="getCurrentPost.sourceLink"
          :tooltip="getCurrentPost.metaCaptionHTML"
        />
      </div>
      <!--      <div class="checkbox-list">-->
      <!--        <div-->
      <!--          class="item extension"-->
      <!--          :key="extension.fieldName"-->
      <!--          v-for="extension in extensions"-->
      <!--          v-show="!isLoadingExtensionsBlock"-->
      <!--        >-->
      <!--          <p>{{ extension.label }}</p>-->
      <!--          <v-checkbox-->
      <!--            :value="getCurrentPost[extension.fieldName]"-->
      <!--            @change="-->
      <!--              selectExtensionHandler(-->
      <!--                extension.fieldName,-->
      <!--                getCurrentPost[extension.fieldName],-->
      <!--                $event-->
      <!--              )-->
      <!--            "-->
      <!--            :disabled="-->
      <!--              currentExtensionName &&-->
      <!--              extension.fieldName !== currentExtensionName-->
      <!--            "-->
      <!--            color="#6e6767"-->
      <!--          ></v-checkbox>-->
      <!--        </div>-->
      <!--        <v-progress-linear-->
      <!--          class="mb-4"-->
      <!--          v-show="isLoadingExtensionsBlock"-->
      <!--          color="#845884"-->
      <!--          indeterminate-->
      <!--          rounded-->
      <!--          height="6"-->
      <!--        ></v-progress-linear>-->
      <!--      </div>-->
      <div class="labels" v-if="getCurrentPost.labels.length">
        <span class="labels__title">Image Tags</span>
        <div class="labels__list">
          <div
            class="labels__item"
            v-for="(label, index) in getCurrentPost.labels"
            :key="index"
          >
            <v-chip
              small
              class="ma-2"
              :class="{
                'old-label': isChangeImageTagsColor,
              }"
              close
              @click:close="deleteImageTagHandler(index)"
            >
            </v-chip>
          </div>
          <div v-if="getCurrentPost.postStyle" class="labels__item">
            <span class="labels__item-post-style">{{
              getCurrentPost.postStyle
            }}</span>
          </div>
        </div>
      </div>
      <div class="labels" v-if="!getCurrentPost.rush">
        <span class="labels__title">Scheduling</span>
        <Scheduling
          :date="getCurrentPost.scheduledForStr"
          @changeDate="changeDateHandler"
          @clearDate="changeDateHandler('')"
        />
      </div>
      <div class="form-control hashtags">
        <span class="form-control__name">Hashtags ({{ hashtagsHint }})</span>
        <v-combobox
          :delimiters="[',', ' ', ';', '#']"
          @change="hashtagsInput"
          v-model="getCurrentPost.tags"
          chips
          hide-details
          class="tags_container"
          label="Tags"
          multiple
          solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="deleteTagHandler(item)"
            >
              <strong>{{ item }}</strong>
            </v-chip>
          </template>
        </v-combobox>
      </div>
    </v-form>
    <ModalDialog
      :isShow="templateDialog.isShow"
      :size="900"
      customClass="template-modal"
      @close="
        templateDialog.isShow = false;
        editedTemplate = null;
        isEditTemplate = false;
      "
    >
      <template v-slot:description>
        <Templates
          @closeTemplateModal="
            templateDialog.isShow = false;
            editedTemplate = null;
            isEditTemplate = false;
          "
          v-if="templateDialog.isShow"
          :post="getCurrentPost"
          :isEditTemplate="isEditTemplate"
          :editedTemplate="editedTemplate"
          :template="currentTemplate"
        />
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { CONTENT_TYPE_LIST, CONTENT_ICON_LIST } from "@/constants/dasboard";

import Post from "@/models/Post";
import MediaField from "@/models/MediaFile/MediaField";

import helper from "@/mixins/helper";
import validationRules from "@/shared/validationRules";
import H from "@/utils/helper";

import Scheduling from "@/components/dashboard/widgets/creationWidget/components/Scheduling";
import { log } from "logrocket";
import Loader from "@/components/global/Loader";
import MyTemplate from "@/components/global/MyTemplate";
import MyRichTextEditor from "@/components/global/MyRichTextEditor";
import ModalDialog from "@/components/global/ModalDialog";
import Templates from "@/components/templatesModal/Templates";
import Template from "@/models/Template";
import MediaFile from "@/models/MediaFile";

export default {
  name: "Post",
  mixins: [validationRules, helper],
  components: {
    Loader,
    Scheduling,
    MyRichTextEditor,
    ModalDialog,
    Templates,
  },
  data: () => ({
    subject: null,
    isCalendarCustomSubject: false,
    valid: true,
    isDialogModifyImageTemplate: [],
    isLoadingGetTemplate: false,
    contentTypeList: CONTENT_TYPE_LIST,
    contentIconList: CONTENT_ICON_LIST,
    isLoadingGenerateTextBtn: false,
    isLoadingRephraseTextBtn: false,
    isLoadingExtensionPasteMedia: false,
    isLoadingExtensionPasteText: false,
    isLoadingTemplate: false,
    editedTemplate: null,
    isEditTemplate: false,
    like: "",
    isShow: false,
    isLabelFalse: false,
    postBeforeUpdatePost: new Post(),
    extensions: [
      {
        label: "DM",
        fieldName: "dm",
      },
      {
        label: "B&A",
        fieldName: "baf",
      },
    ],
    currentExtensionName: null,
    yourTimeValue: null,
    postDate: null,
    isLoadingExtensionsBlock: false,
    listOfClassesAndColors: [],
    textEditorPosition: 1,
    updatePosition: null,
    radios: "square",
    width: 0,
    height: 0,
    isUserChangedRatio: false,
    isFilterEnabled: false,
    templateDialog: {
      isShow: false,
    },
    templateData: {
      similarTemplatesList: [],
      currentTemplate: null,
    },
    currentTemplate: null,
    contentTemplateId: null,
    lastCaptionIdFromChangeEvent: null,
  }),

  async created() {
    this.clearSocialWonderClipboard();
    if (!this.$route.params.isEdit) {
      await this.defaultData();
    } else if (this.$route.params.isEdit) {
      //this.setCropFormatWhenEdit();
      //this.setTemplateFromMedia();
      this.getCurrentPost.customerApprove = "none";
      this.subject =
        this.getCurrentPost.customSubject || this.getCurrentPost.contentSubject;
    }
    if (this.$route.params.isFromCalendar) {
      this.contentTemplateId = this.getCurrentPost.contentTemplateId || null;
      if (this.$route.params.isCalendarItem) {
        this.getCurrentPost.isGenerable &&
        this.getCurrentPost.contentCaptionId === "blank-caption-id"
          ? await this.generateTextHandler()
          : null;
      }
      this.getCurrentPost.tags = this.getInitialHashtags(
        this.getCurrentPost.tags
      );
    }
    await this.preloadingTemplates();
    this.isFilterEnabled = this.getMediaLibraryFilterIsActive;
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("mediaLibraryWidget", [
      "getMediaLibrarySelections",
      "getFirstMediaLibrarySelectionId",
      "getMediaLibraryFilterIsActive",
    ]),
    ...mapGetters("dragAndDrop", ["getCurrentDroppedFile"]),
    ...mapGetters("post", [
      "getCurrentPost",
      "getPrevHistoryArray",
      "getNextHistoryArray",
      "getUpdatePostData",
      "getMediaIsLoading",
    ]),
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("instagramPost", ["getUpdateSuccessFlag"]),

    imagesFilter() {
      return this.isFilterEnabled ? this.getCurrentCustomer.filter : "normal";
    },

    isLoadingExtensionPasteBtn() {
      return (
        this.isLoadingExtensionPasteMedia || this.isLoadingExtensionPasteText
      );
    },
    hashtagsHint() {
      return `${this.getCurrentPost.tags.length}/30`;
    },
    captionHint() {
      return this.getCurrentPost.isGenerable
        ? "Write text or generate..."
        : "Content type is not supported - create captions manually";
    },
    getLastTextId() {
      const prevTextObject = [
        ...this.getNextHistoryArray,
        ...this.getPrevHistoryArray,
      ].find((i) => i.textId !== this.getCurrentPost.textId);
      return prevTextObject ? prevTextObject.textId : undefined;
    },
    isChangeImageTagsColor() {
      return false;
      // return (
      //   this.getAttachedImageId() &&
      //   this.getCurrentPost.currentCaptionForImageId !==
      //     this.getAttachedImageId()
      // );
    },
    isDisabledNextSubject() {
      return (
        !this.getCurrentPost.calendarItemId || this.getCurrentPost.isLastItem
      );
    },
    isDisabledHistoryNext() {
      return this.getNextHistoryArray.length === 0;
    },
    isDisabledHistoryPrev() {
      return this.getPrevHistoryArray.length === 0;
    },
  },
  watch: {
    getMediaLibraryFilterIsActive(val) {
      this.isFilterEnabled = val;
    },
    subject: function (val = null) {
      this.isCalendarCustomSubject =
        this.subject != this.getCurrentPost.contentSubject;
      this.getCurrentPost.customSubject = this.isCalendarCustomSubject
        ? val
        : null;
    },
    getCurrentDroppedFile: function (val) {
      if (val && !val.isInstagramActive) {
        console.warn("getCurrentDroppedFile", val);
        this.pushToPostMediaFiles(val);
        this.setFile();
      }
    },
    getFirstMediaLibrarySelectionId(newValue, oldValue) {
      if (this.getCurrentPost.media.length) {
        this.getImageSizeByUrl(
          H.getMediaUrlForFile(this.getCurrentPost.media[0])
        );
      }

      if (newValue && this.getCurrentPost.media.length === 1 && !oldValue) {
        this.generateLabels({
          imageId: this.getAttachedImageId(),
        });
      }
    },
    getUpdateSuccessFlag() {
      this.currentExtensionName = null;
    },
    async getUpdatePostData(data) {
      if (data) {
        await this.defaultData();
        await this.preloadingTemplates();
        this.updatePostData(false);
      }
    },
  },
  methods: {
    ...mapActions("post", ["runCopywriter"]),
    ...mapActions("dashboard", ["updatePostCaption"]),
    ...mapMutations("dragAndDrop", ["setFile", "setDropOnTheInstagramWidget"]),
    ...mapMutations("mediaLibraryWidget", [
      "removeMediaLibrarySelection",
      "setMediaLibrarySelections",
      "addMediaLibrarySelection",
    ]),
    ...mapActions("mediaLibraryWidget", [
      "uploadMediaFromUrl",
      "getMediaInfo",
      "fetchMediaData",
    ]),
    ...mapActions("templates", [
      "getTemplate",
      "createImageFromTemplate",
      "getTemplatesInfo",
    ]),
    ...mapMutations("templates", ["setSimilarTemplatesList"]),
    ...mapMutations("stories", [
      "updateStoriesMediaFilesFromMediaLibrary",
      "refreshAllMediaFilesFromMediaLibrary",
      "setSelectedStoriesMediaFiles",
      "pushToStoriesMediaFiles",
    ]),
    ...mapMutations("post", [
      "pushToPostMediaFiles",
      "updatePreview",
      "updateNext",
      "setToPreview",
      "setToNext",
      "deleteFromPreview",
      "deleteFromNext",
      "setCurrentPost",
      "wipePostsHistory",
      "updatePostMediaFilesFromMediaLibrary",
      "updatePostData",
      "setCropFormat",
      "pushToStartMediaPosition",
    ]),
    ...mapMutations("videoPlayer", ["setToActiveVideo"]),
    ...mapActions("seed", ["createSeed"]),

    editTemplate(file) {
      this.showTemplatePopUp();
      this.editedTemplate = file.template;
      this.isEditTemplate = true;
    },

    showTemplatePopUp() {
      this.templateDialog.isShow = true;
    },

    async preloadingTemplates() {
      try {
        this.isLoadingTemplate = true;
        let params = {
          calendarItemId: this.getCurrentPost.calendarItemId,
          customerId: this.getCurrentCustomer.id,
        };
        let { similarTemplates, template } = await this.getTemplatesInfo(
          params
        );
        this.currentTemplate = JSON.parse(JSON.stringify(template));
        this.similarTemplatesList = similarTemplates;
        this.setSimilarTemplatesList(similarTemplates);
        this.isLoadingTemplate = false;
      } catch (e) {
        this.isLoadingTemplate = false;
        console.log(e);
      }
    },

    clearSocialWonderClipboard() {
      localStorage.setItem("socialWonderClipboard", "");
      localStorage.setItem("socialWonderClipboardIsRequestedBy", "");
      localStorage.setItem("socialWonderClipboardIsReady", "false");
    },
    closeImageFromTemplate(index) {
      this.$root.$emit("mediaObjectModified", {
        index,
        reason: "templateModified",
      });
      this.setIsDialogModifyImageTemplate(index, false);
    },

    setIsDialogModifyImageTemplateFromEvent(event) {
      this.setIsDialogModifyImageTemplate(event.index, event.value);
    },

    setIsDialogModifyImageTemplate(index, value) {
      const tmpList = [...this.isDialogModifyImageTemplate];
      tmpList[index] = value;
      this.isDialogModifyImageTemplate = tmpList;
    },

    async createTemplateMedia() {
      this.isLoadingGetTemplate = true;
      try {
        let params = {
          calendarItemId: this.getCurrentPost.calendarItemId || "1234",
          customerId: this.getCurrentCustomer.id,
          inputParameters: null,
          ratio: "square",
        };
        let template = await this.getTemplate(params);

        const mediaUrlObj = {
          mediaUrl: template["preview_url"],
          template,
        };

        const newMediaObj = new MediaField(mediaUrlObj);
        this.pushToPostMediaFiles(newMediaObj);
        setTimeout(() => {
          this.setIsDialogModifyImageTemplate(
            this.getCurrentPost.media.length - 1,
            true
          );
        }, 1);
      } catch (e) {
        throw e;
      } finally {
        this.isLoadingGetTemplate = false;
      }
    },
    async uploadMediaAndSeedsFromExtension() {
      this.isLoadingGenerateTextBtn = true;
      localStorage.setItem(
        "socialWonderClipboardIsRequestedBy",
        "creationWidget"
      );
      setTimeout(async () => {
        try {
          await this.uploadMediaAndSeedsFromExtensionHandler();
          this.clearSocialWonderClipboard();
        } finally {
          this.isLoadingGenerateTextBtn = false;
        }
      }, 30);
    },

    async uploadMediaAndSeedsFromExtensionHandler() {
      try {
        while (localStorage.getItem("socialWonderClipboardIsReady") != "true") {
          console.log("awaiting socialWonderClipboardIsReady...");
          await H.sleep(10);
        }
        if (
          !localStorage.socialWonderClipboard ||
          localStorage.socialWonderClipboard == "undefined" ||
          localStorage.socialWonderClipboard == "null" ||
          !localStorage.socialWonderClipboard.length
        ) {
          return;
        }

        const socialWonderClipboard = JSON.parse(
          localStorage.socialWonderClipboard
        );

        const mediaItems = socialWonderClipboard.filter(
          (item) => item["type"] === "media"
        );
        const textItems = socialWonderClipboard.filter(
          (item) => item["type"] === "text"
        );

        if (this.getCurrentPost.isGenerable && textItems && textItems.length) {
          this.isLoadingExtensionPasteText = true;

          const selectionTextList = textItems.map(
            (textItem) => textItem["selectionText"]
          );
          await this.uploadSeedsFromExtensionHandler({
            calendarId: this.getCurrentPost.calendarItemId,
            text: selectionTextList,
          });
        }

        if (mediaItems && mediaItems.length) {
          this.isLoadingExtensionPasteMedia = true;

          this.getCurrentPost.media = [];
          await this.uploadMediaFromExtensionHandler(mediaItems);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async uploadMediaFromExtensionHandler(mediaItems) {
      try {
        let newMediaIds = [];
        let newMediaUrl = [];

        await Promise.all(
          mediaItems.map(async (mediaItem, index) => {
            const data = await this.uploadMediaFromUrl({
              customerId: this.getCurrentCustomer.id,
              inputMediaUrl: mediaItem["srcUrl"],
              createdBy: "6",
              header: {
                "User-Agent":
                  "CoolBot/0.0 (https://example.org/coolbot/; coolbot@example.org)",
              },
            });
            if (data) {
              const media_id = data.data["media_id"];
              newMediaIds.push(media_id);
              let mediaInfo = await this.getMediaInfo(media_id);
              while (!this.isMediaInfoReady(mediaInfo)) {
                await H.sleep(200);
                mediaInfo = await this.getMediaInfo(media_id);
              }
              newMediaUrl[index] = new MediaField(mediaInfo);
            }
          })
        );

        this.getCurrentPost.media = newMediaUrl;
      } finally {
        this.isLoadingExtensionPasteMedia = false;
      }
    },

    isMediaInfoReady(mediaInfo) {
      let mediaState = mediaInfo["mediaState"];
      if (!mediaState) return false;
      let jpg = mediaState["jpg"];
      let mp4 = mediaState["mp4"];
      let thumbnail = mediaState["thumbnail"];

      if (!(thumbnail || jpg || mp4)) return false;
      if (thumbnail == "error" || jpg == "error" || mp4 == "error") return true;
      if (thumbnail == "wip" || jpg == "wip" || mp4 == "wip") return false;

      return true;
    },

    async uploadSeedsFromExtensionHandler({ calendarId, text }) {
      try {
        await this.createSeed({
          calendarId,
          text,
        });

        await this.generateTextHandler();
      } finally {
        this.isLoadingExtensionPasteText = false;
      }
    },

    getAttachedImageId() {
      if (this.getCurrentPost.media.length) {
        const data = this.getCurrentPost.media.find(
          (i) => typeof i === "object" && !i.blob && (i.mediaId || i.id)
        );
        if (!data) return undefined;
        const tmpId = data.mediaId || data.id;
        return tmpId ? tmpId : undefined;
      } else {
        return undefined;
      }
    },
    hashtagsInput(val) {
      if (!val && !val.length) return;
      this.getCurrentPost.tags = H.hashtagsCompose(val);
    },

    setCropFormatWhenEdit() {
      if (this.getCurrentPost.media.length) {
        this.getCurrentPost.media.forEach((item) => {
          if (item.ratio !== null) {
            this.radios = item.ratio;
          }
        });
      }
    },
    async defaultData() {
      try {
        const params = {
          action: "nextCalendarPost",
        };
        this.wipePostsHistory();
        await this.newPostInstance(params, false);
        if (!this.$route.params.isEdit) {
          !this.getCurrentPost.isGenerable
            ? (this.getCurrentPost.text = "")
            : null;
        }
      } catch (e) {
        throw e;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    containsWhitespace(str) {
      return /\s/.test(str);
    },

    async generateTextHandler(isNeedToUpdateTemplates = false) {
      const params = {
        imageId: this.getAttachedImageId(),
        labels: this.getCurrentPost.labels,
        calendarPostId: this.getCurrentPost.calendarPostId,
        calendarItemId: this.getCurrentPost.calendarItemId,
        contentCaptionId:
          this.lastCaptionIdFromChangeEvent ||
          this.getCurrentPost.contentCaptionId,
        customSubject: this.getCurrentPost.customSubject,
        action: "nextContentCaption",
        contentTemplateId:
          isNeedToUpdateTemplates && this.contentTemplateId
            ? this.contentTemplateId
            : undefined,
      };
      await this.newPostInstance(params, true, isNeedToUpdateTemplates);
      this.lastCaptionIdFromChangeEvent = this.getCurrentPost.contentCaptionId;
    },
    async rephraseTextHandler() {
      try {
        this.setPostChangesHistory(H.deepCopy(this.getCurrentPost));
        this.isLoadingRephraseTextBtn = true;
        const params = {
          imageId: this.getAttachedImageId(),
          calendarItemId: this.getCurrentPost.calendarItemId,
          customerId: this.getCurrentCustomer.id,
          captionId: this.getCurrentPost.contentCaptionId,
          text: this.getCurrentPost.text,
          contentTemplateId: this.contentTemplateId || undefined,
          action: "rephraseContentCaption",
        };
        const {
          contentCaptionId,
          tags,
          text,
          captionHTML,
          metaCaptionHTML,
          sourceLink,
          media,
          contentTemplateId,
        } = await this.runCopywriter(params);
        this.getCurrentPost.contentCaptionId = contentCaptionId;
        this.getCurrentPost.text = text;
        this.getCurrentPost.autoGeneratedText = text;
        this.getCurrentPost.tags = this.getInitialHashtags(tags);
        this.contentTemplateId = contentTemplateId
          ? contentTemplateId
          : undefined;

        this.getCurrentPost.contentTemplateId = this.contentTemplateId;
        this.getCurrentPost.captionHTML = captionHTML;
        this.getCurrentPost.metaCaptionHTML = metaCaptionHTML;
        this.getCurrentPost.sourceLink = sourceLink;
        this.getCurrentPost.fakeText = this.getCurrentPost.text || null;
        this.getCurrentPost.fakeCaptionHtml =
          this.getCurrentPost.captionHTML || null;
        if (media.length) {
          this.replaceAutoGenerationTemplates(
            media.map((i) => new MediaFile(i))
          );
        }

        this.isLoadingRephraseTextBtn = false;
      } catch (e) {
        console.log(e);
        this.isLoadingRephraseTextBtn = false;
      }
    },

    async newPostInstance(
      params,
      keepInHistory = true,
      isNeedToUpdateTemplates = false
    ) {
      this.isLoadingGenerateTextBtn = true;
      try {
        if (keepInHistory) {
          this.setPostChangesHistory(H.deepCopy(this.getCurrentPost));
        }

        this.extensions.forEach((extension) => {
          this.getCurrentPost[extension.fieldName] = false;
        });

        this.currentExtensionName = null;

        this.getCurrentPost.textLike = false;
        this.getCurrentPost.textDislike = false;

        const {
          type,
          media,
          text,
          tags,
          postStyle,
          labels,
          textDislike,
          textId,
          textLike,
          customSubject,
          contentTheme,
          contentSubtheme,
          contentSubject,
          contentMeta,
          scheduledForStr,
          calendarPostId,
          calendarItemId,
          contentCaptionId,
          isGenerable,
          isLastItem,
          preferred_site,
          rush,
          captionHTML,
          metaCaptionHTML,
          sourceLink,
          contentTemplateId,
        } = await this.runCopywriterWrapper(params);

        //These should always be overwritten as they are always returned
        this.getCurrentPost.contentCaptionId = contentCaptionId;
        this.getCurrentPost.type = type;
        this.getCurrentPost.text = text;
        this.getCurrentPost.fakeText = text;
        this.getCurrentPost.captionHTML = captionHTML;
        this.getCurrentPost.fakeCaptionHtml = captionHTML;
        this.getCurrentPost.metaCaptionHTML = metaCaptionHTML;
        this.getCurrentPost.sourceLink = sourceLink;
        this.getCurrentPost.rush = rush;
        this.contentTemplateId = contentTemplateId
          ? contentTemplateId
          : undefined;

        this.getCurrentPost.contentTemplateId = this.contentTemplateId;
        this.getCurrentPost.tags = this.getInitialHashtags(tags);

        if (
          this.$route.params.isFromCalendar &&
          this.$route.params.isCalendarItem &&
          this.getCurrentPost.isGenerable
        ) {
          this.getCurrentPost.autoGeneratedText = text;
          this.getCurrentPost.media = media;
        }

        //These should not be overwritten on replcament of ContentCaption
        if (params.action !== "nextContentCaption") {
          this.getCurrentPost.contentSubject = contentSubject;
          this.getCurrentPost.contentMeta = contentMeta;
          this.getCurrentPost.customSubject = customSubject;
          this.subject = customSubject || contentSubject;
          this.getCurrentPost.isGenerable = isGenerable;
          this.getCurrentPost.isLastItem = isLastItem;
          this.getCurrentPost.scheduledForStr = scheduledForStr;
          this.getCurrentPost.contentTheme = contentTheme;
          this.getCurrentPost.contentSubtheme = contentSubtheme;
          this.getCurrentPost.calendarPostId = calendarPostId;
          this.getCurrentPost.calendarItemId = calendarItemId;
          this.getCurrentPost.labels = labels || [];
          this.getCurrentPost.preferred_site = preferred_site;

          if (
            !this.getCurrentPost.contentMeta ||
            !this.getCurrentPost.contentMeta.is_template_flag
          ) {
            this.getCurrentPost.media = media;
          }
          this.getCurrentPost.postStyle = postStyle;
          this.getCurrentPost.textDislike = textDislike;
          this.getCurrentPost.textLike = textLike;
          this.getCurrentPost.textId = textId || contentCaptionId;
          this.getCurrentPost.textIdPrev = this.getLastTextId;
          this.getCurrentPost.isLabelFalse = false;
          this.getCurrentPost.currentCaptionForImageId =
            this.getAttachedImageId();

          this.generateLabels({
            imageId: this.getAttachedImageId(),
          });
        }

        if (isNeedToUpdateTemplates && media.length) {
          this.replaceAutoGenerationTemplates(
            media.map((i) => new MediaFile(i))
          );
        }

        if (this.getCurrentPost.media.length && !this.isUserChangedRatio) {
          await this.getImageSizeByUrl(
            H.getMediaUrlForFile(this.getCurrentPost.media[0])
          );
        }
        this.listOfClassesAndColors = [];

        if (
          this.getCurrentPost.contentMeta &&
          this.getCurrentPost.contentMeta.is_template_flag
        ) {
          this.createTemplateMedia();
        }
      } catch (e) {
        throw e;
      } finally {
        this.getCurrentPost.fakeText = this.getCurrentPost.text || null;
        this.getCurrentPost.fakeCaptionHtml =
          this.getCurrentPost.captionHTML || null;

        this.isLoadingGenerateTextBtn = false;
      }
    },
    replaceAutoGenerationTemplates(arrayOfNewTemplates) {
      const indexOfOldTemplate = this.getCurrentPost.media.findIndex(
        (i) => i.template && i.template.isAutoGenerated
      );

      if (indexOfOldTemplate >= 0) {
        this.getCurrentPost.media = this.getCurrentPost.media.filter(
          (i) => !i.template || i.template.isAutoGenerated === false
        );

        this.getCurrentPost.media.splice(
          indexOfOldTemplate,
          0,
          ...arrayOfNewTemplates
        );
      } else {
        this.pushToStartMediaPosition(...arrayOfNewTemplates);
      }
    },
    getInitialHashtags(tagsFromPost) {
      if (!tagsFromPost.length) {
        const minimal = this.getCurrentCustomer.hashtags_number || 30;
        let tags = [
          ...tagsFromPost,
          ...this.getCurrentCustomer.hashtags_constant,
        ];
        tags = [...new Set(tags)];

        if (tags.length > minimal) {
          return tags;
        }

        let rndTags = [...this.getCurrentCustomer.hashtags];
        while (rndTags.length && tags.length < minimal) {
          const aTag = rndTags.splice(
            Math.floor(Math.random() * rndTags.length),
            1
          )[0];
          if (!tags.includes(aTag)) {
            tags.push(aTag);
          }
        }

        return tags;
      } else {
        return tagsFromPost;
      }
    },

    async runCopywriterWrapper(paramsInput) {
      const params = {
        ...paramsInput,
        customerId: this.getCurrentCustomer.id,
        smmId: this.getCurrentUser.id,
        textIdPrev: this.getLastTextId,
      };
      return await this.runCopywriter(params);
    },
    setPostChangesHistory(currentPost = this.getCurrentPost) {
      if (this.getNextHistoryArray.length && !this.isUserChangedRatio) {
        this.updatePreview([
          ...this.getNextHistoryArray,
          ...[H.deepCopy(currentPost)],
          ...this.getPrevHistoryArray,
        ]);
        this.updateNext();
      } else {
        this.setToPreview(H.deepCopy(currentPost));
      }
    },
    deleteTagHandler(tag) {
      this.getCurrentPost.tags = this.getCurrentPost.tags.filter(
        (val) => val !== tag
      );
    },
    showPrevFormVersion() {
      this.setToNext(H.deepCopy(this.getCurrentPost));
      this.setCurrentPost(H.deepCopy(this.getPrevHistoryArray[0]));
      this.currentExtensionName = null;
      this.extensions.forEach((extension) => {
        if (this.getCurrentPost[extension.fieldName]) {
          this.currentExtensionName = extension.fieldName;
        }
      });
      this.deleteFromPreview();
      this.getCurrentPost.textIdPrev = this.getLastTextId;
      this.updateMediaFilesFromHistoryObject();
      this.subject =
        this.getCurrentPost.customSubject || this.getCurrentPost.contentSubject;
    },
    showNextFormVersion() {
      this.setToPreview(H.deepCopy(this.getCurrentPost));
      this.setCurrentPost(
        H.deepCopy(
          this.getNextHistoryArray[this.getNextHistoryArray.length - 1]
        )
      );
      this.currentExtensionName = null;
      this.extensions.forEach((extension) => {
        if (this.getCurrentPost[extension.fieldName]) {
          this.currentExtensionName = extension.fieldName;
        }
      });
      this.deleteFromNext();
      this.updateMediaFilesFromHistoryObject();
      this.subject =
        this.getCurrentPost.customSubject || this.getCurrentPost.contentSubject;
    },
    async deleteContentHandler(file, index = null) {
      const postBeforeDeleteContent = H.deepCopy(this.getCurrentPost);

      if (file && file.template && file.template.isAutoGenerated) {
        this.contentTemplateId = null;
      }

      if (file && file.isFromMedia) {
        this.updatePostMediaFilesFromMediaLibrary(file);
        this.removeMediaLibrarySelection(file);
        // this.updateStoriesMediaFilesFromMediaLibrary(file);
      } else {
        this.getCurrentPost.media.splice(index, 1);
      }
      await this.changedAttachedMediaList(postBeforeDeleteContent, {
        imageId: this.getAttachedImageId(),
      });
    },
    updateMediaFilesFromHistoryObject() {
      this.setMediaLibrarySelections([
        ...this.getCurrentPost.media.filter((i) => {
          return i.isFromMedia;
        }),
      ]);

      this.refreshAllMediaFilesFromMediaLibrary();
      // this.setSelectedStoriesMediaFiles([...this.getMediaLibrarySelections]);
    },
    async isActive(i) {
      if (i === "like") {
        this.getCurrentPost.textLike = !this.getCurrentPost.textLike;
        if (this.getCurrentPost.textId) {
          this.getCurrentPost.textDislike
            ? await this.likeDislikeHandler({ textDislike: false })
            : null;
          await this.likeDislikeHandler({
            textLike: this.getCurrentPost.textLike,
          });
        }
        this.getCurrentPost.textDislike = false;
      } else {
        if (this.getCurrentPost.textId) {
          this.getCurrentPost.textDislike = !this.getCurrentPost.textDislike;
          this.getCurrentPost.textLike
            ? await this.likeDislikeHandler({ textLike: false })
            : null;
          await this.likeDislikeHandler({
            textDislike: this.getCurrentPost.textDislike,
          });
        }
        this.getCurrentPost.textLike = false;
      }
    },
    async likeDislikeHandler(params) {
      await this.updatePostCaption(
        Object.assign(
          {
            smmId: this.getCurrentUser.id,
            customerId: this.getCurrentCustomer.id,
            textId: this.getCurrentPost.textId,
            text: this.getCurrentPost.text,
          },
          params
        )
      );
    },
    async changedAttachedMediaList(
      postBeforeDeleteContent = H.deepCopy(this.postBeforeUpdatePost),
      params = {
        imageId: this.getAttachedImageId(),
        labels:
          this.getCurrentPost.labels.length && !this.isChangeImageTagsColor
            ? this.getCurrentPost.labels
            : undefined,
      }
    ) {
      this.setPostChangesHistory(postBeforeDeleteContent);
      await this.generateLabels(params);
      if (this.getCurrentPost.media.length) {
        await this.getImageSizeByUrl(
          H.getMediaUrlForFile(this.getCurrentPost.media[0])
        );
      }
    },
    async generateLabels(paramsInput) {
      if (paramsInput && !paramsInput.imageId) {
        this.getCurrentPost.labels = [];
        return;
      }

      const params = { ...paramsInput, action: "getLabels" };
      this.getCurrentPost.labels = await this.runCopywriterWrapper(params);
    },
    async deleteImageTagHandler(indexTag) {
      this.getCurrentPost.labels = this.getCurrentPost.labels.filter(
        (label, index) => index !== indexTag
      );
      await this.generateTextHandler();
    },
    cleanCustomSubject() {
      this.subject = this.getCurrentPost.contentSubject;
    },
    async nextSubjectHandler() {
      await this.newPostInstance({
        calendarPostId: this.getCurrentPost.calendarPostId,
        calendarItemId: this.getCurrentPost.calendarItemId,
        action: "nextCalendarItem",
      });
    },
    async changeImageHandler() {
      await this.changedAttachedMediaList(
        H.deepCopy(this.postBeforeUpdatePost)
      );
    },
    setPostBeforeDraggable(post = H.deepCopy(new Post())) {
      this.postBeforeUpdatePost = post;
    },
    async selectExtensionHandler(extensionFieldName, extensionValue, flag) {
      this.isLoadingExtensionsBlock = true;
      if (flag) {
        this.currentExtensionName = extensionFieldName;
        this.setPostChangesHistory(H.deepCopy(this.getCurrentPost));
        this.getCurrentPost[extensionFieldName] = true;
        const { text, textId } = await this.runCopywriter(
          Object.assign({
            customerId: this.getCurrentCustomer.id,
            smmId: this.getCurrentUser.id,
            imageId: this.getAttachedImageId(),
            textIdPrev: this.getCurrentPost.textId,
            caption: this.getCurrentPost.text,
            labels: this.getCurrentPost.labels,
            extension: extensionFieldName,
            calendarItemId: this.getCurrentPost.calendarItemId,
            action: "getExtension",
          })
        );
        this.getCurrentPost.autoGeneratedText =
          this.getCurrentPost.autoGeneratedText +
          text.replace(this.getCurrentPost.text, "");
        this.getCurrentPost.text = text;
        this.getCurrentPost.textId = textId;
      } else {
        const currentPost = this.getCurrentPost;

        this.setCurrentPost(H.deepCopy(this.getPrevHistoryArray[0]));
        this.setToPreview(currentPost);

        this.getCurrentPost[extensionFieldName] = false;
        this.currentExtensionName = null;
      }
      this.isLoadingExtensionsBlock = false;
    },
    changeDateHandler(newDate) {
      this.getCurrentPost.scheduledForStr = newDate;
    },
    submitHandler() {
      return true;
    },
    changeCropFormat() {
      this.isUserChangedRatio = true;
      this.setCropFormat(this.radios);
    },
    getImageSizeByUrl(url) {
      let image = new Image();

      image.onload = (event) => {
        this.setDefaultRatio(event.target.width, event.target.height);
      };

      image.src = url;
    },
    setDefaultRatio(width = 0, height = 0) {
      if (height === 0 || width === 0) return "square";

      const widthToHeightRatio = width / height;
      const ratios = [1, 1.91, 0.8];

      const closest = ratios.reduce(function (prev, curr) {
        return Math.abs(curr - widthToHeightRatio) <
          Math.abs(prev - widthToHeightRatio)
          ? curr
          : prev;
      });

      switch (closest) {
        case 1:
          this.radios = "square";
          break;
        case 1.91:
          this.radios = "horizontal";
          break;
        case 0.8:
          this.radios = "vertical";
          break;
        default:
          break;
      }

      this.setCropFormat(this.radios);
    },
    blurTextEditorHandler({ html, text }) {
      this.getCurrentPost.fakeCaptionHtml = html || null;
      this.getCurrentPost.fakeText = text || null;
    },
    editorChangedHandler({ html, text }) {
      this.getCurrentPost.captionHTML = html || null;
      this.getCurrentPost.text = text || null;
    },
  },
  destroyed() {
    this.setSimilarTemplatesList([]);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.toggle-btn-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .toggle {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: $color-violet;
    position: relative;
    padding-right: 15px;
    i {
      font-size: 10px;
      position: absolute;
      right: 0;
      bottom: 2px;
    }
    &.less {
      i {
        transform: rotate(180deg);
        bottom: 4px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.tags_container {
  .v-input__append-inner {
    display: none !important;
  }
}
.form-wrapper {
  padding: 0 24px;
}
.clear {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &__btn {
    border: none;
    background: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: $color-violet;
    text-decoration: underline;
  }
}
.form-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
  &__name {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: $color-black;
    margin-bottom: 4px;
    i {
      font-style: normal;
      font-weight: 400;
    }
  }
  &__add-ons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -15px;
    .v-input {
      width: 50%;
      margin: 0 15px;
    }
  }
  &.calendar {
    margin-top: 10px;
    ::v-deep {
      .v-input__icon .v-icon.v-icon {
        font-size: 18px !important;
      }
    }
  }
  ::v-deep {
    .v-input {
      &__slot {
        border: 1px solid $color-grey3;
        box-sizing: border-box;
        border-radius: 4px;
        background: #fafafa !important;
        min-height: 36px !important;
        box-shadow: none !important;
        &:before {
          background: transparent !important;
        }
        fieldset {
          display: none;
        }
        &:after {
          background: transparent !important;
        }
      }
      &__icon {
        height: 36px !important;
        .v-icon.v-icon {
          font-size: 10px;
        }
        .mdi-menu-down::before {
          font-family: "icomoon";
          content: "\e915";
        }
      }
      &__append-inner {
        margin-top: 0 !important;
      }
    }
    .v-label {
      font-size: 14px;
      line-height: 140%;
      font-family: $base-font;
    }
    .v-select {
      &__selections {
        min-height: 36px !important;
        padding: 4px 0 0 0 !important;
        .v-chip {
          height: 28px !important;
          padding: 6px !important;
          margin-top: 0;
          border: 1px solid #eae9ea;
          box-sizing: border-box;
          border-radius: 8px;
          background: $color-white;
          cursor: pointer;
          .icon {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            margin-right: 6px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          strong {
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            color: $color-black;
          }
          .mdi-close-circle::before {
            font-family: "icomoon" !important;
            content: "\e91b";
            font-size: 7px;
          }
        }
      }
      &__selection--comma {
        margin: 3px 4px 7px 0 !important;
        font-size: 14px;
      }
    }
  }
  &.range {
    ::v-deep {
      .v-input {
        margin-top: 20px;
        &__slot {
          background: transparent !important;
          border: none !important;
        }
      }
      .v-slider {
        &__thumb-label {
          background: transparent !important;
          color: $color-black !important;
          top: -20px !important;
        }
        &__thumb {
          width: 20px !important;
          height: 20px !important;
          background: $color-white !important;
          border: 1px solid $color-grey3 !important;
          box-sizing: border-box;
          box-shadow: 0px 2px 6px rgba(31, 35, 37, 0.08) !important;
          &:before {
            display: none !important;
          }
        }
      }
      .primary.lighten-3 {
        background: rgba(47, 72, 88, 0.2) !important;
      }
      .pink {
        background: $color-blueberry !important;
      }
    }
  }
  &.hashtags {
    ::v-deep {
      .v-input__icon .v-icon.v-icon {
        display: none !important;
      }
      .v-text-field__details {
        display: none !important;
      }
      .v-input__slot {
        max-height: 55px;
        overflow-y: auto;
        align-items: baseline;
      }
    }
  }
  .emoji {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
.upload-area {
  .upload-format {
    display: inline-flex;
    align-items: center;
    margin-bottom: 16px;
    &__name {
      color: $color-black;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      margin-right: 12px;
    }
    &__controls {
      display: inline-flex;
      .item {
        display: inline-flex;
        align-items: center;
        height: 36px;
        padding: 5px 11px;
        cursor: pointer;
        position: relative;
        &:first-child {
          border: 1px solid $color-grey3;
          box-sizing: border-box;
          box-shadow: 0px 2px 6px rgba(31, 35, 37, 0.08);
          border-radius: 8px 0px 0px 8px;
          .item {
            &__rectangle {
              border: 1.5px solid rgba(78, 87, 120, 0.6);
              box-sizing: border-box;
              border-radius: 2px;
              flex-shrink: 0;
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }
          }
        }
        &:last-child {
          left: -1px;
          border: 1px solid $color-grey3;
          box-sizing: border-box;
          border-radius: 0px 8px 8px 0px;
          .item {
            &__rectangle {
              border: 1.5px solid rgba(78, 87, 120, 0.6);
              box-sizing: border-box;
              border-radius: 2px;
              flex-shrink: 0;
              width: 18px;
              height: 24px;
              margin-right: 8px;
            }
            &__num {
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
              color: $color-black;
            }
          }
        }
        &__rectangle {
          border: 1.5px solid rgba(78, 87, 120, 0.6);
          box-sizing: border-box;
          border-radius: 2px;
          flex-shrink: 0;
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
        &.active {
          border: 1px solid rgba(126, 86, 137, 0.2) !important;
          background: rgba(126, 86, 137, 0.05);
          color: $color-violet;
          z-index: 10;
          .item {
            &__rectangle {
              border: 1.5px solid $color-violet;
            }
          }
        }
      }
    }
  }
  .upload-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    position: relative;
    grid-gap: 10px;
    margin-bottom: 16px;
    &__item {
      border: 1px solid rgba(47, 72, 88, 0.1);
      position: relative;
      width: 100%;
      height: 60px;
      .inst-video {
        width: 100%;
        height: 100%;
        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .image-file {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .icon-remove {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 20;
        padding: 3px;
        background-color: whitesmoke;
        border-radius: 50%;
        &:hover {
          color: red;
        }
      }
      .icon-edit {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 20;
        padding: 3px;
        background-color: whitesmoke;
        border-radius: 50%;
        &:hover {
          color: red;
        }
      }
    }
  }
}
.image-file {
  padding-top: 67%;
  width: 100%;
  overflow: hidden;
  margin: 1px;

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;

  /* Optional centered background */
  background-position: center;
}
.generate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-top: 1px solid rgba(47, 72, 88, 0.1);
  padding-top: 10px;
  .like-wrapper {
    display: inline-flex;
    align-items: center;
    .like {
      width: 28px;
      height: 28px;
      position: relative;
      i {
        position: absolute;
        left: 50%;
        top: calc(50% - 2px);
        transform: translate(-50%, -50%);
        color: $color-grey2;
        &:before {
          font-family: "icomoon" !important;
          font-style: normal;
          content: "\e927";
          font-size: 20px;
        }
      }
      &.active {
        i {
          color: $color-green;
          &:before {
            content: "\e926";
          }
        }
      }
    }
    .dislike {
      width: 28px;
      height: 28px;
      position: relative;
      margin-left: 12px;
      @media screen and (max-width: 1600px) {
        margin-left: 5px;
      }
      i {
        color: $color-grey2;
        position: absolute;
        left: 50%;
        top: calc(50% + 2px);
        transform: translate(-50%, -50%) rotate(180deg);
        &:before {
          font-family: "icomoon" !important;
          font-style: normal;
          content: "\e927";
          font-size: 20px;
        }
      }
      &.active {
        i {
          color: $color-red;
          &:before {
            content: "\e926";
          }
        }
      }
    }
  }
  .rc {
    display: inline-flex;
    align-items: center;
  }
  &__btn-wrapper {
    margin-left: 28px;
    @media screen and (max-width: 1600px) {
      margin-left: 10px;
    }
    .v-btn {
      background: rgba(126, 86, 137, 0.2) !important;
      border-radius: 16px !important;
      height: 29px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500 !important;
      font-size: 13px !important;
      text-transform: none !important;
      letter-spacing: normal !important;
      line-height: 140%;
      padding: 0 8px !important;
      color: $color-violet;
      box-shadow: none !important;
      margin-left: 5px;
      &:hover {
        background: rgba(126, 86, 137, 0.4);
      }
      &.rephrase {
        height: 25px !important;
        padding: 0 5px !important;
        font-size: 9px !important;
      }
    }
  }
  .history-controls {
    display: inline-flex;
    align-items: center;
    .v-btn {
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      border: 1px solid rgba(126, 86, 137, 0.1) !important;
      box-sizing: border-box;
      border-radius: 16px;
      margin-left: 8px;
      box-shadow: none !important;
      background: $color-white !important;
      @media screen and (max-width: 1600px) {
        margin-left: 5px;
      }
      &[disabled] {
        background: $color-white !important;
        color: $color-grey2 !important;
        i {
          color: $color-grey2 !important;
          opacity: 0.4;
        }
      }
      i {
        font-size: 12px;
        color: $color-violet;
      }
    }
  }
}
.labels {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  &__title {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #1f2325;
    margin-bottom: 4px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
  }
  &__item {
    margin-right: 15px;
    margin-bottom: 5px;
    display: inline-flex;
    ::v-deep {
      .v-chip {
        margin: 0 !important;
        background: rgba(126, 86, 137, 0.2) !important;
        color: $color-violet;
        font-weight: 500;
        .v-icon.v-icon {
          color: $color-violet !important;
        }
      }
    }
    &-post-style {
      font-weight: 500;
      border-radius: 12px;
      font-size: 12px;
      height: 24px;
      padding: 0 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: rgba(34, 156, 28, 0.15) !important;
      color: #568968;
    }
  }
  .old-label {
    background: rgb(255 0 0 / 30%) !important;
  }
}
.checkbox-list {
  display: flex;
  .extension {
    margin-right: 15px;
    p {
      font-size: 10px;
      margin-bottom: 0px;
    }
    .v-input {
      margin-top: unset !important;
    }
  }
}
.image-format-container {
  border-top: 1px solid rgba(47, 72, 88, 0.1);
  padding-top: 10px;
  margin-bottom: 10px !important;
  margin-top: 15px !important;
}
.crop-format {
  display: flex;
  align-items: center;
  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #1f2325;
    margin-right: 5px;
  }
  ::v-deep {
    .v-input {
      padding: 0;
      margin: 0;
      &__slot {
        margin: 0;
        padding: 0;
        border: none;
        background: transparent !important;
        box-shadow: 0px 2px 6px rgba(31, 35, 37, 0.08);
      }
      &--radio-group__input {
        display: flex;
        align-items: center;
        flex-direction: row;
        .v-radio {
          margin-bottom: 0 !important;
          border: 1px solid #c7c9ca;
          box-sizing: border-box;
          &:first-child {
            border-radius: 8px 0 0 8px;
            position: relative;
            right: -1px;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
            position: relative;
            left: -1px;
          }
          .crop-format__item {
            display: inline-flex;
            align-items: center;
            height: 36px;
            padding: 0 12px;
            .icon {
              width: 18px;
              height: 18px;
              margin-right: 8px;
              background: rgba(78, 87, 120, 0.1);
              border: 1.5px solid rgba(78, 87, 120, 0.6);
              box-sizing: border-box;
              border-radius: 2px;
              &.horizontal {
                height: 12px;
              }
              &.vertical {
                width: 12px;
              }
            }
            .name {
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
            }
          }
          &.v-item--active {
            z-index: 11;
            background: rgba(126, 86, 137, 0.05);
            border: 1px solid rgba(126, 86, 137, 0.2);
            .crop-format__item {
              .icon {
                background: rgba(126, 86, 137, 0.05);
                border: 1.5px solid rgba(126, 86, 137, 0.8);
                border-radius: 2px;
              }
              .name {
                color: #7e5689;
              }
            }
          }
        }
      }
      &--selection-controls__input {
        display: none;
      }
      .v-messages {
        display: none;
      }
    }
  }
}

.theme {
  width: 100%;
  &__info {
    display: flex;
    flex-direction: column;
    &-row {
      display: inline-flex;
      align-items: baseline;
      margin-bottom: 5px;
      .name {
        margin-right: 10px;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        color: #1f2325;
      }
      .val {
        font-size: 12px;
        line-height: 140%;
        color: #1f2325;
      }
    }
  }
  &__data {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    &-field {
      width: 100%;
      position: relative;
      input {
        width: 100%;
        border: 1px solid #c7c9ca;
        overflow-y: auto;
        box-sizing: border-box;
        border-radius: 4px;
        background: #fafafa !important;
        height: 36px;
        padding: 0 35px 0 5px;
        font-size: 14px;
        outline: none;
        &.subject__modified {
          background: rgba(255, 0, 0, 0.3) !important;
        }
      }
      .undo-btn {
        position: absolute;
        right: 3px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        button {
          height: 30px !important;
          width: 30px !important;
          min-width: 30px;
          .v-btn__content {
            i {
              font-size: 18px;
            }
          }
        }
      }
    }
    &-buttons {
      margin-left: 10px;
      display: inline-flex;
      align-items: center;
      .v-btn:not([disabled]) {
        background: rgba(126, 86, 137, 0.2) !important;
        box-shadow: none;
        i {
          color: #7e5689 !important;
        }
      }
      button {
        height: 36px !important;
        .v-btn__content {
          i {
            font-size: 18px;
          }
        }
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}
.clipboardExtension {
  background-color: $color-violet !important;
  color: white;
}
.caption-input {
  ::v-deep {
    .v-label.theme--light {
      overflow: unset !important;
      white-space: unset !important;
    }
  }
}

::v-deep {
  .modify-template-modal {
    overflow-x: hidden;
    width: 100%;
    max-width: 1000px;
    padding: 0 30px;
    overflow-y: hidden;
    box-shadow: none;
  }
}

.show-template {
  &.theme--dark.v-btn.v-btn--disabled {
    color: rgba(255, 255, 255, 1) !important;
  }
  &.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}
</style>
