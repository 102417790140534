<template>
  <ModalDialog
    :customClass="'tag-modal'"
    :isShow="dialog.isShow"
    :footerButtons="footerButtons"
    :title="modalTitle"
    @success="setTag()"
    @close="closeHandler"
    @delete="deleteTagHandler"
  >
    <template v-if="!isDeletePopup" v-slot:description>
      <v-form class="product-modal" ref="form" v-model="valid" lazy-validation>
        <div class="form-control">
          <div class="radio-wrap">
            <v-radio-group v-model="radioGroup" :row="true" hide-details>
              <v-radio label="Description" value="description"></v-radio>
              <v-radio label="Client" value="client"></v-radio>
              <v-radio label="Product" value="product"></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="form-control" v-if="radioGroup === 'description'">
          <span class="form-control__name">Description</span>
          <div class="form-control__row">
            <v-textarea
              v-model="description"
              required
              solo
              label="Tag name"
              :rules="[rules.required]"
            ></v-textarea>
            <button
              class="remove-tag"
              @click.prevent="
                $emit('deleteTag', { mediaFile, flag: 'description' })
              "
              v-if="description && isEdit"
            >
              <i class="icon-close"></i>
            </button>
          </div>
        </div>
        <div class="form-control" v-else-if="radioGroup === 'product'">
          <span class="form-control__name">Tags</span>
          <div class="form-control__row">
            <v-textarea
              v-model="productValue"
              placeholder="Select tag"
              :rules="[rules.requiredValue]"
            ></v-textarea>
            <button
              class="remove-tag"
              @click.prevent="
                $emit('deleteTag', { mediaFile, flag: 'product' })
              "
              v-if="productValue && isEdit"
            >
              <i class="icon-close"></i>
            </button>
          </div>
        </div>
        <div class="form-control client-tag" v-else>
          <span class="form-control__name">Tag</span>
          <div class="form-control__row">
            <v-text-field
              v-model="clientTag"
              required
              solo
              label="Tag name"
              :rules="[rules.required, rules.fieldMustBeWithoutSpaces]"
            ></v-text-field>
            <button
              class="remove-tag"
              @click.prevent="$emit('deleteTag', { mediaFile, flag: 'client' })"
              v-if="clientTag && isEdit"
            >
              <i class="icon-close"></i>
            </button>
          </div>
        </div>
      </v-form>
    </template>
  </ModalDialog>
</template>

<script>
import ModalDialog from "@/components/global/ModalDialog";
import validationRules from "@/shared/validationRules";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TagInfo",
  data: () => ({
    valid: false,
    dialog: {
      isShow: false,
    },
    productValue: null,
    clientTag: "",
    radioGroup: "description",
    isEdit: false,
    description: "",
  }),
  props: {
    mediaFile: {
      type: Object,
      default: () => {},
    },
    fileUrl: {
      type: String,
      default: "",
    },
    modalTitle: {
      type: String,
      default: "",
    },
    isCommunicationTag: {
      type: Boolean,
      default: false,
    },
    isDeletePopup: {
      type: Boolean,
      default: false,
    },
    editTagType: {
      type: String,
      default: "all",
    },
  },
  mixins: [validationRules],
  components: { ModalDialog },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    footerButtons() {
      return this.isDeletePopup
        ? [
            { name: "Ok", emitName: "delete", btnClass: "violet" },
            { name: "Cancel", emitName: "close" },
          ]
        : [
            { name: "Save", emitName: "success", btnClass: "violet" },
            { name: "Cancel", emitName: "close" },
          ];
    },
  },
  created() {
    this.dialog.isShow = true;
    if (!this.isDeletePopup) {
      this.setVal();
    }
  },
  methods: {
    ...mapActions("filesController", ["setTagForFile"]),
    setTag() {
      if (this.$refs.form.validate()) {
        const data = {
          url: this.fileUrl || this.mediaFile.mediaUrl,
          tag:
            this.radioGroup === "description"
              ? this.description
              : this.radioGroup === "product"
              ? this.productValue
              : this.clientTag,
          type: this.radioGroup,
          id: this.mediaFile ? this.mediaFile.id : undefined,
        };

        this.addTag(data, this.radioGroup);
      }
    },
    closeHandler() {
      this.dialog.isShow = false;
      this.$emit("close");
    },
    setVal() {
      if (this.mediaFile.tagDescription) {
        this.description = this.mediaFile.tagDescription;
      }

      if (this.mediaFile.tagClient) {
        this.clientTag = this.mediaFile.tagClient;
      }

      if (this.mediaFile.tagProduct) {
        this.productValue = this.mediaFile.tagProduct;
      }

      if (this.mediaFile.tagDescription) {
        this.radioGroup = "description";
        this.isEdit = true;
      } else if (this.mediaFile.tagClient) {
        this.radioGroup = "client";
        this.isEdit = true;
      } else if (this.mediaFile.tagProduct) {
        this.radioGroup = "product";
        this.isEdit = true;
      }
    },
    deleteTagHandler() {
      const data =
        this.editTagType !== "all"
          ? this.generateDeleteData([this.radioGroup])
          : this.generateDeleteData(["client", "product", "description"]);
      data.forEach((item) => {
        this.addTag(item, this.editTagType);
      });
    },
    generateDeleteData(tagTypes = [this.editTagType]) {
      return tagTypes.map((tagType) => {
        return {
          url: this.fileUrl || this.mediaFile.mediaUrl,
          tag: "",
          type: tagType,
          id: this.mediaFile ? this.mediaFile.id : undefined,
        };
      });
    },
    async addTag(data, editTagType = "") {
      try {
        const tag = await this.setTagForFile(data);
        if (tag) {
          if (editTagType) {
            this.$emit("updateMedia", {
              id: this.mediaFile.id,
              tag: tag.data,
              editTagType: editTagType,
            });
          } else {
            this.$emit("updateMedia", {
              id: this.mediaFile.id,
              tag: tag.data,
            });
          }
        }
      } catch (e) {
        throw e;
      } finally {
        this.isEdit = false;
        this.closeHandler();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.product-modal {
  display: block;
  width: 100%;
  ::v-deep {
    .radio-wrap {
      margin-left: -4px;
      margin-bottom: 15px;
    }
    .form-control {
      &__name {
        display: inline-block;
        width: 100%;
        text-align: left;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #1f2325;
        margin-bottom: 4px;
      }
      &__row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        .remove-tag {
          height: 26px;
          width: 26px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #c7c9ca;
          box-sizing: border-box;
          border-radius: 4px;
          margin-left: 10px;
          color: $color-red;
          font-size: 10px;
          margin-top: 8px;
        }
      }
      .v-input__slot {
        background: transparent;
        border: 1px solid #c7c9ca;
        box-sizing: border-box;
        border-radius: 4px;
        &:hover {
          background: transparent !important;
        }
        &:after,
        &:before {
          display: none;
        }
      }
      .v-input--selection-controls {
        margin-top: 0 !important;
      }
      .v-input--radio-group {
        .v-input__slot {
          border: none;
        }
        .mdi-radiobox-blank {
          color: $color-black;
        }
        .mdi-radiobox-marked {
          color: $color-violet !important;
          caret-color: $color-violet !important;
        }
        .v-input--selection-controls__input:hover
          .v-input--selection-controls__ripple:before {
          display: none;
        }
        .v-icon.v-icon {
          font-size: 20px;
        }
        .v-label {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          color: #1f2325;
        }
      }
      .v-select__slot {
        input {
          font-size: 13px;
        }
      }
      .v-text-field__details {
        padding-left: 0;
      }
    }
    .client-tag {
      width: 100%;
      .v-input__slot {
        box-shadow: none !important;
        margin-bottom: 5px;
        input {
          font-size: 14px !important;
        }
      }
      .v-input__control {
        min-height: 40px;
      }
      .v-label {
        font-size: 13px;
      }
    }
  }
}

.tags {
  width: 20px;
  height: 20px;
  position: absolute;
  &__btn {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 11px;
    color: $color-grey2;
    background: $color-white;
    transition: all 0.3s ease;
    cursor: pointer;
    outline: none;
    &:hover {
      color: $color-violet;
    }
    &.tag {
      color: $color-violet;
    }
  }
}
</style>
