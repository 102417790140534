import { mapGetters } from "vuex";
import { UPDATE_BRANDING_TYPES } from "@/constants/smb-client";

export default {
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    ...mapGetters("smbPost", [
      "getAmountPostsToReview",
      "getContentCalendarAmount",
    ]),
    isNewUser() {
      return (
        this.getAmountPostsToReview === 0 && this.getContentCalendarAmount === 0
      );
    },
    isNeedToAddBranding() {
      return (
        !this.getCurrentSmbCustomer.brand_logo &&
        !this.getCurrentSmbCustomer.brokerage_logo &&
        !this.getCurrentSmbCustomer.brand_headshots.length
      );
    },
    isNeedToAddBusiness() {
      return (
        !this.getCurrentSmbCustomer.about_client &&
        !this.getCurrentSmbCustomer.area
      );
    },
    isNeedToAddColor() {
      return (
        !this.getCurrentSmbCustomer.brand_color_palettes ||
        this.getCurrentSmbCustomer.brand_color_palettes[0].length < 3
      );
    },
    isNeedToConnectSocialNetworks() {
      return (
        !this.getCurrentSmbCustomer.ig_page_link &&
        !this.getCurrentSmbCustomer.fb_page_link
      );
    },
    isNeedToUpdateBothSocialsAndBranding() {
      return (
        this.isNewUser && this.isShowTasks && this.isNeedToConnectSocialNetworks
      );
    },
    listOfTasks() {
      return [
        this.isNeedToAddBranding,
        this.isNeedToAddBusiness,
        this.isNeedToAddColor,
      ];
    },
    isShowTasks() {
      const length = this.listOfTasks.filter((item) => item === false).length;
      return length < 2;
    },
    isNeedToUpdateBranding() {
      if (
        (!this.getCurrentSmbCustomer.brand_color_palettes.length ||
          (this.getCurrentSmbCustomer.brand_color_palettes.length &&
            this.getCurrentSmbCustomer.brand_color_palettes[0].length <= 2)) &&
        !this.getCurrentSmbCustomer.brand_logo
      ) {
        return UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_LOGO_AND_COLORS;
      } else if (
        !this.getCurrentSmbCustomer.brand_color_palettes.length ||
        (this.getCurrentSmbCustomer.brand_color_palettes.length &&
          this.getCurrentSmbCustomer.brand_color_palettes[0].length <= 2)
      ) {
        return UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_COLORS;
      } else if (!this.getCurrentSmbCustomer.brand_logo) {
        return UPDATE_BRANDING_TYPES.NEED_TO_UPDATE_LOGO;
      } else {
        return null;
      }
    },
    isCanSmbCustomerGenerateStoriesInTestMode() {
      return (
        this.getCurrentSmbCustomer.plan === 1 &&
        !(
          this.getCurrentSmbCustomer.social_platforms.length === 1 &&
          this.getCurrentSmbCustomer.social_platforms[0] === "facebook"
        ) &&
        this.$dayjs().isBefore(this.$dayjs("2023-08-15"))
      );
    },
  },
};
