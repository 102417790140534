import BaseClass from "@/models/BaseClass";

const fieldsDefault = [
  { field: "free_speech", default: null },
  { field: "cluster_tags", default: [] },
  { field: "employee_tag", default: null },
  { field: "post_type", default: 0 },
  { field: "icon_type", default: 0 },
  { field: "business_name", default: "NYCSalon" },
  { field: "caption_length", default: 0 },
  { field: "captions", default: null },
  { field: "type", default: "post" },
];

export default class TextCreation extends BaseClass {
  constructor(obj = {}, fields = fieldsDefault, isUpdateClass = false) {
    super(obj, fields, isUpdateClass);
  }
}
