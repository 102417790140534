import BaseClass from "@/models/BaseClass";

const fields = [
  { field: "approved", default: 0 },
  { field: "pre-approved", default: 0, alias: "pre-feedback" },
  { field: "rejected", default: 0 },
  { field: "archive", default: 0 },
];

export default class PostCount extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
  }
}
