<template>
  <div class="external-library">
    <div class="external-library__search">
      <v-text-field
        v-model="searchVal"
        :label="searchPlaceholder.length ? searchPlaceholder : 'Search'"
        @keydown.enter="search(1)"
      ></v-text-field>
      <button @click="search(1)" class="btn black">Search</button>
    </div>
    <div class="external-library__filter" style="display: none">
      <div class="external-library__filter-item">
        <v-menu
          v-model="isSelectColor"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" small v-on="on" outlined color="#7E5689">
              Color
              <v-icon right> mdi-chevron-down </v-icon>
            </v-btn>
          </template>

          <v-card>
            <SelectColor
              @saveColor="saveColor($event)"
              @close="isSelectColor = false"
            />
          </v-card>
        </v-menu>
      </div>
      <div class="external-library__filter-item" v-if="selectedColor">
        <v-chip
          class="color-chip"
          v-if="selectedColor"
          close
          @click:close="selectedColor = null"
        >
          <span
            class="color"
            :style="{ backgroundColor: selectedColor }"
          ></span>
        </v-chip>
      </div>
    </div>
    <div v-if="errorText.length" class="error">{{ errorText }}</div>
    <div
      class="external-library__list"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
    >
      <div
        class="item"
        v-for="(item, index) in media"
        :key="index"
        @click="
          $emit('changeImage', {
            mediaUrl: item.media_url,
            externalId: item.media_external_id,
            media_thumbnail_url: item.media_thumbnail_url,
            externalService: item.externalService,
          })
        "
      >
        <img
          :src="
            item.media_thumbnail_url ? item.media_thumbnail_url : item.media_url
          "
          alt=""
        />
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Loader from "@/components/global/Loader";
import helper from "@/mixins/helper";
import SelectColor from "@/components/templatesModal/SelectColor";
import H from "@/utils/helper";

export default {
  name: "StockMedia",
  props: {
    mediaRatio: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    searchTermVal: {
      type: String,
      default: "",
    },
    templateId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    searchVal: null,
    isLoading: false,
    busy: false,
    media: [],
    imagesAlternatives: [],
    nextPage: null,
    service: undefined,
    page: 0,
    limitPages: undefined,
    isSelectColor: false,
    selectedColor: null,
    errorText: "",
  }),
  components: { Loader, SelectColor },
  mixins: [helper],
  methods: {
    ...mapActions("templates", ["getStockMedia"]),
    async search(pageNum) {
      this.errorText = "";
      if (pageNum) {
        this.media = [];
        this.page = 0;
      }
      try {
        if (this.searchVal) {
          this.isLoading = true;
          let params = {
            countPerSearchTerm: this.limitPages,
            templateId: this.templateId,
            nextPage: this.nextPage,
            page: this.page ? this.page : undefined,
            ratio: this.mediaRatio,
            searchTerm: this.searchVal,
            service: this.service,
          };
          const { service, page, photos, count } = await this.getStockMedia(
            params
          );
          this.service = service;
          this.limitPages = count;
          this.page = page + 1;
          if (photos.length) {
            const images = photos;
            this.busy = false;
            this.media = [...this.media, ...images];
          }
          this.isLoading = false;
        }
      } catch (e) {
        this.isLoading = false;
        this.errorText = "Something went wrong please try again";
        console.log(e);
      }
    },
    async loadMore() {
      this.busy = true;
      if (this.page <= this.limitPages) {
        await this.search();
      }
    },
    saveColor(color) {
      this.isSelectColor = false;
      this.selectedColor = color.hex;
    },
  },
};
</script>

<style scoped lang="scss">
.external-library {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  &__search {
    display: flex;
    align-items: baseline;
    margin-top: -20px;
    .btn {
      margin-left: 10px;
    }
  }
  &__filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    &-item {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 2px;
    .item {
      width: 100%;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .color-chip {
    .color {
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 3px;
      border: 1px solid #cccccc;
    }
  }
}
</style>
