<template>
  <div class="wonder-editor__wrapper">
    <quill-editor
      class="wonder-editor"
      ref="myQuillEditor"
      :content="value"
      :options="editorOption"
      @change="onEditorChange($event)"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
    />
    <EmojiPicker
      v-if="isShowEmoji"
      isCustomElement
      class="emoji"
      @newValue="insertEmojiToText"
    />
    <div class="tooltip" v-if="isShowTooltip">
      <template v-if="link">
        <a class="source-link" :href="link" target="_blank">
          <v-icon>mdi-link-variant </v-icon>
        </a>
      </template>
      <template v-if="tooltip">
        <v-icon
          :class="{ 'pl-2': link }"
          color="red"
          @mouseover="hoverOnIconHandler"
          @mouseleave="leaveIconHandler"
        >
          mdi-alert
        </v-icon>
        <v-tooltip v-model="modelTooltip" right>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on"></div>
          </template>
          <span
            @mouseover="hoverOnContentHandler"
            @mouseleave="leaveContentHandler"
            v-html="tooltip"
          >
          </span>
        </v-tooltip>
      </template>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "MyRichTextEditor",
  data() {
    return {
      editorOption: {
        theme: "bubble",
        formats: ["background", "underline"],
        placeholder: this.placeholder,
      },
      cursorPosition: 0,
      isHoverOnIcon: false,
      isHoverOnContent: false,
      modelTooltip: false,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isShowEmoji: {
      type: Boolean,
      default: false,
    },
    isShowTooltip: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    isUsedHtml: {
      type: Boolean,
      default: false,
    },
    leaveTimeOut: {
      type: [Number, String],
      default: 2000,
    },
    link: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Insert your caption here...",
    },
  },
  components: {
    quillEditor,
    EmojiPicker: () => import("@/components/global/EmojiPicker"),
  },
  methods: {
    onEditorBlur(quill = this.editor) {
      this.$emit("blur", { html: quill.root.innerHTML, text: quill.getText() });
    },
    onEditorFocus(quill) {
      this.cursorPosition = quill.getSelection().index;

      quill.removeFormat(
        quill.getSelection().index,
        quill.getSelection().length
      );
    },
    onEditorChange({ quill, html, text }) {
      this.cursorPosition = quill.getSelection()
        ? quill.getSelection().index
        : this.cursorPosition + 2 || text.length + 1 || 0;
      this.$emit("editorChanged", { html, text });
    },
    insertEmojiToText(emoji) {
      this.editor.insertText(this.cursorPosition, emoji);
      this.editor.setSelection(this.cursorPosition);
      this.cursorPosition = this.editor.getSelection().index;
    },
    insertTextWithFormating() {
      if (!this.isUsedHtml && this.value && this.editor) {
        this.editor.deleteText(0, this.value.length);
        this.editor.insertText(0, this.value);
      }
    },
    hoverOnIconHandler() {
      this.isHoverOnIcon = true;
      this.isHoverOnContent = false;
      this.modelTooltip = true;
    },
    hoverOnContentHandler() {
      this.isHoverOnIcon = false;
      this.isHoverOnContent = true;
      this.modelTooltip = true;
    },
    leaveIconHandler() {
      this.isHoverOnIcon = false;

      const timeOut = setTimeout(() => {
        if (!this.isHoverOnContent) {
          this.modelTooltip = false;
        }
        clearTimeout(timeOut);
      }, this.leaveTimeOut);
    },
    leaveContentHandler() {
      this.isHoverOnContent = false;

      const timeOut = setTimeout(() => {
        if (!this.isHoverOnIcon) {
          this.modelTooltip = false;
        }
        clearTimeout(timeOut);
      }, this.leaveTimeOut);
    },
  },
  watch: {
    placeholder(newValue) {
      if (newValue) {
        this.editor.root.dataset.placeholder = newValue;
      }
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    this.cursorPosition = this.cursorPosition || 0;
    this.insertTextWithFormating();
  },
  updated() {
    this.cursorPosition = this.cursorPosition || 0;
    this.insertTextWithFormating();
  },
};
</script>

<style scoped lang="scss">
.wonder-editor__wrapper {
  position: relative;
  .wonder-editor {
    ::v-deep {
      .ql-container {
        height: 140px;
        border: 1px solid #c7c9ca;
        overflow-y: auto;
        box-sizing: border-box;
        border-radius: 4px;
        background: white !important;
        min-height: 36px !important;
        box-shadow: none !important;
        resize: vertical;
        &-focused {
          border: 1px solid #c7c9ca;
          outline: none;
          p {
            margin-bottom: 16px;
          }
        }
        p {
          margin-bottom: 0;
        }
        .ql-editor {
          font-size: 16px;
        }
      }
      .ql-tooltip-arrow,
      .ql-toolbar {
        display: none;
      }
    }
  }
  .emoji {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 2;
  }
  .tooltip {
    position: absolute;
    display: flex;
    right: 8px;
    top: -25px;
    a {
      text-decoration: none !important;
      border: none !important;
      i {
        top: -2px;
        color: #7e5689;
      }
    }
    i {
      font-size: 18px;
    }
  }
}

.v-tooltip__content {
  pointer-events: auto;
}
</style>
