import BaseClass from "@/models/BaseClass";
import H from "@/utils/helper";

const fields = [
  "customerId",
  "id",
  "name",
  "last_visited",
  "personal_name",
  "owner_id",
  { field: "picUrl", default: null },
  { field: "unread_messages", default: 0 },
  { field: "social_platforms", default: [] },
  { field: "domain", default: null },
  { field: "subdomains", default: [] },
  { field: "activity_state", default: null },
  { field: "stripe_state", default: null },
  { field: "rush_post", default: false },
  { field: "isReadyExportStatus", default: false },
  { field: "isReadyChatState", default: false },
  { field: "is_automated", default: false },
  { field: "postAnyway", default: null },
];

export default class Customer extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
    this.meta_tester = !H.isEmpty(obj, "meta_tester")
      ? obj.meta_tester === "true"
      : false;
  }
}
