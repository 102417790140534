import BaseClass from "@/models/BaseClass";
import H from "@/utils/helper";

const fields = [
  { field: "id", default: "" },
  { field: "postId", default: "" },
  { field: "publishAt", default: "" },
  { field: "publishAtStr", default: "" },
  { field: "contentSubject", default: "" },
  { field: "contentSubtheme", default: "" },
  { field: "contentTheme", default: "" },
  { field: "contentMediaIds", default: [] },
  { field: "contentCaptionIds", default: [] },
  { field: "contentHashtags", default: [] },
  { field: "postStatus", default: "" },
  { field: "generation", default: "" },
  { field: "type", default: "" },
  { field: "preferred_site", default: null },
  { field: "isClickedToEditHashtagsIcon", default: null },
];

export default class CalendarPost extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
    this.start =
      !H.isEmpty(obj) && obj.publishAtStr ? obj.publishAtStr.split("_")[0] : "";

    this.contentHashtagsBeforeEdited =
      !H.isEmpty(obj) && obj.contentHashtags && obj.contentHashtags.length
        ? JSON.parse(JSON.stringify(obj.contentHashtags))
        : [];

    this.copyId = !H.isEmpty(obj) && obj.id ? obj.id : "";
  }
}
