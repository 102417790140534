<template>
  <v-app id="inspire">
    <Loader v-if="isLoading" />

    <!--    <Header />-->
    <template v-else>
      <Sidebar />
      <v-main class="main-wrapper">
        <router-view />
      </v-main>
    </template>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Cookies from "js-cookie";
/*import Header from "@/components/global/Header";*/
import Loader from "@/components/global/Loader";
import Sidebar from "@/components/global/Sidebar";
export default {
  name: "MainLayout",
  data: () => ({
    isLoading: false,
  }),
  components: {
    Sidebar,
    Loader,
    /*Header,*/
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("clientSelection", ["getCustomersArray"]),
  },
  methods: {
    ...mapMutations("clientSelection", ["setCurrentCustomer"]),
    ...mapMutations("auth", ["setCurrentUser"]),
    ...mapActions("clientSelection", ["fetchCustomerInfo", "fetchCustomers"]),
  },
  async created() {
    this.isLoading = true;
    if (
      sessionStorage.currentCustomer &&
      JSON.parse(sessionStorage.currentCustomer)
    ) {
      const customerId = JSON.parse(sessionStorage.currentCustomer).id;
      await this.fetchCustomerInfo(customerId);
    }
    if (Cookies.getJSON("currentUser")) {
      this.setCurrentUser(Cookies.getJSON("currentUser"));
    }

    this.isLoading = false;

    if (this.getCurrentUser && !this.getCustomersArray.length) {
      await this.fetchCustomers({ id: this.getCurrentUser.id });
    }
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  background: #f3f2f3;
}
</style>
