export default {
  methods: {
    prepareFilters() {
      return Object.fromEntries(
        Object.keys(this.filter)
          .filter((e) => this.filter[e] !== null)
          .map((key) => {
            if (Array.isArray(this.filter[key])) {
              const values = [];
              this.filter[key].map((e) => values.push(e.value));
              return [key, values];
            } else if (typeof this.filter[key] === "object") {
              return [key, this.filter[key].value];
            } else {
              return [key, this.filter[key]];
            }
          })
      );
    },
  },
};
