import BaseClass from "@/models/BaseClass";
import H from "@/utils/helper";
import Template from "@/models/Template";

const fields = [
  "createdAt",
  "createdBy",
  "mediaUrl",
  "id",
  "sentBy",
  "usedPostId",
  "tagClient",
  "tagProduct",
  "tagDescription",
  { field: "thumbnailUrl", default: null },
  { field: "mediaUrlReduced", default: null },
  { field: "mediaState", default: null },
  { field: "favorite", default: false },
  { field: "hideImg", default: false, alias: "hidden" },
  { field: "used", default: false },
  { field: "isFromMedia", default: true },
  { field: "canGetMoreInformation", default: true },
  { field: "externalSocialPlatform", default: null },
  { field: "externalId", default: null },
  { field: "externalChildren", default: null },
  { field: "externalIgId", default: null },
  { field: "externalPermalink", default: null },
  { field: "externalTimestamp", default: null },
  { field: "externalCaption", default: null },
  { field: "externalLikeCount", default: null },
  { field: "externalMediaProductType", default: null },
  { field: "externalMediaType", default: null },
  { field: "externalCommentsCount", default: null },
  { field: "externalVideoTitle", default: null },
  { field: "externalCarousel", default: null },
  { field: "isShowFullDate", default: false },
];

export default class MediaFile extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);

    this.type =
      !H.isEmpty(obj) && obj.mediaUrl
        ? H.getMediaType(obj.mediaUrl)
        : undefined;

    this.template =
      !H.isEmpty(obj) && obj.template ? new Template(obj.template) : null;
  }
}
