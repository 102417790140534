<template>
  <div>
    <v-dialog v-model="isShow" persistent activator="parent" width="580">
      <v-card class="smb-popup">
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-actions class="smb-popup__actions">
          <v-btn
            class="smb-popup__button"
            color="#1D1D1B"
            @click="$emit('close', isChecked)"
            >OK</v-btn
          >
          <v-checkbox
            v-if="isCanSeeDemoFeatures"
            v-model="isChecked"
            color="black"
            label="don't show me this message again"
          ></v-checkbox>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SmbPopup",
  data: () => ({
    isChecked: false,
  }),
  computed: {
    ...mapGetters("smbClientSelection", ["isCanSeeDemoFeatures"]),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isShowCheckbox: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.smb-popup {
  &__button {
    color: #ffffff;
    width: 100px;
    border-radius: 16px;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    ::v-deep {
      .v-input--selection-controls {
        label {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
  .v-card__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    padding-top: 36px;
    padding-bottom: 15px;
    word-break: break-word;
  }
}
</style>
