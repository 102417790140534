import BaseClass from "@/models/BaseClass";
import H from "@/utils/helper";

const fields = [
  { field: "id", default: null },
  "name",
  { field: "read_only", default: false },
];

export default class User extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);

    this.picture_url =
      !H.isEmpty(obj) && obj.picture_url && obj.picture_url.trim() !== ""
        ? obj.picture_url
        : require("../../assets/default-profile-picture.png");
  }
}
