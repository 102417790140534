import axios from "axios";

const axiosFunctions = axios.create({
  baseURL: process.env.VUE_APP_REMOVE_BG_URL,
  headers: {
    "content-type": "application/x-www-form-urlencoded",
    "X-RapidAPI-Key": "db5ab6d7efmsh0a83c4a0d7c30f5p1da3a7jsne03d94ec2257",
    "X-RapidAPI-Host": "background-removal.p.rapidapi.com",
  },
});
export default {
  namespaced: true,
  actions: {
    async deleteBgForImageByUrl({ commit }, imgUrl = {}) {
      try {
        const formData = new FormData();
        formData.append("size", "auto");
        formData.append("image_url", imgUrl);

        const { data } = await axiosFunctions.post("", formData);
        const { error, response } = data;
        const { image_url } = response;
        return { error, image_url };
      } catch (e) {
        throw e;
      }
    },
  },
};
