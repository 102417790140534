<template>
  <div class="video-wrapper">
    <video
      class="video-container"
      :ref="refName"
      controls
      @play="playHandler"
      @pause="stopHandler"
    >
      <source :src="videoSrc + '#t=0.001'" :type="'video/' + videoType" />
    </video>
    <div class="video-buttons" v-if="isShowButton">
      <i
        v-if="getActiveVideoRefName === refName"
        @click="$refs[refName].pause()"
        class="icon-pause1"
      ></i>
      <i v-else @click="$refs[refName].play()" class="icon-play"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "VideoPlayer",
  props: {
    refName: {
      type: String,
      default: "",
    },
    videoSrc: {
      type: String,
      default: "",
    },
    videoType: {
      type: String,
      default: "mp4",
    },
    isShowButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("videoPlayer", ["getActiveVideoRefName", "getActiveVideo"]),
  },
  methods: {
    ...mapMutations("videoPlayer", ["setToActiveVideo", "resetActiveVideoRef"]),
    playHandler() {
      this.setToActiveVideo({
        element: this.$refs[this.refName], // HTML element
        refName: this.refName,
      });
    },
    stopHandler() {
      if (this.refName === this.getActiveVideoRefName) {
        this.resetActiveVideoRef();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  .video-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video-buttons {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    i {
      color: #fff;
      font-size: 60px;
      cursor: pointer;
      &.icon-pause1 {
        color: transparent;
        transition: all 0.3s ease;
      }
      &.icon-play {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 12px;
          left: 12px;
          width: 30px;
          height: 30px;
          background: #000;
        }
        &:before {
          position: relative;
          z-index: 10;
        }
      }
    }
  }
  &:hover {
    .video-buttons {
      i.icon-pause1 {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  video::-webkit-media-controls-overlay-play-button {
    display: none;
  }

  *::-webkit-media-controls-panel {
    display: none !important;

    -webkit-appearance: none;
  }

  *::--webkit-media-controls-play-button {
    display: none !important;

    -webkit-appearance: none;
  }

  *::-webkit-media-controls-start-playback-button {
    display: none !important;

    -webkit-appearance: none;
  }
}
</style>
