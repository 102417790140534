import axios from "axios";

export default {
  namespaced: true,
  state: {
    isStoriesTabActive: false,
    updateSuccess: false,
  },
  mutations: {
    setIsStoriesTabActive(state, flag) {
      state.isStoriesTabActive = flag;
    },
    setUpdateSuccessFlag(state) {
      state.updateSuccess = new Date();
    },
  },
  actions: {
    async approveInstagramPost({ commit }, params = {}) {
      try {
        await axios.post("smpost", params);
        commit("setUpdateSuccessFlag");
      } catch (e) {
        throw e;
      }
    },
    async copywriter({ commit }, params = {}) {
      try {
        const { data } = await axios.post("copywriter", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getIsStoriesTabActive(state) {
      return state.isStoriesTabActive;
    },
    getUpdateSuccessFlag(state) {
      return state.updateSuccess;
    },
  },
};
