export const CONTENT_TYPE_LIST = [
  { id: 1, name: "Service Highlight" },
  { id: 2, name: "Humorous" },
  { id: 3, name: "Motivation" },
  { id: 4, name: "Service Promotion" },
  { id: 5, name: "Thank You" },
  { id: 6, name: "Tip of the Day" },
  { id: 7, name: "Informative" },
  { id: 8, name: "Wisdom" },
];

export const CONTENT_ICON_LIST = [
  { id: 1, name: "Happy" },
  { id: 2, name: "Sad" },
];

export const WIDGETS_ID = {
  TEXT_CREATION: 0,
  INSTAGRAM_POST: 1,
  COMMUNICATION: 2,
  CLIENT_INFO: 3,
  MEDIA_LIBRARY: 4,
};

export const CHAT_COLORS = [
  "#ebecf0",
  "#d3ebff",
  "#ffedd3",
  "#ffd3d3",
  "#fffad3",
  "#e5c3e8",
  "#d3c3e8",
  "#c3e6e8",
  "#b9ffa2",
  "#f6ea95",
];
