import BaseClass from "@/models/BaseClass";
import ImageFields from "./imageFields";
import H from "@/utils/helper";

const fields = [{ field: "media_id", default: "", alias: "mediaId" }];

export default class NewImage extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
    this.url =
      !H.isEmpty(obj) && obj.url && !H.isEmpty(obj.url, "url")
        ? obj.url.url
        : "";
    this.fields =
      !H.isEmpty(obj) && obj.url && !H.isEmpty(obj.url, "fields")
        ? new ImageFields(obj.url.fields)
        : new ImageFields();
  }
}
