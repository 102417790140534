import BaseClass from "@/models/BaseClass";

const fields = [
  { field: "height", default: 0 },
  { field: "left", default: 0 },
  { field: "top", default: 0 },
  { field: "width", default: 0 },
];

export default class CropDetail extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
  }
}
