import { render, staticRenderFns } from "./ClientNote.vue?vue&type=template&id=3b213894&scoped=true&"
import script from "./ClientNote.vue?vue&type=script&lang=js&"
export * from "./ClientNote.vue?vue&type=script&lang=js&"
import style0 from "./ClientNote.vue?vue&type=style&index=0&id=3b213894&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b213894",
  null
  
)

export default component.exports