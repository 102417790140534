import BaseClass from "@/models/BaseClass";

const fields = [
  "id",
  "ratio",
  "ok",
  "preview_url",
  "video_preview_url",
  "snapshot_url",
  "isLicensed",
  { field: "parameters", default: [] },
  { field: "isAutoGenerated", default: false },
];

export default class Template extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
  }
}
