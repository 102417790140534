import BaseClass from "@/models/BaseClass";

const fields = [
  { field: "brand", default: null },
  { field: "product", default: null },
  { field: "domain", default: null },
  { field: "subdomain", default: null },
  { field: "id", default: null },
  { field: "general", default: [] },
  { field: "specific", default: [] },
  { field: "type_of_product", default: true },
];

export default class Product extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
  }
}
