<template>
  <v-dialog
    @click:outside="clickOutsideHandler()"
    @keydown.esc="$emit('close')"
    :persistent="!isClickOutside"
    v-model="isShowVar"
    :width="size"
    :content-class="customClass"
  >
    <v-card>
      <Loader v-if="isNeedToCallLoaderComponent" />
      <div class="modal" :class="{ 'modal-loader': isLoading }">
        <v-progress-circular
          v-if="isLoading"
          :size="70"
          :width="7"
          color="#7c5789"
          indeterminate
        ></v-progress-circular>
        <div v-else>
          <button class="close" v-if="isCanClose" @click="$emit('close')">
            <i class="icon-close"></i>
          </button>
          <div class="modal__icon" v-if="icon">
            <img :src="icon" alt="" />
          </div>
          <div class="modal__header" v-if="title">
            <div class="title">
              {{ title }}
            </div>
          </div>
          <slot name="custom-header"></slot>
          <div class="modal__header" v-if="subTitle">
            <div class="subTitle">
              {{ subTitle }}
            </div>
          </div>
          <div
            class="modal__body"
            :class="{ 'margin-body': isNeedMarginFromBody }"
          >
            <div class="desc">
              <slot name="description"></slot>
            </div>
          </div>
          <div class="modal__footer" v-if="footerIsShow">
            <div class="btn-wrapper test" v-if="footerButtons.length">
              <button
                v-for="button in footerButtons"
                :key="button.id"
                :disabled="button.isDisabled || false"
                :class="['btn', button.btnClass ? button.btnClass : 'border']"
                @click="$emit(button.emitName, button)"
              >
                {{ button.name }}
              </button>
            </div>
          </div>
          <div v-if="isShowSlotFooter" class="modal__footer">
            <div class="desc">
              <slot name="footer_slot"></slot>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from "@/components/global/Loader.vue";

export default {
  name: "ModalDialog",
  components: { Loader },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 415,
    },
    title: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    footerIsShow: {
      type: Boolean,
      default: true,
    },
    isClickOutside: {
      type: Boolean,
      default: true,
    },
    footerButtons: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCanClose: {
      type: Boolean,
      default: true,
    },
    subTitle: {
      type: String,
      default: "",
    },
    isNeedMarginFromBody: {
      type: Boolean,
      default: true,
    },
    isNeedToCallLoaderComponent: {
      type: Boolean,
      default: false,
    },
    isShowSlotFooter: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isShowVar: false,
  }),
  created() {
    this.isShowVar = this.isShow;
  },
  watch: {
    isShow(val) {
      this.isShowVar = val;
    },
  },
  methods: {
    clickOutsideHandler() {
      if (this.isClickOutside) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.modal {
  padding: 36px;
  position: relative;
  .close {
    position: absolute;
    right: 13px;
    top: 13px;
    font-size: 8px;
    color: $color-black;
    opacity: 0.3;
  }
  &__icon {
    display: flex;
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    margin: 0 auto 18px auto;
    filter: grayscale(1);
  }
  &__header {
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 18px !important;
      line-height: 140%;
      color: $color-black;
      margin-bottom: 8px;
    }
    .subTitle {
      font-weight: normal;
      font-size: 14px !important;
      line-height: 120%;
      color: $color-black;
      margin-bottom: 3px;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    .desc {
      font-size: 14px;
      line-height: 160%;
      width: 100%;
      text-align: center;
      color: #515456;
    }
  }
  .margin-body {
    margin-bottom: 28px;
  }
  &__footer {
    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        font-size: 15px !important;
        margin: 0 10px;
        min-width: 94px;
      }
    }
    .max-width-120 {
      max-width: 120px;
    }
  }
}
.modal-loader {
  display: flex;
  justify-content: center;
}
::v-deep {
  .tag-modal {
    .modal__body {
      margin-bottom: 0;
    }
    .modal__header {
      .title {
        font-weight: normal;
        font-size: 19px !important;
        line-height: 120%;
        color: $color-black;
        margin-bottom: 8px;
      }
      .subTitle {
        font-weight: normal;
        font-size: 14px !important;
        line-height: 120%;
        color: $color-black;
        margin-bottom: 3px;
      }
    }
  }
  .reject-modal-info {
    .slider {
      width: 200px;
      position: relative;
      margin: 0 auto;
      .content-container {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .v-window__prev,
      .v-window__next {
        margin: 0 5px;
        background: transparent !important;
        width: 24px;
        height: 24%;
        .v-btn__content {
          display: none;
        }
        .v-btn {
          &:before {
            display: none;
          }
        }
      }
      .v-window__container {
        .v-window__next {
          right: 0;
        }
      }
      .v-window__prev {
        .v-btn {
          width: 24px;
          height: 24px;
          &:after {
            font-family: "icomoon" !important;
            content: "\e902";
            position: absolute;
            font-size: 24px;
            color: $color-white;
          }
        }
      }
      .v-window__next {
        .v-btn {
          width: 24px;
          height: 24px;
          &:after {
            font-family: "icomoon" !important;
            content: "\e90c";
            position: absolute;
            font-size: 24px;
            color: $color-white;
          }
        }
      }
      .v-carousel {
        overflow: inherit;
        &__controls {
          bottom: -40px;
          .v-item-group {
            .v-btn:before {
              display: none;
            }
            .v-btn__content {
              i {
                display: none;
              }
            }
            .v-btn--icon.v-size--small {
              width: 4px;
              height: 4px;
              background: #c7c9ca;
              margin: 0 4px;
            }
            .v-btn.v-item--active {
              background: #000;
            }
          }
        }
      }
    }
    .review {
      margin-top: 25px;
      pre {
        white-space: normal;
      }
    }
  }
  .btn-wrapper {
    @media screen and (min-width: 389px) {
      flex-direction: column;
      .btn {
        margin-bottom: 20px !important;
        width: 100%;
        min-width: 100%;
        font-size: 15px !important;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .active {
      background: $color-violet;
      color: $color-white;
    }
    .disabled {
      background: rgba(126, 86, 137, 0.3);
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .center_btn {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    @media screen and (min-width: 389px) {
      .btn-wrapper {
        width: 100%;
      }
      padding-top: unset !important;
    }
  }
  .custom_header {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    &__title {
      font-weight: 600;
      color: $color-black;
      padding-right: 5px;
    }
  }
  .sub-title {
    text-align: center;
    font-weight: 600;
    color: $color-black;
  }
  .template-list,
  .template-modal {
    .modal {
      padding: 20px;
      &__body {
        margin-bottom: 0;
      }
      &__footer {
        display: none;
      }
    }
  }
  .approve-delete-media {
    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      .red {
        background: #fe3a43;
        border: 1px solid #1d1d1b;
      }
    }
  }
  .edit-template-modal {
    .modal {
      padding: 36px 20px;
      @media screen and (max-width: 600px) {
        padding: 16px;
      }
      &__body {
        margin-bottom: 0;
      }
    }
  }
  .reset-template-dialog {
    .desc {
      font-size: 16px;
    }
    .btn-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        padding: 0 15px;
        margin-bottom: 0;
      }
    }
  }
}
.edit-font {
  .btn-wrapper {
    display: flex;
    flex-direction: row;
    .violet {
      background: $color-black !important;
      color: $color-white !important;
      &:hover {
        background: #000000;
      }
    }
    .border {
      background: $color-white;
      border: 1px solid $color-black;
      &:hover {
        background: rgba(124, 120, 120, 0.1);
      }
    }
  }
}
.remove-bg-dialog {
  .modal {
    ::v-deep {
      .img-wrapper {
        height: 350px;
        display: inline-flex;
        background-image: linear-gradient(45deg, #dedede 25%, transparent 25%),
          linear-gradient(-45deg, #dededeff 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, #dededeff 75%),
          linear-gradient(-45deg, transparent 75%, #dededeff 75%);
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
        background-repeat: repeat;
        .img-without-bg {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}
.connect-facebook {
  .modal {
    padding: 0;
    &__footer {
      display: flex;
      width: 100%;
      .btn-wrapper {
        width: 100%;
        padding: 10px 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        .black {
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 140%;
          color: #f5ede2;
          margin-bottom: 0 !important;
          margin-right: 0;
          min-width: auto !important;
          white-space: nowrap;
          width: auto;
        }
        .border {
          font-weight: 300 !important;
          font-size: 10px !important;
          line-height: 140% !important;
          margin-right: 10px;
          margin-bottom: 0 !important;
          height: 36px;
          min-width: auto !important;
          padding: 0 5px !important;
          background: #ffffff;
          border: 0.5px solid #1d1d1b;
          border-radius: 16px;
          width: auto;
        }
      }
    }
    &__body {
      margin-bottom: 0;
    }
  }
}

.go-to-live-modal {
  .btn-wrapper {
    @media screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: flex-end;
    }
    .btn {
      width: auto;
      border-radius: 16px;
      height: 50px;
      &.blue-border {
        font-weight: 700;
      }
    }
  }
}
.change-social-media-modal {
  .title {
    text-align: left !important;
    font-size: 24px !important;
  }
  .modal__header {
    .subTitle {
      text-align: left;
      font-size: 18px !important;
    }
  }
  .btn-wrapper {
    @media screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: center;
    }
    .btn {
      width: auto;
      border-radius: 16px;
      height: 50px;
      margin-bottom: 0 !important;
      min-width: 170px;
      &.blue-border {
        font-weight: 700;
      }
    }
  }
}
</style>
