<template>
  <v-tooltip
    :bottom="align('bottom')"
    :top="align('top')"
    :left="align('left')"
    :right="align('right')"
  >
    <template v-slot:activator="{ on }">
      <span @click="copy" v-on="on" @mouseleave="mouseLeft">
        {{ text }}
        <v-icon small> mdi-content-copy </v-icon>
      </span>
    </template>
    <span :key="resetKey">
      <v-icon v-if="copiedIcon" color="success" class="pr-1" small>
        {{ copiedIcon }}
      </v-icon>
      {{ label }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      required: true,
    },
    tooltipPosition: {
      type: String,
      default: "bottom",
      validator: function (value) {
        return ["top", "bottom", "left", "right"].indexOf(value) !== -1;
      },
    },
    tooltipLabel: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      labeltext: "Copy",
      copiedIcon: null,
      resetKey: 0,
    };
  },
  computed: {
    label() {
      return `${this.labeltext}${
        this.tooltipLabel ? `: ${this.tooltipLabel}` : ""
      }`;
    },
    align() {
      return (value) => value === this.tooltipPosition;
    },
  },
  watch: {
    labeltext() {
      this.resetKey += 1;
    },
  },
  methods: {
    mouseLeft() {
      setTimeout(() => {
        this.labeltext = "Copy";
        this.copiedIcon = null;
      }, 500);
    },
    copyDataToClipboard(e) {
      e.preventDefault(); // default behaviour is to copy any selected text
      e.clipboardData.setData("text/plain", this.data);
    },
    copy() {
      document.addEventListener("copy", this.copyDataToClipboard);
      try {
        document.execCommand("copy");
        this.labeltext = "Copied";
        this.copiedIcon = "mdi-check-circle-outline";
      } catch (exception) {
        console.error("Copy to clipboard failed");
      } finally {
        document.removeEventListener("copy", this.copyDataToClipboard);
      }
    },
  },
};
</script>
