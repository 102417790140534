<template>
  <div class="template-media-library">
    <div
      class="template-media-library__wrapper"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
    >
      <div
        class="item"
        v-for="(item, index) in mediaFiles"
        :key="index"
        @click="$emit('changeImage', { mediaUrl: item.mediaUrl })"
      >
        <img
          :src="item.mediaUrlReduced ? item.mediaUrlReduced : item.mediaUrl"
          alt=""
        />
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/global/Loader";
import helper from "@/mixins/helper";

export default {
  name: "TemplateMediaLibrary",
  components: { Loader },
  mixins: [helper],
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("mediaLibraryWidget", ["getIsUpdateMedia"]),
  },
  data: () => ({
    mediaFiles: [],
    isLoading: false,
    busy: false,
    lastKey: null,
    lastKeyClient: null,
  }),
  async created() {
    await this.getMedia();
    await this.getClientMedia();
  },
  methods: {
    ...mapActions("filesController", ["getUrlForSendFile", "uploadFile"]),
    ...mapActions("mediaLibraryWidget", ["fetchMediaData", "getMediaInfo"]),
    async getMedia() {
      this.isLoading = true;
      const params = {
        customerId: this.getCurrentCustomer.id,
        type: 2,
        limit: "30",
        startKey: this.lastKey,
      };
      const { items, last_key } = await this.fetchMediaData(params);
      const imageOnly = items.filter((item) => this.isImage(item.mediaUrl));
      this.mediaFiles = [...this.mediaFiles, ...imageOnly];
      this.lastKey = last_key;
      this.isLoading = false;
      this.busy = false;
    },
    async getClientMedia() {
      this.isLoading = true;
      const params = {
        customerId: this.getCurrentCustomer.id,
        type: 1,
        limit: "30",
        startKey: this.lastKeyClient,
      };
      const { items, last_key } = await this.fetchMediaData(params);
      const imageOnly = items.filter((item) => this.isImage(item.mediaUrl));
      this.mediaFiles = [...this.mediaFiles, ...imageOnly];
      this.lastKeyClient = last_key;
      this.isLoading = false;
      this.busy = false;
    },
    async loadMore() {
      this.busy = true;
      if (this.lastKey) {
        await this.getMedia();
      }
      if (this.lastKeyClient) {
        await this.getClientMedia();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.template-media-library {
  width: 100%;
  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    max-height: 500px;
    min-height: 300px;
    overflow-y: auto;
    padding-right: 2px;
    .item {
      width: 100%;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
