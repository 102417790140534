<template>
  <v-app id="inspire">
    <v-row align-content="center" justify-sm="center">
      <v-col cols="12" sm="7" lg="4" xl="3">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: "LoginLayout",
};
</script>
