import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store";

import guest from "@/middleware/guest";
import auth from "@/middleware/auth";
import isCustomerSelected from "@/middleware/isCustomerSelected";
import middlewarePipeline from "./middlewarePipeline";

const PreFeedback = () => import("@/components/createPost/tabs/PreFeedback");
const Approved = () => import("@/components/createPost/tabs/Approved");
const Rejected = () => import("@/components/createPost/tabs/Rejected");
const Archive = () => import("@/components/createPost/tabs/Archive");

const ClientStatus = () => import("@/views/status/Index");
const Posts = () => import("@/views/clientDashboard/Posts");
const PreviousFeedback = () =>
  import("@/views/clientDashboard/PreviousFeedback");
const NotFound = () => import("@/views/errorPages/Index");

const FbAuth = () => import("@/views/fbAuth/Index");
const Insights = () => import("@/views/insights/Index");
const ModifyClient = () => import("@/views/modifyClient/Index");

const Login = () => import("@/views/login/Index");
const Lead = () => import("@/views/lead/Index");
const Dashboard = () => import("@/views/dashboard/Index");
const CreatePost = () => import("@/views/createPost/Index");
const ContentGeneration = () => import("@/views/contentGeneration/Index");
const ClientDashboardPage = () => import("@/views/clientDashboard/Index");
const SmbRouter = () => import("@/views/smb-client/SmbRouter");
const SmbClient = () => import("@/views/smb-client/Index");
const SmbFeedback = () => import("@/views/smb-client/pages/smbFeedback/Index");
const SmbGenerateContent = () =>
  import("@/views/smb-client/pages/generateContent/Index");
const PersonalUpdate = () =>
  import("@/views/smb-client/pages/generateContent/personalUpdate/Index");
const Tips = () =>
  import("@/views/smb-client/pages/generateContent/tips/Index");
const Vocabulary = () =>
  import("@/views/smb-client/pages/generateContent/vocabulary/Index");
const QuestionsAnswers = () =>
  import("@/views/smb-client/pages/generateContent/questionsAnswers/Index");
const Quote = () =>
  import("@/views/smb-client/pages/generateContent/quote/Index");
const Testimonial = () =>
  import("@/views/smb-client/pages/generateContent/testimonial/Index");
const Listing = () =>
  import("@/views/smb-client/pages/generateContent/listing/Index");
const ContentCalendar = () =>
  import("@/views/smb-client/pages/contentCalendar/Index");
const ProfileSettings = () => import("@/views/smb-client/pages/settings/Index");
const BusinessInfoSettings = () =>
  import("@/views/smb-client/pages/settings/pages/businessInfo/Index");
const TimezoneSettings = () =>
  import("@/views/smb-client/pages/settings/pages/timezone/Index");
const HashtagsSettings = () =>
  import("@/views/smb-client/pages/settings/pages/hashtags/Index");
const ValuesSettings = () =>
  import("@/views/smb-client/pages/settings/pages/values/Index");
const ColorsSettings = () =>
  import("@/views/smb-client/pages/settings/pages/colors/Index.vue");
const CustomerInfoSettings = () =>
  import("@/views/smb-client/pages/settings/pages/customerInfo/Index.vue");
const BusinessAssetsSettings = () =>
  import("@/views/smb-client/pages/settings/pages/businessAssets/Index.vue");
const ClientSelectionPage = () => import("@/views/clientSelection/Index");
const SmmClientPage = () => import("@/views/smmClient/Index");
const NewClient = () => import("@/views/newClient/Index");
const Calendar = () => import("@/views/calendar/Index");
const Templates = () => import("@/views/templates/Index");
const Tasks = () => import("@/views/tasks/Index");
const ContentMixture = () => import("@/views/contentMixture/Index");
const ModifySmm = () => import("@/views/modifySmm/Index");
const SmmAutomaticEmailMessages = () =>
  import("@/views/smmAutomaticEmailMessages/Index");

const BrandingSettings = () =>
  import("@/views/smb-client/pages/settings/pages/branding/Index");

const SaasOnboarding = () =>
  import("@/views/smb-client/pages/onboarding/Index");

const SaasOnboardingNewClient = () =>
  import("@/views/smb-client/pages/onboarding/newClientBoarding.vue");
const SaasNoAccess = () =>
  import("@/views/smb-client/pages/noAccess/Index.vue");

const SaasBetaFeatures = () =>
  import("@/views/smb-client/pages/beta/Index.vue");

const SaasPageNotFound = () =>
  import("@/views/smb-client/pages/pageNotFound/Index.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      layout: "login",
      middleware: [guest],
    },
    component: Login,
  },
  {
    path: "/lead",
    name: "lead",
    meta: {
      layout: "login",
      // middleware: [guest],
    },
    component: Lead,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      middleware: [auth, isCustomerSelected],
    },
    component: Dashboard,
  },
  {
    path: "/",
    name: "home",
    meta: {
      middleware: [auth, isCustomerSelected],
    },
    redirect: {
      name: "calendar",
    },
  },
  {
    path: "/create-post",
    name: "create-post",
    meta: {
      middleware: [auth, isCustomerSelected],
    },
    component: CreatePost,
    children: [
      {
        path: "pre-feedback",
        name: "create-post-pre-feedback",
        component: PreFeedback,
        meta: {
          middleware: [auth, isCustomerSelected],
        },
      },
      {
        path: "approved",
        name: "create-post-approved",
        component: Approved,
        meta: {
          middleware: [auth, isCustomerSelected],
        },
      },
      {
        path: "rejected",
        name: "create-post-rejected",
        component: Rejected,
        meta: {
          middleware: [auth, isCustomerSelected],
        },
      },
      {
        path: "archive",
        name: "create-post-archive",
        component: Archive,
        meta: {
          middleware: [auth, isCustomerSelected],
        },
      },
    ],
  },
  {
    path: "/content-generation",
    name: "content-generation",
    meta: {
      layout: "smb-client",
    },
    component: ContentGeneration,
  },
  {
    path: "/h",
    name: "home-general",
    meta: {
      layout: "smb-client",
    },
    component: SmbRouter,
    children: [
      {
        path: "onboarding",
        name: "saas-new-client-onboarding",
        component: SaasOnboardingNewClient,
        meta: {
          layout: "empty",
        },
      },
      {
        path: ":id",
        name: "smb-client",
        component: SmbClient,
        meta: {
          layout: "smb-client",
        },
      },
      {
        path: ":id/feedback",
        name: "smb-feedback",
        component: SmbFeedback,
        meta: {
          layout: "smb-client",
        },
      },
      {
        path: ":id/generate",
        component: SmbRouter,
        meta: {
          layout: "smb-client",
        },
        children: [
          {
            path: "",
            name: "generate-content",
            component: SmbGenerateContent,
            meta: {
              layout: "smb-client",
            },
          },
          {
            path: "personal-update",
            name: "personal-update",
            component: PersonalUpdate,
            meta: {
              layout: "smb-client",
            },
          },
          {
            path: "tips",
            name: "tips",
            component: Tips,
            meta: {
              layout: "smb-client",
            },
          },
          {
            path: "vocabulary",
            name: "vocabulary",
            component: Vocabulary,
            meta: {
              layout: "smb-client",
            },
          },
          {
            path: "questions-answers",
            name: "questions-answers",
            component: QuestionsAnswers,
            meta: {
              layout: "smb-client",
            },
          },
          {
            path: "quote",
            name: "quote",
            component: Quote,
            meta: {
              layout: "smb-client",
            },
          },
          {
            path: "testimonial",
            name: "testimonial",
            component: Testimonial,
            meta: {
              layout: "smb-client",
            },
          },
          {
            path: "listing",
            name: "listing",
            component: Listing,
            meta: {
              layout: "smb-client",
            },
          },
        ],
      },
      {
        path: ":id/settings",
        component: SmbRouter,
        meta: {
          layout: "smb-client",
        },
        children: [
          {
            path: "",
            component: SmbRouter,
            meta: {
              layout: "smb-client",
            },
            children: [
              {
                path: "",
                name: "profile-settings",
                component: ProfileSettings,
                meta: {
                  layout: "smb-client",
                },
              },
              {
                path: "info",
                name: "profile-settings-info",
                component: CustomerInfoSettings,
                meta: {
                  layout: "smb-client",
                },
              },
              {
                path: "business-assets",
                name: "profile-settings-business-assets",
                component: BusinessAssetsSettings,
                meta: {
                  layout: "smb-client",
                },
              },
              {
                path: "branding",
                name: "branding-settings",
                component: BrandingSettings,
                meta: {
                  layout: "smb-client",
                },
              },
              {
                path: "business-info",
                name: "business-settings-info",
                component: BusinessInfoSettings,
                meta: {
                  layout: "smb-client",
                },
              },
              {
                path: "timezone",
                name: "timezone-settings",
                component: TimezoneSettings,
                meta: {
                  layout: "smb-client",
                },
              },
              {
                path: "hashtags",
                name: "settings-hashtags",
                component: HashtagsSettings,
                meta: {
                  layout: "smb-client",
                },
              },
              {
                path: "values",
                name: "settings-values",
                component: ValuesSettings,
                meta: {
                  layout: "smb-client",
                },
              },
              {
                path: "colors",
                name: "profile-settings-colors",
                component: ColorsSettings,
                meta: {
                  layout: "smb-client",
                },
              },
            ],
          },
        ],
      },
      {
        path: ":id/content-calendar",
        component: SmbRouter,
        meta: {
          layout: "smb-client",
        },
        children: [
          {
            path: "",
            component: SmbRouter,
            meta: {
              layout: "smb-client",
            },
            children: [
              {
                path: "",
                name: "smb-content-calendar",
                component: ContentCalendar,
                meta: {
                  layout: "smb-client",
                },
              },
            ],
          },
        ],
      },
      {
        path: ":id/onboarding",
        name: "saas-onboarding",
        component: SaasOnboarding,
        meta: {
          layout: "smb-boarding",
        },
      },
      {
        path: ":id/no-access",
        name: "saas-no-access",
        component: SaasNoAccess,
        meta: {
          layout: "empty",
        },
      },
      {
        path: ":id/beta",
        name: "saas-beta-features",
        component: SaasBetaFeatures,
        meta: {
          layout: "smb-client",
        },
      },
      {
        path: "page-not-found",
        name: "saas-page-not-found",
        component: SaasPageNotFound,
        meta: {
          layout: "empty",
        },
      },
    ],
  },
  {
    path: "/client-dashboard-page",
    alias: "/p",
    meta: {
      layout: "client",
    },
    component: ClientDashboardPage,
    children: [
      {
        path: ":id",
        name: "client-posts-alias",
        component: Posts,
        meta: {
          layout: "client",
        },
      },
      {
        path: ":id/previous-feedback",
        name: "previous-feedback",
        component: PreviousFeedback,
        meta: {
          layout: "client",
        },
      },
      {
        path: "",
        name: "client-posts",
        component: Posts,
        meta: {
          layout: "client",
        },
      },
      {
        path: ":id?/status/:statusType?",
        name: "client-status",
        component: ClientStatus,
        meta: {
          layout: "client",
        },
      },
    ],
  },
  {
    path: "/status",
    name: "status",
    meta: {
      layout: "client",
    },
    component: ClientStatus,
  },
  {
    path: "/client-selection-page",
    name: "client-selection-page",
    meta: {
      layout: "selection",
      middleware: [auth],
    },
    component: ClientSelectionPage,
  },
  {
    path: "/smm-client-page",
    name: "smm-client-page",
    meta: {
      middleware: [auth, isCustomerSelected],
    },
    component: SmmClientPage,
  },
  {
    path: "/new-client",
    name: "new-client",
    meta: {
      layout: "empty",
    },
    component: NewClient,
  },
  {
    path: "/modify-client:customerId?",
    name: "modify-client",
    meta: {
      layout: "empty",
      middleware: [auth],
    },
    component: ModifyClient,
  },
  {
    path: "/fb-auth",
    name: "fb-auth",
    meta: {
      layout: "client",
    },
    component: FbAuth,
  },
  {
    path: "/modify-smm",
    name: "modify-smm",
    meta: {
      layout: "selection",
      middleware: [auth],
    },
    component: ModifySmm,
  },
  {
    path: "/insights",
    name: "insights",
    meta: {
      middleware: [auth, isCustomerSelected],
    },
    component: Insights,
  },
  {
    path: "/404",
    name: "404",
    meta: {
      layout: "client",
    },
    component: NotFound,
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/templates",
    name: "templates",
    meta: {
      middleware: [auth],
    },
    component: Templates,
  },
  {
    path: "/tasks",
    name: "tasks",
    meta: {
      middleware: [auth],
    },
    component: Tasks,
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: {
      middleware: [auth, isCustomerSelected],
    },
    component: Calendar,
  },
  {
    path: "/content-per-month",
    name: "contentMixture",
    meta: {
      middleware: [auth, isCustomerSelected],
    },
    component: ContentMixture,
  },
  {
    path: "/pre-meeting",
    name: "pre-meeting",
    meta: {
      layout: "login",
      middleware: [auth],
    },
    component: SmmAutomaticEmailMessages,
  },
  {
    path: "/h/:pathMatch(.*)*",
    component: SaasPageNotFound,
    meta: {
      layout: "empty",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
