var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modify-template"},[_c('button',{staticClass:"close",on:{"click":function($event){return _vm.cancel()}}},[_c('i',{staticClass:"icon-close"})]),_c('div',{staticClass:"modify-template__wrapper"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',[_c('div',{staticClass:"modify-template__header"},[_c('div',{staticClass:"title"},[_vm._v("Template:")]),_c('v-autocomplete',{staticClass:"template-autocomplete",attrs:{"loading":_vm.isLoadingGetNextTemplate,"items":_vm.getFilteredTemplates,"return-object":"","item-text":"name","menu-props":{ maxHeight: 500 },"label":"Select Template"},on:{"change":_vm.switchTemplate},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('img',{attrs:{"src":data.item.preview_url,"width":"80px"}})]}}]),model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}})],1),_c('v-container',{attrs:{"fluid":true}},[_c('v-row',[_c('v-col',{attrs:{"md":"7","sm":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.listItems),function(parameter,index){return _c('v-list-item',{key:`listItems-${index}`,attrs:{"dense":""}},[_c('v-list-item-content',[_c('div',{staticClass:"form-row"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-delay":"150","open-delay":"100","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"color",style:(`${_vm.getStyle(
                                  parameter['layer_name']
                                )}`)},'span',attrs,false),on),[_vm._v(_vm._s(parameter["group"]))])]}}],null,true)},[_c('ColorSelect',{on:{"setColor":function($event){return _vm.setParameterColor(parameter, $event)}}})],1),_c('v-text-field',{attrs:{"label":`${parameter['layer_name']} ${
                              parameter['description']
                                ? '  (' + parameter['description'] + ')'
                                : ''
                            }`,"required":"","rules":[_vm.rules.required]},on:{"change":function($event){return _vm.modifyTemplate($event, parameter)}},model:{value:(parameter['value']),callback:function ($$v) {_vm.$set(parameter, 'value', $$v)},expression:"parameter['value']"}})],1)])],1)}),_vm._l((_vm.listExtraItems),function(parameter,index){return _c('v-list-item',{key:`listExtraItems-${index}`,attrs:{"dense":""}},[_c('v-list-item-content',[_c('div',{staticClass:"form-row"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-delay":"150","open-delay":"100","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"color",style:(`${_vm.getStyle(
                                  parameter['layer_name']
                                )}`)},'span',attrs,false),on),[_vm._v(_vm._s(parameter["group"]))])]}}],null,true)},[_c('ColorSelect',{on:{"setColor":function($event){return _vm.setParameterColor(parameter, $event)}}})],1),_vm._v(" "+_vm._s(parameter["layer_name"])+" ")],1)])],1)}),_c('div',{staticClass:"select-image"},_vm._l((_vm.listImagesItems),function(parameter,index){return _c('div',{key:`listImagesItems-${index}-${_vm.imagesAlternativesCount}-${_vm.imageLayerValues}`,staticClass:"select-image__item"},[_c('div',{staticClass:"main-img__name"},[_vm._v(" "+_vm._s(parameter["layer_name"])+" ")]),_c('div',{staticClass:"main-img__img"},[_c('img',{attrs:{"src":_vm.imageLayerValues[_vm.getImageLayerIndex(parameter)]
                                .thumbnailUrl}}),_c('div',{staticClass:"edit-image"},[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"main-img__btn"},[_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.isLoadingImage(parameter)},on:{"click":function($event){return _vm.clickSelectImage(parameter)}}},'v-btn',attrs,false),on))],1)]}}],null,true),model:{value:(
                                _vm.isDialogMediaGrid[
                                  _vm.getImageLayerIndex(parameter)
                                ]
                              ),callback:function ($$v) {_vm.$set(_vm.isDialogMediaGrid, 
                                  _vm.getImageLayerIndex(parameter)
                                , $$v)},expression:"\n                                isDialogMediaGrid[\n                                  getImageLayerIndex(parameter)\n                                ]\n                              "}},[_c('MediaGrid',{attrs:{"mediaIndex":_vm.getImageLayerIndex(parameter),"parameter":parameter,"noMoreImages":_vm.noMoreImages,"gridImagesAlternatives":_vm.imagesAlternatives[
                                    _vm.getImageLayerIndex(parameter)
                                  ],"isDialogMediaGrid":_vm.isDialogMediaGrid[
                                    _vm.getImageLayerIndex(parameter)
                                  ]},on:{"getMoreImages":function($event){return _vm.getMediaImages(parameter, true)},"select":function($event){return _vm.setImageFromGrid($event)},"closeModal":function($event){_vm.isDialogMediaGrid = []}}})],1)],1)]),(
                            _vm.service == 'unsplash' &&
                            _vm.getStockImageUser(parameter) &&
                            _vm.getStockImageUser(parameter).userName
                          )?_c('div',{staticClass:"photo-info"},[_vm._v(" Photo by "),_c('a',{attrs:{"href":`https://unsplash.com/@${
                              _vm.getStockImageUser(parameter).userUsername
                            }?utm_source=Social-Wonder&utm_medium=referral`}},[_vm._v(_vm._s(_vm.getStockImageUser(parameter).userName))]),_vm._v(" on "),_c('a',{attrs:{"href":"https://unsplash.com/?utm_source=Social-Wonder&utm_medium=referral"}},[_vm._v("Unsplash")])]):_vm._e(),_c('v-col',[_c('v-row',[_c('v-text-field',{attrs:{"append-outer-icon":"mdi-image-search-outline","label":"Stock-Media Search term"},on:{"click:append-outer":function($event){return _vm.getAndSetRandomImage(parameter)},"change":function($event){return _vm.searchTermModified(parameter)}},model:{value:(
                                _vm.stockMediaSearchTerm[
                                  _vm.getImageLayerIndex(parameter)
                                ]
                              ),callback:function ($$v) {_vm.$set(_vm.stockMediaSearchTerm, 
                                  _vm.getImageLayerIndex(parameter)
                                , $$v)},expression:"\n                                stockMediaSearchTerm[\n                                  getImageLayerIndex(parameter)\n                                ]\n                              "}})],1)],1)],1)}),0)],2)],1),_c('div',{staticClass:"footer-actions"},[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","loading":_vm.isLoadingGetImageFromTemplate,"disabled":_vm.isLoadingImageArray.some((v) => v),"elevation":"2","raised":"","text":"","color":"primary","dark":""},on:{"click":_vm.createNewImage}},[_vm._v(" Generate Image ")])],1)],1)],1),_c('v-col',{attrs:{"md":"5","sm":"6"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-text',[_c('img',{attrs:{"src":_vm.image || _vm.preview_url,"width":"100%"}})]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"rounded":"","loading":_vm.isLoadingGetImageFromTemplate,"disabled":!_vm.image || !_vm.isTemplateFull || !_vm.generatePressed,"elevation":"2","raised":"","text":"","color":"primary","dark":""},on:{"click":_vm.saveAndClose}},[_vm._v(" Approve ")])],1)],1)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }