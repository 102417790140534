import Post from "@/models/Post";

export default {
  namespaced: true,
  state: {
    currentStories: new Post({ type: "story" }),
    cropFormatStories: "vertical",
    mediaIsLoading: [],
    prevHistoryArray: [],
    nextHistoryArray: [],
  },
  mutations: {
    wipeStoriesHistory(state) {
      state.prevHistoryArray = [];
      state.nextHistoryArray = [];
    },
    setMediaIsLoading(state, { index, value }) {
      let arr = [...state.mediaIsLoading];
      arr[index] = value;
      state.mediaIsLoading = arr;
    },
    setCropFormatStories(state, cropFormat) {
      state.cropFormatStories = cropFormat;
    },
    setCurrentStories(state, newStories) {
      state.currentStories = newStories;
    },
    refreshCurrentStories(state) {
      state.currentStories = new Post({ type: "story" });
    },
    pushToStoriesMediaFiles(state, newFile) {
      state.currentStories.media = [
        ...state.currentStories.media,
        ...[newFile],
      ];
    },
    pushToStartMediaPosition(state, newFile) {
      state.currentPost.media = [...[newFile], ...state.currentPost.media];
    },
    clearStoriesSelectionsMediaFiles(state) {
      const mediaObjects = state.currentStories.media.filter(
        (i) => !i.isFromMedia
      );
      state.currentStories.media = [...mediaObjects];
    },
    updateStoriesMediaFilesFromMediaLibrary(state, fileFromMediaLibrary) {
      let mediaUrls = state.currentStories.media.map((i) => {
        if (i.id && i.isFromMedia) {
          return i.id;
        }
      });
      if (mediaUrls.includes(fileFromMediaLibrary.id)) {
        state.currentStories.media = state.currentStories.media.filter(
          (file) => {
            if (file.isFromMedia) {
              return file.id !== fileFromMediaLibrary.id;
            } else {
              return file;
            }
          }
        );
      } else {
        state.currentStories.media = [
          ...state.currentStories.media,
          ...[fileFromMediaLibrary],
        ];
      }
    },
    refreshAllMediaFilesFromMediaLibrary(state) {
      state.currentStories.media = state.currentStories.media.filter((i) => {
        return !i.isFromMedia;
      });
    },
    setSelectedStoriesMediaFiles(state, arrayOfMediaFiles = []) {
      state.currentStories.media = [
        ...state.currentStories.media,
        ...arrayOfMediaFiles,
      ];
    },
    setToPreview(state, post) {
      state.prevHistoryArray.unshift(Object.assign({}, post));
    },
    setToNext(state, post) {
      state.nextHistoryArray.push(Object.assign({}, post));
    },
    updatePreview(state, arrayOfPost = []) {
      state.prevHistoryArray = [...arrayOfPost];
    },
    updateNext(state, arrayOfPost = []) {
      state.nextHistoryArray = [...arrayOfPost];
    },
    deleteFromPreview(state) {
      state.prevHistoryArray = state.prevHistoryArray.filter((val, index) => {
        return index !== 0;
      });
    },
    deleteFromNext(state) {
      state.nextHistoryArray = state.nextHistoryArray.filter((val, index) => {
        return index !== state.nextHistoryArray.length - 1;
      });
    },
  },
  actions: {
    showPreview({ commit }) {},
  },
  getters: {
    getCurrentStories(state) {
      return state.currentStories;
    },
    getCropFormatStories(state) {
      return state.cropFormatStories;
    },
    getCropStoriesDataNumber(state) {
      return state.cropFormatStories === "square"
        ? 1
        : state.cropFormatStories === "vertical"
        ? 8.99 / 16
        : null;
    },
    getMediaIsLoading(state) {
      return state.mediaIsLoading;
    },
    getPrevHistoryArray(state) {
      return state.prevHistoryArray;
    },
    getNextHistoryArray(state) {
      return state.nextHistoryArray;
    },
  },
};
