<template>
  <div class="sidebar" :class="{ narrow: isNarrowSidebar }">
    <div class="sidebar__logo">
      <img
        class="sidebar__logo-img-short"
        v-if="isNarrowSidebar"
        src="@/assets/img/logo_short.svg"
      />
      <img
        class="sidebar__logo-img"
        v-else
        src="@/assets/img/logo_full.svg"
        width="200"
      />
    </div>
    <div class="sidebar__toggle">
      <button class="sidebar__toggle-btn" @click="toggleSidebar()">
        <i class="icon-open-menu"></i>
      </button>
    </div>
    <div class="sidebar__nav">
      <div class="sidebar__nav-title">
        Welcome {{ isNewUser ? "" : "back" }}
        {{ getCurrentSmbCustomer.personal_name }}
      </div>
      <div class="sidebar__nav-item">
        <div
          @click="approveContentHandler()"
          class="sidebar__nav-link"
          :class="{ 'router-link-active': $route.name === 'smb-feedback' }"
        >
          <div class="button-loader" v-if="isLoadingReviewBtn">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <i
            class="icon-checkbox-icon"
            :class="{ 'pending-state': getAmountPostsToReview }"
          ></i>
          <span class="text">{{
            getAmountPostsToReview
              ? "Pending items for review"
              : "No items for review"
          }}</span>
          <span class="amount" v-if="getAmountPostsToReview">{{
            getAmountPostsToReview
          }}</span>
        </div>
      </div>
      <div class="sidebar__nav-item">
        <router-link
          :to="{
            name: 'generate-content',
          }"
          class="sidebar__nav-link"
        >
          <i class="icon-generate-content"></i>
          <span class="text">Generate content</span>
        </router-link>
      </div>
      <div class="sidebar__nav-item">
        <router-link
          :is="!getContentCalendarAmount ? 'span' : 'router-link'"
          :to="{
            name: 'smb-content-calendar',
          }"
          class="sidebar__nav-link"
          :class="{ disabled: !getContentCalendarAmount }"
        >
          <i class="icon-calendar-empty"></i>
          <span class="text">Content calendar</span>
        </router-link>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-notification" v-if="false">
        <i class="icon-notification"></i>
        <span class="text">Notifications</span>
        <span class="amount">16</span>
      </div>
      <div class="sidebar__footer-profile">
        <div class="sidebar__nav-item">
          <router-link
            :to="{
              name: 'profile-settings',
            }"
            class="sidebar__nav-link"
          >
            <i class="icon-user-avatar"></i>
            <span class="text">Profile and Settings</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { MENU_BUTTONS } from "@/constants/smb-client";
import smbMethods from "@/mixins/smbMethods";

export default {
  name: "SmbSidebar",
  mixins: [smbMethods],
  data: () => ({
    isNarrowSidebar: false,
    menuButtons: MENU_BUTTONS,
    isLoading: false,
    isLoadingReviewBtn: false,
  }),
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    ...mapGetters("smbPost", [
      "getAmountPostsToReview",
      "getContentCalendarAmount",
    ]),
  },
  methods: {
    ...mapActions("smbPost", ["fetchAmountsPostsToReview"]),
    ...mapMutations("smbPost", ["setRefreshPostList"]),
    async amountPosts() {
      this.isLoadingReviewBtn = true;
      try {
        await this.fetchAmountsPostsToReview({
          customerId: this.getCurrentSmbCustomer.id,
        });
        this.isLoadingReviewBtn = false;
      } catch (e) {
        this.isLoadingReviewBtn = false;
        console.log(e);
      }
    },
    toggleSidebar() {
      this.$emit("toggleSidebar");
      this.isNarrowSidebar = !this.isNarrowSidebar;
    },
    async approveContentHandler() {
      if (this.$route.name !== "smb-feedback") {
        await this.$router.push({
          name: "smb-feedback",
          params: { id: this.getCurrentSmbCustomer.id },
        });
      }
      if (!this.getAmountPostsToReview && this.$route.name !== "smb-feedback") {
        await this.amountPosts();
        if (this.getAmountPostsToReview) {
          await this.$router.push({
            name: "smb-feedback",
            params: { id: this.getCurrentSmbCustomer.id },
          });
        }
      }
      if (this.$route.name === "smb-feedback") {
        await this.amountPosts();
        this.setRefreshPostList(true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  background: #fff;
  width: 245px;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  @media screen and (max-width: 1500px) {
    width: 235px;
  }
  &__logo {
    margin-bottom: 50px;
    padding: 14px;
    &-img {
      width: 100%;
      max-width: 184px;
    }
  }
  &__toggle {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    padding: 0 14px;
    margin-bottom: 120px;
    &-btn {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 12px;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
      i {
        position: relative;
        transition: all 0.3s ease;
        transform: rotate(180deg);
      }
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    flex: 1;
    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      opacity: 0.5;
      padding-bottom: 8px;
      margin: 0 15px 45px 15px;
      border-bottom: 1px solid;
      text-align: center;
    }
    &-item {
      position: relative;
      padding: 0 14px;
      margin-bottom: 10px;
    }
    &-link {
      text-decoration: none;
      padding: 0 10px;
      border-radius: 8px;
      height: 40px;
      display: inline-flex;
      width: 100%;
      align-items: center;
      position: relative;
      cursor: pointer;
      &:before {
        content: "";
        background: transparent;
        position: absolute;
        right: -14px;
        width: 3px;
        height: 40px;
        border-radius: 3px 0px 0px 3px;
        transition: all 0.3s ease;
      }
      i {
        color: #671539;
        font-size: 16px;
        margin-right: 10px;
        &.icon-checkbox-icon {
          position: relative;
          background: #fff;
          border-radius: 4px;
          overflow: hidden;
          &:after {
            content: "";
            width: 11px;
            height: 11px;
            background: #fff;
            position: absolute;
            left: 3px;
            right: 0;
            top: 2px;
            bottom: 0;
            border-radius: 3px;
          }
          &:before {
            position: relative;
            z-index: 1;
          }
          &.pending-state {
            position: relative;
            &:before {
              z-index: 0;
            }
          }
        }
      }
      .text {
        color: #1d1d1b;
        font-weight: 500;
        font-size: 13px;
      }
      .amount {
        position: absolute;
        width: 18px;
        height: 18px;
        display: inline-flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        background: #e56969;
        color: #fff;
        font-weight: 700;
        font-size: 11px;
        margin-right: 5px;
        border-radius: 50%;
        right: -10px;
        top: -5px;
        z-index: 5;
      }
      &:hover {
        background: #f5ede2;
      }
      &.router-link-active {
        background: #f5ede2;
        &:before {
          background: #671539;
        }
        .text {
          color: #671539;
        }
      }
    }
    .disabled {
      .text,
      i {
        color: #1d1d1b80;
      }
    }
  }
  &__footer-notification {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 0 14px 10px 14px;
    padding: 0 14px;
    height: 40px;
    i {
      color: #671539;
      font-size: 16px;
      margin-right: 10px;
    }
    .text {
      color: #1d1d1b;
      font-weight: 500;
      font-size: 14px;
    }
    .amount {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 10px;
      color: #1d1d1b;
      line-height: 140%;
      border-radius: 4px;
      background: rgba(29, 29, 27, 0.1);
      margin-left: 10px;
      position: relative;
      &:before {
        content: "";
        background: #e56969;
        border: 1px solid #ffffff;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
      }
    }
  }
  &__footer-profile {
    padding-top: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    &:before {
      content: "";
      left: 15px;
      right: 15px;
      height: 1px;
      background: #2f4858;
      opacity: 0.2;
      border-radius: 1px;
      position: absolute;
      top: 0;
    }
  }
  &.narrow {
    width: 60px;
    .sidebar {
      &__logo-img-short {
        width: 100%;
      }
      &__toggle {
        justify-content: center;
        &-btn {
          i {
            position: relative;
            transition: all 0.3s ease;
            transform: rotate(0deg);
          }
        }
      }
      &__nav-title {
        font-size: 0;
        opacity: 0;
      }
      &__nav {
        &-item {
          padding: 0 10px;
        }
        &-link {
          .text {
            display: none;
          }
          i {
            margin: auto;
            color: #1d1d1b;
          }
          &:before {
            right: -10px;
          }
        }
      }
    }
  }
}

.button-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 2;
}
</style>
