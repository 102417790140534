export const TARIFF_PLAN_LIST = [
  { id: 1, name: "Basic" },
  { id: 2, name: "Plus" },
  { id: 3, name: "Premium" },
];

export const SMB_TARIFF_PLAN_LIST = [
  { id: 1, name: "Starter" },
  { id: 2, name: "Pro" },
  { id: 3, name: "Premium" },
];

export const TIME_ZONE_LIST = [
  { code: "MST", name: "America/Phoenix" },
  { code: "PST", name: "America/Los_Angeles" },
  { code: "CST", name: "America/Chicago" },
  { code: "HST", name: "Pacific/Honolulu" },
  { code: "EST", name: "America/New_York" },
];
