<template>
  <div>
    <ModalDialog
      :isShow="moreInformationMediaDialog.isShow"
      :title="moreInformationMediaDialog.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="moreInformationMediaDialog.footerButtons"
      @approve="approvedMoreInformationHandler()"
      @close="closeHandler"
      :isLoading="isLoading"
    >
      <template v-slot:description>
        Do you want to ask more information about this media from
        {{ getCurrentCustomer.name }}?
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ModalDialog from "@/components/global/ModalDialog";

export default {
  name: "GetMoreMediaInformation",
  components: { ModalDialog },
  props: {
    currentMediaFile: {
      type: Object,
      default: () => {},
    },
    messageId: {
      default: null,
    },
  },
  data: () => ({
    moreInformationMediaDialog: {
      isShow: false,
      title: "Get more information?",
      footerButtons: [
        { name: "Cancel", emitName: "close" },
        { name: "Approve", emitName: "approve", btnClass: "violet" },
      ],
    },
    currentMediaForMoreInformation: null,
    isLoading: false,
  }),
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  created() {
    this.moreInformationMediaDialog.isShow = true;
  },
  methods: {
    ...mapActions("filesController", ["fetchMoreInformationAboutMedia"]),
    async approvedMoreInformationHandler() {
      this.isLoading = true;
      try {
        await this.fetchMoreInformationAboutMedia({
          customerId: this.getCurrentCustomer.id,
          mediaUrl: this.currentMediaFile.mediaUrl,
          messageId: this.messageId || undefined,
          moreInfo: true,
        });
        this.$emit("updateStatus", {
          messageId: this.messageId,
          mediaFile: this.currentMediaFile,
        });
      } catch (e) {
        throw e;
      } finally {
        this.isLoading = false;
        this.closeHandler();
      }
    },
    selectMediaForMoreMediaHandler(mediaFile) {
      this.currentMediaForMoreInformation = mediaFile;
      this.moreInformationMediaDialog.isShow = true;
    },
    closeHandler() {
      this.moreInformationMediaDialog.isShow = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
