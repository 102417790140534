import axios from "axios";

const axiosFunctions = axios.create({
  baseURL: process.env.VUE_APP_PRE_MEETING_URL,
});

export default {
  namespaced: true,
  actions: {
    async sendEmail({ commit }, params = {}) {
      try {
        const { data } = await axiosFunctions.post("", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
