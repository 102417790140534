import axios from "axios";
import Post from "@/models/Post";
import Customer from "@/models/Customer";
import qs from "qs";

const axiosFunctions = axios.create({
  baseURL: process.env.VUE_APP_FUNCTIONS_URL,
});

export default {
  namespaced: true,
  actions: {
    async getSmpostExport({ commit }, id) {
      try {
        const { data } = await axios.get(`smpost/export/${id}`);
        return data;
      } catch (e) {
        console.error(e);
        return new Customer({});
      }
    },
    async fetchCustomerInfo({ commit }, id) {
      try {
        const { data } = await axios.get(`customer/${id}`);
        return new Customer(data);
      } catch (e) {
        console.error(e);
        return new Customer({});
      }
    },
    async fetchPromotionData({ commit }, id) {
      try {
        const { data } = await axiosFunctions.get(`/promotion/`, {
          params: {
            exportId: id,
          },
        });
        return data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchPosts({ commit }, arrayOfIds = []) {
      const url = arrayOfIds.map((n, index) => `id=${n}`).join("&");
      try {
        const { data } = await axios.get(`/smpost?${url}`);
        return data.map((o) => new Post(o));
      } catch (e) {
        throw e;
      }
    },
    async updatePosts({ commit }, params = {}) {
      try {
        await axios.put(`/smpost`, params);
      } catch (e) {
        throw e;
      }
    },
    async updateStatus({ commit }, params = {}) {
      try {
        const { data } = await axios.put(`/exppost`, params);
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
