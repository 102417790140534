import axios from "axios";
import MediaFile from "@/models/MediaFile";
import Post from "@/models/Post";
import qs from "qs";

export default {
  namespaced: true,
  state: {
    selectedMediaFiles: [],
    filterImageIsActive: true,
    isUpdateMedia: false,
  },
  mutations: {
    addMediaLibrarySelection(state, newSelectedFile) {
      state.selectedMediaFiles.push(newSelectedFile);
    },
    setMediaLibraryFilterIsActive(state, newVal) {
      state.filterImageIsActive = newVal;
    },
    setIsUpdateMedia(state, newVal) {
      state.isUpdateMedia = newVal;
    },
    removeMediaLibrarySelection(state, selectedFile) {
      state.selectedMediaFiles = state.selectedMediaFiles.filter((val) => {
        if (val.isFromMedia) {
          return val.id !== selectedFile.id;
        } else {
          return val;
        }
      });
    },
    clearMediaLibrarySelections(state) {
      state.selectedMediaFiles = [];
    },
    setMediaLibrarySelections(state, arrayOfMediaFiles = []) {
      state.selectedMediaFiles = arrayOfMediaFiles;
    },
  },
  actions: {
    async fetchMediaData({ commit }, params = {}) {
      try {
        const { data } = await axios.get(`media`, { params });
        const { items, last_key } = data;
        let list = [];

        items.forEach((mediaItem) => {
          mediaItem.items.forEach((mediaFilesList, mediaFilesListIndex) => {
            if (mediaFilesListIndex === 0) {
              mediaFilesList.isShowFullDate = true;
            }
            list.push(mediaFilesList);
          });
        });

        return { items: list.map((o) => new MediaFile(o)), last_key };
      } catch (e) {
        throw e;
      }
    },

    async fetchInspirationData({ commit }, params = {}) {
      try {
        const { data } = await axios.get(`media`, { params });

        return data;
      } catch (e) {
        throw e;
      }
    },

    async uploadMediaFromUrl({ commit }, params = {}) {
      try {
        return await axios.post(`/media/upload_from_url`, params);
      } catch (e) {
        console.log(e);
      }
    },

    async getMediaInfo({ commit }, id) {
      try {
        const respose = await axios.get(`media/${id}`);
        return new MediaFile(respose.data);
      } catch (e) {
        console.error(e);
        return new MediaFile({});
      }
    },
    async updateMediaFile({ commit }, params = {}) {
      try {
        await axios.put(`media`, params);
      } catch (e) {
        throw e;
      }
    },
    async downloads({ commit }, params = {}) {
      try {
        const response = await axios.get(`media/pull`, {
          params: { ...params },
          paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        });
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async fetchScheduledPosts({ commit }, params) {
      try {
        const { data } = await axios.get(
          `${params.customerId}/smpost?is_published=${false}`
        );
        return data.map((o) => new Post(o));
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getMediaLibrarySelections(state) {
      return state.selectedMediaFiles;
    },
    getMediaLibraryFilterIsActive(state) {
      return state.filterImageIsActive;
    },
    getMediaLibrarySelectionsId(state) {
      return state.selectedMediaFiles.map((i) => i.id);
    },
    getFirstMediaLibrarySelectionId(state) {
      let fistFile = state.selectedMediaFiles.find((i) => i.id);
      return fistFile ? fistFile.id : null;
    },
    getIsUpdateMedia(state) {
      return state.isUpdateMedia;
    },
  },
};
