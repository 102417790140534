<template>
  <div class="form-wrapper">
    <v-form class="mt-4 justify-center" ref="form">
      <div class="form-control">
        <div class="crop-format">
          <div class="crop-format__title">Format</div>
          <div class="crop-format__list">
            <v-radio-group v-model="radios" @change="changeCropFormat()">
              <v-radio value="original">
                <template v-slot:label>
                  <div class="crop-format__item">
                    <div class="name">Original</div>
                  </div>
                </template>
              </v-radio>
              <v-radio value="square">
                <template v-slot:label>
                  <div class="crop-format__item">
                    <div class="icon square"></div>
                    <div class="name">1:1</div>
                  </div>
                </template>
              </v-radio>
              <v-radio value="vertical">
                <template v-slot:label>
                  <div class="crop-format__item">
                    <div class="icon vertical"></div>
                    <div class="name">9:16</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="form-control ma-0 image-format-container">
          <v-row class="pb-3">
            <v-col cols="9">
              <span class="form-control__name">Media files</span>
              <div class="upload-area">
                <draggable
                  v-if="getCurrentStories.media.length"
                  element="span"
                  v-model="getCurrentStories.media"
                >
                  <transition-group name="no" class="upload-list" tag="div">
                    <div
                      class="upload-list__item"
                      v-for="(file, index) in getCurrentStories.media"
                      :key="`stories_${index}_${file.template}_${getMediaIsLoading}_${isDialogModifyImageTemplate}`"
                    >
                      <i
                        class="icon-remove"
                        @click="deleteContentHandler(file, index)"
                      ></i>
                      <i
                        class="icon-edit"
                        v-if="file.isTemplate || file.template"
                        @click="editTemplate(file)"
                      ></i>
                      <img v-if="getMediaIsLoading[index]" src="loading.gif" />
                      <img
                        v-else-if="
                          file && file.mediaUrl && file.type === 'image'
                        "
                        class="post-image"
                        :alt="getMediaUrl(file)"
                        :src="getMediaUrl(file)"
                      />
                      <div
                        class="inst-video"
                        v-else-if="
                          file && file.mediaUrl && file.type === 'video'
                        "
                      >
                        <!--            <v-icon class="pr-2">mdi-image</v-icon>-->
                        <video
                          class="post-image"
                          :poster="
                            getMediaUrl(file) && isImage(getMediaUrl(file))
                              ? getMediaUrl(file)
                              : ''
                          "
                        >
                          <source
                            :src="file.mediaUrl"
                            :type="'video/' + getFileType(file.mediaUrl)"
                          />
                        </video>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mt-2 show-template"
                color="orange lighten-2"
                dark
                block
                :loading="isLoadingTemplate"
                :disabled="isLoadingTemplate"
                @click="showTemplatePopUp()"
              >
                <v-icon>mdi-file-image-plus-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="generate">
          <div class="rc">
            <div class="history-controls">
              <v-btn
                x-small
                :disabled="isDisabledHistoryPrev"
                @click="showPrevFormVersion"
              >
                <i class="icon-previous"></i>
              </v-btn>
              <v-btn
                x-small
                :disabled="isDisabledHistoryNext"
                @click="showNextFormVersion"
              >
                <i class="icon-next"></i>
              </v-btn>
            </div>
            <div class="generate__btn-wrapper">
              <v-btn
                color="primary"
                :disabled="!getCurrentStories.isGenerable"
                :loading="isLoadingGenerateTextBtn"
                small
                @click="changeHandler(true)"
              >
                Change</v-btn
              >
              <v-btn
                color="primary"
                :disabled="!getCurrentStories.isGenerable"
                :loading="isLoadingRephraseTextBtn"
                small
                @click="rephraseTextHandler()"
                class="rephrase"
              >
                Rephrase</v-btn
              >
            </div>
          </div>
        </div>
        <div class="form-control pb-4">
          <span class="form-control__name">Scheduling</span>
          <Scheduling
            :date="getCurrentStories.scheduledForStr"
            @changeDate="changeDateHandler"
            @clearDate="changeDateHandler('')"
          />
        </div>
        <div class="form-control url">
          <span class="form-control__name">Url <i>(optional)</i></span>
          <v-text-field
            solo
            v-model="form.employee_tag"
            label="Link to website"
          ></v-text-field>
          <span class="field-info"
            >Only for users with more than 10,000 subscribers</span
          >
        </div>
      </div>
    </v-form>
    <ModalDialog
      :isShow="templateDialog.isShow"
      :size="900"
      customClass="template-modal"
      @close="
        templateDialog.isShow = false;
        editedTemplate = null;
        isEditTemplate = false;
      "
    >
      <template v-slot:description>
        <Templates
          @closeTemplateModal="
            templateDialog.isShow = false;
            editedTemplate = null;
            isEditTemplate = false;
          "
          v-if="templateDialog.isShow"
          :post="getCurrentStories"
          :isEditTemplate="isEditTemplate"
          :editedTemplate="editedTemplate"
          :template="currentTemplate"
        />
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import helper from "@/mixins/helper";
import H from "@/utils/helper";
import Scheduling from "@/components/dashboard/widgets/creationWidget/components/Scheduling";
import MyTemplate from "@/components/global/MyTemplate";
import ModalDialog from "@/components/global/ModalDialog";
import Templates from "@/components/templatesModal/Templates";
import MediaFile from "@/models/MediaFile";
import MediaField from "@/models/MediaFile/MediaField";

export default {
  name: "Stories",
  mixins: [helper],
  components: {
    Scheduling,
    ModalDialog,
    Templates,
  },
  data: () => ({
    form: {
      url: null,
    },
    isUserChangedRatio: false,
    isLoadingGetTemplate: false,
    isEditTemplate: false,
    isLoadingTemplate: false,
    currentTemplate: null,
    isDialogModifyImageTemplate: [],
    radios: "vertical",
    templateDialog: {
      isShow: false,
    },
    editedTemplate: null,
    isLoadingGenerateTextBtn: false,
    isLoadingRephraseTextBtn: false,
    contentTemplateId: null,
  }),
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("dragAndDrop", ["getCurrentDroppedFile"]),
    ...mapGetters("stories", [
      "getCurrentStories",
      "getMediaIsLoading",
      "getPrevHistoryArray",
      "getNextHistoryArray",
    ]),
    ...mapGetters("post", ["getUpdatePostData"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("mediaLibraryWidget", ["getMediaLibrarySelections"]),

    getFistMediaFile() {
      if (this.getCurrentStories.media.length > 0) {
        return this.getImageSizeByUrl(
          H.getMediaUrlForFile(this.getCurrentStories.media[0])
        );
      }
      return null;
    },
    isDisabledHistoryNext() {
      return this.getNextHistoryArray.length === 0;
    },
    isDisabledHistoryPrev() {
      return this.getPrevHistoryArray.length === 0;
    },
  },
  watch: {
    getCurrentDroppedFile: function (val) {
      if (val && val.isInstagramActive) {
        this.getCurrentStories.media.push(val);
        this.setFile();
      }
    },

    getFistMediaFile: function (val) {
      if (val) {
        this.getImageSizeByUrl(H.getMediaUrlForFile(val));
      }
    },
    async getUpdatePostData(data) {
      if (data) {
        this.wipeStoriesHistory();
        await this.preloadingTemplates();
      }
    },
  },
  async created() {
    if (this.$route.params.isEdit) {
      this.getCurrentStories.customerApprove = "none";
      this.setCropFormatWhenEdit();
    }
    if (this.$route.params.isFromCalendar) {
      this.contentTemplateId = this.getCurrentStories.contentTemplateId;
    }
    await this.preloadingTemplates();
  },
  methods: {
    ...mapMutations("dragAndDrop", ["setFile", "setDropOnTheInstagramWidget"]),
    ...mapMutations("stories", [
      "pushToStoriesMediaFiles",
      "setCurrentStories",
      "updateStoriesMediaFilesFromMediaLibrary",
      "setCropFormatStories",
      "pushToStoriesMediaFiles",
      "pushToStartMediaPosition",
      "setToPreview",
      "setToNext",
      "updatePreview",
      "updateNext",
      "deleteFromPreview",
      "deleteFromNext",
      "refreshAllMediaFilesFromMediaLibrary",
      "setSelectedStoriesMediaFiles",
      "wipeStoriesHistory",
    ]),
    ...mapMutations("mediaLibraryWidget", [
      "removeMediaLibrarySelection",
      "setMediaLibrarySelections",
    ]),
    ...mapMutations("post", ["updatePostMediaFilesFromMediaLibrary"]),
    ...mapActions("templates", [
      "getTemplate",
      "createImageFromTemplate",
      "getTemplatesInfo",
    ]),
    ...mapMutations("templates", ["setSimilarTemplatesList"]),
    ...mapActions("post", ["runCopywriter"]),

    async preloadingTemplates() {
      try {
        this.isLoadingTemplate = true;
        let params = {
          calendarItemId: this.getCurrentStories.calendarItemId,
          customerId: this.getCurrentCustomer.id,
        };
        let { similarTemplates, template } = await this.getTemplatesInfo(
          params
        );
        this.setSimilarTemplatesList(similarTemplates);
        this.currentTemplate = template;
        this.isLoadingTemplate = false;
      } catch (e) {
        this.isLoadingTemplate = false;
        console.log(e);
      }
    },

    closeImageFromTemplate(index) {
      this.$root.$emit("mediaObjectModified", {
        index,
        reason: "templateModified",
      });
      this.setIsDialogModifyImageTemplate(index, false);
    },

    setIsDialogModifyImageTemplateFromEvent(event) {
      this.setIsDialogModifyImageTemplate(event.index, event.value);
    },
    setIsDialogModifyImageTemplate(index, value) {
      const tmpList = [...this.isDialogModifyImageTemplate];
      tmpList[index] = value;
      this.isDialogModifyImageTemplate = tmpList;
    },
    showTemplatePopUp() {
      this.templateDialog.isShow = true;
    },
    editTemplate(file) {
      this.showTemplatePopUp();
      this.editedTemplate = file.template;
      this.isEditTemplate = true;
    },

    // async createTemplateMedia() {
    //   this.isLoadingGetTemplate = true;
    //   try {
    //     let params = {
    //       calendarItemId: this.getCurrentStories.calendarItemId || "1234",
    //       customerId: this.getCurrentCustomer.id,
    //       inputParameters: null,
    //       ratio: "vertical",
    //     };
    //     let template = await this.getTemplate(params);
    //     const mediaUrlObj = {
    //       mediaUrl: template["preview_url"],
    //       template,
    //     };
    //     const newMediaObj = new MediaField(mediaUrlObj);
    //     this.pushToStoriesMediaFiles(newMediaObj);
    //     setTimeout(() => {
    //       this.setIsDialogModifyImageTemplate(
    //         this.getCurrentStories.media.length - 1,
    //         true
    //       );
    //     }, 1);
    //   } finally {
    //     this.isLoadingGetTemplate = false;
    //   }
    // },

    setCropFormatWhenEdit() {
      this.getCurrentStories.media.forEach((item) => {
        if (item.ratio !== null) {
          this.radios = item.ratio;
        }
      });
    },
    deleteContentHandler(file, index = null) {
      if (file && file.isFromMedia) {
        // this.updatePostMediaFilesFromMediaLibrary(file);
        this.removeMediaLibrarySelection(file);
        this.updateStoriesMediaFilesFromMediaLibrary(file);
      } else {
        this.getCurrentStories.media.splice(index, 1);
      }
    },
    changeDateHandler(newDate) {
      this.getCurrentStories.scheduledForStr = newDate;
    },
    changeCropFormat() {
      this.isUserChangedRatio = true;
      this.setCropFormatStories(this.radios);
    },
    getImageSizeByUrl(url) {
      let image = new Image();

      image.onload = (event) => {
        this.setDefaultRatio(event.target.width, event.target.height);
      };

      image.src = url;
    },
    setDefaultRatio(width = 0, height = 0) {
      if (height === 0 || width === 0) return "original";

      const widthToHeightRatio = width / height;
      const ratios = [1, 1.91, 0.8];

      const closest = ratios.reduce(function (prev, curr) {
        return Math.abs(curr - widthToHeightRatio) <
          Math.abs(prev - widthToHeightRatio)
          ? curr
          : prev;
      });

      switch (closest) {
        case 1:
          this.radios = "square";
          break;
        case 1.91:
          this.radios = "original";
          break;
        case 0.8:
          this.radios = "vertical";
          break;
        default:
          break;
      }

      this.setCropFormatStories(this.radios);
    },
    showPrevFormVersion() {
      this.setToNext(H.deepCopy(this.getCurrentStories));
      this.setCurrentStories(H.deepCopy(this.getPrevHistoryArray[0]));
      this.deleteFromPreview();
      this.getCurrentStories.textIdPrev = this.getLastTextId;
      this.updateMediaFilesFromHistoryObject();
    },
    showNextFormVersion() {
      this.setToPreview(H.deepCopy(this.getCurrentStories));
      this.setCurrentStories(
        H.deepCopy(
          this.getNextHistoryArray[this.getNextHistoryArray.length - 1]
        )
      );
      this.deleteFromNext();
      this.updateMediaFilesFromHistoryObject();
      this.subject =
        this.getCurrentStories.customSubject ||
        this.getCurrentStories.contentSubject;
    },
    updateMediaFilesFromHistoryObject() {
      this.setMediaLibrarySelections([
        ...this.getCurrentStories.media.filter((i) => {
          return i.isFromMedia;
        }),
      ]);

      this.refreshAllMediaFilesFromMediaLibrary();
      this.setSelectedStoriesMediaFiles([...this.getMediaLibrarySelections]);
    },
    async changeHandler(isNeedToUpdateTemplates = false) {
      const params = {
        imageId: this.getAttachedImageId(),
        labels: this.getCurrentStories.labels,
        calendarPostId: this.getCurrentStories.calendarPostId,
        calendarItemId: this.getCurrentStories.calendarItemId,
        contentCaptionId:
          this.lastCaptionIdFromChangeEvent ||
          this.getCurrentStories.contentCaptionId,
        action: "nextContentCaption",
        contentTemplateId:
          isNeedToUpdateTemplates && this.contentTemplateId
            ? this.contentTemplateId
            : undefined,
      };
      await this.newStoryInstance(params, true, isNeedToUpdateTemplates);
    },
    getAttachedImageId() {
      if (this.getCurrentStories.media.length) {
        const data = this.getCurrentStories.media.find(
          (i) => typeof i === "object" && !i.blob && (i.mediaId || i.id)
        );
        if (!data) return undefined;
        const tmpId = data.mediaId || data.id;
        return tmpId ? tmpId : undefined;
      } else {
        return undefined;
      }
    },
    async newStoryInstance(
      params,
      keepInHistory = true,
      isNeedToUpdateTemplates = false
    ) {
      this.isLoadingGenerateTextBtn = true;
      try {
        const {
          contentCaptionId,
          contentTemplateId,
          customSubject,
          media,
          contentMeta,
        } = await this.runCopywriterWrapper(params);

        if (keepInHistory) {
          this.setStoryChangesHistory(H.deepCopy(this.getCurrentStories));
        }

        this.getCurrentStories.contentTemplateId = this.contentTemplateId;
        this.getCurrentStories.media = media;
        this.getCurrentStories.customSubject = customSubject;
        this.getCurrentStories.contentMeta = contentMeta;
        this.getCurrentStories.contentCaptionId = contentCaptionId;
        this.contentTemplateId = contentTemplateId
          ? contentTemplateId
          : undefined;

        if (isNeedToUpdateTemplates && media.length) {
          this.replaceAutoGenerationTemplates(
            media.map((i) => new MediaFile(i))
          );
        }

        if (this.getCurrentStories.media.length && !this.isUserChangedRatio) {
          await this.getImageSizeByUrl(
            H.getMediaUrlForFile(this.getCurrentStories.media[0])
          );
        }

        this.listOfClassesAndColors = [];

        if (
          this.getCurrentStories.contentMeta &&
          this.getCurrentStories.contentMeta.is_template_flag
        ) {
          await this.createTemplateMedia();
        }
      } catch (e) {
        throw e;
      } finally {
        this.isLoadingGenerateTextBtn = false;
      }
    },
    async runCopywriterWrapper(paramsInput) {
      const params = {
        ...paramsInput,
        customerId: this.getCurrentCustomer.id,
        smmId: this.getCurrentUser.id,
      };
      return await this.runCopywriter(params);
    },
    async createTemplateMedia() {
      this.isLoadingGetTemplate = true;
      try {
        let params = {
          calendarItemId: this.getCurrentStories.calendarItemId || "1234",
          customerId: this.getCurrentCustomer.id,
          inputParameters: null,
          ratio: "square",
        };

        let template = await this.getTemplate(params);

        const mediaUrlObj = {
          mediaUrl: template["preview_url"],
          template,
        };

        const newMediaObj = new MediaField(mediaUrlObj);
        this.pushToStoriesMediaFiles(newMediaObj);
        setTimeout(() => {
          this.setIsDialogModifyImageTemplate(
            this.getCurrentPost.media.length - 1,
            true
          );
        }, 1);
      } catch (e) {
        throw e;
      } finally {
        this.isLoadingGetTemplate = false;
      }
    },
    replaceAutoGenerationTemplates(arrayOfNewTemplates) {
      const indexOfOldTemplate = this.getCurrentStories.media.findIndex(
        (i) => i.template && i.template.isAutoGenerated
      );

      if (indexOfOldTemplate >= 0) {
        this.getCurrentStories.media = this.getCurrentStories.media.filter(
          (i) => !i.template || i.template.isAutoGenerated === false
        );

        this.getCurrentStories.media.splice(
          indexOfOldTemplate,
          0,
          ...arrayOfNewTemplates
        );
      } else {
        this.pushToStartMediaPosition(...arrayOfNewTemplates);
      }
    },
    async rephraseTextHandler() {
      try {
        this.isLoadingRephraseTextBtn = true;
        const params = {
          imageId: this.getAttachedImageId(),
          calendarItemId: this.getCurrentStories.calendarItemId,
          customerId: this.getCurrentCustomer.id,
          captionId: this.getCurrentStories.contentCaptionId,
          contentTemplateId: this.contentTemplateId || undefined,
          action: "rephraseContentCaption",
        };
        const { contentCaptionId, media, contentTemplateId } =
          await this.runCopywriter(params);

        this.setStoryChangesHistory(H.deepCopy(this.getCurrentStories));

        this.getCurrentStories.contentCaptionId = contentCaptionId;
        this.contentTemplateId = contentTemplateId
          ? contentTemplateId
          : undefined;

        this.getCurrentStories.contentTemplateId = this.contentTemplateId;
        this.getCurrentStories.media = media;

        if (media.length) {
          this.replaceAutoGenerationTemplates(
            media.map((i) => new MediaFile(i))
          );
        }

        this.isLoadingRephraseTextBtn = false;
      } catch (e) {
        console.log(e);
        this.isLoadingRephraseTextBtn = false;
      }
    },
    setStoryChangesHistory(currentPost = this.getCurrentStories) {
      if (this.getNextHistoryArray.length && !this.isUserChangedRatio) {
        this.updatePreview([
          ...this.getNextHistoryArray,
          ...[H.deepCopy(currentPost)],
          ...this.getPrevHistoryArray,
        ]);
        this.updateNext();
      } else {
        this.setToPreview(H.deepCopy(currentPost));
      }
    },
  },
  destroyed() {
    this.wipeStoriesHistory();
    this.setSimilarTemplatesList([]);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.tags_container {
  .v-input__append-inner {
    display: none !important;
  }
}
.form-wrapper {
  padding: 0 24px;
}
.clear {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &__btn {
    border: none;
    background: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: $color-violet;
    text-decoration: underline;
  }
}
.form-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__name {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: $color-black;
    margin-bottom: 4px;
    i {
      font-style: normal;
      font-weight: 400;
    }
  }
  ::v-deep {
    .v-input {
      &__slot {
        border: 1px solid $color-grey3;
        box-sizing: border-box;
        border-radius: 4px;
        background: #fafafa !important;
        min-height: 36px !important;
        box-shadow: none !important;
        &:before {
          background: transparent !important;
        }
        fieldset {
          display: none;
        }
        &:after {
          background: transparent !important;
        }
      }
      &__icon {
        height: 36px !important;
        .v-icon.v-icon {
          font-size: 10px;
        }
        .mdi-menu-down::before {
          font-family: "icomoon";
          content: "\e915";
        }
      }
      &__append-inner {
        margin-top: 0 !important;
      }
    }
    .v-label {
      font-size: 14px;
      line-height: 140%;
      font-family: $base-font;
    }
    .v-select {
      &__selections {
        min-height: 36px !important;
        padding: 4px 0 0 0 !important;
        .v-chip {
          height: 28px !important;
          padding: 6px !important;
          margin-top: 0;
          border: 1px solid #eae9ea;
          box-sizing: border-box;
          border-radius: 8px;
          background: $color-white;
          cursor: pointer;
          .icon {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            margin-right: 6px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          strong {
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            color: $color-black;
          }
          .mdi-close-circle::before {
            font-family: "icomoon" !important;
            content: "\e91b";
            font-size: 7px;
          }
        }
      }
      &__selection--comma {
        margin: 3px 4px 7px 0 !important;
        font-size: 14px;
      }
    }
  }
  &.range {
    ::v-deep {
      .v-input {
        margin-top: 20px;
        &__slot {
          background: transparent !important;
          border: none !important;
        }
      }
      .v-slider {
        &__thumb-label {
          background: transparent !important;
          color: $color-black !important;
          top: -20px !important;
        }
        &__thumb {
          width: 20px !important;
          height: 20px !important;
          background: $color-white !important;
          border: 1px solid $color-grey3 !important;
          box-sizing: border-box;
          box-shadow: 0px 2px 6px rgba(31, 35, 37, 0.08) !important;
          &:before {
            display: none !important;
          }
        }
      }
      .primary.lighten-3 {
        background: rgba(47, 72, 88, 0.2) !important;
      }
      .pink {
        background: $color-blueberry !important;
      }
    }
  }
  &.hashtags {
    ::v-deep {
      .v-input__icon .v-icon.v-icon {
        display: none !important;
      }
      .v-text-field__details {
        display: none !important;
      }
    }
  }
  &.url {
    ::v-deep {
      .v-text-field__details {
        display: none;
      }
    }
    .field-info {
      font-size: 12px;
      line-height: 140%;
      color: rgba(81, 84, 86, 0.6);
      margin-top: -7px;
    }
  }
}
.upload-area {
  border-bottom: 1px solid rgba(47, 72, 88, 0.1);
  margin-bottom: 16px;
  .upload-format {
    display: inline-flex;
    align-items: center;
    margin-bottom: 16px;
    &__name {
      color: $color-black;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      margin-right: 12px;
    }
    &__controls {
      display: inline-flex;
      .item {
        display: inline-flex;
        align-items: center;
        height: 36px;
        padding: 5px 11px;
        cursor: pointer;
        position: relative;
        &:first-child {
          border: 1px solid $color-grey3;
          box-sizing: border-box;
          box-shadow: 0px 2px 6px rgba(31, 35, 37, 0.08);
          border-radius: 8px 0px 0px 8px;
          .item {
            &__rectangle {
              border: 1.5px solid rgba(78, 87, 120, 0.6);
              box-sizing: border-box;
              border-radius: 2px;
              flex-shrink: 0;
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }
          }
        }
        &:last-child {
          left: -1px;
          border: 1px solid $color-grey3;
          box-sizing: border-box;
          border-radius: 0px 8px 8px 0px;
          .item {
            &__rectangle {
              border: 1.5px solid rgba(78, 87, 120, 0.6);
              box-sizing: border-box;
              border-radius: 2px;
              flex-shrink: 0;
              width: 18px;
              height: 24px;
              margin-right: 8px;
            }
            &__num {
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
              color: $color-black;
            }
          }
        }
        &__rectangle {
          border: 1.5px solid rgba(78, 87, 120, 0.6);
          box-sizing: border-box;
          border-radius: 2px;
          flex-shrink: 0;
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
        &.active {
          border: 1px solid rgba(126, 86, 137, 0.2) !important;
          background: rgba(126, 86, 137, 0.05);
          color: $color-violet;
          z-index: 10;
          .item {
            &__rectangle {
              border: 1.5px solid $color-violet;
            }
          }
        }
      }
    }
  }
  .upload-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    position: relative;
    grid-gap: 10px;
    margin-bottom: 16px;
    &__item {
      border: 1px solid rgba(47, 72, 88, 0.1);
      position: relative;
      width: 100%;
      height: 60px;
      .inst-video {
        width: 100%;
        height: 100%;
        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .image-file {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .icon-remove {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 20;
        padding: 3px;
        background-color: whitesmoke;
        border-radius: 50%;
        &:hover {
          color: red;
        }
      }
      .icon-edit {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 20;
        padding: 3px;
        background-color: whitesmoke;
        border-radius: 50%;
        &:hover {
          color: red;
        }
      }
    }
  }
}
.image-file {
  padding-top: 67%;
  width: 100%;
  overflow: hidden;
  margin: 1px;

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;

  /* Optional centered background */
  background-position: center;
}
.generate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(47, 72, 88, 0.1);
  margin-bottom: 16px;
  &__btn-wrapper {
    margin-left: 28px;
    .v-btn {
      background: rgba(126, 86, 137, 0.2) !important;
      border-radius: 16px !important;
      height: 36px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500 !important;
      font-size: 14px !important;
      text-transform: none !important;
      letter-spacing: normal !important;
      line-height: 140%;
      padding: 0 16px !important;
      color: $color-violet;
      box-shadow: none !important;
      &:hover {
        background: rgba(126, 86, 137, 0.4);
      }
    }
  }
  .history-controls {
    display: inline-flex;
    align-items: center;
    .v-btn {
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      border: 1px solid rgba(126, 86, 137, 0.1) !important;
      box-sizing: border-box;
      border-radius: 16px;
      margin-left: 8px;
      box-shadow: none !important;
      background: $color-white !important;
      i {
        font-size: 12px;
        color: $color-violet;
      }
    }
  }
}
.crop-format {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 20px;
  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #1f2325;
    margin-right: 5px;
  }
  ::v-deep {
    .v-input {
      padding: 0;
      margin: 0;
      &__slot {
        margin: 0;
        padding: 0;
        border: none;
        background: transparent !important;
        box-shadow: 0px 2px 6px rgba(31, 35, 37, 0.08);
      }
      &--radio-group__input {
        display: flex;
        align-items: center;
        flex-direction: row;
        .v-radio {
          margin-bottom: 0 !important;
          border: 1px solid #c7c9ca;
          box-sizing: border-box;
          &:first-child {
            border-radius: 8px 0 0 8px;
            position: relative;
            right: -1px;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
            position: relative;
            left: -1px;
          }
          .crop-format__item {
            display: inline-flex;
            align-items: center;
            height: 36px;
            padding: 0 12px;
            .icon {
              width: 18px;
              height: 18px;
              margin-right: 8px;
              background: rgba(78, 87, 120, 0.1);
              border: 1.5px solid rgba(78, 87, 120, 0.6);
              box-sizing: border-box;
              border-radius: 2px;
              &.horizontal {
                height: 12px;
              }
              &.vertical {
                width: 12px;
              }
            }
            .name {
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
            }
          }
          &.v-item--active {
            z-index: 11;
            background: rgba(126, 86, 137, 0.05);
            border: 1px solid rgba(126, 86, 137, 0.2);
            .crop-format__item {
              .icon {
                background: rgba(126, 86, 137, 0.05);
                border: 1.5px solid rgba(126, 86, 137, 0.8);
                border-radius: 2px;
              }
              .name {
                color: #7e5689;
              }
            }
          }
        }
      }
      &--selection-controls__input {
        display: none;
      }
      .v-messages {
        display: none;
      }
    }
  }
}
::v-deep {
  .modify-template-modal {
    overflow-x: hidden;
    width: 100%;
    max-width: 1000px;
    padding: 0 30px;
    overflow-y: hidden;
    box-shadow: none;
  }
}

.show-template {
  &.theme--dark.v-btn.v-btn--disabled {
    color: rgba(255, 255, 255, 1) !important;
  }
  &.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}
.generate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  border-top: 1px solid rgba(47, 72, 88, 0.1);
  padding-top: 10px;
  .rc {
    display: inline-flex;
    align-items: center;
  }
  &__btn-wrapper {
    margin-left: 28px;
    @media screen and (max-width: 1600px) {
      margin-left: 10px;
    }
    .v-btn {
      background: rgba(126, 86, 137, 0.2) !important;
      border-radius: 16px !important;
      height: 29px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500 !important;
      font-size: 13px !important;
      text-transform: none !important;
      letter-spacing: normal !important;
      line-height: 140%;
      padding: 0 8px !important;
      color: $color-violet;
      box-shadow: none !important;
      margin-left: 5px;
      &:hover {
        background: rgba(126, 86, 137, 0.4);
      }
      &.rephrase {
        height: 25px !important;
        padding: 0 5px !important;
        font-size: 9px !important;
      }
    }
  }
  .history-controls {
    display: inline-flex;
    align-items: center;
    .v-btn {
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      border: 1px solid rgba(126, 86, 137, 0.1) !important;
      box-sizing: border-box;
      border-radius: 16px;
      margin-left: 8px;
      box-shadow: none !important;
      background: $color-white !important;
      @media screen and (max-width: 1600px) {
        margin-left: 5px;
      }
      &[disabled] {
        background: $color-white !important;
        color: $color-grey2 !important;
        i {
          color: $color-grey2 !important;
          opacity: 0.4;
        }
      }
      i {
        font-size: 12px;
        color: $color-violet;
      }
    }
  }
}
</style>
