<template>
  <div class="uploads-list" id="by-client-list">
    <div class="upload">
      <div class="upload__title">Media files</div>
      <v-row>
        <v-col cols="9">
          <div class="upload__area">
            <Uploader
              componentRef="mediaUploadsTab"
              :emitNameForSuccessUploadMedia="`addNewMediaToUploads`"
              className="uploader-container"
              @addNewMediaToUploads="uploadFileHandler"
            />
          </div>
        </v-col>
        <v-col class="align-self-center" cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="isLoadingExtensionPasteBtn"
                v-on="on"
                class="clipboardExtension"
                block
                @click="uploadMediaFromExtension"
              >
                <v-icon>mdi-clipboard-arrow-up-outline</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Upload from Chrome Extension</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <Filters :filter="filter" />
    <MediaList
      v-if="mediaFiles.length"
      className="uploads"
      isCanHidden
      :mediaFiles="mediaFiles"
      :isLoading="isLoading"
      :isCanChangePostOrStoriesMedia="isCanChangePostOrStoriesMedia"
      @changedHiddenFlag="changedHiddenFlagHandler"
      @updateMediaList="updateMediaList"
      @tagStatus="tagStatus"
      @loadMoreItems="loadMoreItemsHandler"
      @updateMediaForMoreInformationStatus="updateMoreInformationStatus"
    />
  </div>
</template>

<script>
import Uploader from "@/components/global/Uploader";
import Filters from "@/components/dashboard/widgets/mediaLibraryWidget/Filters";
import MediaList from "@/components/dashboard/widgets/mediaLibraryWidget/MediaList";
import H from "@/utils/helper";

import filterMethods from "@/mixins/filterMethods";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "UploadsWidget",
  components: {
    MediaList,
    Filters,
    Uploader,
  },
  data: () => ({
    filter: {
      notUsed: {
        size: 3,
        value: true,
        type: "checkbox",
        label: "Not Used",
      },
      showHidden: {
        value: false,
        type: "checkbox",
        label: "Show Hidden",
        size: 3,
      },
      // isFilterImageEnabled: {
      //   value: true,
      //   type: "checkbox",
      //   label: "Filter",
      //   size: 3,
      // },
      sortBy: {
        className: "justify-end",
        size: 6,
        value: 1,
        text: "Date Descending",
        type: "list",
        label: "Sort By:",
        listValues: [
          {
            value: 1,
            text: "Date Descending",
          },
          {
            value: 2,
            text: "Date Ascending",
          },
        ],
      },
    },
    mediaFiles: [],
    lastKey: null,
    selectedFilesID: [],
    selectedMediaFiles: [],
    isLoading: true,
    isLoadingExtensionPasteBtn: false,
  }),
  mixins: [filterMethods],
  watch: {
    filter: {
      handler() {
        this.lastKey = null;
        this.mediaFiles = [];
        this.getMedia();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.getMedia();
      },
      deep: true,
    },
    getIsUpdateMedia(val) {
      if (val) {
        this.selectedFilesID = [];
        this.selectedMediaFiles = [];
        this.lastKey = null;
        this.mediaFiles = [];
        this.getMedia();
        this.setIsUpdateMedia(false);
      }
    },
  },
  props: {
    isCanChangePostOrStoriesMedia: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("mediaLibraryWidget", ["getIsUpdateMedia"]),
    // isFilterEnabled() {
    //   return this.filter && this.filter.isFilterImageEnabled.value;
    // },
  },
  methods: {
    ...mapActions("filesController", ["getUrlForSendFile", "uploadFile"]),
    ...mapActions("mediaLibraryWidget", [
      "fetchMediaData",
      "uploadMediaFromUrl",
      "getMediaInfo",
    ]),
    ...mapMutations("mediaLibraryWidget", ["setIsUpdateMedia"]),
    async getMedia() {
      this.isLoading = true;
      const params = {
        customerId: this.getCurrentCustomer.id,
        type: 2,
        limit: "30",
        startKey: this.lastKey,
        ...this.prepareFilters(),
      };
      const { items, last_key } = await this.fetchMediaData(params);
      this.mediaFiles = [...this.mediaFiles, ...items];
      this.lastKey = last_key;
      this.isLoading = false;
    },

    async uploadMediaFromExtension() {
      this.isLoadingExtensionPasteBtn = true;
      localStorage.setItem(
        "socialWonderClipboardIsRequestedBy",
        "mediaLibraryWidget"
      );
      setTimeout(async () => {
        try {
          await this.uploadMediaFromExtensionHandler();
          localStorage.setItem("socialWonderClipboard", "");
          localStorage.setItem("socialWonderClipboardIsReady", "false");
          localStorage.setItem("socialWonderClipboardIsRequestedBy", "");
        } finally {
          this.isLoadingExtensionPasteBtn = false;
          this.lastKey = null;
          this.mediaFiles = [];
          await this.getMedia();
        }
      }, 30);
    },
    async uploadMediaFromExtensionHandler() {
      while (localStorage.getItem("socialWonderClipboardIsReady") != "true") {
        console.log("awaiting socialWonderClipboardIsReady...");
        await H.sleep(10);
      }
      if (
        !localStorage.socialWonderClipboard ||
        localStorage.socialWonderClipboard == "undefined" ||
        localStorage.socialWonderClipboard == "null" ||
        !localStorage.socialWonderClipboard.length
      )
        return;
      const socialWonderClipboard = JSON.parse(
        localStorage.socialWonderClipboard
      );
      const mediaItems = socialWonderClipboard.filter(
        (item) => item["type"] === "media"
      );
      if (!(mediaItems && mediaItems.length)) return;

      await Promise.all(
        mediaItems.map(async (mediaItem) => {
          const data = await this.uploadMediaFromUrl({
            customerId: this.getCurrentCustomer.id,
            inputMediaUrl: mediaItem["srcUrl"],
          });
          if (data) {
            const media_id = data.data["media_id"];
            var mediaInfo = await this.getMediaInfo(media_id);
            while (!this.isMediaInfoReady(mediaInfo)) {
              await H.sleep(200);
              mediaInfo = await this.getMediaInfo(media_id);
              // console.log("mediaInfo", mediaInfo);
            }
          }
        })
      );
    },
    loadMoreItemsHandler() {
      if (this.lastKey) {
        this.getMedia();
      }
    },
    isMediaInfoReady(mediaInfo) {
      let mediaState = mediaInfo["mediaState"];
      if (!mediaState) return false;
      let jpg = mediaState["jpg"];
      let mp4 = mediaState["mp4"];
      let thumbnail = mediaState["thumbnail"];

      if (!(thumbnail || jpg || mp4)) return false;
      if (thumbnail == "error" || jpg == "error" || mp4 == "error") return true;
      if (thumbnail == "wip" || jpg == "wip" || mp4 == "wip") return false;

      return true;
    },

    async uploadFileHandler(filesList) {
      this.isLoading = true;
      for (const file of filesList) {
        const { url, mediaId, fields } = await this.createUrlForFile(
          file,
          this.getCurrentCustomer.id,
          "media"
        );
        await this.uploadFile({ url, file: file.file, fields });
        await this.createUrlForFile(
          file,
          this.getCurrentCustomer.id,
          "media",
          true,
          mediaId
        );
      }

      this.mediaFiles = [];
      this.lastKey = null;
      await this.getMedia();
    },
    async createUrlForFile(
      file,
      customerId,
      type,
      isTag = false,
      imageId = null
    ) {
      try {
        const { url, mediaId, fields } = await this.getUrlForSendFile({
          file,
          customerId,
          type,
          isTag,
          imageId,
        });
        return { url, mediaId, fields };
      } catch (e) {
        throw e;
      }
    },
    updateMediaList(e) {
      this.mediaFiles.map((val) => {
        if (val.id === e.id) {
          val.favorite = !val.favorite;
        }
      });
    },
    tagStatus(data) {
      this.mediaFiles.forEach((val) => {
        if (val.id === data.id) {
          switch (data.editTagType) {
            case "client":
              val.tagClient = data.tag.tagClient;
              break;
            case "product":
              val.tagProduct = data.tag.tagProduct;
              break;
            case "description":
              val.tagDescription = data.tag.tagDescription;
              break;
            default:
              val.tagClient = "";
              val.tagProduct = "";
              val.tagDescription = "";
          }
        }
      });
    },
    changedHiddenFlagHandler(mediaFile) {
      if (mediaFile.hidden && !this.filter.showHidden.value) {
        this.mediaFiles = this.mediaFiles.filter(
          (file) => file.id !== mediaFile.id
        );
      }
    },
    updateMoreInformationStatus($event) {
      this.mediaFiles.forEach((file) => {
        if (file.id === $event.mediaFile.id) {
          file.canGetMoreInformation = false;
        }
      });
    },
  },
  async created() {
    await this.getMedia();
    localStorage.setItem("socialWonderClipboard", "");
    localStorage.setItem("socialWonderClipboardIsRequestedBy", "");
    localStorage.setItem("socialWonderClipboardIsReady", "false");
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.upload {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-top: 5px;

  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: $color-black;
    margin-bottom: 3px;
  }
  ::v-deep {
    .uploader-container {
      margin-bottom: 0;
    }
  }
}
.clipboardExtension {
  background-color: $color-violet !important;
  color: white;
}
</style>
