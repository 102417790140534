<template>
  <div class="chat-container" v-if="activeChat">
    <div class="loader" v-show="loading">Loading...</div>
    <div v-show="!loading" class="chat-container__wrapper">
      <div class="chat-container__messages" ref="msgContainer">
        <template v-for="(msg, i) in messages">
          <div
            class="message"
            :key="i"
            :class="{
              reverse:
                msg.to === getCurrentCustomer.primary_phone || msg.outgoing,
            }"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <div class="message__wrapper">
                  <div
                    tag="div"
                    class="message-media-container"
                    v-if="msg.mediaUrl && isImage(msg.mediaUrl)"
                  >
                    <div class="item">
                      <div
                        v-if="msg.to !== getCurrentCustomer.primary_phone"
                        class="item__info"
                      >
                        <div class="user-icon">
                          <img :src="getCurrentCustomer.picUrl" alt="" />
                        </div>
                        <div class="messenger">
                          <img src="@/assets/img/icons/whatsApp.svg" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="item__msg">
                          <div
                            class="text"
                            :style="
                              msg.to !== getCurrentCustomer.primary_phone
                                ? setColor(msg.from)
                                : ''
                            "
                          >
                            <div class="phone">+{{ msg.from }}</div>
                            <draggable tag="div" class="item__media">
                              <img
                                class="message-img"
                                @dragend="setDroppedFile(msg)"
                                :src="
                                  msg.mediaInfo && msg.mediaInfo.mediaUrl
                                    ? getMediaUrl(msg.mediaInfo)
                                    : msg.mediaUrl
                                "
                                v-on="on"
                              />
                              <button
                                class="item__user"
                                @click.prevent="
                                  selectMediaForMoreMediaHandler(
                                    msg.mediaInfo,
                                    msg.id
                                  )
                                "
                                v-if="
                                  msg.canGetMoreInformation &&
                                  !(
                                    msg.to ===
                                      getCurrentCustomer.primary_phone ||
                                    msg.outgoing
                                  ) &&
                                  msg.mediaInfo.canGetMoreInformation
                                "
                              >
                                <i class="icon-user-avatar"></i>
                              </button>
                              <button
                                class="item__favorite"
                                v-if="
                                  !(
                                    msg.to ===
                                      getCurrentCustomer.primary_phone ||
                                    msg.outgoing
                                  )
                                "
                                :class="{ favorite: msg.mediaInfo.favorite }"
                                @click="addToFavoriteHandler(msg.mediaInfo)"
                              >
                                <i class="icon-star"></i>
                              </button>
                              <div
                                class="tag-btn-wrapper"
                                :class="{
                                  active:
                                    msg.mediaInfo.tagDescription ||
                                    msg.mediaInfo.tagClient ||
                                    msg.mediaInfo.tagProduct ||
                                    msg.mediaInfo.externalCaption,
                                }"
                                v-if="
                                  !(
                                    msg.to ===
                                      getCurrentCustomer.primary_phone ||
                                    msg.outgoing
                                  )
                                "
                              >
                                <button
                                  class="item__tag"
                                  :class="{
                                    tag:
                                      msg.mediaInfo.tagDescription ||
                                      msg.mediaInfo.tagClient ||
                                      msg.mediaInfo.tagProduct ||
                                      msg.mediaInfo.externalCaption,
                                  }"
                                  @click="addTag(msg.mediaInfo)"
                                >
                                  <i class="icon-tag_icon"></i>
                                </button>
                                <button
                                  class="remove-tag"
                                  @click="deleteTag(msg.mediaInfo)"
                                >
                                  <i class="icon-close"></i>
                                </button>
                              </div>
                            </draggable>
                            <p v-if="msg.body">{{ msg.body }}</p>
                          </div>
                          <div class="date">
                            <strong>{{ getSmmNameFromId(msg.smmId) }}</strong>
                            {{ dateFormatter(msg.createdAt) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="message-media-container"
                    v-else-if="msg.mediaUrl && !isImage(msg.mediaUrl)"
                  >
                    <div class="item">
                      <div
                        v-if="msg.to !== getCurrentCustomer.primary_phone"
                        class="item__info"
                      >
                        <div class="user-icon">
                          <img :src="getCurrentCustomer.picUrl" alt="" />
                        </div>
                        <div class="messenger">
                          <img src="@/assets/img/icons/whatsApp.svg" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="item__msg">
                          <div
                            class="text"
                            :style="
                              msg.to !== getCurrentCustomer.primary_phone
                                ? setColor(msg.from)
                                : ''
                            "
                          >
                            <div class="phone">+{{ msg.from }}</div>
                            <draggable tag="div" class="item__media">
                              <video
                                class="message-img"
                                controls
                                :poster="
                                  msg.mediaInfo &&
                                  msg.mediaInfo.mediaUrl &&
                                  msg.mediaInfo.mediaUrl.thumbnailUrl
                                    ? getMediaUrl(msg.mediaInfo)
                                    : ''
                                "
                                @dragend="setDroppedFile(msg)"
                                :ref="'communicationVideo' + i"
                                @play="
                                  setToActiveVideo({
                                    element: $refs['communicationVideo' + i][0],
                                    refName: 'communicationVideo' + i,
                                  })
                                "
                              >
                                <source :src="msg.mediaUrl" />
                              </video>
                              <button
                                class="item__user"
                                @click.prevent="
                                  selectMediaForMoreMediaHandler(
                                    msg.mediaInfo,
                                    msg.id
                                  )
                                "
                                v-if="
                                  msg.canGetMoreInformation &&
                                  !(
                                    msg.to ===
                                      getCurrentCustomer.primary_phone ||
                                    msg.outgoing
                                  ) &&
                                  msg.mediaInfo.canGetMoreInformation
                                "
                              >
                                <i class="icon-user-avatar"></i>
                              </button>
                              <button
                                class="item__favorite"
                                v-if="
                                  !(
                                    msg.to ===
                                      getCurrentCustomer.primary_phone ||
                                    msg.outgoing
                                  )
                                "
                                :class="{ favorite: msg.mediaInfo.favorite }"
                                @click="addToFavoriteHandler(msg.mediaInfo)"
                              >
                                <i class="icon-star"></i>
                              </button>
                              <div
                                class="tag-btn-wrapper"
                                :class="{
                                  active:
                                    msg.mediaInfo.tagDescription ||
                                    msg.mediaInfo.tagClient ||
                                    msg.mediaInfo.tagProduct ||
                                    msg.mediaInfo.externalCaption,
                                }"
                                v-if="
                                  !(
                                    msg.to ===
                                      getCurrentCustomer.primary_phone ||
                                    msg.outgoing
                                  )
                                "
                              >
                                <button
                                  class="item__tag"
                                  :class="{
                                    tag:
                                      msg.mediaInfo.tagDescription ||
                                      msg.mediaInfo.tagClient ||
                                      msg.mediaInfo.tagProduct ||
                                      msg.mediaInfo.externalCaption,
                                  }"
                                  @click="addTag(msg.mediaInfo)"
                                >
                                  <i class="icon-tag_icon"></i>
                                </button>
                                <button
                                  class="remove-tag"
                                  @click="deleteTag(msg.mediaInfo)"
                                >
                                  <i class="icon-close"></i>
                                </button>
                              </div>
                            </draggable>

                            <p v-if="msg.body">{{ msg.body }}</p>
                          </div>
                          <div class="date">
                            <strong>{{ getSmmNameFromId(msg.smmId) }}</strong>
                            {{ dateFormatter(msg.createdAt) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="msg.isBodyLink" class="item">
                    <div
                      v-if="msg.to !== getCurrentCustomer.primary_phone"
                      class="item__info"
                    >
                      <div class="user-icon">
                        <img :src="getCurrentCustomer.picUrl" alt="" />
                      </div>
                      <div class="messenger">
                        <img src="@/assets/img/icons/whatsApp.svg" alt="" />
                      </div>
                    </div>
                    <div class="item__msg">
                      <div class="text">
                        <div class="phone">+{{ msg.from }}</div>
                        <div v-html="msg.body"></div>
                      </div>
                      <div class="date">
                        <strong>{{ getSmmNameFromId(msg.smmId) }}</strong>
                        {{ dateFormatter(msg.createdAt) }}
                      </div>
                    </div>
                  </div>
                  <template v-else>
                    <div class="item">
                      <div
                        v-if="msg.to !== getCurrentCustomer.primary_phone"
                        class="item__info"
                      >
                        <div class="user-icon">
                          <img
                            v-if="getCurrentCustomer.picUrl"
                            :src="getCurrentCustomer.picUrl"
                            :alt="getCurrentCustomer.name"
                          />
                          <img
                            v-else
                            src="@/assets/default-profile-picture.png"
                            :alt="getCurrentCustomer.name"
                          />
                        </div>
                        <div class="messenger">
                          <img src="@/assets/img/icons/whatsApp.svg" alt="" />
                        </div>
                      </div>
                      <div class="item__msg">
                        <div
                          class="text"
                          :style="
                            msg.to !== getCurrentCustomer.primary_phone
                              ? setColor(msg.from)
                              : ''
                          "
                        >
                          <div class="phone">+{{ msg.from }}</div>
                          <pre> {{ msg.body }}</pre>
                        </div>
                        <div class="date">
                          <strong>{{ getSmmNameFromId(msg.smmId) }}</strong>
                          {{ dateFormatter(msg.createdAt) }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
              <v-list v-if="msg.mediaUrl && isImage(msg.mediaUrl)">
                <v-list-item class="pointer">
                  <v-list-item-title @click="downloadHandler(msg)"
                    >Download</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </div>
      <div class="input-area">
        <div class="attached" v-if="image || video">
          <div class="attached__list">
            <div class="attached__item">
              <img v-if="image" :src="image.blob" alt="" />
              <video
                v-else
                ref="myVideo"
                @play="
                  setToActiveVideo({
                    element: $refs.myVideo,
                    refName: 'myVideo',
                  })
                "
                class="attached__item"
                controls="false"
              >
                <source :src="video.blob" />
              </video>
              <i class="icon-remove" @click="deleteMediaDataFromMessage"></i>
            </div>
          </div>
        </div>
        <div class="input-area__field">
          <v-textarea
            ref="captionSelect"
            v-model="message.text"
            label="Text message here..."
            spellcheck="true"
            hide-details
            solo
          ></v-textarea>
        </div>
        <div class="input-area__controls">
          <div class="item">
            <button class="refresh" @click="fetchMessages">
              <v-icon>mdi-refresh</v-icon>
            </button>
          </div>
          <div class="item">
            <EmojiPicker
              :inputRef="$refs.captionSelect"
              :content="message.text"
              @newValue="message.text = $event"
              class="emoji"
              customClass="chat"
            />
          </div>
          <div class="item">
            <Uploader
              class="uploader"
              className="messages chat-uploader"
              :isShowDragAndDropText="false"
              isUseCustomElementInSlot
              componentRef="communication-uploader"
              @uploadFile="uploadFileHandler"
            >
              <template v-slot:uploader-block>
                <v-icon> mdi-paperclip </v-icon>
              </template>
            </Uploader>
          </div>
          <div class="item">
            <button
              class="send-btn"
              :class="{ 'disabled-btn': isSendBtnDisabled }"
              @click="sendMessageHandler"
              :disabled="isSendBtnDisabled"
            >
              <i class="icon-send"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <GetMoreMediaInformation
      v-if="isShowMoreInformationPopup"
      :currentMediaFile="currentMediaForMoreInformation"
      :messageId="currentMessageId"
      @updateStatus="updateMediaForMoreInformation"
      @close="isShowMoreInformationPopup = false"
    />
    <TagInfo
      v-if="isShowTags"
      :isDeletePopup="isDelete"
      :editTagType="deleteTagType"
      :mediaFile="currentMediaForMoreInformation"
      @deleteTag="deleteCurrentTag"
      @close="isShowTags = false"
      :isCommunicationTag="true"
      :modalTitle="modalTitle"
      @updateMedia="fetchMessages()"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";

import { CHAT_COLORS } from "@/constants/dasboard";
import helper from "@/mixins/helper";
import { mapActions, mapGetters, mapMutations } from "vuex";
import H from "@/utils/helper";
import Uploader from "@/components/global/Uploader";
// import EmojiPicker from "@/components/global/EmojiPicker";
import TagInfo from "@/components/global/TagInfo";
import GetMoreMediaInformation from "@/components/global/GetMoreMediaInformation";

export default {
  name: "CommunicationWidget",
  data: () => ({
    activeChat: 1,
    messages: [],
    message: {
      text: "",
    },
    smmsList: [],
    loading: true,
    image: null,
    video: null,
    isSendBtnDisabled: false,
    colorList: [],
    isShowMoreInformationPopup: false,
    currentMediaForMoreInformation: null,
    currentMessageId: null,
    isShowTags: false,
    isDelete: false,
    deleteTagType: "all",
    msgIdForTag: null,
    interval: null,
  }),
  mixins: [helper],
  components: {
    Uploader,
    draggable,
    EmojiPicker: () => import("@/components/global/EmojiPicker"),
    GetMoreMediaInformation,
    TagInfo,
  },
  async mounted() {
    await this.getSmmList();
    await this.fetchMessages();
    this.loading = false;
    this.scrollToBottom();
    this.refreshChat();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("dragAndDrop", ["getDropOnTheInstagramWidgetFlag"]),
    ...mapGetters("instagramPost", ["getIsStoriesTabActive"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    modalTitle() {
      return this.isDelete && this.deleteTagType === "all"
        ? "Are you sure you want to delete all information about this media file?"
        : this.deleteTagType === "client" || this.deleteTagType === "product"
        ? `Are you sure you want to delete ${
            this.currentMediaForMoreInformation.tagClient &&
            this.deleteTagType === "client"
              ? `'${this.currentMediaForMoreInformation.tagClient}'`
              : ""
          } ${
            this.currentMediaForMoreInformation.tagProduct &&
            this.deleteTagType === "product"
              ? `'${this.currentMediaForMoreInformation.tagProduct}'`
              : ""
          } tag(s)?`
        : `Are you sure you want to delete description?`;
    },
  },
  methods: {
    ...mapActions("communication", ["getChatMessages", "sendMessage"]),
    ...mapActions("clientForm", ["fetchSmmList"]),
    ...mapActions("filesController", ["getUrlForSendFile", "uploadFile"]),
    ...mapMutations("dragAndDrop", ["setFile", "setDropOnTheInstagramWidget"]),
    ...mapMutations("videoPlayer", ["setToActiveVideo"]),
    ...mapActions("mediaLibraryWidget", ["updateMediaFile"]),
    async getSmmList() {
      try {
        const data = await this.fetchSmmList();
        if (data) {
          this.smmsList = data;
        }
      } catch (e) {
        throw e;
      }
    },
    getSmmNameFromId(smmId) {
      const smm = this.smmsList.find((smm) => smm.id == smmId);
      return smm && smm.name && H.capitalizeFirstLetter(smm.name);
    },
    async fetchMessages() {
      this.messages = await this.getChatMessages(this.getCurrentCustomer.id);
    },
    async sendMessageHandler() {
      this.isSendBtnDisabled = true;
      const params = {
        smmId: this.getCurrentUser.id,
        to: this.getCurrentCustomer.primary_phone,
        text: this.message && this.message.text ? this.message.text : "",
        media_url: "",
        customerId: this.getCurrentCustomer.id,
      };

      if (this.video || this.image) {
        const file = this.video || this.image;
        const { url, mediaId, fields } = await this.getUrlForSendFile({
          file,
          customerId: this.getCurrentCustomer.id,
          type: "media",
        });
        await this.uploadFile({ url, file: file.file, fields });
        params.media_url = `${url}${fields.key}`;
        params.imageId = mediaId;
        await this.getUrlForSendFile({
          file,
          customerId: this.getCurrentCustomer.id,
          type: "media",
          isTag: true,
          imageId: mediaId,
        });
      }

      if ((this.message && this.message.text) || this.video || this.image) {
        await this.sendMessage(params);
        this.message = {};
        this.deleteMediaDataFromMessage();
        await this.fetchMessages();
      }
      this.isSendBtnDisabled = false;
    },
    uploadFileHandler(files) {
      const file = files[0];
      if (this.isImage(file.name)) {
        this.image = file;
        this.video = null;
      } else {
        this.video = file;
        this.image = null;
      }
    },
    deleteMediaDataFromMessage() {
      this.image = null;
      this.video = null;
    },
    setDroppedFile(data) {
      if (data) {
        this.setFile({
          isInstagramActive: this.getIsStoriesTabActive,
          mediaUrl:
            data.mediaInfo && data.mediaInfo.mediaUrl
              ? data.mediaInfo.mediaUrl
              : data.mediaUrl,
          dragged: true,
        });
      }
    },
    downloadHandler(data) {
      if (data.mediaInfo && data.mediaInfo.mediaUrl) {
        this.download(data.mediaInfo.mediaUrl);
      } else if (Array.isArray(data.mediaUrl)) {
        data.mediaUrl.forEach((item) => {
          this.download(item);
        });
      } else {
        this.download(data.mediaUrl);
      }
    },
    scrollToBottom() {
      setTimeout(() => {
        if (this.$refs.msgContainer) {
          this.$refs.msgContainer.scrollTop =
            this.$refs.msgContainer.scrollHeight;
        }
      });
    },
    dateFormatter(date) {
      return this.$dayjs.unix(date).format("HH:mm DD.MM.YYYY");
    },
    refreshChat() {
      this.interval = setInterval(() => {
        this.fetchMessages();
        this.scrollToBottom();
      }, 45000);
    },
    setColor(phone) {
      if (this.colorList.length) {
        let result = this.colorList.find((item) => item.phone === phone);
        return result !== undefined
          ? { backgroundColor: result.color }
          : this.addColor(phone);
      } else {
        this.colorList.push({
          phone: phone,
          color: CHAT_COLORS[this.colorList.length],
        });
      }
    },
    addColor(phone) {
      this.colorList.push({
        phone: phone,
        color: CHAT_COLORS[this.colorList.length],
      });
    },
    selectMediaForMoreMediaHandler(mediaFile, messageId) {
      this.currentMediaForMoreInformation = mediaFile;
      this.currentMessageId = messageId;
      this.isShowMoreInformationPopup = true;
    },
    addTag(mediaFile) {
      this.currentMediaForMoreInformation = mediaFile;
      this.isShowTags = true;
      this.isDelete = false;
    },
    deleteTag(mediaFile) {
      this.deleteTagType = "all";
      this.currentMediaForMoreInformation = mediaFile;
      //this.currentMediaForMoreInformation = this.tagStatus(mediaFile);
      this.isShowTags = true;
      this.isDelete = true;
    },
    tagStatus(mediaFile) {
      switch (this.deleteTagType) {
        case "client":
          mediaFile.tagClient = "";
          break;
        case "product":
          mediaFile.tagProduct = "";
          break;
        case "description":
          mediaFile.tagDescription = "";
          break;
        default:
          mediaFile.tagClient = "";
          mediaFile.tagProduct = "";
          mediaFile.tagDescription = "";
          return mediaFile;
      }
    },
    deleteCurrentTag(data) {
      this.deleteTagType = data.flag;
      this.currentMediaForMoreInformation = data.mediaFile;
      this.isShowTags = true;
      this.isDelete = true;
    },
    updateMediaForMoreInformation() {
      this.fetchMessages();
    },
    async addToFavoriteHandler(mediaFile) {
      try {
        await this.updateMediaFile({
          id: mediaFile.id,
          favorite: String(!mediaFile.favorite),
          customerId: this.getCurrentCustomer.id,
        });
        this.updateMediaForMoreInformation();
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.chat-container {
  width: 100%;
  min-height: calc(100vh - 79px);
  overflow: hidden;
  position: relative;
  margin-bottom: -16px;
  .loader {
    font-size: 18px;
    text-align: center;
    padding-top: 30px;
  }
  &__wrapper {
    min-height: calc(100vh - 95px);
    max-height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
  }
  &__messages {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    padding: 0 16px;
    @include scrollbar(rgba(47, 72, 88, 0.2), $color-violet, 6px, 6px);
  }
  .message {
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    position: relative;
    &.reverse {
      justify-content: flex-end;
      .item {
        &__msg {
          align-items: flex-end;
          .text {
            background: $color-blueberry;
            color: $color-white;
          }
          .phone {
            display: none;
          }
        }
        &__media {
          display: inline-flex;
          flex-direction: column;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          p {
            margin-bottom: 0;
            padding: 8px 12px;
            background: $color-blueberry;
            font-weight: normal;
            font-size: 12px;
            line-height: 140%;
            color: $color-white;
          }
        }
      }
    }
    .item {
      display: inline-flex;
      flex-direction: row;
      align-items: flex-start;
      &__info {
        display: inline-flex;
        flex-direction: column;
        margin-right: 4px;
        align-items: center;
        .user-icon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          overflow: hidden;
          flex-shrink: 0;
          margin-bottom: 6px;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .messenger {
          width: 10px;
          height: 10px;
          flex-shrink: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      &__msg {
        display: inline-flex;
        flex-direction: column;
        .text {
          border-radius: 4px;
          padding: 8px 12px;
          background: rgba(78, 87, 120, 0.1);
          font-weight: normal;
          font-size: 12px;
          line-height: 140%;
          color: $color-black;
          margin-bottom: 2px;
          max-width: 258px;
          pre {
            white-space: pre-wrap;
          }
        }
        .date {
          font-weight: normal;
          font-size: 8px;
          line-height: 140%;
          color: $color-grey2;
        }
        .phone {
          font-size: 10px;
          margin-bottom: 4px;
          font-weight: 500;
          line-height: 140%;
          color: #6a7a82;
        }
      }
      &__media {
        display: inline-flex;
        flex-direction: column;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        p {
          margin-bottom: 0;
          padding: 8px 12px;
          background: rgba(78, 87, 120, 0.1);
          font-weight: normal;
          font-size: 12px;
          line-height: 140%;
          color: $color-black;
        }
      }
    }
    .message-media-container {
      width: 100%;
      max-width: 202px;
      img {
        width: 100%;
      }
      video {
        width: 100%;
      }
    }
  }
  .input-area {
    width: 100%;
    border-top: 1px solid rgba(47, 72, 88, 0.1);
    padding-top: 8px;
    position: relative;
    top: 16px;
    &__field {
      width: 100%;
      padding: 0 16px;
      ::v-deep {
        .v-input__slot {
          box-shadow: none !important;
        }
        .v-label {
          font-size: 14px !important;
        }
        textarea {
          box-shadow: none;
          width: 100%;
          height: 50px;
          resize: none;
          font-size: 14px;
          line-height: 140%;
          color: $color-black;
          outline: none !important;
        }
      }
    }
    &__controls {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      .item {
        display: inline-flex;
        margin-left: 10px;
        cursor: pointer;
        .send-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 44px;
          height: 36px;
          background: $color-violet;
          color: $color-white;
          border-radius: 4px 0 8px 0;
          transition: all 0.3s ease;
          i {
            position: relative;
            right: -2px;
          }
          &:hover {
            background: darken($color-violet, 10);
          }
        }
        .disabled-btn {
          background: #c1bcc2;
          &:hover {
            background: darken(#c1bcc2, 10);
          }
        }
        .uploader {
          cursor: pointer;
        }
        .refresh {
          height: 36px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 21px;
            color: $color-blueberry;
          }
        }
        .emoji {
          width: 18px;
          height: 18px;
          position: static;
          ::v-deep {
            .emoji {
              position: static;
            }
            .emoji__picker {
              right: 20px;
              bottom: calc(100% + 20px);
            }
          }
        }
      }
    }
    .attached {
      width: 100%;
      &__list {
        width: 100%;
        padding: 0 16px;
        margin-bottom: 8px;
      }
      &__item {
        width: 129px;
        height: 129px;
        border-radius: 4px;
        position: relative;
        background: rgba(78, 87, 120, 0.3);
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .icon-remove {
          position: absolute;
          right: 3px;
          top: 3px;
          font-size: 12px;
          color: $color-white;
          cursor: pointer;
        }
      }
    }
  }
  .item__user {
    position: absolute;
    left: 8px;
    bottom: 30px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 11px;
    color: #8f9192;
    background: #ffffff;
    transition: all 0.3s ease;
  }
  .item__tag {
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 11px;
    color: #8f9192;
    background: #ffffff;
    transition: all 0.3s ease;
    &.tag {
      color: $color-red;
    }
  }
  .remove-tag {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 9px;
    color: #8f9192;
    background: #ffffff;
    transition: all 0.3s ease;
  }
  .tag-btn-wrapper {
    position: absolute;
    left: 8px;
    bottom: 8px;
    height: 20px;
    .remove-tag {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 9px;
      color: $color-grey2;
      background: $color-white;
      transition: all 0.3s ease;
    }
    &.active {
      width: 50px;
      &:hover {
        .remove-tag {
          left: 22px;
        }
      }
    }
  }
  .item__favorite {
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 11px;
    color: $color-grey2;
    background: $color-white;
    transition: all 0.3s ease;
    &.favorite {
      color: $color-violet;
    }
  }
}
.chat-uploader {
  .v-icon {
    font-size: 21px !important;
    color: #4e5778;
  }
}
</style>
