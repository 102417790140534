import axios from "axios";

export default {
  namespaced: true,
  actions: {
    async createSeed({ commit }, params = {}) {
      try {
        const { data } = await axios.post("/seeds", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
