<template>
  <v-app fluid>
    <Header />
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/global/Header";
import Cookies from "js-cookie";
import User from "@/models/User";
import { mapMutations } from "vuex";
export default {
  name: "ClientLayout",
  components: {
    Header,
  },
  methods: {
    ...mapMutations("auth", ["setUser"]),
  },
  created() {
    if (Cookies.getJSON("currentUser")) {
      this.setUser(new User(Cookies.getJSON("currentUser")));
    }
  },
};
</script>

<style scoped></style>
