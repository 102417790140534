import BaseClass from "@/models/BaseClass";

const fields = [
  { field: "type", default: null },
  {
    field: "hint",
    default: "",
  },
  { field: "tip", default: "" },
  {
    field: "icon",
    default: "",
  },
  {
    field: "color",
    default: "",
  },
  {
    field: "textColor",
    default: "",
  },
];

export default class PostTooltip extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);
  }
}
