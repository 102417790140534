import Message from "@/models/Message";
import axios from "axios";

export default {
  namespaced: true,
  actions: {
    async getChatMessages({ commit }, customerId = "") {
      try {
        const { data } = await axios.get(`${customerId}/message`);
        return data.map((o) => new Message(o));
      } catch (e) {
        throw e;
      }
    },
    async sendMessage({ commit }, params = {}) {
      try {
        await axios.post(`/message/send`, params);
      } catch (e) {
        throw e;
      }
    },
  },
};
