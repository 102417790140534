import axios from "axios";

const axiosFunctions = axios.create({
  baseURL: process.env.VUE_APP_FUNCTIONS_URL,
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},

  actions: {
    async getCustomerSubscription({ commit }, stripe_customer_id) {
      try {
        const { data } = await axiosFunctions.get(
          `stripe/subscription/${stripe_customer_id}`
        );

        return data;
      } catch (e) {
        console.log("Error during getCustomerSubscription:", e);
        throw e;
      }
    },
  },
};
