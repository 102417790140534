<template>
  <Loader v-if="isLoading" />
  <router-view v-else />
</template>

<script>
import Loader from "@/components/global/Loader";
import { mapActions, mapGetters } from "vuex";
import LogRocket from "logrocket";
import { ACTIVITY_STATE } from "@/constants/globals";
export default {
  name: "EmptyLayout",
  data: () => ({
    isLoading: false,
    blockedCustomersIdsForLogrocket: [
      "1f13170e-be5a-11ed-8e4f-9a2ec078a965",
      "dan18982-9e4a-11ec-a0ac-1d80a5b0104f",
      "xHCz",
    ],
    activityStateList: ACTIVITY_STATE,
  }),
  components: {
    Loader,
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  async created() {
    this.isLoading = true;
    if (this.$route.params.id && !this.getCurrentSmbCustomer.id) {
      await this.fetchSmbClientInfo(this.$route.params.id);
    }

    if (
      this.getCurrentSmbCustomer.activity_state ===
        this.activityStateList.find((activity) => activity.id === 3).name ||
      this.getCurrentSmbCustomer.activity_state ===
        this.activityStateList.find((activity) => activity.id === 5).name
    ) {
      await this.$router.push({ name: "saas-no-access" });
    }

    if (!this.getCurrentSmbCustomer.onboarding_required) {
      await this.$router.push({ name: "smb-client" });
    }
    if (
      !this.blockedCustomersIdsForLogrocket.includes(
        this.getCurrentSmbCustomer.id
      )
    ) {
      LogRocket.init("m2bv2o/smm-dashboard");
      LogRocket.startNewSession();
      LogRocket.identify(this.getCurrentSmbCustomer.id, {
        name: this.getCurrentSmbCustomer.name,
      });
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
  },
};
</script>

<style scoped></style>
