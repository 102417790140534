var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"available-templates"},[_c('div',{staticClass:"available-templates__list"},[_c('div',{staticClass:"available-templates__list-wrapper"},_vm._l((_vm.templatesList),function(template){return _c('div',{key:template.id,staticClass:"item",class:{
          selected: _vm.selectedTemplate.id === template.id,
          'not-match': template.ok !== 'ok',
        },on:{"click":function($event){return _vm.selectTemplate(template)}}},[_c('div',{staticClass:"item__img"},[(template.preview_url)?_c('img',{attrs:{"src":template.preview_url,"alt":""}}):_vm._e(),(template.ok !== 'ok')?_c('div',{staticClass:"item__name"},[_vm._v(" "+_vm._s(template.ok)+" ")]):_vm._e()])])}),0)]),_c('div',{staticClass:"available-templates__buttons"},[_c('button',{staticClass:"btn violet",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn violet",class:{
        disabled:
          !_vm.selectedTemplate.id || _vm.selectedTemplate.id === _vm.currentTemplate.id,
      },attrs:{"disabled":!_vm.selectedTemplate.id || _vm.selectedTemplate.id === _vm.currentTemplate.id},on:{"click":function($event){return _vm.$emit('newTemplate', _vm.selectedTemplate)}}},[_vm._v(" Approve ")])]),(_vm.isLoading)?_c('Loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }