<template>
  <div class="brief-header">
    <div class="user">
      <div class="user__avatar">
        <img
          v-if="getCurrentCustomer.picUrl"
          :src="getCurrentCustomer.picUrl"
          :alt="getCurrentCustomer.name"
        />
        <img
          v-else
          src="@/assets/default-profile-picture.png"
          :alt="getCurrentCustomer.name"
        />
      </div>
      <div class="user__info">
        <div class="user__name">{{ getCurrentCustomer.name }}</div>
        <div
          class="user__location"
          v-if="getCustomerAddress && isShowCustomerAddress"
        >
          {{ getCustomerAddress }}
        </div>
      </div>
    </div>
    <div class="last-touch">
      <span>{{ getCurrentCustomer.last_sent_message }} </span> since our last
      touch point
    </div>
    <div class="customer-time" v-if="currentUserTimeZoneName">
      Customer's time:
      {{ $dayjs().tz(currentUserTimeZoneName).format("HH:MM A") }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TIME_ZONE_LIST } from "@/constants/customer";

export default {
  name: "BriefHeader",
  data: () => ({
    timeZoneList: TIME_ZONE_LIST,
  }),
  props: {
    isShowCustomerAddress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("clientSelection", [
      "getCurrentCustomer",
      "getCustomerAddress",
    ]),
    currentUserTimeZoneName() {
      const result =
        this.getCurrentCustomer.time_zone.trim() !== ""
          ? this.timeZoneList.find(
              (i) => i.code === this.getCurrentCustomer.time_zone
            )
          : null;
      return result ? result.name : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.brief-header {
  width: 100%;
  background: #fff;
  padding: 18px 20px;
  @media screen and (max-height: 950px) {
    padding: 10px 20px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  .user {
    display: inline-flex;
    align-items: center;
    &__avatar {
      height: 30px;
      width: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__info {
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
    &__name {
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      color: #1f2325;
    }
    &__location {
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      color: #1f2325;
      margin-top: 2px;
    }
  }
  .customer-time {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #1f2325;
  }
  .last-touch {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    span {
      display: inline-flex;
      align-items: center;
      font-weight: 700;
      font-size: 22px;
      margin-right: 5px;
      color: $color-red;
    }
  }
}
</style>
