<template>
  <div class="instagram-post">
    <div class="instagram-post__header">
      <div class="instagram-post__title">
        <div class="icon">
          <img src="@/assets/img/icons/Instagram_logo.svg" alt="" />
        </div>
        <div class="name">Instagram</div>
      </div>
      <div class="instagram-post__type">
        {{ getIsStoriesTabActive ? "Story" : "Post" }}
      </div>
    </div>
    <div v-if="!getIsStoriesTabActive" class="post">
      <div class="post__header">
        <div class="user">
          <div
            class="user__avatar"
            color="primary"
            :size="$vuetify.breakpoint.xsOnly ? 30 : 56"
          >
            <img
              v-if="getCurrentCustomer.picUrl"
              :src="getCurrentCustomer.picUrl"
            />
            <img v-else src="@/assets/default-profile-picture.png" />
          </div>
          <div class="user__info">
            <div class="user__name">{{ getCurrentCustomer.name }}</div>
            <div
              class="user__location"
              v-if="getCustomerAddress && isShowCustomerAddress"
            >
              {{ getCustomerAddress }}
            </div>
          </div>
        </div>
        <div class="crop-format">
          <div class="crop-format__current">
            <div class="crop-format__item">
              <div class="icon" :class="getCropFormat"></div>
              <div class="name">
                {{ formatName }}
              </div>
            </div>
          </div>
          <div class="crop-format__list">
            <v-radio-group :value="getCropFormat" @change="changeCropFormat">
              <v-radio value="square">
                <template v-slot:label>
                  <div class="crop-format__item">
                    <div class="icon square"></div>
                    <div class="name">1:1</div>
                  </div>
                </template>
              </v-radio>
              <v-radio value="horizontal">
                <template v-slot:label>
                  <div class="crop-format__item">
                    <div class="icon horizontal"></div>
                    <div class="name">1.91:1</div>
                  </div>
                </template>
              </v-radio>
              <v-radio value="vertical">
                <template v-slot:label>
                  <div class="crop-format__item">
                    <div class="icon vertical"></div>
                    <div class="name">4:5</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="controls">
          <div class="controls__edit">
            <span class="controls__edit-item">
              <input
                id="isCroppingMode"
                type="checkbox"
                class="checkbox-btn"
                v-model="isCroppingMode"
              />
              <label for="isCroppingMode">
                <i class="icon-crop_image"></i>
              </label>
            </span>
            <!-- <span class="controls__edit-item filter">
              <input
                id="isFilterEnabled"
                class="checkbox-btn"
                :disabled="disableFilterButton"
                type="checkbox"
                v-model="isFilterEnabled"
              />
              <label for="isFilterEnabled"></label>
            </span> -->
          </div>
        </div>
        <div class="more-info">
          <i class="icon-more"></i>
        </div>
      </div>
      <div class="post__img">
        <Loader v-if="isLoadingMedia" />
        <splide
          :slides="currentContentReference.media"
          v-if="
            currentContentReference.media &&
            currentContentReference.media.length
          "
          :options="options"
          class="post__slider"
          @splide:moved="activeSlide"
          @splide:mounted="activeSlide"
        >
          <splide-slide
            v-for="(file, index) in currentContentReference.media"
            :key="index"
          >
            <div class="content-container" v-if="file">
              <img
                :class="previewClass"
                style="display: flex"
                :ref="`preview${index}`"
                src="loading.gif"
              />
              <cropper
                :class="cropperClass"
                :imageClass="cropperClass"
                v-if="file && file.mediaUrl && file.type === 'image'"
                :ref="`cropper${index}`"
                :stencil-props="{ aspectRatio: getCropDataNumber }"
                :src="getMediaUrlForFile(file)"
                :canvas="true"
                :default-size="defaultSize"
                @change="onCropperChange({ ...$event, index })"
                :autoZoom="true"
                :cross-origin="true"
              />
              <div
                class="inst-video"
                v-else-if="file && file.mediaUrl && file.type === 'video'"
              >
                <VideoPlayer
                  :refName="'instagramPostVideo' + index"
                  :videoSrc="file.mediaUrl"
                />
              </div>
            </div>
          </splide-slide>
        </splide>
        <div class="default-img" v-else>
          <img src="@/assets/img/default-inst-img.svg" alt="" />
        </div>
      </div>
      <div class="post__controls">
        <div class="cell">
          <div class="item">
            <i class="icon-hart"></i>
          </div>
          <div class="item">
            <i class="icon-message"></i>
          </div>
          <div class="item">
            <i class="icon-direct"></i>
          </div>
        </div>
        <div class="cell">
          <div class="item">
            <i class="icon-bookmarks"></i>
          </div>
        </div>
      </div>
      <div class="post__text-container">
        <div class="text" v-if="currentContentReference.text">
          <pre><strong>{{ getCurrentCustomer.name }}</strong> {{ currentContentReference.text }}</pre>
        </div>
        <div class="tags-list">
          <span
            class="tags-list__item"
            v-for="(tag, index) in currentContentReference.tags"
            :key="index"
            >{{ tag }}</span
          >
        </div>
      </div>
    </div>
    <div v-else class="stories">
      <div class="post__header">
        <div class="user">
          <div class="user__avatar" color="primary">
            <img
              v-if="getCurrentCustomer.picUrl"
              :src="getCurrentCustomer.picUrl"
              :alt="getCurrentCustomer.name"
            />
            <img
              v-else
              src="@/assets/default-profile-picture.png"
              :alt="getCurrentCustomer.name"
            />
          </div>
          <div class="user__info">
            <div class="user__name">
              {{ getCurrentCustomer.name }} <span class="time">2h</span>
            </div>
            <div
              class="user__location"
              v-if="getCustomerAddress && isShowCustomerAddress"
            >
              {{ getCustomerAddress }}
            </div>
          </div>
        </div>
        <div class="more-info">
          <i class="icon-more"></i>
        </div>
      </div>
      <div class="post__img">
        <splide
          :slides="currentContentReference.media"
          v-if="currentContentReference.media.length"
          :options="options"
          class="post__slider"
        >
          <splide-slide
            v-for="(file, index) in currentContentReference.media"
            :key="index"
          >
            <div class="content-container" v-if="file">
              <cropper
                :imageClass="cropperClass"
                v-if="file && file.mediaUrl && file.type === 'image'"
                :ref="`cropper${index}`"
                :stencil-props="{ aspectRatio: getCropStoriesDataNumber }"
                :src="getMediaUrlForFile(file)"
                :canvas="true"
                :default-size="defaultSize"
                @change="onCropperChange({ ...$event, index })"
                :autoZoom="true"
                :cross-origin="true"
              />
              <div
                class="inst-video"
                v-else-if="file && file.mediaUrl && file.type === 'video'"
              >
                <video
                  class="post-image"
                  autoplay="autoplay"
                  playsinline
                  muted
                  loop
                >
                  <source
                    :src="file.mediaUrl"
                    :type="'video/' + getFileType(file.mediaUrl)"
                  />
                </video>
              </div>
            </div>
          </splide-slide>
        </splide>
        <div class="default-img" v-else>
          <img src="@/assets/img/default-inst-img.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="post__btn">
      <button
        :disabled="isLoading || isFiltersLoading || isLoadingApprovePlus"
        class="btn violet"
        @click="clickApproveHandler"
      >
        <span v-if="!isLoading || isFiltersLoading" class="btn__text"
          >Approve</span
        >
        <span v-else class="btn__loader"></span>
        <span v-if="isSuccess" class="btn__success">
          <i class="icon-check-mark"></i>
        </span>
      </button>
      <button
        v-if="isStoriesEdit"
        :disabled="isLoadingApprovePlus"
        @click="clickToApprovePlus"
        class="btn violet light"
      >
        <span v-if="!isLoadingApprovePlus" class="btn__text">Approve +</span>
        <span v-else class="btn__loader"></span>
      </button>
    </div>
    <ModalDialog
      :isShow="notification.isShow"
      :icon="notification.icon"
      :title="notification.title"
      :footerButtons="notification.footerButtons"
      @close="close()"
    >
      <template v-slot:description>
        {{ notification.description }}
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="spellModal.isShow"
      :icon="spellModal.icon"
      :title="spellModal.title"
      :footerButtons="spellModal.footerButtons"
      @close="spellModal.isShow = false"
      @approve="approveSpellCheck"
    >
      <template v-slot:description>
        {{ spellModal.description }}
      </template>
    </ModalDialog>

    <ModalDialog
      :isShow="templateModal.isShow"
      :icon="templateModal.icon"
      :footerButtons="templateModal.footerButtons"
      @close="closeTemplateModal"
      @approve="closeTemplateModal"
      :size="500"
    >
      <template v-slot:description>
        {{ templateModal.description }}
      </template>
    </ModalDialog>

    <ModalDialog
      :title="toLongPostCharacters.title"
      :icon="toLongPostCharacters.icon"
      :footerButtons="toLongPostCharacters.footerButtons"
      :is-show="toLongPostCharacters.isShow"
      @ok="toLongPostCharacters.isShow = false"
      @close="toLongPostCharacters.isShow = false"
      :size="500"
    >
      <template v-slot:description>
        The post exceeds the allowed number of characters. Only 2200 are
        allowed.
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import ModalDialog from "@/components/global/ModalDialog";
import VideoPlayer from "@/components/global/VideoPlayer";
import helper from "@/mixins/helper";
import H from "@/utils/helper";
import { mapGetters, mapActions, mapMutations } from "vuex";
//import LogRocket from "logrocket";
import rasterizeHTML from "rasterizehtml";
import Loader from "@/components/global/Loader";

export default {
  name: "InstagramPost",
  components: { ModalDialog, VideoPlayer, Loader },
  data: () => ({
    isCroppingMode: true,
    isFilterEnabled: false,
    disableFilterButton: true,
    cropperClass: "",
    previewClass: "hidden",
    isShowImage: false,
    isLoadingData: false,
    isShowVideo: false,
    arrayOfMediaUrls: [],
    arrayOfFiles: [],
    isLoading: false,
    isLoadingMedia: false,
    isLoadingApprovePlus: false,
    isSuccess: false,
    notification: {},
    spellModal: {
      isShow: false,
      icon: require("@/assets/img/icons/warning-icon.svg"),
      title: "Fail spell check!",
      description: "",
      footerButtons: [
        { name: "Approve", emitName: "approve", btnClass: "violet" },
        { name: "Edit Post", emitName: "close" },
      ],
    },
    templateModal: {
      isShow: false,
      icon: require("@/assets/img/icons/warning-icon.svg"),
      title: "Template not full!",
      description: "",
      footerButtons: [{ name: "Close", emitName: "close" }],
    },
    toLongPostCharacters: {
      isShow: false,
      icon: require("@/assets/img/icons/warning-icon.svg"),
      title: "Please note!",
      footerButtons: [
        { name: "Ok, will rephrase", emitName: "ok", btnClass: "violet" },
      ],
    },
    customSubject: "",
    isUserChangedRatio: false,
    // filterIsActive: true,
    currentContentReference: null,
  }),
  props: {
    isShowCustomerAddress: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [helper],
  watch: {
    // getMediaLibraryFilterIsActive(val) {
    //   this.isFilterEnabled = val;
    // },

    // isFilterEnabled(data) {
    //   this.setMediaLibraryFilterIsActive(data);

    //   if (this.isCroppingMode) return;

    //   if (!data) {
    //     this.previewClass = "normal";
    //   }

    //   this.currentContentReference.media.forEach((file, index) => {
    //     if (!file.preview) {
    //       file.preview = [];
    //     }

    //     file.preview.filter = this.filterClass;
    //     this.updatePreviewImages(index);
    //   });
    // },

    isCroppingMode() {
      if (this.isCroppingMode) {
        this.disableFilterButton = true;
        if (this.isFilterEnabled) {
          this.cropperClass = this.getCurrentCustomer.filter || "normal";
        } else {
          this.cropperClass = "normal";
        }
        this.previewClass = "hidden";
      } else {
        this.disableFilterButton = false;
        this.previewClass = "normal";
        this.cropperClass = "hidden";
      }
    },

    getCropFormatStories(val) {
      this.turnCropperOn();
      this.forceCropperReset();
      // this.forceCropperRefresh();
    },
    // getCropFormat(val) {
    // this.forceCropperRefresh();
    // },
    // getCropStoriesDataNumber(val) {
    //   this.forceCropperRefresh();
    // },
    // getCropDataNumber(val) {
    // this.forceCropperRefresh();
    // },

    getIsStoriesTabActive(val) {
      this.init();
    },
    getCropFormat() {
      this.turnCropperOn();
      this.forceCropperReset();
    },
    getCurrentPost: {
      handler(val) {
        this.currentContentReference.text = val.text;
        this.currentContentReference.media = val.media;
        this.currentContentReference.tags = val.tags;
        this.currentContentReference.contentCaptionId = val.contentCaptionId;
      },
      deep: true,
    },
    getCurrentStories: {
      handler(val) {
        this.currentContentReference.media = val.media;
        this.currentContentReference.contentCaptionId = val.contentCaptionId;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("clientSelection", [
      "getCurrentCustomer",
      "getCustomerAddress",
    ]),
    ...mapGetters("dragAndDrop", ["getCurrentDroppedFile"]),
    ...mapGetters("instagramPost", ["getIsStoriesTabActive"]),
    ...mapGetters("post", [
      "getCurrentPost",
      "getCropFormat",
      "getCropDataNumber",
      "getMediaIsLoading",
    ]),
    ...mapGetters("stories", [
      "getCurrentStories",
      "getCropStoriesDataNumber",
      "getCropFormatStories",
    ]),
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("mediaLibraryWidget", ["getMediaLibraryFilterIsActive"]),
    options() {
      return {
        type: "slide",
        perPage: 1,
        height: "30vh",
        arrows: true,
        autoWidth: false,
        pagination: true,
      };
    },
    isFiltersLoading() {
      return this.getMediaIsLoading.some((v) => v == true);
    },
    isStoriesEdit() {
      return (
        this.$route.params.isEdit &&
        this.$route.params.isFromCalendar &&
        this.getIsStoriesTabActive == 1
      );
    },
    filterClass() {
      return this.isFilterEnabled ? this.getCurrentCustomer.filter : "normal";
    },
    formatName() {
      const formats = { square: "1:1", vertical: "4:5", horizontal: "1.91:1" };
      return formats[this.getCropFormat];
    },
    getAttachedImageId() {
      if (this.currentContentReference.media.length) {
        const data = this.currentContentReference.media.find(
          (i) => typeof i === "object" && !i.blob && (i.mediaId || i.id)
        );
        if (!data) return undefined;
        const tmpId = data.mediaId || data.id;
        return tmpId ? tmpId : undefined;
      } else {
        return undefined;
      }
    },
    changeSubjectTitle() {
      return `Subject: ${this.currentContentReference.contentSubject}\nDoes caption match the subject?\nIf not, what should the subject be?`;
    },
    changeSubjectButtons() {
      const isCustomSubject =
        this.customSubject && this.customSubject.trim() !== "";
      const btnClass = `violet ${isCustomSubject ? "" : "disabled"}`;

      return [
        {
          name: "Update Subject",
          emitName: "approve",
          btnClass: btnClass,
          isDisabled: !this.customSubject && this.customSubject.trim() === "",
        },
        { name: "Didn’t change Subject", emitName: "close" },
      ];
    },
  },
  created() {
    this.init();
    // if (this.currentContentReference.type === "post") {
    //   this.turnCropperOff();
    // }
  },
  mounted() {
    this.$root.$on("mediaObjectModified", (data) => {
      const { index } = data;
      this.forceCropperRefresh(index);
    });
  },
  methods: {
    ...mapActions("createPost", ["deletePost"]),
    ...mapActions("filesController", ["getUrlForSendFile", "uploadFile"]),
    ...mapActions("post", ["deletePostOrStoryById"]),
    ...mapActions("instagramPost", ["approveInstagramPost", "copywriter"]),
    ...mapMutations("dragAndDrop", ["setFile", "setDropOnTheInstagramWidget"]),
    ...mapMutations("post", [
      "refreshCurrentPost",
      "updatePostData",
      "setCropFormat",
      "setMediaIsLoading",
    ]),
    ...mapMutations("stories", [
      "refreshCurrentStories",
      "setCropFormatStories",
      "setCurrentStories",
    ]),
    ...mapMutations("mediaLibraryWidget", [
      "clearMediaLibrarySelections",
      "setMediaLibraryFilterIsActive",
      "setIsUpdateMedia",
    ]),
    ...mapMutations("videoPlayer", ["setToActiveVideo"]),
    ...mapActions("mediaLibraryWidget", ["uploadMediaFromUrl"]),
    ...mapActions("calendar", ["savePost", "getStoryByCalendarID"]),

    init() {
      this.setCurrentContent(this.getIsStoriesTabActive ? "story" : "post");

      this.setCropperCoordinates();
      const ratio = this.getMediaItemRatio();
      this.setCropperFormatValue(ratio);
      // this.forceCropperRefresh();
    },

    getMediaUrlForFile(file) {
      return H.getMediaUrlForFile(file);
    },

    setCropperFormatValue(ratio) {
      if (this.currentContentReference.type === "story") {
        this.setCropFormatStories(ratio);
      } else if (this.currentContentReference.type === "post") {
        this.setCropFormat(ratio);
      }
    },

    setCurrentContent(type) {
      if (type === "story") {
        this.currentContentReference = this.getCurrentStories;
      } else if (type === "post") {
        this.currentContentReference = this.getCurrentPost;
      }
    },

    activeSlide(Slide) {
      // Slide.Components.Elements.slides.forEach((item, index) => {
      //   if (item.classList.contains("is-active")) {
      //     Slide.Components.Elements.slides[index].querySelector(
      //       ".video-wrapper"
      //     )
      //       ? (this.filterIsActive = false)
      //       : (this.filterIsActive = true);
      //   }
      // });
    },

    onCropperChange(event) {
      const { coordinates, image, index, canvas, visibleArea } = event;
      if (!canvas || canvas == null) {
        this.setMediaIsLoading({ index, value: false });
        return;
      }

      if (
        this.currentContentReference.media[index] &&
        !this.currentContentReference.media[index].preview
      ) {
        this.currentContentReference.media[index].preview = [];
      }
      if (
        H.isObjectsEqual(
          coordinates,
          this.currentContentReference.media[index].preview["coordinates"]
        )
      ) {
        this.setMediaIsLoading({ index, value: false });
        return;
      }

      let src = canvas.toDataURL();
      if (this.currentContentReference.type == "post")
        this.$refs[`preview${index}`][0].src = src;
      this.setMediaIsLoading({ index, value: false });

      // if (src == "data:,") {
      //   this.currentContentReference.media[index].preview.src = image.src;
      //   this.currentContentReference.media[index].preview.filter = this.filterClass;
      //   this.currentContentReference.media[index].preview.coordinates = coordinates;
      //   this.currentContentReference.media[index].preview.init = true;
      //   let tmpImage = new Image();

      //   tmpImage.onload = (event) => {
      //     this.currentContentReference.media[index].preview.width = event.target.width;
      //     this.currentContentReference.media[index].preview.height = event.target.height;
      //     this.updatePreviewImages(index);
      //   };

      //   tmpImage.src = image.src;
      // } else {
      //   this.currentContentReference.media[index].preview = {
      //     coordinates: coordinates,
      //     filter: this.filterClass,
      //     width: coordinates.width,
      //     height: coordinates.height,
      //     src: src,
      //     init: true,
      //   };

      //   this.updatePreviewImages(index, canvas);
      // }
    },

    // updatePreviewImages(index, canvas) {
    //   if (this.getIsStoriesTabActive) return;
    //   let { filter, width, height, src, init } =
    //     this.currentContentReference.media[index].preview;
    //   const html =
    //     '<head><link rel="stylesheet" href="/css/filters.css"></head><body style="margin:0;"><div style="width: ' +
    //     width +
    //     "; height: " +
    //     height +
    //     '"><img class="' +
    //     filter +
    //     '" style="width: ' +
    //     width +
    //     "; height: " +
    //     height +
    //     '" src="' +
    //     src +
    //     '"/></div></body>';
    //   let previewImage = this.$refs[`preview${index}`][0];
    //   let setLoading = (index, value) => {
    //     setTimeout(() => {
    //       this.setMediaIsLoading({ index, value });
    //     }, 1);
    //   };

    //   setLoading(index, true);
    //   previewImage.src = "loading.gif";
    //   const outputFormat = "image/jpeg";
    //   if (canvas && !init) {
    //     previewImage.src = canvas.toDataURL(outputFormat);
    //     setLoading(index, false);
    //   } else {
    //     rasterizeHTML.drawHTML(html).then(function (renderResult) {
    //       var ycanvas = document.createElement("canvas");
    //       ycanvas.width = width;
    //       ycanvas.height = height;

    //       var context = ycanvas.getContext("2d");
    //       context.drawImage(renderResult.image, 0, 0);

    //       let dataURL = ycanvas.toDataURL(outputFormat);
    //       previewImage.src = dataURL;
    //       setLoading(index, false);
    //       console.timeEnd(`rasterizeHTML_${index}`);
    //     });
    //   }
    // },
    async checkIsNeedToChangeSubject() {
      try {
        const params = {
          action: "getSimilarity",
          contentCaptionId: this.currentContentReference.contentCaptionId,
          captionB: this.currentContentReference.text,
          smmId: this.getCurrentUser.id,
          customerId: this.getCurrentCustomer.id,
          calendarItemId: this.currentContentReference.calendarItemId,
        };
        return await this.copyWriterRequest(params);
      } catch (e) {
        console.error(
          "unable to complete check for subject change. continue without. error:",
          e
        );
        return null;
      }
    },

    isTemplatesOk() {
      const { isTemplatesFullValue, missingFields } = this.isTemplatesFull();
      if (this.currentContentReference.template && !isTemplatesFullValue) {
        this.templateModal.description =
          "Please edit the template and fill all mandatory fields: " +
          missingFields.join(", ");
        return false;
      }
      return true;
    },

    async clickApproveHandler() {
      if (!this.isTemplatesOk()) {
        this.templateModal.isShow = true;
        return;
      }
      if (this.getIsStoriesTabActive) {
        await this.successApprove();
      } else {
        const tags =
          typeof this.currentContentReference.tags === "object"
            ? this.currentContentReference.tags.join(" ")
            : "";

        if ((this.currentContentReference.text + "\n" + tags).length <= 2200) {
          this.isLoading = true;

          try {
            let val = this.getMediaIsLoading.some((v) => v == true);
            while (val) {
              await H.sleep(100);
              val = this.getMediaIsLoading.some((v) => v == true);
            }

            await this.approve();
          } finally {
            this.isLoading = false;
          }
        } else {
          this.toLongPostCharacters.isShow = true;
        }
      }
    },
    async approve() {
      try {
        const { isSuccess, message } = await this.spellCheckHandler();

        if (isSuccess) {
          await this.approveSpellCheck();
        } else {
          this.spellModal.description = message;
          this.spellModal.isShow = true;
        }
      } catch (e) {
        throw e;
      }
    },
    async approveSpellCheck() {
      try {
        this.spellModal.isShow = false;
        await this.successApprove();
      } catch (e) {
        throw e;
      }
    },
    async successApprove() {
      if (this.$route.params.isFromCalendar && this.$route.params.isReadyPost) {
        const id = this.currentContentReference.id;
        if (id) {
          await this.deletePost(id);
        }
      }
      this.currentContentReference.type == "story"
        ? await this.approveStoryHandler()
        : await this.approvePostHandler();
      this.updatePostData(true);
      if (
        this.getCurrentCustomer.domain === "beauty" &&
        !this.$route.params.redirectPath
      ) {
        this.$router.push({ name: "calendar" });
      } else if (this.$route.params.redirectPath) {
        this.$router.push({ path: this.$route.params.redirectPath });
      }
    },
    async newStories(date) {
      try {
        const params = {
          publishAtStr: date + "_09-00-00",
          customerId: this.getCurrentCustomer.id,
          type: "story",
        };
        const data = await this.savePost(params);
        const stories = await this.getStoryByCalendarID(data[0].id);
        this.setCurrentStories({
          ...stories,
          calendarPostId: data[0].postId,
          calendarItemId: data[0].id,
        });
        this.setCurrentContent("story");
      } catch (e) {
        console.log(e);
      }
    },
    async clickToApprovePlus() {
      this.isLoadingApprovePlus = true;
      if (this.$route.params.isFromCalendar && this.$route.params.isReadyPost) {
        const id = this.currentContentReference.id;
        await this.deletePost(id);
      }
      const nextItemDate = this.$dayjs(
        this.currentContentReference.scheduledForStr.split("_")[0]
      )
        .add(1, "day")
        .format("YYYY-MM-DD");
      await this.approveStoryPlusHandler();
      await this.newStories(nextItemDate);
      this.isLoadingApprovePlus = false;
      this.setIsUpdateMedia(true);
    },
    forceCropperRefresh(specificIndex) {
      this.currentContentReference.media.forEach((item, index) => {
        if (item.type !== "image") return;
        if (specificIndex && specificIndex !== index) return;
        if (!this.$refs[`cropper${index}`] || !this.$refs[`cropper${index}`][0])
          return;

        this.setMediaIsLoading({ index, value: true });
        this.$refs[`cropper${index}`][0].refresh();
      });
    },
    forceCropperReset(specificIndex) {
      this.currentContentReference.media.forEach((item, index) => {
        if (item.type !== "image") return;
        if (specificIndex && specificIndex !== index) return;
        if (!this.$refs[`cropper${index}`] || !this.$refs[`cropper${index}`][0])
          return;

        this.$refs[`cropper${index}`][0].reset();
      });
    },
    countHashtagCaptions() {
      return this.currentContentReference.text
        ? this.currentContentReference.text.replace(/\s/g, "").split("#")
            .length - 1
        : 0;
    },
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },

    getMediaItemRatio() {
      if (
        this.currentContentReference.media.length &&
        this.currentContentReference.media[0].ratio
      )
        return this.currentContentReference.media[0].ratio;
      if (this.currentContentReference.type == "post") return "square";
      if (this.currentContentReference.type == "story") return "vertical";
      console.error("cannot determine aspect-ratio. using square");
      return "square";
    },

    setCropperCoordinates() {
      if (
        !this.currentContentReference.media ||
        this.currentContentReference.media.length === 0
      )
        return;
      this.currentContentReference.media.forEach((item, index) => {
        setTimeout(() => {
          if (!item.cropDetails) return;
          if (
            !this.$refs[`cropper${index}`] ||
            !this.$refs[`cropper${index}`][0]
          )
            return;
          this.$refs[`cropper${index}`][0].setCoordinates(item.cropDetails);
        }, 0);
      });
    },

    getMediaIdFromUr(mediaUrl) {
      if (!mediaUrl || !mediaUrl.includes("amazonaws.com/media/")) {
        return null;
      }

      const resultArray = mediaUrl.split("/");
      if (resultArray.length < 7) {
        return null;
      }
      return resultArray[5];
    },

    isTemplatesFull() {
      if (!this.currentContentReference.template) {
        return true;
      }
      let parameters = this.currentContentReference.template.parameters;
      let missingFields = parameters
        .map((p) => {
          if (p["isMandatory"] && p["value"] == null) return p["layer_name"];
        })
        .filter(Boolean);

      return {
        isTemplatesFullValue: Boolean(missingFields.length == 0),
        missingFields: missingFields.filter((v) => !!v),
      };
    },
    removeDuplicatesUrlFromMedia(array) {
      const uniqueUrls = [];

      const unique = array.filter((element) => {
        const isDuplicate = uniqueUrls.includes(element.mediaUrl || element);

        if (!isDuplicate) {
          uniqueUrls.push(element.mediaUrl || element);

          return true;
        }

        return false;
      });
      return unique;
    },

    async approvePostHandler() {
      //LogRocket.track("PostApproved");

      this.isLoading = true;
      try {
        let params = {
          ...this.currentContentReference,
        };
        params.customerId = this.getCurrentCustomer.id;
        params.customerApprove = "none";
        if (params.customerComment && params.customerComment.length)
          params.customerComment = "";

        params.customerId = this.getCurrentCustomer.id;

        /*params.media.length
          ? (params.media = this.removeDuplicatesUrlFromMedia(params.media))
          : null;*/

        if (params.tags.length + +this.countHashtagCaptions() > 30) {
          this.isLoading = false;
          this.notification = {
            isShow: true,
            icon: require("@/assets/img/icons/warning-icon.svg"),
            title: "Something went wrong!",
            description: "Maximum of 30 hashtags is allowed!",
            footerButtons: [
              { name: "Ok", emitName: "close", btnClass: "violet" },
            ],
          };
          return;
        }
        if (params.media.length) {
          let media = [];

          // const postMediaUrls = await this.uploadPreviewMediaItems(
          //   params.media
          // );

          await Promise.all(
            params.media.map(async (i, index) => {
              if (i.template) {
                const newlyUploadedMedia = await this.uploadObjectMedia(i);
                i.mediaUrl = newlyUploadedMedia.mediaUrl;
                i.id = newlyUploadedMedia.mediaId;
              }

              let imageCoordinates = null;
              let ratio = null;

              if (i.type === "image") {
                const { coordinates } =
                  this.$refs[`cropper${index}`][0].getResult();
                imageCoordinates = coordinates;
                ratio = this.getCropFormat;
              }

              media[index] = {
                ratio: ratio,
                cropDetails: imageCoordinates,
                mediaUrl: i.mediaUrl,
                // postMediaUrl: postMediaUrls[index],
                template: i.template,
                mediaId: i.id ? i.id : this.getMediaIdFromUr(i.mediaUrl),
                isLicensed: i.template && i.template.isLicensed,
              };
            })
          );
          params = { ...params, media };
        }

        await this.approveInstagramPost(
          Object.assign(params, { smmId: this.getCurrentUser.id })
        );
        this.refreshCurrentPost();
        this.clearMediaLibrarySelections();
        this.init();
        this.isLoading = false;
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 3000);
      } catch (e) {
        this.notification = {
          isShow: true,
          icon: require("@/assets/img/icons/warning-icon.svg"),
          title: "Something went wrong!",
          footerButtons: [
            { name: "Ok", emitName: "close", btnClass: "violet" },
          ],
        };
        throw e;
      } finally {
        this.isLoading = false;
      }
    },

    async uploadObjectMedia(mediaObject) {
      const data = await this.uploadMediaFromUrl({
        customerId: this.getCurrentCustomer.id,
        inputMediaUrl: mediaObject.mediaUrl,
        isLicensed: mediaObject.isLicensed,
        processMedia: false,
        createMediaItem: true,
        createdBy: "6",
        fileName: "post_raw_file", //TODO, shoule be unique per post
        folderName: "None",
      });
      const mediaUrl = data.data["media_url"];
      const mediaId = data.data["media_id"];
      return { mediaUrl, mediaId };
    },

    async uploadPreviewMediaItems(mediaUrls) {
      let postMediaUrls = [];
      await Promise.all(
        mediaUrls.map(async (i, index) => {
          postMediaUrls[index] = null;
          if (!this.isImage(i.mediaUrl)) {
            return;
          }
          let previewImage = this.$refs[`preview${index}`][0];
          let postMediaBase64 = previewImage.src;

          const media_id = i.id ? i.id : this.getMediaIdFromUr(i.mediaUrl);
          const data = await this.uploadMediaFromUrl({
            customerId: this.getCurrentCustomer.id,
            inputMediaUrl: postMediaBase64,
            processMedia: false,
            createMediaItem: false,
            fileName: "post_raw_file.jpg", //TODO, shoule be unique per post
            folderName: "None",
            existingMediaId: media_id,
          });
          const media_url = data.data["media_url"];
          postMediaUrls[index] = media_url;
        })
      );
      return postMediaUrls;
    },

    async spellCheckHandler() {
      try {
        if (this.getIsStoriesTabActive) {
          return { isSuccess: true, message: "" };
        }
        let params = {
          text: this.currentContentReference.text,
          imageId: this.getAttachedImageId,
          action: "spellCheck",
          customerId: this.getCurrentCustomer.id,
        };

        return this.copyWriterRequest(params);
      } catch (e) {
        console.error(
          "unable to complete check for spelling. continue without. error:",
          e
        );
        return { isSuccess: false, message: "" };
      }
    },
    async copyWriterRequest(params) {
      return await this.copywriter(params);
    },
    async approveStory() {
      try {
        this.isLoading = true;
        await this.approveStoryHandler();
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 3000);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async approveStoryPlusHandler() {
      try {
        await this.approveStoryHandler();
      } catch (e) {
        console.log(e);
      }
    },
    async approveStoryHandler() {
      let params = {
        ...this.currentContentReference,
      };
      params.customerId = this.getCurrentCustomer.id;

      /* params.media.length
        ? (params.mediaUrl = this.removeDuplicatesUrlFromMedia(params.mediaUrl))
        : null;*/

      try {
        if (params.media.length) {
          let media = [];
          await Promise.all(
            params.media.map(async (i, index) => {
              if (i.template) {
                const newlyUploadedMedia = await this.uploadObjectMedia(i);
                i.mediaUrl = newlyUploadedMedia.mediaUrl;
                i.id = newlyUploadedMedia.mediaId;
              }

              let imageCoordinates = null;
              let ratio = null;

              if (i.type === "image") {
                const { coordinates } =
                  this.$refs[`cropper${index}`][0].getResult();
                imageCoordinates = coordinates;
                ratio = this.getCropFormatStories;
              }

              media[index] = {
                ratio: ratio,
                cropDetails: imageCoordinates,
                mediaUrl: i.mediaUrl,
                template: i.template,
                mediaId: i.id
                  ? i.id
                  : i.mediaId
                  ? i.mediaId
                  : this.getMediaIdFromUr(i),
                isLicensed: i.template && i.template.isLicensed,
              };
            })
          );
          params = { ...params, media };
        }
        await this.approveInstagramPost(params);
        this.refreshCurrentPost();
        this.refreshCurrentStories();
        this.clearMediaLibrarySelections();
        this.init();
      } catch (e) {
        this.notification = {
          isShow: true,
          icon: require("@/assets/img/icons/warning-icon.svg"),
          title: "Something went wrong!",
          footerButtons: [
            { name: "Ok", emitName: "close", btnClass: "violet" },
          ],
        };
        throw e;
      }
    },

    async createUrlForFile(
      file,
      customerId,
      type = "media",
      isTag = false,
      imageId = null
    ) {
      try {
        const { url, mediaId, fields } = await this.getUrlForSendFile({
          file,
          customerId,
          type,
          isTag,
          imageId,
        });
        return { url, mediaId, fields };
      } catch (e) {
        throw e;
      }
    },
    close() {
      this.notification.isShow = false;
    },
    closeTemplateModal() {
      this.templateModal.isShow = false;
    },
    turnCropperOn() {
      this.isCroppingMode = true;
    },
    turnCropperOff() {
      this.isCroppingMode = false;
      this.disableFilterButton = false;
      this.cropperClass = "hidden";
      // if (this.isFilterEnabled) {
      //   this.previewClass = this.getCurrentCustomer.filter || "normal";
      // } else {
      //   this.previewClass = "normal";
      // }
    },
    changeCropFormat(newVal) {
      this.setCropperFormatValue(newVal);
      this.turnCropperOn();
      this.forceCropperReset();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.instagram-post {
  padding: 0 24px;
  max-height: calc(100vh - 65px);
  min-height: calc(100vh - 65px);
  overflow-y: auto;

  .hidden {
    visibility: hidden;
    position: absolute;
    left: -99999px;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  &__title {
    display: inline-flex;
    align-items: center;

    .icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-right: 6px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .name {
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: $color-black;
    }
  }

  &__type {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: $color-violet;
  }

  .post {
    background: $color-white;
    border: 1px solid #eeeff0;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px rgba(31, 35, 37, 0.06) !important;
    border-radius: 0 !important;
    max-width: 370px;
    margin: 0 auto;

    &__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      .controls {
        display: flex;
        align-items: center;
        margin-left: 5px;
        &__edit {
          display: inline-flex;
          align-items: center;
          &-item {
            position: relative;
            display: inline-flex;
            .checkbox-btn {
              position: absolute;
              left: -9999px;
              visibility: hidden;

              & + label {
                display: inline-flex;
                align-items: center;
                user-select: none;
                width: 24px;
                height: 24px;
                margin: 0 5px;
                justify-content: center;
                border: 1.5px solid rgba(78, 87, 120, 0.4);
                border-radius: 3px;
                i {
                  font-size: 16px;
                }
              }
              &:checked + label {
                border: 1.5px solid $color-violet;
                i {
                  display: none;
                  color: $color-violet;
                }
                &:before {
                  content: "\e935";
                  font-family: "icomoon" !important;
                  font-size: 14px;
                  font-weight: 600;
                }
              }
            }
            &.filter {
              .checkbox-btn {
                position: absolute;
                left: -9999px;
                visibility: hidden;
                & + label {
                  display: inline-flex;
                  align-items: center;
                  user-select: none;
                  &:before {
                    content: "";
                    width: 15px;
                    height: 15px;
                    background: url("../../../assets/img/icons/image-filter-off.png")
                      no-repeat center;
                    background-size: contain;
                  }
                }
                &:checked + label {
                  &:before {
                    content: "";
                    width: 15px;
                    height: 15px;
                    background: url("../../../assets/img/icons/image-filter-on.png")
                      no-repeat center;
                    background-size: contain;
                  }
                }
                &[disabled] {
                  & + label {
                    opacity: 0.5;
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }

      .user {
        display: inline-flex;
        align-items: center;

        &__avatar {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          flex-shrink: 0;
          margin-right: 8px;

          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &__info {
          display: inline-flex;
          flex-direction: column;
        }

        &__name {
          font-weight: 500;
          font-size: 10px;
          line-height: 120%;
          color: $color-black;
        }

        &__location {
          font-weight: normal;
          font-size: 10px;
          line-height: 120%;
          color: #515456;
          span {
            margin-right: 2px;
          }
        }
      }

      .more-info {
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
          font-size: 14px;
          color: $color-black;
        }
      }
    }

    &__img {
      background: rgba(78, 87, 120, 0.2);
      position: relative;

      .default-img {
        position: static;
        width: 100%;
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__slider {
      width: 100% !important;
      margin-bottom: 13px;

      ::v-deep {
        .splide {
          &__track {
            height: 100%;
          }

          &__list {
            height: 100%;
            align-items: center;
          }

          &__slide {
            height: 330px !important;
            .content-container {
              display: flex;
            }
          }

          &__arrow {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &:disabled {
              opacity: 0;
            }
            &--prev {
              &:before {
                font-family: "icomoon" !important;
                content: "\e902";
                position: absolute;
                font-size: 24px;
                color: $color-white;
              }
            }
            &--next {
              &:before {
                font-family: "icomoon" !important;
                content: "\e90c";
                position: absolute;
                font-size: 24px;
                color: $color-white;
              }
            }
            svg {
              display: none;
            }
          }

          &__pagination {
            padding-left: 0;
            bottom: -18px;
            &__page {
              width: 4px;
              height: 4px;
              background: $color-grey3;
              transform: scale(1);
              padding: 2px !important;
              transition: all 0.3s ease;
              &.is-active {
                background: $color-black;
              }
            }
          }
        }
      }

      .content-container {
        width: 100%;
        height: 100%;

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .image-file {
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: contain;
        }

        .vue-advanced-cropper {
          width: 100%;
        }
      }

      .inst-video {
        width: 100%;
        height: 100%;
        position: absolute;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__controls {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px 12px;
      .cell {
        display: inline-flex;
        align-items: center;
        .item {
          display: inline-flex;
          margin-right: 16px;
          i {
            font-size: 16px;
            color: $color-black;
            font-weight: bold;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__text-container {
      width: 100%;
      padding: 0 10px;

      .likes {
        font-weight: 500;
        font-size: 10px;
        line-height: 140%;
        color: $color-black;
        margin-bottom: 8px;
      }

      .text {
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #515456;
        margin-bottom: 8px;
        pre {
          font-weight: normal;
          font-size: 14px;
          line-height: 150%;
          color: #515456;
          white-space: pre-wrap;
          font-family: $base-font;
        }
      }

      .tags-list {
        display: inline-flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        &__item {
          font-weight: normal;
          font-size: 14px;
          line-height: 140%;
          color: $link-color;
          margin-right: 5px;
        }
      }
    }

    &__btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0;
      .btn {
        height: 36px;
        width: 100%;
        max-width: 200px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: $color-white;
        border-radius: 16px;
        background: $color-violet;
        transition: all 0.3s ease;
        &:hover {
          background: darken($color-violet, 10);
        }
        &.light {
          max-width: 120px;
          margin-left: 15px;
        }
      }
    }
  }
}

.stories {
  position: relative;
  max-width: 350px;
  margin: 0 auto;
  .post {
    &__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      .icon-more {
        color: $color-white !important;
      }
      .user {
        &__name {
          color: $color-white;
          .time {
            opacity: 0.7;
          }
        }
        &__location {
          color: $color-white;
          opacity: 0.7;
        }
      }
    }
    &__img {
      width: 100%;
      .default-img {
        height: 520px;
      }
    }
    &__slider {
      .content-container {
        img {
          object-fit: cover;
          object-position: center;
        }
        .image-file {
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
        }
      }
      .inst-video {
        .v-icon {
          display: none;
        }
        video {
          object-fit: cover;
        }
      }
      ::v-deep {
        .splide__pagination {
          bottom: inherit;
          top: -6px;
          z-index: 10;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
          grid-gap: 2px;
        }
        .splide__slide {
          height: 520px !important;
        }
        .splide__pagination__page {
          width: 100%;
          margin: 0;
          border-radius: 1px;
          height: 2px;
          padding: 0 !important;
          opacity: 0.6;
          background: $color-white;
          &.is-active {
            background: $color-white;
            opacity: 1;
          }
        }
      }
    }
  }
}

.crop-format {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: auto;
  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #1f2325;
    margin-right: 5px;
    position: absolute;
    left: 0;
    top: -10px;
    z-index: 10;
  }
  &__list {
    transform: scale(0);
    transition: all 0.3s ease;
    position: absolute;
    z-index: 100;
    top: 100%;
    right: 0;
  }
  &:hover {
    .crop-format {
      &__list {
        transform: scale(1);
        transition: all 0.3s ease;
      }
    }
  }
  ::v-deep {
    .v-input {
      padding: 0;
      margin: 0;
      &__slot {
        margin: 0;
        padding: 0;
        padding-top: 2px;
        border: none;
        background: transparent !important;
      }
      &--radio-group__input {
        display: flex;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        .v-radio {
          margin-bottom: 2px !important;
          width: 100%;
          .crop-format__item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            padding: 0 5px;
            background: $color-white;
            border: 1.5px solid rgba(78, 87, 120, 0.6);
            border-radius: 3px;
            width: 100%;
            min-width: 55px;
            .icon {
              width: 12px;
              height: 12px;
              margin-right: 4px;
              background: rgba(78, 87, 120, 0.1);
              border: 1.5px solid rgba(78, 87, 120, 0.6);
              box-sizing: border-box;
              border-radius: 2px;
              &.horizontal {
                height: 8px;
              }
              &.vertical {
                width: 8px;
              }
            }
            .name {
              font-weight: 500;
              font-size: 11px;
              line-height: 140%;
            }
          }
          &.v-item--active {
            display: none;
          }
        }
      }
      &--selection-controls__input {
        display: none;
      }
      .v-messages {
        display: none;
      }
    }
  }
  &__current {
    display: flex;
    .crop-format {
      &__item {
        height: 24px;
        border: 1.5px solid #7e5689;
        display: inline-flex;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        cursor: pointer;
        .icon {
          width: 12px;
          height: 12px;
          margin-right: 4px;
          background: rgba(78, 87, 120, 0.1);
          border: 1.5px solid rgba(78, 87, 120, 0.6);
          box-sizing: border-box;
          border-radius: 2px;
          &.horizontal {
            height: 8px;
          }
          &.vertical {
            width: 8px;
          }
        }
        .name {
          font-weight: 500;
          font-size: 11px;
          line-height: 140%;
        }
      }
    }
  }
}
</style>
