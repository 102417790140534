import TextForm from "@/models/TextForm";

import TextCreation from "@/components/dashboard/widgets/TextCreation";
import InstagramPost from "@/components/dashboard/widgets/InstagramPost";
import Communication from "@/components/dashboard/widgets/Communication";
import ClientInfo from "@/components/dashboard/widgets/ClientInfo";
import MediaLibrary from "@/components/dashboard/widgets/MediaLibrary";
import axios from "axios";

const readOnlyTabs = [
  {
    id: 0,
    header: "Client Info Widget",
    isActive: false,
    isShowCloseIcon: true,
    component: ClientInfo,
    icon: "icon-lient_info",
    color: "#B76988",
    background: "rgba(183, 105, 136, 0.2)",
    borderColor: "rgba(183, 105, 136, 0.1)",
  },
  {
    id: 1,
    header: "Media Library Widget",
    isActive: false,
    isShowCloseIcon: true,
    component: MediaLibrary,
    icon: "icon-media-library",
    color: "#515BD4",
    background: "rgba(81, 91, 212, 0.18)",
    borderColor: "rgba(81, 91, 212, 0.1)",
  },
];

const allTabs = [
  {
    id: 0,
    header: "Content Creation",
    isActive: true,
    isShowCloseIcon: false,
    component: TextCreation,
    icon: "icon-preview",
    color: "#7E5689",
    background: "rgba(130, 97, 139, 0.2)",
    borderColor: "rgba(130, 97, 139, 0.1)",
  },
  {
    id: 1,
    header: "Preview Widget",
    isActive: true,
    isShowCloseIcon: true,
    component: InstagramPost,
    icon: "icon-preview",
    color: "#DC7C73",
    background: "rgba(220, 124, 115, 0.2)",
    borderColor: "rgba(220, 124, 115, 0.1)",
  },
  {
    id: 2,
    header: "Communication Widget",
    isActive: false,
    isShowCloseIcon: true,
    component: Communication,
    icon: "icon-communication",
    color: "#4E5778",
    background: "rgba(78, 87, 120, 0.2)",
    borderColor: "rgba(78, 87, 120, 0.1)",
  },
  {
    id: 3,
    header: "Client Info Widget",
    isActive: false,
    isShowCloseIcon: true,
    component: ClientInfo,
    icon: "icon-lient_info",
    color: "#B76988",
    background: "rgba(183, 105, 136, 0.2)",
    borderColor: "rgba(183, 105, 136, 0.1)",
  },
  {
    id: 4,
    header: "Media Library Widget",
    isActive: true,
    isShowCloseIcon: true,
    component: MediaLibrary,
    icon: "icon-media-library",
    color: "#515BD4",
    background: "rgba(81, 91, 212, 0.18)",
    borderColor: "rgba(81, 91, 212, 0.1)",
  },
];

export default {
  namespaced: true,
  state: {
    tabs: [],
    activeTabs: [],
  },
  mutations: {
    setTabs(state, read_only = false) {
      if (state.tabs.length) return;
      state.tabs = read_only ? readOnlyTabs : allTabs;
      state.activeTabs = state.tabs.filter(
        (tab) => tab.id === 1 || tab.id === 4
      );
    },
    setActiveFlagById(state, id) {
      if (state.activeTabs.length >= 2) {
        state.tabs.forEach((item) => {
          item.id === state.activeTabs[0].id ? (item.isActive = false) : null;
        });
        state.activeTabs.shift();
      }
      state.tabs.forEach((item) => {
        item.id === id ? (item.isActive = true) : null;
      });
      state.activeTabs.push(state.tabs.filter((val) => val.id === id)[0]);
    },
    setInActiveFlagById(state, id) {
      state.tabs.forEach((item) => {
        item.id === id ? (item.isActive = false) : null;
      });
      state.activeTabs = state.activeTabs.filter((val) => val.id !== id);
    },
    setTextAfterApprove(state, textObject = new TextForm()) {
      state.currentText = textObject;
    },
  },
  actions: {
    async generateText({ commit }, params) {
      try {
        const { data } = await axios.post("/copywriter", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getDefaultData({ commit }, id) {
      try {
        const { data } = await axios.post("/copywriter", { customerId: id });
        return data;
      } catch (e) {
        throw e;
      }
    },
    async generateTextNew({ commit }, id) {
      try {
        const { data } = await axios.post("/copywriter", { imageId: id });
        return data;
      } catch (e) {
        throw e;
      }
    },
    async generateTags({ commit }, params) {
      try {
        const response = await axios.post("keyword", params);
        return response && response.data && response.data.text
          ? response.data.text.split(",")
          : [];
      } catch (e) {
        throw e;
      }
    },
    async updatePostCaption({ commit }, params) {
      try {
        await axios.put("caption", params);
      } catch (e) {
        throw e;
      }
    },
    async getCustomerCategories({ commit }, params = {}) {
      try {
        const { data } = await axios.post("/copywriter", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async fetchSameCategories({ commit }, params = {}) {
      try {
        const { data } = await axios.post("/copywriter", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async saveCategories({ commit }, params = {}) {
      try {
        const { data } = await axios.post("/copywriter", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getTabs(state) {
      return state.tabs;
    },
    getActiveTabs(state) {
      return [state.tabs[0], ...state.activeTabs];
    },
    getInactiveTabs(state) {
      return state.tabs.filter((val) => !val.isActive && val.isShowCloseIcon);
    },
  },
};
