var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar",class:{ narrow: _vm.isNarrowSidebar }},[_c('div',{staticClass:"sidebar__logo"},[(_vm.isNarrowSidebar)?_c('img',{staticClass:"sidebar__logo-img-short",attrs:{"src":require("@/assets/img/logo_short.svg")}}):_c('img',{staticClass:"sidebar__logo-img",attrs:{"src":require("@/assets/img/logo_full.svg"),"width":"200"}})]),_c('div',{staticClass:"sidebar__toggle"},[_c('button',{staticClass:"sidebar__toggle-btn",on:{"click":function($event){return _vm.toggleSidebar()}}},[_c('i',{staticClass:"icon-open-menu"})])]),_c('div',{staticClass:"sidebar__nav"},[_c('div',{staticClass:"sidebar__nav-title"},[_vm._v(" Welcome "+_vm._s(_vm.isNewUser ? "" : "back")+" "+_vm._s(_vm.getCurrentSmbCustomer.personal_name)+" ")]),_c('div',{staticClass:"sidebar__nav-item"},[_c('div',{staticClass:"sidebar__nav-link",class:{ 'router-link-active': _vm.$route.name === 'smb-feedback' },on:{"click":function($event){return _vm.approveContentHandler()}}},[(_vm.isLoadingReviewBtn)?_c('div',{staticClass:"button-loader"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('i',{staticClass:"icon-checkbox-icon",class:{ 'pending-state': _vm.getAmountPostsToReview }}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.getAmountPostsToReview ? "Pending items for review" : "No items for review"))]),(_vm.getAmountPostsToReview)?_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.getAmountPostsToReview))]):_vm._e()])]),_c('div',{staticClass:"sidebar__nav-item"},[_c('router-link',{staticClass:"sidebar__nav-link",attrs:{"to":{
          name: 'generate-content',
        }}},[_c('i',{staticClass:"icon-generate-content"}),_c('span',{staticClass:"text"},[_vm._v("Generate content")])])],1),_c('div',{staticClass:"sidebar__nav-item"},[_c(!_vm.getContentCalendarAmount ? 'span' : 'router-link',{tag:"router-link",staticClass:"sidebar__nav-link",class:{ disabled: !_vm.getContentCalendarAmount },attrs:{"to":{
          name: 'smb-content-calendar',
        }}},[_c('i',{staticClass:"icon-calendar-empty"}),_c('span',{staticClass:"text"},[_vm._v("Content calendar")])])],1)]),_c('div',{staticClass:"sidebar__footer"},[(false)?_c('div',{staticClass:"sidebar__footer-notification"},[_c('i',{staticClass:"icon-notification"}),_c('span',{staticClass:"text"},[_vm._v("Notifications")]),_c('span',{staticClass:"amount"},[_vm._v("16")])]):_vm._e(),_c('div',{staticClass:"sidebar__footer-profile"},[_c('div',{staticClass:"sidebar__nav-item"},[_c('router-link',{staticClass:"sidebar__nav-link",attrs:{"to":{
            name: 'profile-settings',
          }}},[_c('i',{staticClass:"icon-user-avatar"}),_c('span',{staticClass:"text"},[_vm._v("Profile and Settings")])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }