export default {
  methods: {
    isImage(name) {
      if (/images\.unsplash\.com\/photo/i.test(name)) return true;
      return /\.(jpeg|jpe|jpg|gif|png|webp|images\.unsplash\.com)/i.test(name);
    },
    getFileType(fileName) {
      if (!fileName) {
        return "";
      }
      const resultArray = fileName.split(".");
      if (resultArray.length > 1) {
        return resultArray[resultArray.length - 1];
      } else {
        return "";
      }
    },
    getFileName(link) {
      const resultArray = link.split("/");
      if (resultArray.length > 1) {
        return resultArray[resultArray.length - 1];
      } else {
        return "";
      }
    },
    async download(downloadLink) {
      const file = await fetch(downloadLink);
      const fileBlob = await file.blob();
      const fileURL = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileURL;
      link.download = this.getFileName(downloadLink);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getMediaUrl(mediaItem) {
      if (
        mediaItem &&
        mediaItem.mediaState &&
        mediaItem.mediaState.thumbnail &&
        mediaItem.mediaState.thumbnail == "done"
      ) {
        return mediaItem.thumbnailUrl;
      }
      return mediaItem.mediaUrl;
    },
    replaceFromString(string, searchItem, replaceTo = "", searchFlag = "ig") {
      return string.replace(new RegExp(searchItem, searchFlag), (match) => {
        return match.replace(match, replaceTo);
      });
    },
    parseStrDate(date, timeFormat = "hh:mm A", dateFormat = "MM/DD/YYYY") {
      if (date && date.trim() != "") {
        let parseDate = date.split("_");
        return {
          dateStr: this.$dayjs(parseDate[0]).format(dateFormat),
          timeStr: this.$dayjs(
            this.replaceFromString(
              this.$dayjs(parseDate[0]).format(dateFormat) + " " + parseDate[1],
              "-",
              ":"
            )
          ).format(timeFormat),
        };
      } else {
        return {
          dateStr: "",
          timeStr: "",
        };
      }
    },
    parseStringDate(scheduledForStr) {
      if (scheduledForStr.trim() !== "") {
        const parseDate = scheduledForStr.split("_");
        const date = this.isSmall
          ? this.$dayjs(parseDate[0]).format("MM/DD/YY")
          : this.$dayjs(parseDate[0]).format("MM/DD/YYYY");
        const time = this.$dayjs(
          this.replaceFromString(date + " " + parseDate[1], "-", ":")
        ).format("hh:mm A");
        return date + " " + time;
      } else {
        return "";
      }
    },
    parseStringDateForSmb(scheduledForStr) {
      if (scheduledForStr.trim() !== "") {
        const parseDate = scheduledForStr.split("_");
        const date = this.isSmall
          ? this.$dayjs(parseDate[0]).format("MM/DD/YY")
          : this.$dayjs(parseDate[0]).format("MM/DD/YYYY");
        const time = this.$dayjs(
          this.replaceFromString(date + " " + parseDate[1], "-", ":")
        ).format("hh:mma");
        return this.$dayjs(date).format("MM.DD.YYYY") + " " + time;
      } else {
        return "";
      }
    },
    async delighted() {
      !(function (e, t, r, n) {
        if (!e[n]) {
          for (
            var a = (e[n] = []),
              i = [
                "survey",
                "reset",
                "config",
                "init",
                "set",
                "get",
                "event",
                "identify",
                "track",
                "page",
                "screen",
                "group",
                "alias",
              ],
              s = 0;
            s < i.length;
            s++
          ) {
            var c = i[s];
            a[c] =
              a[c] ||
              (function (e) {
                return function () {
                  var t = Array.prototype.slice.call(arguments);
                  a.push([e, t]);
                };
              })(c);
          }
          a.SNIPPET_VERSION = "1.0.1";
          var o = t.createElement("script");
          (o.type = "text/javascript"),
            (o.async = !0),
            (o.src =
              "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" +
              r +
              "/" +
              n +
              ".js");
          var p = t.getElementsByTagName("script")[0];
          p.parentNode.insertBefore(o, p);
        }
      })(window, document, "WHk5m1YvupOYswet", "delighted");
    },
  },
};
