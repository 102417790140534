import axios from "axios";
import PostCount from "@/models/Post/PostCount";
import Post from "@/models/Post";

export default {
  namespaced: true,
  state: {
    amountPostsToReview: 0,
    contentCalendarAmount: 0,
    isRefreshPostList: false,
    unselectedSuggestedContent: [],
  },
  mutations: {
    setAmountPostsToReview(state, amount) {
      state.amountPostsToReview = amount;
    },
    setContentCalendarAmount(state, amount) {
      state.contentCalendarAmount = amount;
    },
    setRefreshPostList(state, val) {
      state.isRefreshPostList = val;
    },
    setUnselectedSuggestedContent(state, val) {
      state.unselectedSuggestedContent = val;
    },
  },
  actions: {
    async fetchAmountsPostsToReview({ commit }, { customerId = null }) {
      try {
        const { data } = await axios.get(`${customerId}/smpost?count=true`);
        const {
          "pre-feedback": preFeedback,
          archive,
          rejected,
        } = new PostCount({ ...data, ...{ archive: data.archived } });

        commit("setAmountPostsToReview", preFeedback);
        commit("setContentCalendarAmount", rejected + archive);
        return new PostCount(data);
      } catch (e) {
        throw e;
      }
    },
    async fetchSmbData(
      { commit },
      {
        routeName = "",
        customerId = null,
        limit = 100,
        smb = true,
        type = "post",
      }
    ) {
      try {
        const { data } = await axios.get(
          `${customerId}/smpost?customer_approve=${routeName}&limit=${limit}&type=${type}&by_smb=${smb}`
        );
        return data.map((o) => new Post(o));
      } catch (e) {
        throw e;
      }
    },
    async fetchSmbPosts(
      { commit },
      { routeName = "", customerId = null, limit = 100 }
    ) {
      try {
        const { data } = await axios.get(
          `${customerId}/smpost?customer_approve=${routeName}&limit=${limit}`
        );
        return data.map((o) => new Post(o));
      } catch (e) {
        throw e;
      }
    },
    async actionSmbPosts({ commit }, params = {}) {
      try {
        const { data } = await axios.put(`smpost`, params);
      } catch (e) {
        throw e;
      }
    },
    async changePostCaptionTone({ commit }, params = {}) {
      try {
        const { data } = await axios.post(`copywriter`, params);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getPost({ commit }, id) {
      try {
        const { data } = await axios.get(`smpost/${id}`);
        return data.map((o) => new Post(o));
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getAmountPostsToReview(state) {
      return state.amountPostsToReview;
    },
    getContentCalendarAmount(state) {
      return state.contentCalendarAmount;
    },
    getRefreshPostList(state) {
      return state.isRefreshPostList;
    },
  },
};
