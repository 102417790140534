import H from "@/utils/helper";
import BaseClass from "@/models/BaseClass";
import MediaField from "@/models/MediaFile/MediaField";
import Coupon from "@/models/Coupon";
import PostTooltip from "@/models/Post/PostTooltip";

const fields = [
  "autoGeneratedText",
  "createAt",
  "customerApprove",
  "customerComment",
  "customerId",
  "id",
  "free_speech",
  "employee_tag",
  { field: "customerRating", default: 0 },
  { field: "post_type", default: 1 },
  { field: "icon_type", default: 1 },
  { field: "caption_length", default: 550 },
  { field: "publishedPost", default: false },
  { field: "type", default: "post" },
  "reviewedAt",
  "text",
  "updatedAt",
  "textIdPrev",
  "scheduledFor",
  "image_id",
  "postStyle",
  { field: "preferred_site", default: null },
  { field: "labels", default: [] },
  { field: "textDislike", default: false },
  { field: "textLike", default: false },
  { field: "isLabelFalse", default: false },
  { field: "textId", default: null },
  { field: "employee", default: false },
  { field: "scheduledForStr", default: "" },
  { field: "generationType", default: "" },
  { field: "generation", default: "" },
  { field: "style", default: null },
  { field: "customSubject", default: null },
  { field: "contentTheme", default: null },
  { field: "contentSubtheme", default: null },
  { field: "contentSubject", default: null },
  { field: "contentMeta", default: null },
  { field: "isGenerable", default: null },
  { field: "calendarPostId", default: null },
  { field: "calendarItemId", default: null },
  { field: "contentCaptionId", default: null },
  { field: "smmId", default: null },
  { field: "isLastItem", default: false },
  //for extensions
  { field: "dm", default: false },
  { field: "baf", default: false },
  { field: "product", default: false },
  { field: "descriptive", default: false },
  { field: "template", default: null },
  { field: "captionHTML", default: null },
  { field: "metaCaptionHTML", default: null },
  { field: "sourceLink", default: null },
  { field: "rush", default: false },
  { field: "contentTemplateId", default: null },
  { field: "editedByCustomer", default: false },
  {
    field: "textBySmm",
    default: "",
  },
  { field: "tagsBySmm", default: [] },
  { field: "mediaUrl", default: [] },
  { field: "couponEnable", default: false },
  { field: "publishState", default: "" },
  { field: "contentCaptionIds", default: [] },
  { field: "isSkeletonLoading", default: false },
  { field: "isLoadingSaveForLater", default: false },
  { field: "isLoadingPublishNow", default: false },
  { field: "isLoadingSchedule", default: false },
  { field: "postTo", default: [] },
];

export default class Post extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);

    this.media =
      !H.isEmpty(obj) && obj.media && obj.media.length
        ? obj.media.map((i) => new MediaField(i))
        : [];

    this.fakeText = obj.text || null;
    this.fakeCaptionHtml = !obj.editedByCustomer ? obj.captionHTML : null;
    this.couponDetails =
      !H.isEmpty(obj) && obj.couponDetails && !H.isEmpty(obj.couponDetails)
        ? new Coupon(obj.couponDetails)
        : new Coupon();
    this.tooltip =
      !H.isEmpty(obj) && obj.tooltip && obj.tooltip.length
        ? obj.tooltip.map((tooltip) => new PostTooltip(tooltip))
        : [];
    this.tags =
      !H.isEmpty(obj) && obj.tags && obj.tags.length
        ? addHashToAllHashtagsWhereItNeeds(obj.tags)
        : [];
    this.videoSupported =
      obj.videoSupported &&
      obj.media.some((media) => {
        return media && media.template && !H.isVideo(media.mediaUrl);
      });
  }
}

const addHashToAllHashtagsWhereItNeeds = (tagsList) => {
  return tagsList.map((tag) => {
    return tag.trim().startsWith("#", 0) ? tag : "#" + tag;
  });
};
