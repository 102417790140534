import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import Dayjs from "vue-dayjs";
import VueSplide from "@splidejs/vue-splide";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import draggable from "vuedraggable";
import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
//import LogRocket from "logrocket";
import VueScrollTo from "vue-scrollto";
import "../src/assets/fonts/fonts.css";
import device from "vue-device-detector";
import infiniteScroll from "vue-infinite-scroll";
import VueMobileDetection from "vue-mobile-detection";
// import { isNewerVersionAvailable } from "@/utils/version";
import DatePicker from "vue2-datepicker";
import Sticky from "vue-sticky-directive";
import Vue2Editor from "vue2-editor";

import "vue2-datepicker/index.css";

require("dotenv").config();
//LogRocket.init("m2bv2o/smm-dashboard");

const VueUploadComponent = require("vue-upload-component");

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

Vue.config.productionTip = false;

Vue.use(Dayjs, {
  lang: "en",
  filters: {
    ago: "ago",
  },
  directives: {
    countdown: "countdown",
  },
});

Vue.prototype.$dayjs.extend(timezone);
Vue.prototype.$dayjs.extend(utc);
Vue.prototype.$dayjs.extend(weekday);

Vue.use(VueSplide);
Vue.use(VueScrollTo, {
  lazy: false,
  x: false,
  y: true,
});
Vue.use(infiniteScroll);
Vue.use(Cropper);
Vue.use(device);
Vue.use(VueMobileDetection);
Vue.use(Sticky);
Vue.use(Vue2Editor);
Vue.component("file-upload", VueUploadComponent);
Vue.component("draggable", draggable);
Vue.component("DatePicker", DatePicker);
Vue.component("vue-draggable-resizable", VueDraggableResizable);
new Vue({
  router,
  store,
  vuetify,
  axios,
  // created() {
  //   setInterval(async () => {
  //     const newVersionAvailable = await isNewerVersionAvailable();
  //     if (newVersionAvailable) {
  //       window.location.reload();
  //     }
  //   }, 3 * 60 * 1000); // check for new version every 3 minutes
  // },
  render: (h) => h(App),
}).$mount("#app");

if (process.env.VUE_APP_BUILD_VER != "LOCAL") {
  console.log("Pipeline number:", process.env.VUE_APP_BUILD_VER);
}
if (process.env.VUE_APP_BRANCH_NAME != "LOCAL") {
  console.log("Branch name:", process.env.VUE_APP_BRANCH_NAME);
}
if (process.env.VUE_APP_ENVIRONMENT != "LOCAL") {
  console.log("Environment is:", process.env.VUE_APP_ENVIRONMENT);
}
