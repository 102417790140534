<template>
  <div class="to-do">
    <div class="to-do__title">To-Do list</div>
    <div class="to-do__list">
      <div class="to-do__list-wrapper" v-if="tasksList.length">
        <div class="to-do__list-item" v-for="item in tasksList" :key="item.id">
          <v-checkbox
            @change="changeStatus(item)"
            v-model="item.isClosed"
            :label="item.text"
          ></v-checkbox>
        </div>
      </div>
      <div v-else class="to-do__blank">
        <div class="icon">
          <i class="icon-tasks-svgrepo-com"></i>
        </div>
        <div class="text">Nothing in To-Do list</div>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/global/Loader";

export default {
  name: "ToDo",
  components: { Loader },
  data: () => ({
    isLoading: false,
    tasksList: [],
  }),
  async created() {
    await this.fetchTasks();
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  methods: {
    ...mapActions("task", ["listTasks", "updateTask"]),
    async fetchTasks() {
      try {
        this.isLoading = true;
        let params = {
          customerId: this.getCurrentCustomer.id,
        };

        this.tasksList = await this.listTasks(params);
      } catch (e) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async changeStatus(item) {
      const params = {
        ...item,
        ...{
          isClosed: item.isClosed,
        },
      };
      const taskId = item.id;
      await this.updateTask({ taskId, params });
    },
  },
};
</script>

<style scoped lang="scss">
.to-do {
  width: 100%;
  &__title {
    width: 100%;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
    @media screen and (max-height: 950px) {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  &__list {
    width: 100%;
    background: #fff;
    &-wrapper {
      overflow-y: auto;
      max-height: 200px;
    }
    &-item {
      border-bottom: 1px solid #ccc;
      padding: 0 20px;
    }
  }
  &__blank {
    height: 200px;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(126, 89, 137, 1);
    background: rgba(126, 89, 137, 0.2);
    border-radius: 5px;
    border: 1px solid rgba(126, 89, 137, 0.4);
    .text {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
    }
    .icon {
      width: 42px;
      height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: #ffffff;
      font-size: 22px;
      margin-bottom: 20px;
      color: rgba(126, 89, 137, 1);
    }
  }
}
</style>
