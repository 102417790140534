import axios from "axios";
import Product from "@/models/Product";
import CalendarPost from "@/models/Post/CalendarPost";

export default {
  namespaced: true,
  state: {
    domainsName: [],
    subdomainsName: [],
    domainsTree: [],
  },
  mutations: {
    setDomainsTree(state, domainsTree) {
      state.domainsTree = domainsTree;
    },
    setDomainsName(state, domainsName) {
      state.domainsName = domainsName;
    },
    setSubdomainsName(state, subdomainsName) {
      state.subdomainsName = subdomainsName;
    },
  },

  actions: {
    async fetchDomainsTree({ commit }) {
      try {
        const { data } = await axios.get(`defaults/domains_tree`);

        commit("setDomainsTree", data);
        const domainsName = data.map((domain) => domain.name);
        commit("setDomainsName", [...domainsName]);
      } catch (e) {
        console.log("Error during fetchDomainsTree:", e);
        throw e;
      }
    },
    async fetchProducts({ commit }, params = {}) {
      try {
        const { data } = await axios.get(`product`, { params });
        return data && data.map((o) => new Product(o));
      } catch (e) {
        throw e;
      }
    },
    async saveTrialUserInfo({ commit }, params = {}) {
      try {
        await axios.post(
          `https://hook.us1.make.com/95dv74dchsn2q5a0rtjvc7r94jw8ou1v`,
          params
        );
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    getDomainsTree: (state) => state.domainsTree,
    getSubdomainsTree: (state) => (domain) =>
      state.domainsTree.filter((d) => d.name === domain)[0]["children"],
    getDomainsName: (state) => state.domainsName,
    getOptionalFeatures: (state) => (domain, subdomain) =>
      state.domainsTree
        .filter((d) => d.name === domain)[0]
        ["children"].filter((sd) => sd.name === subdomain)
        .map((sd) =>
          sd["optional_features"] ? sd["optional_features"] : []
        )[0],
    getCategories: (state) => (domain, subdomain) =>
      state.domainsTree
        .filter((d) => d.name === domain)[0]
        ["children"].filter((sd) => sd.name === subdomain)
        .map((sd) => (sd["categories"] ? sd["categories"] : []))[0],
    getPersonaParameters: (state) => (domain, subdomain) =>
      state.domainsTree
        .filter((d) => d.name === domain)[0]
        ["children"].filter((sd) => sd.name === subdomain)
        .map((sd) =>
          sd["optional_features"] ? sd["persona_parameters"] : []
        )[0],
    getPostRecipeDefault: (state) => (domain, subdomain) =>
      state.domainsTree
        .filter((d) => d.name === domain)[0]
        ["children"].filter((sd) => sd.name === subdomain)
        .map((sd) =>
          sd["optional_features"] ? sd["post_recipe_default"] : []
        )[0],
  },
};
