import BaseClass from "@/models/BaseClass";
import H from "@/utils/helper";
import CropDetail from "@/models/MediaFile/CropDetail";

const fields = [
  "editedMediaUrl", //this is a file with context of a specific post
  "editedMediaUrlReduced", //created along with creation of editedMediaUrl and is reduced in size
  "mediaId",
  "mediaUrl", //points to a file after the media-handler processed the original media
  "mediaUrlReduced", //points to a file after the media-handler processed the original media ais reduced in size
  "thumbnailUrl", //created along with creation of mediaUrl by the media-handler
  // "postMediaUrl", //a temporary file as an input for the media-hander to generate the editedMediaUrl
  { field: "template", default: null },
  { field: "ratio", default: "square" },
  { field: "toPostProcess", default: false },
  { field: "toSkipCropping", default: false },
  { field: "type", default: "" },
  { field: "isLicensed", default: null },
];

export default class MediaField extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);

    this.cropDetails =
      !H.isEmpty(obj) && obj.cropDetails
        ? new CropDetail(obj.cropDetails)
        : null;

    this.type =
      !H.isEmpty(obj) && obj.mediaUrl
        ? H.getMediaType(obj.mediaUrl)
        : undefined;
  }
}
