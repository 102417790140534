export default {
  namespaced: true,
  state: {
    sidebarIsMin: true,
  },
  mutations: {
    setSidebarIsMin(state) {
      state.sidebarIsMin = !state.sidebarIsMin;
    },
  },
  actions: {
    sidebarSize({ commit }) {
      commit("setSidebarIsMin");
    },
  },
  getters: {
    getSidebarIsMin(state) {
      return state.sidebarIsMin;
    },
  },
};
