<template>
  <div class="preview">
    <div class="preview__title">Reality vs Inspiration</div>
    <div class="preview__list">
      <div class="item" v-if="clientItems.length">
        <div class="item__top">
          <div class="item__user">
            <div class="image">
              <img
                v-if="getCurrentCustomer.picUrl"
                :src="getCurrentCustomer.picUrl"
                :alt="getCurrentCustomer.name"
              />
              <img
                v-else
                src="@/assets/default-profile-picture.png"
                :alt="getCurrentCustomer.name"
              />
            </div>
            <a
              :href="
                getCurrentCustomer.ig_page
                  ? getCurrentCustomer.ig_page
                  : getCurrentCustomer.fb_page
              "
              target="_blank"
              class="name"
              >{{ getCurrentCustomer.name }}</a
            >
          </div>
        </div>
        <div class="item__list">
          <div class="cell" v-for="(item, index) in clientItems" :key="index">
            <img
              v-if="
                isImage(
                  item.mediaUrlReduced ? item.mediaUrlReduced : item.mediaUrl
                )
              "
              :src="item.mediaUrlReduced ? item.mediaUrlReduced : item.mediaUrl"
            />
            <video
              v-else
              :src="item.mediaUrlReduced ? item.mediaUrlReduced : item.mediaUrl"
            />
          </div>
        </div>
      </div>
      <template v-else>
        <div class="item">
          <div class="placeholder">
            <div class="icon">
              <i class="icon-instagram-logo-fill-svgrepo-com"></i>
            </div>
            <div class="text">
              No {{ socialPlatformType }} account connected
            </div>
          </div>
        </div>
      </template>
      <template v-if="inspirationItems.length">
        <div
          class="item"
          v-for="(item, index) in inspirationItems"
          :key="index"
        >
          <div class="item__top">
            <div class="item__user">
              <div class="image">
                <img v-if="index === 0" src="@/assets/img/icons/One.png" />
                <img v-if="index === 1" src="@/assets/img/icons/Two.png" />
                <img v-if="index === 2" src="@/assets/img/icons/Three.png" />
              </div>
              <a
                target="_blank"
                v-if="item.link"
                :href="item.link"
                class="name"
                >{{ item.link.replace(/\/$/, "").split("/").pop() }}</a
              >
            </div>
          </div>
          <div class="item__list">
            <div class="cell" v-for="(item, index) in item.items" :key="index">
              <img
                v-if="item.items[0] && item.items[0].mediaUrl"
                :src="
                  item.items[0].externalMediaType === 'VIDEO'
                    ? item.items[0].thumbnailUrl
                    : item.items[0].mediaUrl
                "
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="item">
          <div class="placeholder">
            <div class="icon">
              <i class="icon-instagram-logo-fill-svgrepo-com"></i>
            </div>
            <div class="text">No inspiration IG profile</div>
          </div>
        </div>
        <div class="item">
          <div class="placeholder">
            <div class="icon">
              <i class="icon-instagram-logo-fill-svgrepo-com"></i>
            </div>
            <div class="text">No inspiration IG profile</div>
          </div>
        </div>
      </template>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import helper from "@/mixins/helper";
import Loader from "@/components/global/Loader";

export default {
  name: "Preview",
  components: { Loader },
  data: () => ({
    widgetPanelIsShow: false,
    isLoading: false,
    clientItems: [],
    inspirationItems: [],
  }),
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    socialPlatformType() {
      return this.getCurrentCustomer.social_platforms.length > 1
        ? "IG"
        : this.getCurrentCustomer.social_platforms[0] === "facebook"
        ? "FB"
        : "IG";
    },
  },
  async created() {
    await this.getMedia();
    await this.getInspiration();
  },
  mixins: [helper],
  methods: {
    ...mapActions("mediaLibraryWidget", [
      "fetchMediaData",
      "fetchScheduledPosts",
      "fetchInspirationData",
    ]),
    async getMedia() {
      try {
        this.isLoading = true;
        const params = {
          customerId: this.getCurrentCustomer.id,
          type: 3,
          socialPlatform:
            this.getCurrentCustomer.social_platforms.length > 1
              ? "instagram"
              : this.getCurrentCustomer.social_platforms[0],
          sortBy: 1,
          limit: "9",
        };
        const { items } = await this.fetchMediaData(params);
        this.clientItems = items;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async getInspiration() {
      try {
        this.isLoading = true;
        const params = {
          customerId: this.getCurrentCustomer.id,
          type: 8,
          socialPlatform: "instagram",
          sortBy: 1,
          limit: "9",
        };
        const data = await this.fetchInspirationData(params);
        if (data.length) {
          this.inspirationItems = data.filter(
            (i) => i.items.length && i.items.length === 9
          );
        }
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.preview {
  width: 100%;
  &__title {
    width: 100%;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
    @media screen and (max-height: 950px) {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    .item {
      display: flex;
      flex-direction: column;
      &__top {
        display: inline-flex;
        align-items: center;
        margin-bottom: 20px;
        @media screen and (max-height: 950px) {
          margin-bottom: 8px;
        }
      }
      &__user {
        display: inline-flex;
        align-items: center;
        .image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid #fff;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .name {
          font-weight: 500;
          font-size: 14px;
        }
      }
      &__list {
        display: grid;
        grid-template-columns: repeat(3, 120px);
        @media screen and (max-width: 1500px) {
          grid-template-columns: repeat(3, 100px);
        }
        grid-gap: 10px;
        @media screen and (max-height: 870px) {
          grid-template-columns: repeat(3, 80px);
        }
        @media screen and (max-height: 745px) {
          grid-gap: 5px;
        }
        .cell {
          height: 120px;
          @media screen and (max-width: 1500px) {
            height: 100px;
          }
          @media screen and (max-height: 870px) {
            height: 80px;
          }
          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .placeholder {
        min-height: 400px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(126, 89, 137, 0.2);
        border-radius: 5px;
        border: 1px solid rgba(126, 89, 137, 0.4);
        @media screen and (max-height: 870px) {
          min-height: 300px;
        }
        .text {
          font-weight: 600;
          font-size: 14px;
          line-height: 130%;
          text-align: center;
        }
        .icon {
          width: 42px;
          height: 45px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background: #ffffff;
          font-size: 22px;
          margin-bottom: 20px;
          color: rgba(126, 89, 137, 1);
        }
      }
    }
  }
}
</style>
