<template>
  <div class="client-info-container">
    <div class="user">
      <div class="user__photo">
        <img
          v-if="getCurrentCustomer.picUrl"
          :src="getCurrentCustomer.picUrl"
        />
        <img
          v-else
          src="@/assets/img/icons/default-user.svg"
          :alt="getCurrentCustomer.name"
        />
      </div>
      <div class="user__info">
        <div>
          {{ getCurrentCustomer.personal_name }}
          <span v-if="!getCurrentUser.read_only">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click="modifyCustomer()">
                  mdi-pencil-outline
                </v-icon>
              </template>
              <span style="font-size: 12px">Modify client</span>
            </v-tooltip>
          </span>
        </div>
        <div class="user__contacts">
          <div class="user__contacts-row">
            <i class="icon-email"></i>
            <span>{{ getCurrentCustomer.mail }}</span>
          </div>
          <div class="user__contacts-row">
            <i class="icon-phone"></i>
            <span>{{ getCurrentCustomer.primary_phone }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-item">
      <div class="container-item__title">Business info</div>
      <div class="company-info">
        <div class="row" v-if="currentPlan">
          <div class="title">Current Plan</div>
          <div class="data">{{ currentPlan }}</div>
        </div>
        <div class="row">
          <div class="title">Address</div>
          <div class="data">
            {{
              getCurrentCustomer.address_street
                ? getCurrentCustomer.address_street + ","
                : ""
            }}
            {{
              getCurrentCustomer.address_city
                ? getCurrentCustomer.address_city + ","
                : ""
            }}
            {{
              getCurrentCustomer.address_state
                ? getCurrentCustomer.address_state + ","
                : ""
            }}
            {{
              getCurrentCustomer.address_country
                ? getCurrentCustomer.address_country + "."
                : ""
            }}
          </div>
        </div>
        <div class="row">
          <div class="title">Opening hours</div>
          <!--          <div class="data">
            <div class="time">Mon - Fri, <span>8 am - 5 pm</span></div>
            <div class="time">Sat - Sun, <span>10 am - 9 pm</span></div>
          </div>-->
        </div>
        <div class="row">
          <div class="title">Your local time</div>
          <div class="data">{{ $dayjs().format("hh:mm a") }}</div>
        </div>
        <div class="row" v-if="currentUserTimeZoneName">
          <div class="title">Customer time</div>
          <div class="data">
            {{ $dayjs().tz(currentUserTimeZoneName).format("hh:mm a") }}
          </div>
        </div>
        <div class="row">
          <div class="title">Tone</div>
          <!--          <div class="data">Friendly</div>-->
        </div>
        <div class="row">
          <div class="title">Pricing range</div>
          <!--          <div class="data pricing">$$$</div>-->
        </div>
        <div class="row">
          <div class="title">Brief</div>
          <div class="data brief">
            <button class="btn violet" @click="briefIsShow = true">
              Show brief
            </button>
          </div>
        </div>
      </div>
    </div>
    <v-row>
      <v-col>
        <div v-if="isCustomerHasSocialLinks" class="container-item">
          <div class="container-item__title">Social links</div>
          <div class="social">
            <a
              :href="getCurrentCustomer.fb_page"
              class="social__item"
              v-if="
                getCurrentCustomer.fb_page &&
                getCurrentCustomer.fb_page.trim() !== ''
              "
              target="_blank"
            >
              <img src="@/assets/img/icons/facebook_f_logo.svg" alt="" />
            </a>
            <a
              :href="getCurrentCustomer.ig_page"
              class="social__item"
              v-if="
                getCurrentCustomer.ig_page &&
                getCurrentCustomer.ig_page.trim() !== ''
              "
              target="_blank"
            >
              <img src="@/assets/img/icons/Instagram_logo.svg" alt="" />
            </a>
            <!--        <a href="#" class="social__item">
          <img src="@/assets/img/icons/linkedIn.svg" alt="" />
        </a>
        <a href="#" class="social__item">
          <img src="@/assets/img/icons/twitter.svg" alt="" />
        </a>-->
          </div>
        </div>
      </v-col>
      <v-col>
        <div
          v-if="
            getCurrentCustomer.inspiration_links &&
            getCurrentCustomer.inspiration_links.length
          "
          class="container-item"
        >
          <div class="container-item__title">Inspiration Links</div>
          <div class="inspiration">
            <span
              class="inspiration__item"
              v-for="(
                inspirationLink, index
              ) in getCurrentCustomer.inspiration_links.slice(0, 4)"
              :key="index"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    small
                    :href="inspirationLink"
                    target="_blank"
                    v-on="on"
                  >
                    <img
                      class="inspiration__item"
                      v-if="index === 3"
                      src="@/assets/img/icons/Four.png"
                      alt=""
                    />
                    <img
                      class="inspiration__item"
                      v-if="index === 2"
                      src="@/assets/img/icons/Three.png"
                      alt=""
                    />
                    <img
                      class="inspiration__item"
                      v-if="index === 1"
                      src="@/assets/img/icons/Two.png"
                      alt=""
                    />
                    <img
                      class="inspiration__item"
                      v-if="index === 0"
                      src="@/assets/img/icons/One.png"
                      alt=""
                    />
                  </v-btn>
                </template>
                <span style="font-size: 12px"
                  >Open in new tab: {{ inspirationLink }}</span
                >
              </v-tooltip>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- <v-col>
        <div v-if="getCurrentCustomer.files_sync" class="container-item"> -->
    <!-- <div class="container-item__title">zzz</div> -->

    <FilesSync
      class="container-item"
      :initialFilesSync="getCurrentCustomer.files_sync"
      :customerId="getCurrentCustomer.id"
    />
    <!-- </div>
      </v-col> -->
    <!-- </v-row> -->
    <!--    <div class="container-item">
      <div class="container-item__title">Business info</div>
      <div class="strategy">
        <div class="strategy__row">
          <div class="strategy__name">Goal</div>
          <div class="strategy__data">Increase visibility</div>
        </div>
        <div class="strategy__row">
          <div class="strategy__name">Target audience</div>
          <div class="strategy__data">
            Females, Age 20 - 35, Live in NYC Metro Area, Ree-spirits, College
            graduates
          </div>
        </div>
        <div class="strategy__row">
          <div class="strategy__name">Audience`s behaviour</div>
          <div class="strategy__data">
            Like Art. Likes Banana Republic brand.
          </div>
        </div>
        <div class="strategy__row">
          <div class="strategy__name">Differentiation</div>
          <div class="strategy__data">
            We offer the best service in the area.
          </div>
        </div>
      </div>
    </div>-->
    <!--    <div class="container-item">
      <div class="container-item__title">Schedule</div>
      <div class="schedule">
        <div class="table">
          <div class="table__header-row">
            <div class="table__header-cell"></div>
            <div class="table__header-cell">Sun</div>
            <div class="table__header-cell">Mon</div>
            <div class="table__header-cell">Tue</div>
            <div class="table__header-cell">Wed</div>
            <div class="table__header-cell">Thu</div>
            <div class="table__header-cell">Fri</div>
            <div class="table__header-cell">Sat</div>
          </div>
          <div class="table__body-row">
            <div class="cell cell__name">
              <div class="icon">
                <img src="@/assets/img/icons/Instagram_logo.svg" alt="" />
              </div>
              <div class="name">Post</div>
            </div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell"></div>
            <div class="cell">
              <div class="item reject">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
              <div class="item pending">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell"></div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
              <div class="item pending">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell"></div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
          </div>
          <div class="table__body-row">
            <div class="cell cell__name">
              <div class="icon">
                <img src="@/assets/img/icons/Instagram_logo.svg" alt="" />
              </div>
              <div class="name">Stories</div>
            </div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">7</div>
                <div class="item__time">12 AM</div>
              </div>
            </div>
            <div class="cell"></div>
            <div class="cell"></div>
            <div class="cell"></div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
              <div class="item pending">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell"></div>
          </div>
          <div class="table__body-row">
            <div class="cell cell__name">
              <div class="icon">
                <img src="@/assets/img/icons/facebook_f_logo.svg" alt="" />
              </div>
              <div class="name">Post</div>
            </div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell"></div>
            <div class="cell"></div>
            <div class="cell">
              <div class="item reject">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
              <div class="item pending">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
              <div class="item pending">
                <div class="item__amount">1</div>
                <div class="item__time">4 AM</div>
              </div>
            </div>
            <div class="cell"></div>
            <div class="cell"></div>
          </div>
          <div class="table__body-row">
            <div class="cell cell__name">
              <div class="icon">
                <img src="@/assets/img/icons/facebook_f_logo.svg" alt="" />
              </div>
              <div class="name">Stories</div>
            </div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell"></div>
            <div class="cell"></div>
            <div class="cell"></div>
            <div class="cell">
              <div class="item approve">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
              <div class="item pending">
                <div class="item__amount">2</div>
                <div class="item__time">6 AM</div>
              </div>
            </div>
            <div class="cell"></div>
          </div>
        </div>
      </div>
    </div>-->
    <!--    <div class="container-item">
      <div class="container-item__title">Intro meeting summary</div>
      <div class="summary">
        <div class="summary__text" :class="{ active: isShow }">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat.
          </p>
          <ul>
            <li>Duis aute irure dolor</li>
            <li>In reprehenderit in voluptate</li>
            <li>Velit esse cillum dolore eu fugiat</li>
          </ul>
          <p>
            Nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
            in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
        <div class="summary__toggle">
          <span class="toggle-btn" @click="isShow = !isShow">
            <template v-if="isShow">Show less</template>
            <template v-else>Show more</template>
          </span>
        </div>
      </div>
    </div>-->

    <div class="container-item notes" id="notes">
      <div class="notes__header">
        <div class="container-item__title">Notes</div>
        <span class="notes__btn" @click="toggleNote()">
          <template v-if="isShowNewNote"
            ><i class="icon-close"></i><i class="text">Close</i></template
          >
          <template v-else
            ><i class="icon-close add"></i
            ><i class="text">Add note</i></template
          >
        </span>
      </div>
      <div class="notes__body">
        <div class="add-note" v-if="isShowNewNote">
          <Loader v-if="isLoading" />
          <div class="notes__list">
            <div
              class="notes__list-item"
              v-for="(note, index) in getCurrentCustomer.note"
              :key="index"
            >
              <ClientNote @editNote="edit" :itemData="note" />
            </div>
          </div>

          <div class="add-note__title">
            {{ editFile ? "Edit note" : "New note" }}
          </div>
          <NoteRichTextEditor
            :noteMessage="noteMessage"
            @updateNote="noteMessage = $event"
            :attachFiles="attachFiles"
            :isEditFile="!!editFile"
            @cancelEdit="cancelEditHandler"
            @uploadFile="uploadFileHandler"
            @removeFile="removeFile($event)"
            @save="saveNote"
          />
        </div>
      </div>
    </div>
    <Brief @hideBrief="hideBrief()" v-if="briefIsShow" />
  </div>
</template>

<script>
import helper from "@/mixins/helper";
import { mapGetters, mapActions, mapMutations } from "vuex";
import ClientNote from "@/components/global/ClientNote";
import FilesSync from "@/components/global/FilesSync";
import Loader from "@/components/global/Loader";
import { TARIFF_PLAN_LIST, TIME_ZONE_LIST } from "@/constants/customer";
import NoteRichTextEditor from "@/components/global/NoteRichTextEditor";
import Brief from "@/components/brief/Index";
import { S3_FILES_PATH_NOTES } from "@/constants/s3files";

export default {
  name: "ClientInfo",
  components: { ClientNote, Loader, NoteRichTextEditor, FilesSync, Brief },
  data: () => ({
    isShow: false,
    briefIsShow: false,
    isShowNewNote: true,
    attachFiles: [],
    noteMessage: "",
    editFile: null,
    tariffPlan: TARIFF_PLAN_LIST,
    timeZoneList: TIME_ZONE_LIST,
    isLoading: false,
  }),
  mixins: [helper],
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    currentPlan() {
      return this.getCurrentCustomer && this.getCurrentCustomer.plan
        ? this.tariffPlan.find((i) => i.id === this.getCurrentCustomer.plan)
            .name
        : null;
    },
    isCustomerHasSocialLinks() {
      return (
        (this.getCurrentCustomer.fb_page &&
          this.getCurrentCustomer.fb_page.trim() !== "") ||
        (this.getCurrentCustomer.ig_page &&
          this.getCurrentCustomer.ig_page.trim() !== "")
      );
    },
    currentUserTimeZoneName() {
      const result =
        this.getCurrentCustomer.time_zone.trim() !== ""
          ? this.timeZoneList.find(
              (i) => i.code === this.getCurrentCustomer.time_zone
            )
          : null;
      return result ? result.name : null;
    },
  },
  created() {
    this.getCustomerInfo();
  },
  methods: {
    ...mapActions("filesController", ["getUrlForSendFile", "uploadFile"]),
    ...mapActions("clientSelection", ["sendNote", "fetchCustomerInfo"]),
    ...mapMutations("clientSelection", ["setCurrentCustomer"]),
    hideBrief() {
      this.briefIsShow = false;
    },
    modifyCustomer() {
      let routeData = this.$router.resolve({
        name: "modify-client",
        query: { customerId: this.getCurrentCustomer.id },
      });
      window.open(routeData.href, "_blank");
    },
    uploadFileHandler(files) {
      this.attachFiles = [...this.attachFiles, ...files];
    },
    removeFile(file) {
      this.attachFiles = this.attachFiles.filter((i) => i.name !== file.name);
    },
    async saveNote() {
      this.isLoading = true;
      let params = {
        note: true,
        comments: this.noteMessage,
        attached_files: [],
        smmId: this.getCurrentUser.id,
      };
      if (this.attachFiles.length) {
        for (const file of this.attachFiles) {
          if (!file.isEdit) {
            params.attached_files.push(await this.uploadFiles(file));
          } else {
            params.attached_files.push(file.url);
          }
        }
      }
      if (this.editFile) {
        params.note_id = this.editFile.id;
      }
      await this.sendNote({
        customerId: this.getCurrentCustomer.id,
        params: params,
      });
      await this.getCustomerInfo();
      params = {};
      this.noteMessage = "";
      this.attachFiles = [];
      this.editFile = null;
      this.isLoading = false;
    },
    async getCustomerInfo() {
      const data = await this.fetchCustomerInfo(this.getCurrentCustomer.id);
      data.note.sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1));
      this.setCurrentCustomer(data);
    },
    async uploadFiles(file) {
      const { url, mediaId, fields } = await this.getUrlForSendFile({
        file,
        customerId: this.getCurrentCustomer.id,
        type: "notes",
        isTag: false,
        imageId: null,
      });
      await this.uploadFile({ url, file: file.file, fields });
      return `${url}${fields.key}`;
    },
    edit(e) {
      this.isShowNewNote = true;
      this.editFile = e;
      this.noteMessage = e.comment;
      this.attachFiles = e.files.map((i) => {
        return { name: this.getFileName(i), isEdit: true, url: i };
      });
      this.goto();
    },
    cancelEditHandler() {
      this.editFile = null;
    },
    toggleNote() {
      this.isShowNewNote = !this.isShowNewNote;
    },
    goto() {
      document.getElementById("notes").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.client-info-container {
  width: 100%;
  padding: 0 24px;
  overflow-y: auto;
  max-height: calc(100vh - 95px);
  min-height: calc(100vh - 95px);
  .user {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 8px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgb(47, 72, 88, 0.1);
    &__photo {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 24px;
      flex-shrink: 0;
      margin-top: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__info {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &__name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 8px;
    }
    &__contacts {
      width: 100%;
      padding: 8px 12px;
      background: rgba(78, 87, 120, 0.05);
      border: 1px solid rgba(47, 72, 88, 0.05);
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      &-row {
        display: inline-flex;
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          font-weight: normal;
          font-size: 12px;
          line-height: 140%;
        }
        i {
          font-size: 12px;
          line-height: 140%;
          margin-right: 10px;
        }
      }
    }
  }
  .container-item {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgb(47, 72, 88, 0.1);
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 12px;
    }
  }
  .company-info {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      margin: 0 0 8px 0;
      align-items: baseline;
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        font-weight: 500;
        font-size: 12px !important;
        line-height: 140%;
        color: #4e5778;
        width: 90px;
        margin-right: 20px;
      }
      .data {
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        &.pricing {
          color: $color-green;
        }
      }
    }
  }
  .social {
    width: 100%;
    display: flex;
    align-items: center;
    &__item {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1px solid #eeeff0;
      box-sizing: border-box;
      box-shadow: 0px 2px 12px rgba(31, 35, 37, 0.06);
      border-radius: 50%;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .strategy {
    &__row {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__name {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      margin-bottom: 4px;
      color: #4e5778;
    }
    &__data {
      font-weight: normal;
      font-size: 14px;
      line-height: 160%;
      color: #1f2325;
    }
  }
  .inspiration {
    width: 100%;
    display: flex;
    align-items: center;
    &__item {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1px solid #eeeff0;
      box-sizing: border-box;
      box-shadow: 0px 2px 12px rgba(31, 35, 37, 0.06);
      border-radius: 50%;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .schedule {
    width: 100%;
    .table {
      width: 100%;
      display: grid;
      border: 1px solid rgba(47, 72, 88, 0.1);
      box-sizing: border-box;
      box-shadow: 0 2px 12px rgba(31, 35, 37, 0.06);
      border-radius: 4px;
      background: #fff;
      &__header-row {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        border-radius: 3px 3px 0 0;
        background: rgb(81, 91, 212, 0.1);
      }
      &__header-cell {
        height: 29px;
        padding: 5px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 130%;
        color: #4e5778;
        border-right: 1px solid rgba(47, 72, 88, 0.1);
        &:last-child {
          border-right: none;
        }
      }
      &__body-row {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        border-top: 1px solid rgba(47, 72, 88, 0.1);
        .cell {
          border-right: 1px solid rgba(47, 72, 88, 0.1);
          padding: 1px;
          display: inline-flex;
          flex-direction: column;
          &:last-child {
            border-right: none;
          }
          &__name {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .icon {
              display: inline-flex;
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-bottom: 2px;
              }
            }
            .name {
              text-align: center;
              font-weight: 500;
              font-size: 10px;
              line-height: 140%;
              color: #1f2325;
            }
          }
          .item {
            width: 100%;
            padding: 0 4px;
            display: inline-flex;
            flex-direction: column;
            margin-bottom: 1px;
            border-radius: 2px;
            &:last-child {
              margin-bottom: 0;
            }
            &__amount {
              width: 100%;
              font-weight: 600;
              font-size: 8px;
              line-height: 140%;
              letter-spacing: 0.01em;
              color: #4e5778;
            }
            &__time {
              font-weight: 600;
              font-size: 8px;
              line-height: 140%;
              text-align: right;
              letter-spacing: 0.01em;
              color: #4e5778;
            }
            &.approve {
              background: #b8e8d4;
            }
            &.pending {
              background: #f9dcbe;
            }
            &.reject {
              background: #f4cbdb;
            }
          }
        }
      }
    }
  }
  .summary {
    display: flex;
    flex-direction: column;
    &__text {
      height: 100px;
      overflow: hidden;
      &.active {
        height: auto;
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        color: #1f2325;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        li {
          font-weight: normal;
          font-size: 14px;
          line-height: 160%;
          color: #1f2325;
          margin-bottom: 3px;
        }
      }
    }
    &__toggle {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 5px;
      .toggle-btn {
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        text-decoration-line: underline;
        color: #7e5689;
        cursor: pointer;
      }
    }
  }
  .notes {
    width: 100%;
    &__header {
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .container-item__title {
        margin-bottom: 0;
      }
    }
    &__btn {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      i {
        font-style: normal;
        &.icon-close {
          font-size: 9px;
          color: #7e5689;
          position: relative;
          top: 1px;
          &.add {
            transform: rotate(45deg);
            font-size: 7px;
          }
        }
        &.text {
          text-decoration: underline;
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          text-align: right;
          letter-spacing: 0.01em;
          color: #7e5689;
          margin-left: 4px;
        }
      }
    }
    .add-note {
      position: relative;
      margin-bottom: 12px;
      &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #1f2325;
        margin-bottom: 4px;
      }
      &__input {
        position: relative;
        .quillWrapper {
          ::v-deep {
            .ql-snow .ql-tooltip,
            .ql-tooltip.ql-editing {
              left: 50% !important;
              transform: translateX(-50%) !important;
            }
          }
        }
      }
      .attach-list {
        display: inline-flex;
        flex-wrap: wrap;
        &__item {
          margin-top: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: $color-violet;
          padding: 0 7px;
          border-radius: 4px;
          height: 20px;
          margin-right: 4px;
          .file-name {
            font-size: 12px;
            color: $color-white;
            font-weight: 500;
            width: 100%;
            max-width: 210px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .file-remove {
            margin-left: 8px;
            font-size: 9px;
            color: $color-white;
            cursor: pointer;
          }
        }
      }
      ::v-deep {
        .v-input__slot {
          box-shadow: none;
          background: #fafafa;
          border: 1px solid #c7c9ca;
          box-sizing: border-box;
          border-radius: 4px;
          padding-bottom: 40px;
          textarea {
            resize: none;
          }
        }
      }
      .controls {
        position: absolute;
        bottom: 1px;
        right: 1px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        &__item {
          margin-left: 15px;
        }
        .save {
          background: #7e5689;
          height: 36px;
          padding: 0 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          border-radius: 3px 0 3px 0;
          color: #fff;
        }
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      &-item {
        margin-bottom: 8px;
      }
    }
  }
}
.brief {
  .btn {
    height: 30px;
    font-size: 12px;
  }
}
</style>
