<template>
  <div class="available-templates">
    <div class="available-templates__list">
      <div class="available-templates__list-wrapper">
        <div
          class="item"
          :class="{
            selected: selectedTemplate.id === template.id,
            'not-match': template.ok !== 'ok',
          }"
          v-for="template in templatesList"
          :key="template.id"
          @click="selectTemplate(template)"
        >
          <div class="item__img">
            <img
              v-if="template.preview_url"
              :src="template.preview_url"
              alt=""
            />
            <div class="item__name" v-if="template.ok !== 'ok'">
              {{ template.ok }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="available-templates__buttons">
      <button class="btn violet" @click="$emit('close')">Cancel</button>
      <button
        class="btn violet"
        :class="{
          disabled:
            !selectedTemplate.id || selectedTemplate.id === currentTemplate.id,
        }"
        :disabled="
          !selectedTemplate.id || selectedTemplate.id === currentTemplate.id
        "
        @click="$emit('newTemplate', selectedTemplate)"
      >
        Approve
      </button>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import Template from "@/models/Template";
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/global/Loader";
import Post from "@/models/Post";

export default {
  name: "TemplatesList",
  data: () => ({
    selectedTemplate: new Template(),
    isLoading: false,
    templatesList: [],
  }),
  components: { Loader },
  props: {
    currentTemplate: {
      type: Object,
      default: () => {},
    },
    post: {
      type: Object,
      default: new Post(),
    },
    isEditTemplate: {
      type: Boolean,
      default: true,
    },
    isMediaTemplate: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("templates", ["getSimilarTemplatesList"]),
  },
  async created() {
    if (!this.getSimilarTemplatesList) {
      await this.getSimilarTemplates();
    } else {
      this.templatesList = this.getSimilarTemplatesList;
    }
    this.selectedTemplate = this.currentTemplate;
  },
  methods: {
    ...mapActions("templates", ["getTemplatesInfo", "createImageFromTemplate"]),

    selectTemplate(template) {
      if (template.ok === "ok") {
        this.selectedTemplate = template;
      }
    },
    async getSimilarTemplates() {
      try {
        this.isLoading = true;
        let params = {
          calendarItemId: this.post.calendarItemId,
          customerId: this.getCurrentCustomer.id,
        };
        let { similarTemplates } = await this.getTemplatesInfo(params);
        this.templatesList = similarTemplates;
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.available-templates {
  display: flex;
  flex-direction: column;
  &__list {
    margin-top: 20px;
    display: flex;
    flex: 1;
    max-height: 350px;
    overflow-y: auto;
    padding-right: 3px;
    &-wrapper {
      width: 100%;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(3, 134px);
      justify-content: space-evenly;
      align-items: baseline;
      .item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: 2px solid transparent;
        &.selected {
          border: 2px solid $color-violet;
        }
        &__img {
          height: 130px;
          width: 130px;
          position: relative;
          background: #e7e7e7;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          &:hover {
            .item {
              &__name {
                transform: scale(1);
              }
            }
          }
        }
        &__name {
          position: absolute;
          z-index: 10;
          text-align: left;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          transform: scale(0);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid #ccc;
          background: #fff;
          padding: 5px;
          transition: all 0.3s ease;
        }
        &.not-match {
          .item {
            &__img {
              overflow: hidden;
              position: relative;
              filter: grayscale(60%);
              &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                z-index: 1;
                top: 0;
                bottom: 0;
                background: rgba(98, 98, 98, 0.6);
              }
              .template-info {
                position: absolute;
                left: 10px;
                top: 10px;
                z-index: 10;
                opacity: 1;
                .icon {
                  width: 20px;
                  height: 20px;
                  background: #fff;
                  border-radius: 2px;
                  display: inline-flex !important;
                  align-items: center;
                  justify-content: center;
                  color: $color-violet;
                }
              }
            }
          }
        }
      }
    }
  }
  &__buttons {
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    .disabled {
      background: #ccc;
    }
  }
}
</style>
