<template>
  <div style="background-color: white">
    <v-row>
      <v-col
        class="palette"
        v-for="(palette, index) in getCurrentCustomer.brand_color_palettes"
        :key="index"
      >
        <div v-for="(color, color_index) in palette" :key="color_index">
          <div
            class="colorItem"
            :style="`;background-color:${color}`"
            @click="$emit('setColor', color)"
          >
            <!-- {{ color }} -->
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ColorSelect",
  data: () => ({
    colors: [],
  }),

  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },

  created() {
    this.colors = this.getCurrentCustomer.brand_color_palettes[0];
  },

  //   props: {
  //     customerId: {
  //       type: string,
  //       mand

  //     },
  //     index: {
  //       type: Number,
  //       default: 0,
};
</script>
<style scoped lang="scss">
.palette {
  padding: 30px;
}

.colorItem {
  height: 30px;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  box-shadow: 1px;
  width: 40px;
  padding: 0px;
  margin: 0px;
}
</style>
>
