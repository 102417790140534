<template>
  <div class="by-client-list" id="by-client-list">
    <Filters :filter="filter" />
    <MediaList
      v-if="mediaFiles.length"
      :isFilterEnabled="isFilterEnabled"
      :mediaFiles="mediaFiles"
      :isLoading="isLoading"
      @updateMediaList="updateMediaList"
      isCanHidden
      @changedHiddenFlag="changedHiddenFlagHandler"
      @tagStatus="tagStatus"
      @loadMoreItems="loadMoreItemsHandler"
      :isCanChangePostOrStoriesMedia="isCanChangePostOrStoriesMedia"
      @updateMediaForMoreInformationStatus="updateMoreInformationStatus"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Filters from "@/components/dashboard/widgets/mediaLibraryWidget/Filters";
import MediaList from "@/components/dashboard/widgets/mediaLibraryWidget/MediaList";

import filterMethods from "@/mixins/filterMethods";

export default {
  name: "Hidden",
  components: {
    MediaList,
    Filters,
  },
  data: () => ({
    filter: {
      notUsed: {
        value: true,
        type: "checkbox",
        label: "Not Used",
        size: 6,
      },
      isFilterImageEnabled: {
        value: true,
        type: "checkbox",
        label: "Filter",
        size: 3,
      },
      sortBy: {
        className: "justify-end",
        size: 6,
        value: 1,
        text: "Date Descending",
        type: "list",
        label: "Sort By:",
        listValues: [
          {
            value: 1,
            text: "Date Descending",
          },
          {
            value: 2,
            text: "Date Ascending",
          },
        ],
      },
    },
    mediaFiles: [],
    lastKey: null,
    selectedFilesID: [],
    selectedMediaFiles: [],
    isLoading: true,
  }),
  mixins: [filterMethods],
  watch: {
    filter: {
      handler() {
        this.lastKey = null;
        this.mediaFiles = [];
        this.getMedia();
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.getMedia();
      },
      deep: true,
    },
    getIsUpdateMedia(val) {
      if (val) {
        this.selectedFilesID = [];
        this.selectedMediaFiles = [];
        this.lastKey = null;
        this.mediaFiles = [];
        this.getMedia();
        this.setIsUpdateMedia(false);
      }
    },
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("mediaLibraryWidget", ["getIsUpdateMedia"]),
    isFilterEnabled() {
      return this.filter && this.filter.isFilterImageEnabled.value;
    },
  },
  props: {
    isCanChangePostOrStoriesMedia: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions("mediaLibraryWidget", ["fetchMediaData"]),
    async getMedia() {
      this.isLoading = true;
      const params = {
        customerId: this.getCurrentCustomer.id,
        type: 5,
        limit: "30",
        startKey: this.lastKey,
        ...this.prepareFilters(),
      };
      const { items, last_key } = await this.fetchMediaData(params);
      this.mediaFiles = [...this.mediaFiles, ...items];
      this.lastKey = last_key;
      this.isLoading = false;
    },
    loadMoreItemsHandler() {
      if (this.lastKey) {
        this.getMedia();
      }
    },
    updateMediaList(e) {
      this.mediaFiles.map((val) => {
        if (val.id === e.id) {
          val.favorite = !val.favorite;
        }
      });
    },
    changedHiddenFlagHandler(mediaFile) {
      this.mediaFiles = this.mediaFiles.filter(
        (file) => file.id !== mediaFile.id
      );
    },
    tagStatus(data) {
      this.mediaFiles.forEach((val) => {
        if (val.id === data.id) {
          switch (data.editTagType) {
            case "client":
              val.tagClient = data.tag.tagClient;
              break;
            case "product":
              val.tagProduct = data.tag.tagProduct;
              break;
            case "description":
              val.tagDescription = data.tag.tagDescription;
              break;
            default:
              val.tagClient = "";
              val.tagProduct = "";
              val.tagDescription = "";
          }
        }
      });
    },
    updateMoreInformationStatus($event) {
      this.mediaFiles.forEach((file) => {
        if (file.id === $event.mediaFile.id) {
          file.canGetMoreInformation = false;
        }
      });
    },
  },
  async created() {
    await this.getMedia();
  },
};
</script>

<style scoped lang="scss">
.active {
  border: 1px solid peru;
}
.by-client-list {
  display: flex;
  flex-direction: column;
}
</style>
