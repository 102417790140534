import { log } from "logrocket";

export default {
  namespaced: true,
  state: {
    activeVideo: null,
    currentRefName: "",
  },
  mutations: {
    setToActiveVideo(state, params = { element: null, refName: "" }) {
      state.activeVideo = params.element;
      state.currentRefName = params.refName;
    },
    resetActiveVideoRef(state) {
      state.currentRefName = "";
    },
  },
  getters: {
    getActiveVideo(state) {
      return state.activeVideo;
    },
    getActiveVideoRefName(state) {
      return state.currentRefName;
    },
  },
};
