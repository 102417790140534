import axios from "axios";
import { DEFAULT_JSON_URL, DATA_PATH } from "@/constants/generatePost";
import Chart from "@/models/jsonConfig/Chart";

export default {
  namespaced: true,
  actions: {
    async fetchDefaultJson({ commit }) {
      try {
        const { data } = await axios.get(DEFAULT_JSON_URL);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async fetchJsonData({ commit }, url) {
      try {
        const { data } = await axios.get(url);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async fetchGeneratedContentFileList({ commit }) {
      try {
        const { data } = await axios.get(
          `${DATA_PATH}/get_generated_content_file_list/`
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    async contentGenerationData({ commit }, params = {}) {
      try {
        const { data } = await axios.post("/copywriter", params);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async fetchChartList({ commit }, params = {}) {
      try {
        const { data } = await axios.post("/copywriter", params);
        return data.map((el) => new Chart(el));
      } catch (e) {
        throw e;
      }
    },
  },
};
