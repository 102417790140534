const surpriseMeButton = {
  name: "Surprise me",
  emitName: "surpriseMe",
  isLoading: false,
};

const subjectIds = {
  tips: {
    surprise: "52a3675c-bdd0-11ed-b618-f20da23890fb",
    seller: "ae9bdc20-be47-11ed-bfe5-f20da23890fb",
    buyer: "c25ce1c8-be74-11ed-bfe5-f20da23890fb",
    general: "2680c828-c171-11ed-82d5-f20da23890fc",
  },
  vocabulary: {
    general: "ada2f06a-be79-11ed-bfe5-f20da23890fb",
    surprise: "ada2f06a-be79-11ed-bfe5-f20da23890fb",
  },
  qa: {
    general: "89183fd6-c174-11ed-82d5-f20da23890fc",
    surprise: "89183fd6-c174-11ed-82d5-f20da23890fc",
  },
  quotes: {
    general: "323eccc8-c194-11ed-82d5-f20da23890fc",
    surprise: "323eccc8-c194-11ed-82d5-f20da23890fc",
  },
  testimonial: {
    general: "6abc8ffc-be76-11ed-bfe5-f20da23890fb",
  },
  listing: {
    general: "f6f91c80-1591-11ed-b236-f20da23890fa",
  },
};

export const MENU_BUTTONS = [
  {
    id: 1,
    name: "Generate content",
    emitName: "generateContent",
    icon: "icon-generate-content",
  },
  {
    id: 2,
    name: "Content calendar",
    emitName: "contentCalendar",
    icon: "icon-calendar-empty",
  },
  { id: 3, name: "Settings", emitName: "settings", icon: "icon-user-avatar" },
];

export const MENU_BUTTONS_IDS = {
  GENERATE_CONTENT: 1,
  CONTENT_CALENDAR: 2,
  SETTINGS: 3,
};

export const GENERATE_CONTENT_BUTTONS = [
  {
    id: 5,
    name: "Custom post",
    subText: "share your idea, get a tailored post",
    emitName: "personalUpdate",
  },
  {
    id: 8,
    name: "Listing",
    emitName: "listing",
    isLoading: false,
  },
  { id: 7, name: "Testimonial", emitName: "testimonial", isLoading: false },
  {
    id: 1,
    name: "Tips",
    emitName: "tips",
    subMenu: [{ ...surpriseMeButton, subjectId: subjectIds.tips.surprise }],
  },
  {
    id: 2,
    name: "Vocabulary",
    emitName: "vocabulary",
    subMenu: [
      { ...surpriseMeButton, subjectId: subjectIds.vocabulary.surprise },
    ],
  },
  {
    id: 3,
    name: "Quote",
    emitName: "quote",
    subMenu: [{ ...surpriseMeButton, subjectId: subjectIds.quotes.surprise }],
  },
  {
    id: 4,
    name: "Questions & Answers",
    emitName: "questions&Answers",
    subMenu: [{ ...surpriseMeButton, subjectId: subjectIds.qa.surprise }],
  },
  {
    id: 6,
    name: "Personal interest",
    emitName: "personalInterest",
    isShowForDemo: true,
  },
];

export const GENERATE_CONTENT_BUTTONS_IDS = {
  CUSTOM_POST_STORY: 5,
  LISTING: 8,
  TESTIMONIAL: 7,
  TIPS: 1,
  VOCABULARY: 2,
  QUOTE: 3,
  QUESTIONS_ANSWERS: 4,
  PERSONAL_INTEREST: 6,
};

export const TIPS_MENU = [
  {
    id: 1,
    title: "Sellers",
    subjectId: subjectIds.tips.seller,
    buttons: [
      { id: 1, name: "Negotiation", isLoading: false },
      {
        id: 2,
        name: "Price your property",
        isLoading: false,
      },
      {
        id: 3,
        name: "Open house prep",
        isLoading: false,
      },
      {
        id: 4,
        name: "Home staging",
        isLoading: false,
      },
    ],
  },
  {
    id: 2,
    title: "Buyers",
    subjectId: subjectIds.tips.buyer,
    buttons: [
      { id: 1, name: "First time buyers", isLoading: false },
      {
        id: 2,
        name: "Credit score",
        isLoading: false,
      },
      {
        id: 3,
        name: "Home Inspection",
        isLoading: false,
      },
      {
        id: 4,
        name: "Negotiation",
        isLoading: false,
      },
    ],
  },
  {
    id: 3,
    title: "General",
    subjectId: subjectIds.tips.general,
    buttons: [{ id: 1, name: "Home decor", isLoading: false }],
  },
];

export const VOCABULARY_BUTTONS = [
  { id: 1, name: "Real estate terms", isLoading: false },
  { id: 2, name: "Industry acronyms", isLoading: false },
  { id: 3, name: "Legal jargon", isLoading: false },
  { id: 4, name: "Mortgage vocabulary", isLoading: false },
  { id: 5, name: "Inspection terminology", isLoading: false },
];

export const QUESTIONS_ANSWERS_BUTTONS = [
  { id: 1, name: "Surprise me", isLoading: false },
  { id: 2, name: "Selling process Q&A", isLoading: false },
  { id: 3, name: "Buying process Q&A", isLoading: false },
  { id: 4, name: "Home inspection Q&A", isLoading: false },
  { id: 5, name: "Mortgage Q&A", isLoading: false },
  { id: 6, name: "Real estate legal Q&A", isLoading: false },
  { id: 7, name: "Real estate investment Q&A", isLoading: false },
  { id: 8, name: "Common mistakes Q&A", isLoading: false },
];

export const QUOTE_BUTTONS = [
  { id: 1, name: "Surprise me", isLoading: false },
  { id: 2, name: "Presidential quotes", isLoading: false },
  { id: 3, name: "Inspirational quotes", isLoading: false },
  { id: 4, name: "Motivational quotes", isLoading: false },
  { id: 5, name: "Humorous quotes", isLoading: false },
  { id: 6, name: "Quotes about success", isLoading: false },
];

export const NAV_MENU = [
  {
    id: 1,
    name: "Cancel scheduling",
    emitName: "cancelScheduling",
    customClass: "red",
  },
  { id: 2, name: "Edit captions", emitName: "editCaption" },
  {
    id: 3,
    name: "Add/remove media",
    emitName: "editMedia",
    isArrowIcon: true,
    isDisabled: false,
  },
  {
    id: 9,
    name: "Edit media",
    emitName: "editTemplate",
    isDisabled: false,
  },
  {
    id: 4,
    name: "Generate new caption",
    emitName: "generateNewCaption",
    isDisabled: false,
    isLoading: false,
  },
  { id: 5, name: "Change scheduled time", emitName: "changeScheduledTime" },
  { id: 6, name: "Split content", emitName: "splitContent" },
  {
    id: 7,
    name: "How to edit/delete a live IG post",
    emitName: "howToEditLiveIgPost",
  },
  {
    id: 8,
    name: "How to edit/delete a live FB post",
    emitName: "howToEditLiveFbPost",
  },
  {
    id: 10,
    name: "Change social accounts",
    emitName: "changeSocialAccounts",
  },
];

export const NAV_MENU_ID = {
  CANCEL_SCHEDULING: 1,
  EDIT_CAPTIONS: 2,
  EDIT_MEDIA: 3,
  GENERATE_NEW_CAPTION: 4,
  CHANGE_SCHEDULED_TIME: 5,
  SPLIT_CONTENT: 6,
  HOW_EDIT_IG_POST: 7,
  HOW_EDIT_FB_POST: 8,
  EDIT_TEMPLATE: 9,
  CHANGE_SOCIAL_ACCOUNTS: 10,
};

export const SUBJECT_IDS = {
  TIPS: {
    GENERAL: subjectIds.tips.general,
    SURPRISE: subjectIds.tips.surprise,
  },
  VOCABULARY: {
    GENERAL: subjectIds.vocabulary.general,
    SURPRISE: subjectIds.vocabulary.surprise,
  },
  QA: {
    GENERAL: subjectIds.qa.general,
    SURPRISE: subjectIds.qa.surprise,
  },
  QUOTE: {
    GENERAL: subjectIds.quotes.general,
    SURPRISE: subjectIds.quotes.surprise,
  },
  TESTIMONIAL: {
    GENERAL: subjectIds.testimonial.general,
  },
  LISTING: {
    GENERAL: subjectIds.listing.general,
  },
};

export const UPDATE_BRANDING_TYPES = {
  NEED_TO_UPDATE_LOGO_AND_COLORS: 1,
  NEED_TO_UPDATE_LOGO: 2,
  NEED_TO_UPDATE_COLORS: 3,
};

export const POP_UP_TEXT =
  "The new post is now being generated. You can check it out in the Pending content section in ~30 seconds.";

export const SUGGESTED_COLORS = [
  {
    id: 1,
    colors: ["#363C46", "#5D6472", "#CFB6A8", "#E0EEE9", "#FFFFFF"],
  },
  {
    id: 2,
    colors: ["#28373E", "#C29F5E", "#3B576B", "#556958", "#292F36"],
  },
  {
    id: 3,
    colors: ["#E0DDDC", "#31373A", "#68C1C4", "#D8483E", "#F4B555"],
  },
  {
    id: 4,
    colors: ["#0D1A35", "#3A4A97", "#BFB189", "#1A1A1A", "#FFFFFF"],
  },
  {
    id: 5,
    colors: ["#1A1A1A", "#81936E", "#BB7E5D", "#EAD1B6", "#EEF3E4"],
  },
  {
    id: 6,
    colors: ["#1C4650", "#869693", "#E8EBEC", "#D8D9D3", "#64656A"],
  },
  {
    id: 7,
    colors: ["#D68248", "#A75B32", "#B1C0CF", "#607896", "#1F3F6B"],
  },
  {
    id: 8,
    colors: ["#9F343E", "#404E48", "#CEC3AF", "#A3A3A3", "#000000"],
  },
  {
    id: 9,
    colors: ["#68832D", "#D3DDA4", "#F3EDE6", "#6E84A2", "#2E435A"],
  },
  {
    id: 10,
    colors: ["#26322C", "#D0AAA9", "#5F756B", "#4C323B", "#F6F0ED"],
  },
  {
    id: 11,
    colors: ["#071B49", "#FCEFF2", "#FFFFFF", "#D7BB84", "#A57F45"],
  },
  {
    id: 12,
    colors: ["#E8CE9B", "#21324B", "#2D4365", "#575757", "#D2D2D2"],
  },
];

export const BROKERAGE_COLORS = [
  {
    id: 1,
    colors: ["#C43D38", "#000000", "#6D6E71", "#A7A9AC", "#FFFFFF"],
  },
  {
    id: 2,
    colors: ["#0C2F67", "#518BD6", "#EAC7A7", "#FFFFFF", "#000000"],
  },
  {
    id: 3,
    colors: ["#404040", "#818183", "#A19272", "#CEC09B", "#FFFFFF"],
  },
  {
    id: 4,
    colors: ["#002349", "#C29B40", "#999999", "#666666", "#FFFFFF"],
  },
  {
    id: 5,
    colors: ["#339933", "#E2E4E5", "#636468", "#9E9FA3", "#000000"],
  },
  {
    id: 6,
    colors: ["#552448", "#EAE3D4", "#CECECE", "#BDBDBD", "#ADADAD"],
  },
  {
    id: 7,
    colors: ["#B40101", "#999999", "#CCCCCC", "#666666", "#000000"],
  },
  {
    id: 8,
    colors: ["#DC1C2E", "#003DA5", "#1A3668", "#C3C6CB", "#FFFFFF"],
  },
  {
    id: 9,
    colors: ["#000000", "#FFFFFF", "#285FE1", "#1B46A5", "#0F3C9E"],
  },
  {
    id: 10,
    colors: ["#19469D", "#F5821F", "#699ABD", "#1B1E2A", "#58595A"],
  },
];
