<template>
  <v-container class="filter-wrapper">
    <v-row no-gutters class="filter" align="center">
      <v-col
        class="d-flex"
        v-for="(filter, index) in filtersData"
        :key="index"
        :sm="filter.size"
        :class="filter.className"
      >
        <div class="form-control search" v-if="filter.type === 'text'">
          <span class="form-control__name">Unsplash search</span>
          <v-text-field
            solo
            label="Search..."
            prepend-inner-icon="icon-search"
          ></v-text-field>
        </div>
        <div class="custom-checkbox" v-if="filter.type === 'checkbox'">
          <input
            v-model="filter.value"
            :id="index"
            class="styled-checkbox"
            type="checkbox"
          />
          <label :for="index">{{ filter.label }}</label>
        </div>
        <div class="select" v-if="filter.type === 'list'">
          <div class="select-name" v-if="filter.label">{{ filter.label }}</div>
          <v-select
            item-value="value"
            item-text="text"
            :items="filter.listValues"
            v-model="filter.value"
            solo
          ></v-select>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Filters",
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    filtersData() {
      return this.filter;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.filter {
  padding: 0 11px;
}
.filter-wrapper {
  padding-top: 5px;
}
.form-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__name {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: $color-black;
    margin-bottom: 4px;
    i {
      font-style: normal;
      font-weight: 400;
    }
  }
  ::v-deep {
    .v-text-field__details {
      display: none;
    }
    .v-input__slot {
      box-shadow: none !important;
      border: 1px solid $color-grey3;
      box-sizing: border-box;
      border-radius: 4px !important;
      min-height: 38px;
      background: #fafafa !important;
    }
    .v-icon {
      font-size: 16px;
    }
    .v-label {
      font-weight: normal;
      font-size: 14px !important;
      color: $color-black !important;
      opacity: 0.4 !important;
      top: calc(50% - 10px) !important;
    }
  }
}
.select {
  display: inline-flex;
  align-items: center;
  .select-name {
    flex-shrink: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    text-align: right;
    letter-spacing: 0.01em;
    color: $color-violet;
    margin-right: 3px;
  }
  ::v-deep {
    .v-text-field__details {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0;
      padding: 0 !important;
      box-shadow: none !important;
    }
    .v-input__control {
      min-height: 15px;
    }
    .v-input {
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      text-align: right;
      letter-spacing: 0.01em;
      color: #2f4858;
      margin-right: -8px;
    }
    .v-select__selection {
      max-width: 100%;
    }
    .v-input__control input {
      display: none;
    }
    .v-input__icon {
      .v-icon {
        color: $color-violet !important;
        font-size: 20px;
      }
    }
  }
}
</style>
