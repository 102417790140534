import axios from "axios";
import Template from "@/models/Template";

export default {
  namespaced: true,
  state: {
    filteredTemplates: [],
    inputParameters: [],
    similarTemplatesList: [],
  },

  mutations: {
    setFilteredTemplates(state, filteredTemplates) {
      state.filteredTemplates = filteredTemplates.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    setInputParameters(state, inputParameters) {
      state.inputParameters = inputParameters;
    },
    setSimilarTemplatesList(state, templates) {
      state.similarTemplatesList = templates;
    },
  },
  getters: {
    getFilteredTemplates(state) {
      return state.filteredTemplates;
    },
    getInputParameters(state) {
      return state.inputParameters;
    },
    getSimilarTemplatesList(state) {
      return state.similarTemplatesList;
    },
  },
  actions: {
    async listTemplates({ commit }, params = {}) {
      try {
        params["task"] = "list_templates";
        const { data } = await axios.post(`/templates`, params);
        const { templates } = data;
        return templates;
      } catch (e) {
        throw e;
      }
    },

    async refreshAllTemplates({ commit }, params = {}) {
      try {
        params["task"] = "refresh_all_templates";
        await axios.post(`/templates`, params);
      } catch (e) {
        throw e;
      }
    },

    async diagestTemplate({ commit }, params = {}) {
      try {
        params["task"] = "diagest_template";

        const { data } = await axios.post(`/templates`, params);
        const { template } = data;
        return template;
      } catch (e) {
        throw e;
      }
    },

    async getTemplate({ commit }, params = {}) {
      try {
        params["task"] = "get_next_template";

        const { data } = await axios.post(`/templates`, params);
        const { template } = data;
        return template;
      } catch (e) {
        throw e;
      }
    },

    async getStockMedia({ commit }, params = {}) {
      try {
        params["task"] = "get_images";
        const { data } = await axios.post(`/stock_media`, params);
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getSmbStockMedia({ commit }, params = {}) {
      try {
        params["task"] = "get_images";
        const { data } = await axios.post(`/stock_media`, params);
        return data;
      } catch (e) {
        throw e;
      }
    },

    async modifyTemplate({ commit }, params = {}) {
      try {
        params["task"] = "modify_template";
        const { data } = await axios.post(`/templates`, params);

        return data;
      } catch (e) {
        throw e;
      }
    },

    async getTemplatesInfo({ commit }, params = {}) {
      try {
        params["task"] = "get_templates";
        const { data } = await axios.post(`/templates`, params);
        const similarTemplates = data.similar_templates.length
          ? data.similar_templates.map((i) => new Template(i))
          : [];
        const template = new Template(data.template);
        return { similarTemplates, template };
      } catch (e) {
        throw e;
      }
    },

    async createImageFromTemplate({ commit }, params = {}) {
      try {
        const { data } = await axios.post(`/templates`, params);
        return data;
      } catch (e) {
        console.error("exception createImageFromTemplate:", e);
        return null;
      }
    },

    async checkVideoIsUpload({ commit }, params) {
      try {
        const { data } = await axios.get(`/media/${params}?type=render`);
        return data;
      } catch (e) {
        console.error("exception createImageFromTemplate:", e);
        return null;
      }
    },
  },
};
