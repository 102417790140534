import { diff } from "deep-object-diff";
export default class Helper {
  static isEmpty(obj, key = "") {
    if (typeof key === "string" && key.length && Object.keys(obj).length) {
      return !Object.prototype.hasOwnProperty.call(obj, key);
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  static sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static isImage(name) {
    if (/images\.unsplash\.com\/photo/i.test(name)) return true;
    return /\.(jpeg|jpe|jpg|gif|png|webp|images\.unsplash\.com)/i.test(name);
  }

  static isVideo(name) {
    return /\.(mov|mp4|avi|webm|wmv|mkv|FLV| F4V|SWF)/i.test(name);
  }

  static getMediaType(name) {
    if (this.isImage(name)) {
      return "image";
    } else if (this.isVideo(name)) {
      return "video";
    } else {
      return undefined;
    }
  }

  static convertBucketUrl(url) {
    let newUrl = url
      .replace(
        "smbdata-development.s3.us-east-1.amazonaws.com",
        "d1entobq4gxou3.cloudfront.net"
      )
      .replace(
        "smbdata-development.s3.amazonaws.com",
        "d1entobq4gxou3.cloudfront.net"
      )
      .replace(
        "smbdata-staging.s3.us-west-2.amazonaws.com",
        "d2uyu36eyyxdji.cloudfront.net"
      )
      .replace(
        "smbdata-staging.s3.amazonaws.com",
        "d2uyu36eyyxdji.cloudfront.net"
      )
      .replace(
        "smbdata-production.s3.us-west-1.amazonaws.com",
        "douzv6rr4lauh.cloudfront.net"
      )
      .replace(
        "smbdata-production.s3.amazonaws.com",
        "douzv6rr4lauh.cloudfront.net"
      );
    return newUrl;
  }

  static getMediaUrlForFile(file) {
    const url = this.selectMediaUrl(file);
    return this.convertBucketUrl(url);
  }

  static selectMediaUrl(file) {
    if (
      file.mediaUrlReduced &&
      file.mediaState["jpg_reduced"] === "done" &&
      file.mediaState["thumbnail"] === "done"
    ) {
      return file.mediaUrlReduced;
    }

    return file.mediaUrl;
  }

  static isObjectsEqual(obj1, obj2) {
    const diffsObj = diff(obj1 || {}, obj2 || {});
    return Object.keys(diffsObj).length === 0;
  }

  static urlsCompose(val) {
    const splitRegex = /[\s,;]+/;
    const words = this.wordSplitter(val, splitRegex, "");
    return words.map((w) => (w.startsWith("http") ? w : "http://" + w));
  }

  static tagsCompose(val) {
    const splitRegex = /[\s,;]+/;
    return this.wordSplitter(val, splitRegex, "");
  }

  static capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static hashtagsCompose(val) {
    const splitRegex = /[\s,;#]+/;
    return this.wordSplitter(val, splitRegex, "#");
  }

  static getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  static getRandomElement(array) {
    const index = this.getRandomInt(array.length);
    return array[index];
  }

  static wordSplitter(val, splitRegex, appendPrefix) {
    if (!val && !val.length) return;
    const wordsList = [];

    val.map((fullString) => {
      fullString
        .trim()
        .split(splitRegex)
        .map((word) => {
          if (!word) {
            return;
          }
          const prefix = word.substr(0, 1) !== appendPrefix ? appendPrefix : "";
          const hashtag = prefix + word;

          if (!wordsList.includes(hashtag)) {
            wordsList.push(hashtag);
          }
        });
    });
    return wordsList;
  }

  static prepareUrl(link, protocol = false) {
    const expression =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/gi;
    const regex = new RegExp(expression);
    let nlink = "";

    if (link.length && link.match(regex)) {
      nlink = link.replace(/^(https?:|)\/\//, "");
      if (protocol) {
        nlink = `https://${nlink}`;
      }
    }
    return nlink;
  }

  static appendFormData(obj) {
    const data = new FormData();
    const includesArray = ["files", "attachments"];
    if (!this.isEmpty(obj)) {
      for (const key in obj) {
        const field = obj[key];
        if (includesArray.includes(key) && field !== null && field.length) {
          for (let i = 0; i < field.length; i++) {
            data.append(`${key}[]`, field[i]);
          }
          continue;
        }
        if (
          typeof field === "object" &&
          field !== null &&
          Object.keys(field).length &&
          !(field instanceof File)
        ) {
          const akey = Object.keys(field);
          akey.forEach((e) => data.append(`${key}[${e}]`, field[e]));
          continue;
        }
        switch (field) {
          case true:
            data.append(key, 1);
            break;
          case false:
            data.append(key, 0);
            break;
          case null:
            break;
          default:
            data.append(key, field);
        }
      }
    }
    return data;
  }
  static formatPhoneNumber(phoneNumberString, isNeedPlusOneInStart = true) {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? `${isNeedPlusOneInStart ? "+1" : ""} ` : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }
}
