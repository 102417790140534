export default {
  namespaced: true,
  state: {
    currentCreationContentType: "post",
  },
  mutations: {
    setCurrentCreationContentType(state, newCreationContentType) {
      state.currentCreationContentType = newCreationContentType;
    },
  },
  getters: {
    getCurrentCreationContentType(state) {
      return state.currentCreationContentType;
    },
  },
};
