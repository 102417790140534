import BaseClass from "@/models/BaseClass";
import H from "@/utils/helper";
import Recommendation from "@/models/Recommendations/Recommendation";

const fields = [{ field: "done", default: false }];

export default class RecommendationResponse extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);

    this.list =
      obj && obj.list && obj.list.length
        ? obj.list.map((recommendation) => new Recommendation(recommendation))
        : [];
  }
}
