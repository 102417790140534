<template>
  <div class="item" v-if="itemData">
    <div class="item__edit">
      <i class="icon-edit" @click="$emit('editNote', itemData)"></i>
    </div>
    <div class="item__top">
      <div class="item__user">
        <div class="photo">
          <i class="icon-user-avatar"></i>
        </div>
        <div class="name">
          {{ itemData && itemData.smmName ? itemData.smmName : "SMM" }}
        </div>
      </div>
      <div class="item__date">{{ dateFormatter(itemData.updated_at) }}</div>
    </div>
    <div
      class="item__text"
      v-if="itemData.comment"
      v-html="itemData.comment.replaceAll('\n', '<br/>')"
    ></div>
    <div class="item__text-toggle"></div>
    <div class="item__attachments" v-if="itemData.files">
      <div class="list" v-if="itemData.files.length">
        <div class="list__icon">
          <i class="icon-attach"></i>
        </div>
        <template v-for="(file, index) in itemData.files">
          <div @click="downloadFile(file)" :key="index" class="list__item">
            {{ getFileName(file) }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "@/mixins/helper";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "ClientNote",
  props: {
    itemData: {
      type: Object,
      default: () => null,
    },
  },
  mixins: [helper],
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  methods: {
    downloadFile(file) {
      axios({
        url: file,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.getFileName(file));
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    dateFormatter(date) {
      return this.$dayjs.unix(date).format("HH:mm DD.MM.YYYY");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba(255, 223, 158, 0.3);
  border: 1px solid rgba(255, 223, 158, 0.2);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px;
  position: relative;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  &__user {
    display: inline-flex;
    align-items: center;
    .photo {
      width: 16px;
      height: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #c4c4c4;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 8px;
      i {
        font-size: 8px;
        color: #4e5778;
      }
    }
    .name {
      font-weight: 600;
      font-size: 11px;
      line-height: 130%;
      color: #1f2325;
    }
  }
  &__date {
    font-weight: 600;
    font-size: 8px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #8f9192;
  }
  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #1f2325;
    pre {
      white-space: pre-wrap;
    }
  }
  &__attachments {
    display: inline-flex;
    width: 100%;
    .list {
      display: inline-flex;
      flex-wrap: wrap;
      &__icon {
        height: 16px;
        margin-right: 6px;
        flex-shrink: 0;
        font-size: 12px;
        color: #1f2325;
        margin-top: 10px;
      }
      &__item {
        background: rgba(230, 157, 91, 0.2);
        border-radius: 4px;
        height: 16px;
        padding: 0 6px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #1f2325;
        margin-right: 6px;
        cursor: pointer;
        max-width: 210px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 10px;
      }
    }
  }
  &__edit {
    transform: scale(0);
    position: absolute;
    right: 10px;
    top: 1px;
    font-size: 12px;
    cursor: pointer;
    color: #1f2325;
    transition: all 0.3s ease;
  }
  &:hover {
    .item {
      &__edit {
        transform: scale(1);
      }
    }
  }
}
</style>
