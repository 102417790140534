<template>
  <div class="media-library">
    <v-tabs
      class="tabs-controls"
      show-arrows
      v-model="tab"
      centered
      align-with-title
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab v-for="item in tabs" :key="item.id">
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabs" :key="item.id">
        <v-card flat>
          <template v-if="item.id === tab">
            <component
              :is="item.component"
              :isCanChangePostOrStoriesMedia="isCanChangePostOrStoriesMedia"
            ></component>
          </template>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ByClient from "@/components/dashboard/widgets/mediaLibraryWidget/tabs/ByClient";
import Uploads from "@/components/dashboard/widgets/mediaLibraryWidget/tabs/Uploads";
import IGProfile from "@/components/dashboard/widgets/mediaLibraryWidget/tabs/IGProfile";
import FBProfile from "@/components/dashboard/widgets/mediaLibraryWidget/tabs/FBProfile";
import Favorites from "@/components/dashboard/widgets/mediaLibraryWidget/tabs/Favorites";
import Hidden from "@/components/dashboard/widgets/mediaLibraryWidget/tabs/Hidden";

export default {
  name: "MediaLibrary",
  data() {
    return {
      tab: 0,
      tabs: [
        {
          id: 0,
          title: "By Client",
          component: ByClient,
        },
        {
          id: 1,
          title: "Uploads",
          component: Uploads,
        },
        {
          id: 2,
          title: "IG Profile",
          component: IGProfile,
        },
        {
          id: 3,
          title: "FB Profile",
          component: FBProfile,
        },
        {
          id: 4,
          title: "Favorites",
          component: Favorites,
        },
        {
          id: 5,
          title: "Hidden",
          component: Hidden,
        },
      ],
    };
  },
  props: {
    isCanChangePostOrStoriesMedia: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.media-library {
  height: calc(100vh - 65px);
  .tabs-controls {
    .v-tabs-bar {
      height: 35px;
    }

    .v-slide-group {
      .v-slide-group__content {
        justify-content: space-between;
        padding: 0 24px;
        margin-bottom: 16px;

        .v-tabs-slider-wrapper {
          display: none !important;
        }

        & > *:last-child {
          margin-right: inherit !important;
        }

        .v-tab {
          margin-left: 0 !important;
          display: inline-flex;
          width: 100%;
          border-radius: 4px 4px 0px 0px;
          height: 28px;
          align-items: center;
          font-family: $base-font;
          letter-spacing: inherit !important;
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 140% !important;
          text-transform: none !important;
          color: rgba(31, 35, 37, 0.2) !important;
          border-bottom: 1px solid rgba(81, 84, 86, 0.4);
          background: transparent;
          transition: all 0.3s ease;
          margin-right: 8px;
          white-space: nowrap;
          &:last-child {
            margin-right: 0;
          }

          &--active {
            color: $color-violet !important;
            background: rgba(126, 86, 137, 0.1);
            border-bottom: 1px solid $color-violet;
          }
        }
      }
    }
  }
  .v-tabs-items {
    max-height: calc(100vh - 130px);
  }
}
</style>
