import BaseClass from "@/models/BaseClass";

const fields = [
  "topic",
  "id",
  { field: "posts_per_month", default: 0 },
  { field: "occurrence", default: 0 },
  { field: "is_selected", default: true },
  { field: "is_changed", default: false },
];

export default class ContentMixture extends BaseClass {
  constructor(obj = {}) {
    super(obj, fields);

    this.is_changed = false;
  }
}
