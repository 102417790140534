<template>
  <div class="template-media">
    <v-tabs v-model="tab" background-color="transparent" color="basil">
      <v-tab v-for="item in tabs" :key="item.id">
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabs" :key="item.id">
        <v-card color="basil" flat>
          <div class="media-wrapper">
            <template v-show="item.id === tab">
              <component
                :calendarMediaItems="calendarMediaItems"
                :mediaRatio="mediaRatio"
                :templateId="templateId"
                @changeImage="$emit('changeImage', $event)"
                :is="item.component"
              ></component>
            </template>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TemplateMediaLibrary from "@/components/templatesModal/TemplateMediaLibrary";
import StockMedia from "@/components/templatesModal/StockMedia";
import TemplateRelatedMedia from "@/components/templatesModal/TemplateRelatedMedia";

export default {
  name: "TemplatesMediaWidget",
  components: { TemplateMediaLibrary, StockMedia, TemplateRelatedMedia },
  props: {
    calendarMediaItems: {
      type: Array,
      default: () => [],
    },
    mediaRatio: {
      type: String,
      default: "",
    },
    templateId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          id: 0,
          title: "Medialibrary",
          component: TemplateMediaLibrary,
        },
        {
          id: 1,
          title: "Stock Photos",
          component: StockMedia,
        },
      ],
    };
  },
  beforeMount() {
    if (this.calendarMediaItems.length) {
      this.setRelatedMediaTab();
    }
  },
  methods: {
    setRelatedMediaTab() {
      this.tabs = this.tabs.map((item) => {
        return {
          id: item.id + 1,
          title: item.title,
          component: item.component,
        };
      });
      this.tabs.unshift({
        id: 0,
        title: "Related media",
        component: TemplateRelatedMedia,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.media-wrapper {
  width: 100%;
  padding-top: 20px;
  min-height: 300px;
}
.template-media {
  .v-tab {
    font-size: 13px !important;
  }
}
</style>
