<template>
  <div class="header" :class="{ fixed: $route.name === 'smb-feedback' }">
    <div class="wrapper">
      <div class="cell">
        <div class="logo">
          <img class="logo__img" src="@/assets/img/logo_full.svg" />
        </div>
      </div>
      <div class="cell"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 12px;
  background: $color-white;
  @media screen and (min-width: 760px) {
    display: none !important;
  }
  &.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-title {
    display: none;
  }

  .logo {
    flex-shrink: 0;
    width: 100%;
    max-width: 180px;
    display: inline-flex;

    &__img {
      width: 100%;
    }
  }
}
</style>
